<div class="modal-header">
    <div class="modal-title">{{'ORGANIZATION.MODAL_TITLE'| translate}}</div>
    <button (click)="onClose()" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="newOrganisationForm">
    <div class="modal-body">

        <!-- New organization name-->
        <div class="form-row">
            <app-input-text [errors]="{
            required: 'ORGANIZATION.ERROR_NEW_NAME' | translate}"
                [hasOnInput]="true" [idPostfix]="'newOrganisationName'"
                [label]="'ORGANIZATION.NAME' | translate"
                [placeholder]="'ORGANIZATION.PLACEHOLDER_NEW_NAME' | translate" class="col"
                formControlName="organizationName" icon="user" inputTextPosition="right" type="text">
            </app-input-text>
        </div>
         <!-- New organization Email-->
        <div class="form-row">
            <app-input-text [errors]="{required: 'ORGANIZATION.ERROR_NEW_NAME' | translate}"
                [hasOnInput]="true" [idPostfix]="'newOrganisationEmail'"
                [label]="'ORGANIZATION.EMAIL' | translate"
                [placeholder]="'ORGANIZATION.PLACEHOLDER_NEW_EMAIL' | translate" class="col"
                formControlName="organizationEmail" icon="far fa-fw fa-envelope" inputTextPosition="right" type="text">
            </app-input-text>
        </div>
        <span *ngIf="hasEmailError()" class="text-danger">
          {{'ORGANIZATION.ERROR_PATTERN_EMAIL' | translate}}
        </span>
    </div>

    <div class="modal-footer">
        <button id="create-new-org-button" class="btn btn-primary" type="submit" (click)="onSubmit()" [disabled]="newOrganisationForm.invalid">
            {{'ORGANIZATION.CREATE' | translate}}
        </button>
    </div>
</form>
