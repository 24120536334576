import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from 'luxtrust-cosi-api/api/authentication.service';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {ConfigData} from 'luxtrust-cosi-api/model/configData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import * as moment from 'moment';
import 'moment-timezone';
import {fromEvent, merge, BehaviorSubject} from 'rxjs';
import {auditTime, filter, take} from 'rxjs/operators';
import {TranslateService} from 'src/app/core/service/translate.service';
import {EntitlementsEnum} from 'src/app/services/enum/entitlementsList';
import {environment} from '../../../environments/environment';
import {AccessToken} from '../model/access-token.model';
import {AlertService} from './alert-service';
import {JwtService} from './jwt.service';

@Injectable()
export class AppService {
  config: ConfigData;
  readonly tokenName: string = 'default-token';
  readonly IS_AUTHENTICATED_KEY: string = 'is-authenticated';
  private user$ = new BehaviorSubject<EnduserData>(undefined);
  private revokeHandler;
  private refreshHandler;
  public ent = EntitlementsEnum;
  private readonly defaultInactivityTimeInMin: number = 10;
  private inactivityTimeInMin: number;
  private inactivityTimeInMs: number;
  public numberOfRequest = 0;

  public currentEnduser: EnduserData; // fixme: to be fixed by Intech, to be removed (ask Bob first)

  constructor(private translateService: TranslateService,
              private router: Router,
              private enduserService: EnduserService,
              private authenticationService: AuthenticationService,
              private jwtService: JwtService,
              private alertService: AlertService,
              private modalService: NgbModal,
              private ngZone: NgZone
  ) {
    if (this.isIframe()) {
      window.onmessage = (function (event) {
        const styleNode = document.createElement('style');
        styleNode.innerHTML = event.data.css.replace(/(<[^>]+>)/ig, '');
        document.body.appendChild(styleNode);
      }).bind(this);
    }

  }

  initHandlers(configTimeoutInMinutes: number) {
    this.inactivityTimeInMin = configTimeoutInMinutes ? +configTimeoutInMinutes : this.defaultInactivityTimeInMin;
    this.inactivityTimeInMs = this.inactivityTimeInMin * 60 * 1000;

    merge(fromEvent(document, 'keypress'), // keyboard user activities
      fromEvent(document, 'click'), // mouse click user activities
      fromEvent(document, 'mousemove'), // mouse move user activities
      fromEvent(document, 'touchmove'), // touchscreen move user activities
      fromEvent(document, 'touchstart') // touchscreen tap user activities
    ).pipe(filter(() => this.isLoggedIn()), // don't update inactivity logout time if current token is invalid
      auditTime(1000)) // don't calculate inactivity logout time more than each second
      .subscribe(() => this.prepareRevokeHandler()); // actually update inactivity logout time
    this.prepareRevokeHandler();

    const jwt = this.get();
    if (jwt && jwt.exp * 1000 > Date.now()) {
      this.refresh(jwt.raw);
    }
  }

  removeTokenFromStorage() {
    sessionStorage.removeItem(this.IS_AUTHENTICATED_KEY);
    sessionStorage.removeItem(environment.TOKEN_NAME);
  }

  getUser = () => this.user$.getValue();
  getUser$ = () => this.user$.asObservable();

  userHasAdminEntitlement(): boolean {
    const user = this.getUser();
    return user && user.entitlementNames && user.entitlementNames.some(e =>
      e === this.ent.admin ||
      e === this.ent.manage_metadata ||
      e === this.ent.manage_notifications ||
      e === this.ent.manage_legal_notices ||
      e === this.ent.manage_layout ||
      e === this.ent.manage_translations ||
      e === this.ent.manage_entitlements ||
      e === this.ent.manage_circles ||
      e === this.ent.manage_users ||
      e === this.ent.session_translation ||
      e === this.ent.manage_directories ||
      e === this.ent.manage_documentation
    );
  }

  userHasEntitlement(ent: EntitlementsEnum): boolean {
    const user = this.getUser();
    return user && user.entitlementNames && user.entitlementNames.some(e => e === ent);
  }

  isIframe(): boolean {
    return window.top !== window;
  }

  loadEnduser(): Promise<EnduserData> {
    if (this.get()) {
      return this.enduserService.getCurrentEnduser(moment.tz.guess(true)).toPromise()
        .then(enduser => {
          this.translateService.use(enduser.localeCode.toLowerCase());
          this.user$.next(enduser);
          this.currentEnduser = enduser;  // fixme: to be fixed by Intech, to be removed (ask Bob first)
          return enduser;
        }, () => {
          this.logout();
          return undefined;
        });
    }
    return Promise.resolve(undefined);
  }

  updateUser(user: EnduserData) {
    this.user$.next(user);
    this.currentEnduser = user;
  }

  logout() {
    sessionStorage.clear();
    // emit event to footer for hide component
    this.user$.next(undefined);
  }

  validateToken(rawToken: string) {
    const token = this.jwtService.decodeAndValidateJwt(rawToken);
    sessionStorage.setItem(environment.TOKEN_NAME, JSON.stringify(token));
    this.setIsAuthenticated('true');
  }

  refresh(rawToken: string, prepareRevoke = true) {
    this.validateToken(rawToken);
    this.prepareRefreshHandler();
    if (prepareRevoke) {
      this.prepareRevokeHandler();
    }
  }

  revoke(): Promise<any> {
    return this.authenticationService.revokeJwt().toPromise()
      .then(() => this.removeTokenFromStorage())
      .catch(() => this.removeTokenFromStorage());
  }

  get(bypassCheck = false): AccessToken {
    const isAuthenticated = (sessionStorage.getItem(this.IS_AUTHENTICATED_KEY) === 'true');
    const token: string = sessionStorage.getItem(environment.TOKEN_NAME);
    return (bypassCheck || isAuthenticated) && token && JSON.parse(token);
  }

  getCurrentUserId(bypassCheck = false): any {
    const token = this.get(bypassCheck);
    return token && environment.SUB_FORMATTER(token.sub);
  }

  getRaw(bypassCheck = false): string {
    const jwt = this.get(bypassCheck);
    return jwt && jwt.raw;
  }

  isLoggedIn(bypassCheck = false): boolean {
    return !!this.get(bypassCheck);
  }

  public setIsAuthenticated(isAuthenticated: string) {
    sessionStorage.setItem(this.IS_AUTHENTICATED_KEY, isAuthenticated);
  }

  private prepareRefreshHandler() {
    if (this.refreshHandler) {
      clearTimeout(this.refreshHandler);
    }
    this.ngZone.runOutsideAngular(() => {
      this.refreshHandler = setTimeout(() => {
        this.ngZone.run(() => {
          this.refreshHandler = undefined;
          this.authenticationService.refreshJwt().pipe(take(1)).subscribe(
            (token: any) => this.refresh(token.access_token, false),
            () => this.revoke()
          );
        });
      }, 60 * 5 * 1000);
    });
  }

  private prepareRevokeHandler() {
    if (this.revokeHandler) {
      clearTimeout(this.revokeHandler);
    }
    this.ngZone.runOutsideAngular(() => {
      this.revokeHandler = setTimeout(() => {
        this.ngZone.run(() => {
          this.revokeHandler = undefined;
          const tenantName = this.getUser().tenantName;
          this.revoke().then(() => {
            this.modalService.dismissAll();
            sessionStorage.clear();
            this.alertService.info('ALERT.LOGOUT', {minutes: this.inactivityTimeInMin});
            this.router.navigateByUrl(`/login?tenantName=${tenantName}`);
          });
        });
      }, this.inactivityTimeInMs);
    });
  }



}
