import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import {StepService, TenantService} from 'luxtrust-cosi-api';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {SessionTemplateService} from 'luxtrust-cosi-api/api/sessionTemplate.service';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {CONFIG, KEYBOARD} from 'src/app/app.constant';
import {AppService} from 'src/app/services/services/app.service';
import {ConfigurationService} from 'src/app/services/services/configuration.service';
import {ValidatorHelper} from 'src/app/services/validators/validators.helper';
import {ApiError} from '../../error/api-error.model';
import {AlertService} from '../../services/services/alert-service';
import {IEService} from '../../services/services/ie.service';
import {ModalConfiguratorComponent} from '../../session/step/components/wizard-step-configurator/modal-configurator/modal-configurator.component';

@Component({
  templateUrl: './modal-template-session.component.html', styleUrls: ['./modal-template-session.component.scss']
})
export class ModalTemplateSessionComponent implements OnInit {

  private readonly SEARCH_INPUT_PLACEHOLDER = 'MODAL_TEMPLATE_SESSION.FILTER_SESSION_TEMPLATE';

  @ViewChild('startAutoSwitch', { static: false }) startAutoSwitch;
  @ViewChild('template', { static: false }) template;
  @ViewChild('sessionName', { static: false }) sessionName;
  @ViewChild('sessionNameInput', { static: false }) sessionNameInput;
  disableCreate = false;
  templates: SessionData[];
  selectedTemplate: SessionData;
  value = '';
  newSessionForm: FormGroup;
  selectedTemplateControl: FormControl;
  sessionNameControl: FormControl;
  defaultStartAutoSwitcherValue = true;
  selectedTemplateConfiguratorEnabled = true;
  searchInputPlaceholder: string;

  constructor(public activeModal: NgbActiveModal,
              private readonly ieService: IEService,
              private readonly translate: TranslateService,
              private readonly templateService: SessionTemplateService,
              private readonly sessionService: SessionService,
              private readonly stepService: StepService,
              private readonly alertService: AlertService,
              private readonly configService: ConfigurationService,
              private readonly tenantService: TenantService,
              private readonly appService: AppService,
              private readonly modal: NgbModal,
              private readonly formBuilder: FormBuilder
  ) {
    this.loadTemplates().then(() => {
      setTimeout(() => {
        this.template.nativeElement.focus();
      });
    });
  }

  ngOnInit() {
    this.initForm();
    this.searchInputPlaceholder = this.translate.instant(this.SEARCH_INPUT_PLACEHOLDER);
    this.tenantService.getTenantConfig(this.appService.getUser().tenantName).toPromise().then(tenantConfig => {
      this.defaultStartAutoSwitcherValue = tenantConfig[CONFIG.TENANT.AUTH.AUTO_START_KEY] === '1';
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  private initForm() {
    this.selectedTemplateControl = this.formBuilder.control('', [Validators.required]);
    this.sessionNameControl = this.formBuilder.control('', [Validators.required, Validators.maxLength(255), ValidatorHelper.noWhitespace]);
    this.newSessionForm = this.formBuilder.group({
      selectedTemplateControl: this.selectedTemplateControl,
      sessionNameControl: this.sessionNameControl
    });
    this.selectedTemplateControl.valueChanges.subscribe((newValue: string) => {
      if (newValue || this.selectedTemplate) {
        this.searchInputPlaceholder = '';
      } else if (this.template.nativeElement.value.length === 0) {
        this.searchInputPlaceholder = this.translate.instant(this.SEARCH_INPUT_PLACEHOLDER);
      }
    });
  }

  private loadTemplates() {
    return this.templateService.getTemplateList().toPromise().then((templates: SessionData[]) => {
      if (templates.length !== 0) {
        this.templates = templates;
      }
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  private openConfiguratorModal(value) {
    const modalConfig = this.modal.open(ModalConfiguratorComponent, { backdrop: false, size: 'lg' });
    modalConfig.componentInstance.session = value.session;
    modalConfig.result.then(() => {
      this.activeModal.close();
    });
  }

  create() {
    this.disableCreate = true;
    this.sessionService.createSession({
      label: this.sessionNameControl.value.trim(), templateId: this.selectedTemplate.id, simple: true
    }).pipe(mergeMap((sessionData: SessionData) => {
      if (this.startAutoSwitch && this.startAutoSwitch.nativeElement.checked) {
        if (!this.selectedTemplateConfiguratorEnabled) {
          return this.sessionService.startSession(sessionData.id)
            .pipe(catchError((error: ApiError) => {
              this.alertService.errorApiWithCustomMessage('MODAL_TEMPLATE_SESSION.START_SESSION_FAILED', error, { label: this.selectedTemplate.label });
              return of({ value: true });
            }));
        }
      }
      return of({
        session: sessionData,
        value: false
      });

    }), catchError((error: ApiError) => {
      this.disableCreate = false;
      this.alertService.errorApiWithCustomMessage('MODAL_TEMPLATE_SESSION.CREATE_SESSION_FAILED', error, { label: this.selectedTemplate.label });
      return of({ value: true });
    }))
      .subscribe((value) => {
        if (value == undefined || !value.value) {
          this.alertService.success('MODAL_TEMPLATE_SESSION.CREATE_SESSION_SUCCESSFUL');
          if (this.selectedTemplateConfiguratorEnabled) {
            this.openConfiguratorModal(value);
          } else {
            this.activeModal.close(true);
          }
        } else {
          this.disableCreate = false;
          this.activeModal.dismiss('error');
        }
      });
  }

  selectTemplate(selected, templateData: SessionData) {
    if (selected.isUserInput) {
      this.selectedTemplate = templateData;
      this.selectedTemplateConfiguratorEnabled = templateData.configuration;
      this.sessionNameControl.setValue(templateData.label);
      this.searchInputPlaceholder = '';
      this.selectedTemplateControl.setValue(templateData.label);
      if (this.ieService.checkIfIE()) {
        setTimeout(() => this.sessionNameInput.nativeElement.focus(), 1000);
      } else {
        this.sessionName.nativeElement.click();
      }
      if (this.startAutoSwitch) {
        this.startAutoSwitch.nativeElement.checked = this.defaultStartAutoSwitcherValue;
      }
    }
  }

  clearInput() {
    if (this.template.nativeElement.value.length > 0) {
      this.selectedTemplateControl.setValue('');
      this.selectedTemplate = undefined;
      this.searchInputPlaceholder = this.translate.instant(this.SEARCH_INPUT_PLACEHOLDER);
    }
  }

  isCreateButtonCurrentlyDisabled() {
    return this.disableCreate
      || this.templates && this.templates.length === 0
      || !this.selectedTemplate
      || this.sessionNameControl.invalid;
  }

  onSessionNameInputKeyUp(event) {
    if (event.keyCode === KEYBOARD.ENTER && this.sessionNameControl.pristine) {
      this.sessionNameControl.markAsDirty();
      this.sessionNameControl.markAllAsTouched();
      return;
    }
    if (event.keyCode === KEYBOARD.ENTER && this.sessionNameControl.dirty && this.sessionNameControl.valid) {
      this.create();
    }
  }

  onOptionClick(event) {
    if (event.isTrusted) {
      this.sessionNameControl.markAsDirty();
      this.sessionNameControl.markAllAsTouched();
    }
  }

}
