<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title | translate}}</h4>
</div>

<div class="modal-body">

  <div class="form-group">
    <label for="commentModalTextarea">{{label | translate}}</label>
    <textarea
      [formControl]="comment"
      class="form-control fixed-textarea"
      id="commentModalTextarea"
      maxlength="255"
      rows="5"
    >
    </textarea>
  </div>
</div>

<div class="modal-footer">
  <button (click)=" handleCancel()" class="btn btn-light mr-1"
          type="button"> {{close | translate}} </button>
  <button
    (click)="handleAction()"
    [ngClass]="{ 'disabled' : !comment.value.trim()}"
    class="btn btn-primary mr-1"
    type="button"
  >{{action | translate}}
  </button>
</div>
