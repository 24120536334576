import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganisationData } from 'luxtrust-cosi-api';

@Component({
  selector: 'app-organisations-item',
  templateUrl: './organisations-item.component.html',
  styleUrls: ['./organisations-item.component.scss']
})
export class OrganisationsItemComponent implements OnInit {

  @Input() organisation: OrganisationData; 
  @Output() delete = new EventEmitter<OrganisationData>();

  displayIconUser = false;

  constructor() { }

  ngOnInit() {
  }

  deleteOrganisation(){
    this.delete.emit(this.organisation);
  }
}
