import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {CircleData, CircleOfEndusersService, EnduserData, UpdateCirclePayload} from '../../../../../luxtrust-cosi-api';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {REGEXES} from "../../../app.constant";

@Component({
  selector: 'app-modal-is-ie',
  templateUrl: './modal-is-ie.component.html',
  styleUrls: ['./modal-is-ie.component.scss']
})
export class ModalIsIeComponent {
  constructor(public activeModal: NgbActiveModal) {
  }

}
