<div #header class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title | translate}}</h4>
    <button (click)="activeModal.dismiss()" class="close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body break-words">
    {{message | translate:{tagName: name} }}
  </div>
  
  <div class="modal-footer">
    <button (click)="delete()" class="btn btn-primary"
            type="button">{{'MODAL_DELETE_SESSION.DELETE' | translate}}</button>
    <button (click)="activeModal.close()" class="btn btn-cancel"
            type="button">{{'MODAL_DELETE_SESSION.CLOSE' | translate}}</button>
  </div>
  