export const defaultConfig = {
  manager: {
    users: {
      replace: true,
      add: true,
      clone: true
    },
    watchers: {
      replace: false,
      add: false,
      clone: false
    },
    documents: {
      replace: true,
      add: true,
      delete: true,
      clone: true,
      sprofile: true
    },
    metadata: {
      update: true
    }
  },
  signer: {
    users: {
      replace: true,
      add: true,
      clone: true
    },
    watchers: {
      replace: false,
      add: false,
      clone: false
    },
    documents: {
      replace: true,
      add: true,
      delete: true,
      clone: true,
      sprofile: true
    },
    metadata: {
      update: true
    }
  }
};
