<div class="modal-header">
  <label class="modal-title" center id="modal-basic-title" >
    {{message | translate}}
  </label>
</div>

<div class="modal-footer">
  <div class="text-center mb-3">
    <button id="modal-yes-id" (click)="close('yes')" class="btn mr-3" [ngClass]="yesIsDanger ? 'btn-danger' : 'btn-primary'" type="button">
      {{'MODAL_YES_NO.YES' | translate}}
    </button>
    <button id="modal-no-id" (click)="close('no')" class="btn mr-3" [ngClass]="noIsDanger ? 'btn-danger' : 'btn-primary'" type="button">
      {{'MODAL_YES_NO.NO' | translate}}
    </button>
  </div>
</div>
