import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TagData} from 'luxtrust-cosi-api';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {StepRuleOrderService} from 'luxtrust-cosi-api/api/stepRuleOrder.service';
import {ConfigurationData} from 'luxtrust-cosi-api/model/configurationData';
import {CreateEnduserPayload} from 'luxtrust-cosi-api/model/createEnduserPayload';
import {CreateStepEnduserPayload} from 'luxtrust-cosi-api/model/createStepEnduserPayload';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {StepTag} from 'src/app/services/enum/step-tag';
import {ApiError} from '../../../../../../error/api-error.model';
import {AlertService} from '../../../../../../services/services/alert-service';
import {AppService} from '../../../../../../services/services/app.service';
import {Status} from '../../../../../../shared/components/input-loader/input-loader.component';
import {StepEnduserComponent} from '../../../../model/stepEnduser.component';
import {wizardStepConfiguratorEnum} from '../../wizardStepConfigurator.enum';
import {ModalNewSignerComponent} from '../../../../../../shared/components/modal-new-signer/modal-new-signer.component';

@Component({
  selector: 'app-modal-configurator-signers',
  templateUrl: './modal-configurator-signers.component.html',
  styleUrls: ['./modal-configurator-signers.component.scss']
})
export class ModalConfiguratorSignersComponent extends StepEnduserComponent implements OnInit, OnChanges {

  @Input() manager: boolean;
  @Input() signer: boolean;
  @Input() configStep: ConfigurationData;
  @Input() page: number;
  @Input() stepTagList: TagData[];
  @Output() outputAddUser: EventEmitter<StepEnduserData> = new EventEmitter<StepEnduserData>();
  @Output() outputDeleteUser: EventEmitter<number> = new EventEmitter<number>();
  @Output() outputOrderSigner: EventEmitter<EnduserData[]> = new EventEmitter<EnduserData[]>();
  @Output() outputUpdateSigner: EventEmitter<{ expected: number, idCircle: number }> = new EventEmitter<{ expected: number, idCircle: number }>();
  replaceAloneSigner = false;
  enableInviteNewSigner = true;
  loaderStatusWatcher: Status;
  availableWatcher: EnduserData[];

  addingUSer = false;
  removingUser = false;
  orderingUser = false;
  addingWatcher = false;
  removingWatcher = false;

  replaceEnduserId: number;
  replaceMode: boolean;

  constructor(private stepService: StepService,
              protected appService: AppService,
              private stepRuleOrderService: StepRuleOrderService,
              protected stepEnduserService: StepEnduserService,
              protected modalService: NgbModal,
              protected alertService: AlertService,
              protected enduserService: EnduserService) {
    super(modalService, appService, stepEnduserService, alertService, enduserService);
  }

  ngOnInit(): void {
    this.getStepEndusers();
    this.replaceAloneSigner = this.canReplace() && !this.canAdd() && this.stepEndusers.filter(enduser => enduser.signer).length === 1
      && this.stepEndusers.filter(enduser => enduser.signer)[0].enduser.id !== this.appService.getUser().id;
    this.checkStepTag();
  }

  checkStepTag() {
    this.enableInviteNewSigner = !this.stepTagList.some(tag => tag.alias === StepTag.INVITE_BUTTON_DISABLE);
  }

  getStepEndusers() {
    this.stepEndusers = this.stepEndusers.sort((a, b) => a.orderIndex - b.orderIndex);
    this.getSelectedUsers();
    this.getSelectedWatcher();
    this.getSelectedManager();
  }

  addUsers(user: EnduserData) {
    this.loaderStatusUsers = Status.LOAD;
    let createStepEnduserPayload: CreateStepEnduserPayload;
    let createEndUserpayload: CreateEnduserPayload;
    if (user.id) {
      createStepEnduserPayload = {
        enduserId: user.id, signer: true, manager: user.id === this.appService.getCurrentUserId()
      };
    } else {
      createEndUserpayload = {
        userId: user.email,
        email: user.email,
        lastName: user.lastName,
        firstName: user.firstName,
        phone: user.phone,
        directoryAlias: user.directoryAlias,
        organisationId: this.appService.getUser().organisation.organisationId
      };
      createStepEnduserPayload = {
        createEnduserPayload: createEndUserpayload, signer: true
      };
    }
    if (this.canAdd()) {
      this.addingUSer = true;
      this.stepEnduserService.createStepEnduser(this.sessionId, this.step.id, createStepEnduserPayload).toPromise()
        .then((result) => {
          this.selectedUsers.push(result.enduser);
          this.outputAddUser.emit(result);
          this.loaderStatusUsers = Status.SUCCESS;
        }, (error: ApiError) => {
          this.loaderStatusUsers = Status.ERROR;
          this.alertService.errorApi(error);
        })
        .then(value => this.addingUSer = false);
    } else {
      this.alertService.error('MODAL_CONFIGURATOR.ERROR_CANNOT_REPLACE');
    }
  }

  openModal(replace?, oldEnduserId?: number ) {
    this.replaceEnduserId = oldEnduserId;
    const modalRef = this.modalService.open(ModalNewSignerComponent, {backdrop:false});
    (<ModalNewSignerComponent>modalRef.componentInstance).step = this.step;
    modalRef.componentInstance.newSignerModalResult.subscribe(result => {
      if (replace) {
        this.onReplaceUsers(oldEnduserId, result, this.selectedUsers, () => {
          this.replaceEnduserId = undefined;
          this.replaceMode = this.replaceAloneSigner;
        });
      } else {
        this.addUsers(result);
      }
    });
  }

  onUpdateExpected(event) {
    const circle = event.user;
    const nb = event.nb;
    this.stepEnduserService.partialUpdateStepEnduser(circle.id, this.sessionId, this.step.id, {
      signer: true,
      expected: nb
    }).subscribe(() => {
      this.outputUpdateSigner.emit({expected: parseInt(nb), idCircle: circle.id});
    });
  }

  orderUsers(users: EnduserData[], stepId) {
    this.orderingUser = true;
    this.loaderStatusUsers = Status.LOAD;
    this.stepRuleOrderService.updateStepEnduserOrder(this.sessionId, stepId, {
      orders: users.map((u, i) => ({
        enduserId: u.id, orderIndex: i
      }))
    }).toPromise().then(() => {
      this.outputOrderSigner.emit();
      this.loaderStatusUsers = Status.SUCCESS;
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      this.loaderStatusUsers = Status.ERROR;
    })
      .then(value => this.orderingUser = false);
  }

  unsortUsers(users: EnduserData[], stepId) {
    this.loaderStatusUsers = Status.LOAD;
    this.orderingUser = true;
    this.stepRuleOrderService.updateStepEnduserOrder(this.sessionId, stepId, {
      orders: users.map(u => ({
        enduserId: u.id, orderIndex: 0
      }))
    }).toPromise().then(() => {
      this.loaderStatusUsers = Status.SUCCESS;
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      this.loaderStatusUsers = Status.ERROR;
    }).then(value => this.orderingUser = false);
  }

  removeUsers(users: EnduserData, stepId) {
    this.removingUser = true;
    if (users.id === this.appService.getCurrentUserId()) {
      const createStepEnduserPayload: CreateStepEnduserPayload = {
        enduserId: users.id, signer: false
      };
      this.stepEnduserService.createStepEnduser(this.sessionId, stepId, createStepEnduserPayload).toPromise()
        .catch((error: ApiError) => this.alertService.errorApi(error))
        .then(value => this.removingUser = false);
      this.removeIntoSelectedUsers(users);
    } else {
      // normal case - related to quick fix
      this.stepEnduserService.deleteStepEnduser(users.id, this.sessionId, stepId).toPromise().then(() => {
        this.removeIntoSelectedUsers(users);
      }, (error: ApiError) => this.alertService.errorApi(error))
        .then(value => this.removingUser = false);
    }
  }

  private removeIntoSelectedUsers(users: EnduserData) {
    const index: number = this.selectedUsers.findIndex((item: EnduserData) => item.id === users.id);
    if (index > -1) {
      this.selectedUsers.splice(index, 1);
      this.outputDeleteUser.emit(users.id);
    }
  }

  private can(action: wizardStepConfiguratorEnum) {
    return !!(
      this.manager
      &&
      this.configStep.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.USER].configurationMap[action]
      ||
      (
        this.signer
        &&
        this.configStep.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.USER].configurationMap[action]
      ));

  }

  canAdd(): boolean {
    return this.can(wizardStepConfiguratorEnum.ADD);
  }

  // TODO : Feature to add
  canClone() {
    return true;
  }

  canReplace() {
    return this.can(wizardStepConfiguratorEnum.REPLACE);
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  detectSortMode(sort: boolean) {
    this.sortMode.emit(sort);
  }
}
