<span *ngIf="!isConfigurator && isSignerManagement" (click)="onOpenModal()" class="create-new-signer-link">
          {{'NEW_SESSION.SIGNERS.NEW_SIGNERS.ADD' | translate}}
      </span>

<div [class.shifted]="sortable && !isConfigurator">
  <h3 for="inputSearch" *ngIf="!isConfigurator">{{title}}
    <app-input-loader [status]="loaderStatus"></app-input-loader>
  </h3>
  <app-items-table [borderBottom]="borderBottom" [items]="selectedEndusers" [noResult]="noResult"
                   [singleList]="singleList" [canAdd]="canAdd" [canReplace]="canReplace"
                   [enableInviteNewSigner]="enableInviteNewSigner"
                   [enableInviteNewWatcher]="enableInviteNewWatcher"
                   [isConfigurator]="isConfigurator" (openModal)="onOpenModal()">

    <ng-container action>
      <div class="app-input-text" *ngIf="canAdd || canReplace">
        <div #searchList class="form-group">
          <div class="input-group">
            <input #inputSearchUser
                   (focus)="onFocusSearch()"
                   (input)="handleInputChange($event.target.value)"
                   (keydown)="signerKeydown($event)"
                   (ngModelChange)="onFocusSearch()"
                   [(ngModel)]="inputSearch"
                   [disabled]="disabled"
                   autocomplete="off"
                   class="form-control"
                   id="inputSearch{{idPostfix}}"
                   placeholder="{{placeholder}}">
            <div class="input-group-append">
              <div class="input-group-text">
                <i class="fas fa-search"></i>
              </div>
            </div>
            <div [hidden]="!searchListOpen" class="search-results">
              <ul class="list-group list-unstyled">
                <li *ngIf="!availableEndusers || availableEndusers?.length === 0" class="empty">
                  {{'NEW_SESSION.SIGNERS.NO_RESULT' | translate}}
                  <span *ngIf="(canAdd || canReplace) && isConfigurator"> -
                    <span class="modal-link" (click)="onOpenModal()">{{(enableInviteNewWatcher ? 'NEW_SESSION.WATCHERS.ADD' :'NEW_SESSION.SIGNERS.NEW_SIGNERS.ADD') | translate}}</span>
                  </span>
                </li>
                <li *ngFor="let signer of availableEndusers; let i = index"
                    [class.active]="i === activeSignerIndex"
                    class="list-group-item result">
                  <div [ngClass]="{'enduserInfo': search}" >
                  <a (click)="handleOnAddOrReplaceEnduser(signer)">
                    {{signer.firstName &&  signer.firstName !== null ? signer.firstName : ''}}
                    {{signer.lastName && signer.lastName !== null ? signer.lastName : ''}}
                    <ng-container *ngIf="signer.email && signer.email !== null"> -<i>{{signer.email}}</i></ng-container>
                  </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #itemsCards let-stepEnduser>
      <app-enduser-item *ngIf="!isConfigurator || checkAlreadySign"
                        (delete)="handleOnAddRemove($event)"
                        (onUpdateExpected)="handleOnUpdateExpected($event)"
                        (onReplaceEnduserId)="onClickReplaceEnduser($event)"
                        [idReplace]="replaceEnduserId"
                        [replaceMode]="replaceMode"
                        [replaceAloneSigner]="replaceAloneSigner"
                        [disabled]="disabled || hasAlreadySign(stepEnduser) "
                        [disabledSelectCircle]="disabledSelectCircle"
                        [intoWizardGeneral]="intoWizardGeneral"
                        [canReplace]="canReplace"
                        [canAdd]="canAdd"
                        [sessionId]="sessionId"
                        [stepId]="step?.id"
                        [isConfigurator]="isConfigurator"
                        [enduser]="stepEnduser"
                        [disableSelectOptionForWatcher]="disableSelectOptionForWatcher"
      ></app-enduser-item>
      <ng-container *ngIf="sortable &&  sortedUsers">
        <div class="enduser-sort">
          <button (click)="up(stepEnduser)" [class.btn-sorted]="sortedUsers"
                  [disabled]="noUp(stepEnduser)"
                  class="btn"><i class="fa fa-arrow-circle-up"></i></button>
          <button (click)="down(stepEnduser)" [class.btn-sorted]="sortedUsers"
                  [disabled]="noDown(stepEnduser)"
                  class="btn"><i class="fa fa-arrow-circle-down"></i></button>
        </div>
      </ng-container>
    </ng-template>
  </app-items-table>

  <div class="links_sort" *ngIf="selectedEndusers?.length > 1">
    <a (click)="unsort()" *ngIf="sortable && sortedUsers"
      class="reset-link"
      href="javascript:">{{'RESET_ORDER' | translate}}</a>
   <a (click)="sort()" *ngIf="sortable && !sortedUsers" class="reset-link"
      href="javascript:">{{'DEFINE_ORDER' | translate }}</a>
  </div>
</div>
