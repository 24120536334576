/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface SignatureContext { 
    /**
     * Metadata to associate to the signature. This data is added as a metadata (DIRECTSIGN_META) of the signature session inside the signature platform to ease
     */
    metadata?: string;
    /**
     * Type of signature applied on the document, can be a automatic seal, user signature click-to-sign(C2S) type, visa (no cryptography - no signature image on pdf), visa with image (no cryptography - with signature image on pdf)
     */
    sprofile?: SignatureContext.SprofileEnum;
}
export namespace SignatureContext {
    export type SprofileEnum = 'SKIP' | 'NEXT' | 'VISA' | 'VISA_WITH_IMAGE' | 'C2S';
    export const SprofileEnum = {
        SKIP: 'SKIP' as SprofileEnum,
        NEXT: 'NEXT' as SprofileEnum,
        VISA: 'VISA' as SprofileEnum,
        VISAWITHIMAGE: 'VISA_WITH_IMAGE' as SprofileEnum,
        C2S: 'C2S' as SprofileEnum
    };
}
