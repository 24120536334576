/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./organisations-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./organisations-item.component";
var styles_OrganisationsItemComponent = [i0.styles];
var RenderType_OrganisationsItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrganisationsItemComponent, data: {} });
export { RenderType_OrganisationsItemComponent as RenderType_OrganisationsItemComponent };
function View_OrganisationsItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "small-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organisation.emailSender; _ck(_v, 2, 0, currVal_0); }); }
export function View_OrganisationsItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "enduser-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "d-flex justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "flex-grow-1 left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "big-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganisationsItemComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "flex-grow-0 w-trash"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn-delete icon-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteOrganisation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.organisation.emailSender; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organisation.name; _ck(_v, 4, 0, currVal_0); }); }
export function View_OrganisationsItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-organisations-item", [], null, null, null, View_OrganisationsItemComponent_0, RenderType_OrganisationsItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.OrganisationsItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrganisationsItemComponentNgFactory = i1.ɵccf("app-organisations-item", i3.OrganisationsItemComponent, View_OrganisationsItemComponent_Host_0, { organisation: "organisation" }, { delete: "delete" }, []);
export { OrganisationsItemComponentNgFactory as OrganisationsItemComponentNgFactory };
