import { Component, Input, OnInit, Output, EventEmitter  } from '@angular/core';
import { StepTagService } from 'luxtrust-cosi-api/api/stepTag.service';
import { TagData } from 'luxtrust-cosi-api/model/tagData';
import {UpdateItemTagPayload} from '../../../../../luxtrust-cosi-api';


@Component({
  selector: 'step-metadata', templateUrl: './step.metadata.component.html', styleUrls: ['./step.metadata.component.scss']
})
export class StepMetadataComponent implements OnInit {

  @Input() stepTags: TagData [];
  @Input() sessionId: number;
  @Input() stepId: number;
  @Input() alreadySigned: boolean;
  @Input() disableAllMetadata = false;
  @Input() isWatcher = false;
  @Output() onChangeMetadata: EventEmitter<void> = new EventEmitter();

  constructor(private stepTagService: StepTagService) {}
  ngOnInit(): void {
  }

  updateStepTagMeta(tagItem: TagData) {
      const body: UpdateItemTagPayload = {
        tagId: tagItem.id, value: tagItem.value, encrypt: tagItem.encrypted, orderIndex: tagItem.orderIndex, configurator: tagItem.configurator
      } as UpdateItemTagPayload;
      this.stepTagService.updateStepTag(this.sessionId, this.stepId, body).subscribe(() => this.onChangeMetadata.emit());
  }

  trackSteptag(index: number) {
    return index;
  }

}
