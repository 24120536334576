<div *ngIf="activeStep">
  <div class="modal-header">
    <button id="close-modal-id" *ngIf="!comingFromSession && !comingFromStep" (click)="openModalDeleteSession()" class="close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="flex-center text-center flex-column configurator">

    <div class="container-title-config">
      <h3 class="text-left flex-title" [ngClass]="{'align-title': comingFromSession || comingFromStep || configByStep.length === 1}"> {{'MODAL_TEMPLATE_SESSION.CONFIGURATOR.TITLE' | translate }} </h3>
      <div [ngClass]="{'config-step': !comingFromSession && !comingFromStep && configByStep.length > 1}">
        <mat-form-field *ngIf="!comingFromSession && !comingFromStep && !oneStepConfigurator" class="formFieldCustom formSelectStep text-center">
          <mat-select [ngModel]="activeStep.id" (ngModelChange)="changePage($event)" [disabled]="hasErrorWithLinkTag">
            <mat-option *ngFor="let config of configByStep, let i = index" [value]="config.stepId"
            >{{config.stepName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <h5 tooltipClass="custom-tooltip" placement='right' [ngbTooltip]="activeStep.label" class="labelStep pr-4" *ngIf="comingFromSession || comingFromStep || oneStepConfigurator">{{activeStep.label| truncate: {max: 20} }}</h5>
      </div>
    </div>

      <hr>
      <div *ngIf="canSeeSigners()" [ngClass]="{'overlay': isStepInactive()}">
        <h5 class="mb-2 text-left"> {{'WIZARD.CONFIG.USERS' | translate}}</h5>
        <div *ngIf="currentStepEndusers && stepTagList">
          <app-modal-configurator-signers
            [sessionId]="session.id"
            [step]="activeStep"
            [signer]="this.currentUserSigner"
            [manager]="this.currentUserManager"
            [configStep]="getConfig()"
            [stepEndusers]="currentStepEndusers"
            [stepTagList]="stepTagList"
            [page]="page"
            (sortMode)="detectSortMode($event)"
            (outputAddUser)="addUser($event)"
            (outputOrderSigner)="orderSigner()"
            (outputUpdateSigner)="updateCircleSigner($event)"
            (outputDeleteUser)="deleteUser($event)"
            (orderAfterReplace)="orderAfterReplace()">
          </app-modal-configurator-signers>
        </div>
        <hr>
      </div>
      <div *ngIf="canSeeWatchers()" [ngClass]="{'overlay': isStepInactive()}">
        <h5 class="mb-2 text-left"> {{'WIZARD.CONFIG.WATCHERS' | translate}}</h5>
        <div *ngIf="currentStepEndusers && stepTagList">
          <app-modal-configurator-watchers
            [sessionId]="session.id"
            [step]="activeStep"
            [signer]="this.currentUserSigner"
            [manager]="this.currentUserManager"
            [configStep]="getConfig()"
            [stepEndusers]="currentStepEndusers"
            [stepTagList]="stepTagList"
            [page]="page"
            (sortMode)="detectSortMode($event)"
            (outputAddUser)="addUser($event)"
            (outputOrderSigner)="orderSigner()"
            (outputUpdateSigner)="updateCircleSigner($event)"
            (outputDeleteUser)="deleteUser($event)"
            (orderAfterReplace)="orderAfterReplace()">
          </app-modal-configurator-watchers>
        </div>
        <hr>
      </div>
      <div *ngIf="canSeeDocuments()" [ngClass]="{'overlay': isStepInactive()}">
        <h5 class="mb-2 text-left"> Documents </h5>
        <div *ngIf="currentStepEndusers && stepTagList">
          <app-modal-configurator-documents
            [session]="session"
            [step]="activeStep"
            [signer]="this.currentUserSigner"
            [manager]="this.currentUserManager"
            [configStep]="getConfig()"
            [stepEndusers]="currentStepEndusers"
            [stepTagList]="stepTagList"
            [oneStepConfigurator]="oneStepConfigurator"
            (updateMetadata)="updateMetadata()"
          >
          </app-modal-configurator-documents>
        </div>
        <hr>
      </div>
      <div *ngIf="canSeeMetadata(activeStep.id) && session" [ngClass]="{'overlay': isStepInactive()}">
        <h5 class="mb-2 text-left" *ngIf="activeStep.tags.length > 0"> {{'WIZARD.CONFIG.METADATA' | translate}}  </h5>
        <app-modal-configurator-metadatas
          [sessionId]="session.id"
          (controlValueTagMandatory)="controlValueTagMandatory($event)"
          (updateTag) = updateTag($event);
          (errorLinkTag)="checkErrorLinkTag($event)"
          [step]="activeStep"
        >
        </app-modal-configurator-metadatas>
        <hr *ngIf="activeStep.tags.length > 0">
      </div>

      <ng-container *ngIf="configuratorCreationMode">
        <span class="text-danger form-text" *ngIf="displayErrorMessage()">
          {{'WIZARD.CONFIG.MANDATORY_TAGS_CHECK_STEP' | translate: {stepNoVisited: listStepWithAccessNovisited } }}
        </span>
        <span class="text-danger form-text" *ngIf="isLastActiveStep() && listNameStepWithError.length === 1">
          {{'WIZARD.CONFIG.MANDATORY_TAGS_STEP_ERROR' | translate: {stepWithError: listNameStepWithError } }}
        </span>
        <span class="text-danger form-text" *ngIf="isLastActiveStep() && listNameStepWithError.length > 1">
          {{'WIZARD.CONFIG.MANDATORY_TAGS_STEPS_ERROR' | translate: {stepWithError: listNameStepWithError } }}
        </span>
      </ng-container>
      <span class="text-danger form-text" *ngIf="hasErrorWithLinkTag">
          {{'WIZARD.CONFIG.LINK_TAG_ERROR' | translate}}
        </span>

      <div class="navButton">
        <button (click)="previousStep()"
                *ngIf="!comingFromSession && !comingFromStep && page !== 0"
                [disabled]="hasErrorWithLinkTag || deletingSession"
                class="btn btn-primary btn-min-width float-left" type="submit" >
          {{'WIZARD.PREVIOUS_STEP' | translate}}
        </button>

        <div class="float-right">
          <div class="float-right">
            <button (click)="closeConfigurator()"
                    [disabled]="hasErrorWithLinkTag || savingTag || tagMandatoryHasNoValue || deletingSession"
                    *ngIf=" comingFromSession || comingFromStep"
                    class="btn btn-primary btn-min-width" type="submit">
              {{'WIZARD.CLOSE' | translate}}
            </button>

            <button (click)="closeModal(true)"
                    id="start-button"
                    *ngIf="!comingFromSession && !comingFromStep && isLastActiveStep()"
                    [disabled]="hasErrorWithLinkTag || savingTag || disabledSaveAndCreate || tagMandatoryHasNoValue || !canStartSession() || deletingSession || isSignatureStepInvalid()"
                    class="btn btn-primary btn-min-width btn-success btn-start" type="submit">
              <i class="fas fa-play"></i> {{'WIZARD.START' | translate}}
            </button>
          </div>

          <button (click)="nextStep()" id="configurator-next"
                  *ngIf="!comingFromSession && !comingFromStep && !isLastActiveStep() "
                  [disabled]="hasErrorWithLinkTag || savingTag || deletingSession || isSignatureStepInvalid()" class="btn btn-primary btn-min-width float-right"
                  type="submit">{{'WIZARD.NEXT_STEP' | translate}} </button>
        </div>
      </div>
  </div>
</div>
