<app-tooltip [tooltipText]="'HELP.WIZARD_SIGNERS' | translate"></app-tooltip>

<app-wizard-step>
  <div class="fixed-container" content>
    <app-endusers-document-table
      (reloadData)="reloadData()"
      [documents]="documents"
      [noResultFound]="'WIZARD.SIGNERS.NO_RESULT_FOUND' | translate"
      [sessionId]="sessionId"
      [sprofileList]="sprofileList"
      [stepEndUsers]="stepEndUsers"
      [stepId]="stepId"
      [title]="'WIZARD.SIGNERS.TITLE' | translate">
    </app-endusers-document-table>
  </div>

  <div *ngFor="let sprofileCode of sprofileCodes" class="fixed-container" content>
      <table class="custom-table table-signature">
        <tr class="table-header">
          <th>{{getLabelByCode(sprofileCode)}}</th>
          <th>{{'WIZARD.SIGNERS.SPROFILE_KEY' | translate}}</th>
          <th>{{'WIZARD.SIGNERS.SPROFILE_VAL' | translate}}</th>
        </tr>
        <tr *ngFor="let key of sprofileMapKeys[sprofileCode]" class="table-row">
          <td></td>
          <td class="table-row-i1">
            {{key}}
          </td>
          <td class="table-row-i2 form-group">
            <div class="input-group">
              <input (change)="updateSprofileKeyVal(sprofileCode, key)"
                     *ngIf="key!=='signature-form'"
                     [disabled]="updatingSprofile"
                     [(ngModel)]="sprofileMapKeyvals[sprofileCode][key]"
                     class="form-control"
                     type="text" />

              <select (change)="updateSprofileKeyVal(sprofileCode, key)"
                      *ngIf="key==='signature-form'"
                      [disabled]="updatingSprofile"
                      [(ngModel)]="sprofileMapKeyvals[sprofileCode][key]"
                      class="form-control">
                <option *ngFor="let o of sprofileKeyValues[key]"[selected]="sprofileMapKeyvals[sprofileCode][key]===o">{{o}}</option>
              </select>
            </div>
          </td>
        </tr>
      </table>
  </div>

  <div class="bottom-nav-btns mt-3">
    <app-wizard-step-button [action]="stepActions.PREVIOUS"
                            [label]="'WIZARD.PREVIOUS_STEP' | translate"></app-wizard-step-button>
    <app-wizard-step-button [action]="stepActions.NEXT" [label]="'WIZARD.NEXT_STEP' | translate"
                            class="float-right"></app-wizard-step-button>
  </div>
</app-wizard-step>
