import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormElement } from '../../form.element';

@Component({
  selector: 'app-autocomplete-custom',
  templateUrl: './autocomplete-custom.component.html',
  styleUrls: ['./autocomplete-custom.component.scss']
})
export class AutocompleteCustomComponent<T> extends FormElement<T> implements OnInit, OnChanges {
// Option change is managed into function "manageValuesFormChanged" into parent
  @Input() datasProviders = [];
  @Input() tabIndex: number;
  @Input() sortAsc = true;
  @Input() isLangue = false;
  @Input() isFilter = false;
  @Input() filterWithInput = true;
  @Input() addEnduser = false;
  @Input() newValue;
  @Input() filter;
  @Input() focusOnInput = false;
  @Input() testId = '';
  @Input() formLabel: string;
  isDisabled = false;
  @Input('isDisabled')
  public set _isDisabled(value: boolean) {
    this.isDisabled = value;
    this.isDisabled ? this.control.disable() : this.control.enable();
  }
  valueChoose: any;
  @Output() valueInputChange: EventEmitter<string> = new EventEmitter<string>();

  inputSub: Subscription;

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger, static: false }) inputAutoComplit: MatAutocompleteTrigger;
  @ViewChild('inputAutoComplit', {
    read: ElementRef, static: false
  }) inputSearchUser;

  control = new FormControl();
  filteredOptions: Observable<any[]>;

  ngOnInit() {
    if (this.focusOnInput) {
      setTimeout(() => {
        this.inputSearchUser.nativeElement.focus();
        this.inputAutoComplit.closePanel();
        }, 10);
    }

    if (this.ngControl.value) {
      this.control.setValue(this.ngControl.value);
      this.valueChoose = this.ngControl.value;
    }

    this.ngControl.valueChanges.subscribe(value => {
      this.control.setValue(this.ngControl.value);
      this.valueChoose = this.ngControl.value;
    })

    if (this.filterWithInput) {
      this.setFilteredOptions();
    } else {
      this.inputSub = this.control.valueChanges.subscribe((value: string) => {
        if (value && value.length > 2) {
          this.valueInputChange.emit(value);
        } else {
          this.datasProviders = [];
        }
      });
    }

  }

  setFilteredOptions() {
    this.filteredOptions = this.control.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datasProviders'] && this.filterWithInput) {
      this.setFilteredOptions();
    }
    if (changes['newValue'] && !changes['newValue'].firstChange) {
      this.onChange(this.newValue);
      if (!this.newValue.value) {
        this.valueChoose = undefined;
        this.clearInput(false);
      }
      this.newValue = undefined;
    }
  }

  display(data): string {
    if (data) {
      return data.key;
    }
    return '';
  }

  selectOption($event) {
    this.onChange($event.option.value);
    this.valueChoose = $event.option.value;
    if (this.addEnduser) {
      this.clearInput(false);
      this.onChange(undefined);
    }
  }

  private _filter(value) {
    let filterValue;
    if (value) {
      if (value.key) {
        filterValue = value.key;
      } else {
        filterValue = value;
      }
      return this.datasProviders.filter(option => this.normalize(option.key.toLowerCase()).includes(this.normalize(filterValue.toString().toLowerCase())));
    }
    return this.datasProviders;
  }

  private normalize(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  clearInput(open: boolean) {
    if (this.control.value) {
      this.control.setValue(undefined);
      if (open) {
        this.inputAutoComplit.openPanel();
      }
    }
  }

  manageLabel(): string {
    if (this.control && this.control.value && this.control.value.key !== '') {
      return 'always';
    } else {
      return 'never';
    }
  }

  checkValue() {
    if (!this.control.value && this.valueChoose && !this.addEnduser) {
      this.control.setValue(this.valueChoose);
    }
  }

}
