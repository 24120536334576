import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from 'src/app/core/service/translate.service';
import {SignatureService} from 'luxtrust-cosi-api/api/signature.service';
import {CreateValidationCodePayload} from 'luxtrust-cosi-api/model/createValidationCodePayload';
import {SealWithCodePayload} from 'luxtrust-cosi-api/model/sealWithCodePayload';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {SprofileService} from '../../../../../luxtrust-cosi-api';
import {ValidationCodeError} from '../../../services/enum/validation-code-error';

@Component({
  templateUrl: './modal-sign-with-code.component.html'
})
export class ModalSignWithCodeComponent implements OnInit, OnDestroy {

  @Input() sessionId: number;
  @Input() stepId: number;
  @Input() signatureId: number;
  @Input() sprofile: string;
  @Output() generationEvent: EventEmitter<{ message: string, params: Object }> = new EventEmitter<{ message: string, params: Object }>();
  @ViewChild('inputValidationCode', {
    read: ElementRef, static: false
  }) inputValidationCode: ElementRef;
  channel: CreateValidationCodePayload.ValidationCodeChannelEnum;
  form: FormGroup;
  placeHolder: string;
  submitted = false;
  private subscriptionGeneration: Subscription;
  private subscriptionValidation: Subscription;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private spinnerService: NgxSpinnerService,
              private alertService: AlertService,
              private sprofileService : SprofileService,
              private signatureService: SignatureService) {
  }

  get validationCode() {
    return this.form.get('validationCode');
  }

  ngOnInit() {
    this.form = this.createFormGroup();
    this.getValidationCodeChannel();
  }

  ngOnDestroy() {
    if (this.subscriptionGeneration) {
      this.subscriptionGeneration.unsubscribe();
    }
    if (this.subscriptionValidation) {
      this.subscriptionValidation.unsubscribe();
    }
  }

  generateCode() {
    let errorMessage;
    if (this.subscriptionGeneration) {
      this.subscriptionGeneration.unsubscribe();
    }
    this.subscriptionGeneration = this.signatureService.generateValidationCodeSignature(this.sessionId, this.signatureId, this.stepId,
      <CreateValidationCodePayload>{
        validationCodeChannel: this.channel
      }).pipe(take(1))
      .subscribe(
        () => this.generationEvent.emit({
          message: 'VALIDATION_CODE.GENERATION_SUCCESS',
          params: {channel: this.channel}
        }),
        (error: ApiError) => {
          // check if we have message from backend
          if (error.error.message) {
            errorMessage = error.error.message;
          } else {
            errorMessage = 'VALIDATION_CODE.GENERATION_FAILED';
          }
          this.activeModal.close({
            status: 'FAILED', message: errorMessage
          })
        });
  }

  validateCode() {
    if (this.form.invalid) {
      return;
    }
    this.spinnerService.show();
    this.submitted = true;
    const validationCode: string = this.form.value.validationCode.trim();
    if (validationCode) {
      if (this.subscriptionValidation) {
        this.subscriptionValidation.unsubscribe();
      }
      this.subscriptionValidation = this.signatureService.applyValidationCodeSignature(this.sessionId, this.signatureId, this.stepId,
        <SealWithCodePayload>{
          code: validationCode
        }).pipe(take(1))
        .subscribe(
          () => {
            this.activeModal.close({status: 'SUCCEEDED', message: 'ALERT.SIGNATURE_SUCCESS'});
            this.spinnerService.hide();
          }, (err: ApiError) => {
            this.spinnerService.hide();
            switch(err.error.code) {
              case ValidationCodeError.OTP_INCORRECT:
                this.alertService.error('VALIDATION_CODE.INCORRECT');
                break;
              case ValidationCodeError.OTP_LIMIT_EXCEEDED:
                this.alertService.error('VALIDATION_CODE.LIMIT_EXCEEDED');
                break;
              case ValidationCodeError.OTP_EXPIRED:
                this.alertService.error('VALIDATION_CODE.EXPIRED');
                break;
              default:
                this.alertService.error('ALERT.ERROR_OCCURRED');
            }
            this.submitted = false;
          });
    }
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group({
      validationCode: new FormControl('', [Validators.required, Validators.pattern('^\\s*(([0-9]\\s*){6})\\s*')])
    });
  }

  private getValidationCodeChannel() {
    this.sprofileService.getSprofileList().subscribe(sprofiles => {
      let sprofile = sprofiles.find(sprofile => sprofile.code === this.sprofile);
      if (sprofile && sprofile.handler === 'CODE_SMS') {
        this.channel = CreateValidationCodePayload.ValidationCodeChannelEnum.SMS;
        this.translateService.stream('VALIDATION_CODE.INPUT', {channel: this.channel}).subscribe(result => this.placeHolder = result);
        this.generateCode();
      } else if (sprofile && sprofile.handler === 'CODE_EMAIL') {
        this.channel = CreateValidationCodePayload.ValidationCodeChannelEnum.EMAIL;
        this.translateService.stream('VALIDATION_CODE.INPUT', {channel: this.channel}).subscribe(result => this.placeHolder = result);
        this.generateCode();
      } else if (sprofile && sprofile.handler === 'OTP') {
        this.translateService.stream('VALIDATION_CODE.INPUT_2').subscribe(result => this.placeHolder = result);
      } else {
        this.activeModal.close({status: 'FAILED', message: 'VALIDATION_CODE.CHANNEL_NOT_SUPPORTED'});
      }
    });
  }

}
