<!-- Users -->
<div class="signers">
  <app-endusers-table
    (addEnduser)="addWatcher($event)"
    (outputSearch)="onSearchWatcher($event)"
    (removeEnduser)="removeWatcher($event)"
    (replaceUsers)="onReplaceUsers($event.oldId, $event.newUser, selectedUsers)"
    (outputUpdateExpected)="onUpdateExpected($event)"
    (openModal)="openModal($event.replace, $event.oldEnduserId)"
    [borderBottom]="false"
    [disabledSelectCircle]="false"
    [disabled]="replacingUser || addingWatcher || removingWatcher"
    [availableEndusers]="availableWatcher"
    [loaderStatus]="loaderStatusWatcher"
    [noResult]="'WIZARD.USERS.NO_RESULT_WATCHER' | translate"
    [placeholder]="'WIZARD.USERS.SEARCH_WATCHER' | translate"
    [selectedEndusers]="selectedWatcher"
    [singleList]="true"
    [canAdd]="canAdd()"
    [canReplace]="canReplace()"
    [replaceAloneSigner]="replaceAloneSigner"
    [page]="page"
    [sessionId]="sessionId"
    [step]="step"
    [isConfigurator]="true"
    [replaceEnduserId]="replaceEnduserId"
    [replaceMode]="replaceMode"
    [enableInviteNewWatcher]="enableInviteNewWatcher"
    [disableSelectOptionForWatcher]="disableSelectOptionForWatcher"
    class="fixed-md">
  </app-endusers-table>
</div>
