<div class="container width-mobile-phone">
  <div class="row text-center directories-title">
    <h1 class="col-sm-6 text-center offset-sm-3">{{'MANAGE_DOCUMENT.TITLE' | translate}}</h1>
  </div>
  <div class="mb-4 documentation-container ">
    <table *ngIf="sessionSystemDocumentList" class="custom-table table-documentations">
      <tr class="table-header">
        <th class="th-session-container">{{'MANAGE_DOCUMENT.Category' | translate}}</th>
        <th class="th-status-container">{{'MANAGE_DOCUMENT.DISPLAY_TEXT' | translate}}</th>
        <th class="th-actions-container">{{'MANAGE_DOCUMENT.FILE_NAME' | translate}}</th>
        <th class="th-actions-container">{{'MANAGE_DOCUMENT.FILE' | translate}}</th>
      </tr>

      <tr *ngFor="let systemDoc of sessionSystemDocumentList; let i = index"
          class="table-row">
        <td class="th-session-container">{{systemDoc.type}}</td>
        <td class="th-status-container">{{systemDoc.sessionLabel}}</td>
        <td class="table-row-i3">{{systemDoc.documentName}}</td>
        <td class="table-row-i4">
          <div class="table-row-i3-btns">
            <button
              [routerLink]="['/doc-personal-data', systemDoc.type,systemDoc.sessionId,systemDoc.stepId,systemDoc.documentId]"
              [ngbTooltip]="'MANAGE_DOCUMENT.SELECT_DOC' | translate"
              [id]="'select-doc-button-'+ i"
              class="action-button i3-select icon-button"
              container="body">
              <i class="step-bar-icon fas fa-file-alt"></i>
            </button>
            <button  (click)="editDoc(systemDoc)" class="action-button i3-edit icon-button"
                     [ngbTooltip]="'MANAGE_DOCUMENT.EDIT_DOC' | translate"
                     [id]="'edit-doc-button-'+ i"
                    container="body">
              <i class="fas fa-pencil-alt"></i>
            </button>
            <button [id]="'delete-doc-button-'+ i" [ngbTooltip]="'MANAGE_DOCUMENT.DELETE_DOC' | translate" class="action-button i3-remove icon-button" container="body"><i class="fas fa-trash-alt"></i></button>
          </div>

      </tr>
    </table>
  </div>

