<div class="app-input-text">
  <div class="form-group" [style.margin-bottom]="noMargin? '0' : '1rem'">
    <div class="input-group mr-2" on-mouseenter="setDisabledState(false)"
         on-mouseleave="setDisabledState(true)">
      <div *ngIf="leftLabel"
           class="input-group-prepend">
        <div class="input-group-text">{{leftLabel}}</div>
      </div>
      <input (blur)="inputBlur()"
             (change)="onInputChange($event)"
             [attr.placeholder]="placeholder"
             [disabled]="disabled || manualDisable"
             id="id{{idPostfix}}"
             [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"
             [ngModel]="getDate()"
             [value]="value ? value : ''"
             class="form-control"/>

      <div class="input-group-append">
        <button [disabled]="disabled"
                [ngbPopover]="calendarContent"
                [placement]="placement"
                (hidden)="onBlur.emit()"
                type="button"
                class="input-group-text">
          <i aria-hidden="true"
             class="far fa-fw fa-calendar"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #calendarContent>
  <div>
    <div *ngIf="!showTimePickerToggle">
      <ngb-datepicker (ngModelChange)="onDateChange($event)"
                      [lang]="translateService.currentLang"
                      [maxDate]="maxDate"
                      [minDate]="minDate"
                      [ngModel]="datetime"
                      name="datepicker">
      </ngb-datepicker>
      <button (click)="toggleDateTimeState($event)"
              *ngIf="!dateOnly"
              [disabled]="!datetime?.day"
              class="btn btn-block btn-outline-secondary"
              type="button">
        <i aria-hidden="true"
           class="far fa-fw fa-clock"></i>
      </button>
    </div>
    <div *ngIf="showTimePickerToggle">
      <button (click)="toggleDateTimeState($event)"
              [ngbPopover]="calendarContent"
              class="btn btn-block btn-outline-secondary"
              type="button">
        <i aria-hidden="true"
           class="far fa-fw fa-calendar"></i>
      </button>
      <div class="mt-auto">
        <ngb-timepicker (ngModelChange)="onTimeChange($event)"
                        [hourStep]="hourStep"
                        [lang]="translateService.currentLang"
                        [minuteStep]="minuteStep"
                        [ngModel]="datetime"
                        [formControl]="controlDateBefore"
                        [secondStep]="secondStep"
                        [seconds]="seconds"
                        name="timepicker"></ngb-timepicker>
      </div>
    </div>
  </div>
</ng-template>
