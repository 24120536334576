import {Component, EventEmitter, HostListener, Inject, OnInit, Optional} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import * as introJs from 'intro.js/intro.js';
import {AdditionalSessionDataService} from 'luxtrust-cosi-api/api/additionalSessionData.service';
import {AuthenticationService} from 'luxtrust-cosi-api/api/authentication.service';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {SessionTemplateService} from 'luxtrust-cosi-api/api/sessionTemplate.service';
import {AdditionalSessionData} from 'luxtrust-cosi-api/model/additionalSessionData';
import {CountData} from 'luxtrust-cosi-api/model/countData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {SessionFilter} from 'luxtrust-cosi-api/model/sessionFilter';
import {BASE_PATH} from 'luxtrust-cosi-api/variables';
import {NgxSpinnerService} from 'ngx-spinner';
import {filter, take} from 'rxjs/internal/operators';
import {SprofileWrapperService} from 'src/app/services/services/sprofile-wrapper.service';
import {AlertService} from '../../services/services/alert-service';
import {AppService} from '../../services/services/app.service';
import {NavigationService} from '../../services/services/navigation.service';
import {ModalCreateSessionComponent} from '../../shared/components/modal-create-session/modal-create-session.component';
import {ModalDeleteSessionComponent} from '../../shared/components/modal-delete-session/modal-delete-session.component';
import {ModalDisableSessionComponent} from '../../shared/components/modal-disable-session/modal-disable-session.component';
import {ModalTemplateSessionComponent} from '../modal-template-session/modal-template-session.component';
import {ApiError} from "../../error/api-error.model";
import StatusEnum = SessionData.StatusEnum;
import SignatureStatusEnum = SessionFilter.SignatureStatusEnum;
import {DocumentationService} from "../../admin/documentation/documentation.service";
import { SESSION_STORAGE_SYSTEM_DOC_KEY } from 'src/app/app.constant';
import {DownloadService} from "../../services/services/download.service";

@Component({
  templateUrl: './dashboard.component.html', styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  private static readonly SMALL_DEVICE_WIDTH = 992;
  private static readonly PHONE_DEVICE_WIDTH = 460;
  private static readonly PHONE_DEVICE_LIMIT_PAGINATE = 1;
  private static readonly SMALL_DEVICE_LIMIT_PAGINATE = 3;
  private static readonly LARGE_DEVICE_LIMIT_PAGINATE = 10;
  private static readonly SMALL_DEVICE_LIMIT = 5;
  private static readonly LARGE_DEVICE_LIMIT = 10;

  reset = new EventEmitter();

  sessions: SessionData[] = [];
  sessionsAdditionalDatas: AdditionalSessionData[] = [];
  template = false;
  sessionFilter: SessionFilter;

  // pagination
  limit = DashboardComponent.LARGE_DEVICE_LIMIT;
  paginateMaxSize = DashboardComponent.LARGE_DEVICE_LIMIT_PAGINATE;
  page = 1;
  collectionSize: number;
  introJSVisitDashboard = introJs();
  introJSCreateSession = introJs();
  introJSStart = introJs();
  currentUser;
  searchSession = false;

  deletingSession = false;
  editingSession = false;

  constructor(public appService: AppService,
              private authService: AuthenticationService,
              private translate: TranslateService,
              private sessionService: SessionService,
              private templateService: SessionTemplateService,
              private route: ActivatedRoute,
              private router: Router,
              private modal: NgbModal,
              private alertService: AlertService,
              private spinnerService: NgxSpinnerService,
              private additionalSessionDataService: AdditionalSessionDataService,
              private navigationService: NavigationService,
              private sprofileService: SprofileWrapperService,
              private downloadService: DownloadService,
              private documentationService: DocumentationService,
              @Optional() @Inject(BASE_PATH) private basePath: string) {
    this.sprofileService.getSprofileList();
  }

  introJSCreateSessionsetup() {
    this.introJSCreateSession.setOptions({
      exitOnOverlayClick: false,
      overlayOpacity: 0.5,
      prevLabel: this.translate.instant('NEW_SESSION.TUTORIAL.PREVLABEL'),
      nextLabel: this.translate.instant('NEW_SESSION.TUTORIAL.NEXTLABEL'),
      doneLabel: this.translate.instant('NEW_SESSION.TUTORIAL.NEXTLABEL'),
      steps: [
        {
          intro: this.translate.instant('DASHBOARD.TUTORIAL.CREATE.INTRO')
        },
        {
          element: '#createSessionStep1',
          intro: this.translate.instant('DASHBOARD.TUTORIAL.CREATE.STEP')
        }
      ]
    });
  }

  introJSVisitDashboardsetup() {

    this.introJSVisitDashboard.setOptions({
      overlayOpacity: 0.5,
      exitOnOverlayClick: false,
      prevLabel: this.translate.instant('NEW_SESSION.TUTORIAL.PREVLABEL'),
      nextLabel: this.translate.instant('NEW_SESSION.TUTORIAL.NEXTLABEL'),
      skipLabel: this.translate.instant('NEW_SESSION.TUTORIAL.SKIPLABEL'),
      doneLabel: this.translate.instant('NEW_SESSION.TUTORIAL.DONELABEL'),
      steps: [
        {
          intro: this.translate.instant('DASHBOARD.TUTORIAL.INTRO')
        },
        {
          element: '#visiteDashboardStep1',
          intro: this.translate.instant('DASHBOARD.TUTORIAL.FIRSTSTEP')
        },
        {
          element: '#visiteDashboardStep2',
          intro: this.translate.instant('DASHBOARD.TUTORIAL.SECONDSTEP')
        },
        {
          element: '#visiteDashboardStep3',
          intro: this.translate.instant('DASHBOARD.TUTORIAL.THIRDSTEP')
        },
        {
          element: '#visiteDashboardStep4',
          intro: this.translate.instant('DASHBOARD.TUTORIAL.FOURTHSTEP')
        },
        {
          element: '#visiteDashboardStep5',
          intro: this.translate.instant('DASHBOARD.TUTORIAL.FIFTHSTEP')
        },
        {
          element: '#visiteDashboardStep6',
          intro: this.translate.instant('DASHBOARD.TUTORIAL.SIXTHSTEP')
        }, {
          element: '#visiteDashboardStep7',
          intro: this.translate.instant('DASHBOARD.TUTORIAL.SEVENTHSTEP')
        }
      ]
    });
  }

  introJSStartSetup() {
    this.introJSStart.setOptions({
      tooltipClass: 'tutoStart',
      overlayOpacity: 0.5,
      showBullets: false,
      steps: [
        {
          element: '#tutoButtonId',
          intro: this.translate.instant('DASHBOARD.TUTORIAL.START.INTRO')
        }
      ]
    });
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    const previousLimit = this.limit;
    this.limit = event.target.innerWidth > DashboardComponent.SMALL_DEVICE_WIDTH ? DashboardComponent.LARGE_DEVICE_LIMIT : DashboardComponent.SMALL_DEVICE_LIMIT;
    this.paginateMaxSize = event.target.innerWidth > DashboardComponent.SMALL_DEVICE_WIDTH ?
      DashboardComponent.LARGE_DEVICE_LIMIT_PAGINATE :
      DashboardComponent.SMALL_DEVICE_LIMIT_PAGINATE;
    if (previousLimit !== this.limit) {
      this.onSearch();
    }
  }

  tutoSetup() {
    this.introJSStartSetup();
    this.introJSVisitDashboardsetup();
    this.introJSCreateSessionsetup();
  }

  initTuto() {
    this.translate.onLangChange.subscribe(() => {
      this.tutoSetup();
    });
    this.translate.get('DASHBOARD.TUTORIAL.START.INTRO').subscribe((res) => {
      this.tutoSetup();
      this.launchStartTuto();
    });
  }

  ngOnInit() {
    if (!sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)) {
      this.documentationService.buildSystemDocumentation();
    }
    sessionStorage.setItem('comToParapheur', 'false');
    if (sessionStorage.getItem('stateTemplateDashboard')) {
      if (sessionStorage.getItem('stateTemplateDashboard') === 'true') {
        this.template = true;
      }
    }
    this.currentUser = this.appService.getCurrentUserId();
    this.limit = window.innerWidth > DashboardComponent.SMALL_DEVICE_WIDTH ? DashboardComponent.LARGE_DEVICE_LIMIT : DashboardComponent.SMALL_DEVICE_LIMIT;

    this.paginateMaxSize = window.innerWidth > DashboardComponent.SMALL_DEVICE_WIDTH ? DashboardComponent.LARGE_DEVICE_LIMIT_PAGINATE :
      window.innerWidth > DashboardComponent.PHONE_DEVICE_WIDTH ? DashboardComponent.SMALL_DEVICE_LIMIT_PAGINATE : DashboardComponent.PHONE_DEVICE_LIMIT_PAGINATE;

    this.sessions = [];
    this.sessionFilter = {
      text: '', sortingFilters: [{
        field: 'CREATED', asc: false
      }]
    };

    this.route.queryParams.pipe(take(1)).subscribe((params: Params) => {
      if (Number.isInteger(+params['page'])) {
        this.page = +params['page'];
        this.onSearch();
      }
    });

    this.onSearch();

    this.initTuto();

    this.redirectionAfterSamlAuthentication();
  }

  redirectionAfterSamlAuthentication() {
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    const tenantName = this.route.snapshot.queryParams['tenantName'];
    const goToStep = '/session/' + sessionId + '/step/' + stepId;
    const goToSession = '/session/' + sessionId;
    if (sessionId) {
      if (stepId) {
        this.router.navigateByUrl(`${goToStep}?tenantName=${tenantName}`);
      } else {
        this.router.navigateByUrl(`${goToSession}?tenantName=${tenantName}`);
      }
    }
  }

  pageChange(page: number) {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {page: page}, queryParamsHandling: 'merge', preserveFragment: true
    });
    this.page = page;
    this.onSearch();
  }

  filterChange(sessionFilter: SessionFilter) {
    this.sessionFilter = sessionFilter;
    this.onSearch();
  }

  templateChange(template: boolean) {
    this.sessionFilter.text = '';
    this.template = template;
    this.pageChange(1);
    this.onSearch();
  }

  // check on selected page [1,pageMax]
  pageCheck() {
    let pageMax = Math.trunc(this.collectionSize / this.limit);
    if (this.collectionSize !== this.limit) {
      pageMax++;
    }
    if (this.page < 1 || pageMax < this.page) {
      // if invalid page, set page to 1
      this.pageChange(1);
    }
  }

  onSearch() {
    if (this.template) {
      this.sessionFilter.text = this.sessionFilter.text || undefined;
      this.sessionFilter.offset = (this.page - 1) * this.limit;
      this.sessionFilter.limit = this.limit;
      return Promise.all([
        this.templateService.getSessionSearchCount1(this.sessionFilter).toPromise(),
        this.templateService.searchSessionTemplates(this.sessionFilter).toPromise()
      ]).then(([count, sessions]) => {
        this.collectionSize = count.count;
        this.sessions = sessions;
        this.searchSession = false;
        this.getAdditionalSessionData();
        this.pageCheck();
      });

    } else {
      this.appService.getUser$().pipe(filter(e => !!e))
        .pipe(take(1))
        .subscribe(() => {
          const jwts = [];
          jwts.push({
            hostname: this.basePath, jwt: sessionStorage.getItem(this.appService.tokenName), local: true
          });
          let globalCount = 0;

          this.pageCheck();
          this.sessionFilter.offset = (this.page - 1) * this.limit;
          this.sessionFilter.limit = this.limit;
          const sessionsPromise = this.sessionService.getSessionList(this.sessionFilter).toPromise();

          return Promise.all(jwts.map(() => {
            return this.sessionService.getSessionSearchCount(this.sessionFilter)
              .toPromise().then((count: CountData) => globalCount += count.count);
          })).then(() => {
            this.collectionSize = globalCount;
            return Promise.all(
              jwts.filter((_, i) => i !== jwts.length - 1)
                .map(jwt => sessionsPromise.then(sessions => sessions))
            )
            .then(sessionsBundles => {
              return sessionsPromise.then(ownSessions => {
                this.sessions = sessionsBundles.reduce((prev, now) => prev.concat(now), ownSessions);
                this.getAdditionalSessionData();
              });
            });
          }).catch((error: ApiError) => {
            this.alertService.errorApi(error);
          });
        });
    }
  }

  getAdditionalSessionData() {
    const sessionsIds = this.sessions.map((session: SessionData) => session.id);
    if (sessionsIds.length) {
      this.additionalSessionDataService.getAdditionalSessionData(sessionsIds).toPromise()
        .then((additionalSessionDatas: AdditionalSessionData[]) => {
          this.sessionsAdditionalDatas = additionalSessionDatas.filter(addData =>
            this.sessions.filter(session => session.id === addData.id));
        });
    }
  }

  launchStartTuto() {
    const valueLSVisit = localStorage.getItem('tutoVisit' + this.currentUser);
    if (!valueLSVisit) {
      this.introJSStart.start();
    }
    localStorage.setItem('tutoVisit' + this.currentUser, 'true');
  }

  launchTuto() {
    const valueLSCreate = localStorage.getItem('tutoCreate' + this.currentUser);
    if (this.appService.userHasEntitlement(this.appService.ent.create_standalone) &&
      !valueLSCreate) {
      this.introJSCreateSession.start().oncomplete(() => {
          this.createFastSession();
        }
      );
      localStorage.setItem('tutoCreate' + this.currentUser, 'true');
    }
    if ((this.appService.userHasEntitlement(this.appService.ent.create_standalone) && valueLSCreate)
      || !this.appService.userHasEntitlement(this.appService.ent.create_standalone)) {
      this.launchVisitTuto();
    }
  }

  launchVisitTuto() {
    this.introJSVisitDashboard.start();
  }

  deleteSession(session: SessionData) {
    this.deletingSession = true;
    const modalRef = this.modal.open(ModalDeleteSessionComponent, {backdrop: false});
    modalRef.componentInstance.session = session;
    modalRef.componentInstance.title = session.template ? 'MODAL_DELETE_SESSION.TITLE_TEMPLATE' : 'MODAL_DELETE_SESSION.TITLE_SESSION';
    modalRef.componentInstance.message = session.template ? 'MODAL_DELETE_SESSION.MESSAGE_TEMPLATE' : 'MODAL_DELETE_SESSION.MESSAGE_SESSION';
    modalRef.result.then(value => {
      if (value === 'session_deleted') {
        this.alertService.success(session.template ? 'ALERT.TEMPLATE_DELETED' : 'ALERT.SESSION_DELETED', {sessionName: session.label});
        this.onSearch();
      }
      this.reset.emit();
    }, () => undefined)
      .then(value => this.deletingSession = false);
  }

  disableSession(session: SessionData) {
    const modalRef = this.modal.open(ModalDisableSessionComponent, {backdrop: false});
    modalRef.componentInstance.session = session;
    modalRef.result
      .then(value => {
        if (value === 'session_disabled') {
          this.alertService.success('ALERT.SESSION_DISABLED', {sessionName: session.label});
          this.onSearch();
        }
        this.reset.emit();
      }, () => undefined);
  }

  // This method allows to click in the table cell (which is easier on mobile/tablet displays) to go to the session instead of clicking on the session name
  goToSession(sessionId: number) {
    this.editingSession = true;
    this.navigationService.goToDocumentOrFirstStartedStepOrSession(sessionId).then(value => this.editingSession = false);
  }

  createSession() {
    const modalRef = this.modal.open(ModalCreateSessionComponent, {backdrop: false});
    modalRef.componentInstance.inProgress.pipe(take(1)).subscribe(() => this.spinnerService.show());
    modalRef.result.then((value: { sessionId: number, stepId: number }) => {
      this.router.navigate(['/session', value.sessionId, 'step', value.stepId, 'wizard'])
        .then(() => this.spinnerService.hide());
    }, () => this.spinnerService.hide());
  }

  createSessionFromTemplate() {
    const modalRef = this.modal.open(ModalTemplateSessionComponent, {backdrop: false, size: 'lg'});
    modalRef.result.then((searchSession) => {
      if (searchSession) {
        this.searchSession = searchSession;
      }
      if (sessionStorage.getItem('stateTemplateDashboard') === 'false'
        || !sessionStorage.getItem('stateTemplateDashboard')) {
        this.onSearch();
      }
      this.reset.emit();
    }, () => this.onSearch());
  }

  createFastSession() {
    this.introJSCreateSession.exit();
    this.router.navigate(['/new-session']);
  }

  onStatusFilter(event: {expired: boolean, status: StatusEnum, sigStatus: SignatureStatusEnum}) {
    if (event.expired) {
      this.sessionFilter = {expired: true}
    } else {
      if (event.status !== null) {
        this.sessionFilter.status = event.status;
        this.sessionFilter.signatureStatus = undefined;
      } else {
        this.sessionFilter.status = undefined;
        this.sessionFilter.signatureStatus = event.sigStatus;
      }
      this.sessionFilter.expired = undefined;
    }
    this.onSearch();
  }

  exportSession() {
    this.sessionService.exportSessionsUsingMapping(this.sessionFilter,"").subscribe(blob => {
        this.downloadService.download(blob, 'export_sessions.csv', 'text/csv');
        // this.exporting = false;
      }, (error: ApiError) => {
        // this.exporting = false;
        this.alertService.errorApi(error);
      }
    );
  }
}
