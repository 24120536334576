import {EventEmitter, Injectable} from '@angular/core';
import {DocumentService, SystemDocumentData, TenantService} from "../../../../luxtrust-cosi-api";
import {Subject} from "rxjs";
import {ApiError} from "../../error/api-error.model";
import {AlertService} from "../../services/services/alert-service";
import {DocumentationTypeEnum} from "./documentation-type.enum";
import {SESSION_STORAGE_SYSTEM_DOC_KEY} from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {

  sessionSystemDocumentListResult: SystemDocumentData[] = [];
  public sessionSystemDocumentListSubject: Subject<SystemDocumentData[]> = new Subject();

  constructor(private documentService: DocumentService,
              private alertService: AlertService,
              private tenantService: TenantService) {
  }

  public buildSystemDocumentation() {
    this.sessionSystemDocumentListResult = [];
    this.tenantService.getSystemDocumentList().subscribe(systemDocuments => {
      this.sessionSystemDocumentListResult = systemDocuments.sort((a, b) => this.setOrder(a) - this.setOrder(b));
      sessionStorage.setItem(SESSION_STORAGE_SYSTEM_DOC_KEY, JSON.stringify(this.sessionSystemDocumentListResult));
      this.sessionSystemDocumentListSubject.next(this.sessionSystemDocumentListResult);
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      this.sessionSystemDocumentListSubject.next([]);
    });
  }

  /**
   * set Order
   * @param sessionDoc
   */
  private setOrder(sessionDoc: SystemDocumentData) {
    let indexOrder = 0;
    const systemDocumentTypeTag = sessionDoc.type;
    if (systemDocumentTypeTag) {
      switch (systemDocumentTypeTag.toUpperCase()) {
        case DocumentationTypeEnum.GDPR:
          indexOrder = 0;
          break;
        case DocumentationTypeEnum.HELP:
          indexOrder = 1;
          break;
        case DocumentationTypeEnum.OTHER:
          indexOrder = 2;
          break;
        case DocumentationTypeEnum.FOOTER:
          indexOrder = 3;
          break;
        case DocumentationTypeEnum.PURGED:
          indexOrder = 4;
          break;
        default:
          console.log("case no yet implemented");
          break;
      }
    }
    return indexOrder;
  }

}
