import {Component, HostListener} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import {environment} from '../../../environments/environment';
import {AlertService} from '../../services/services/alert-service';
import {AppService} from '../../services/services/app.service';

@Component({
  templateUrl: './modal-orely-link.component.html', styleUrls: ['./modal-orely-link.component.scss']
})
export class ModalOrelyLinkComponent {

  orelyAuthUrl: SafeResourceUrl;
  iFrameVisible = true;

  constructor(public activeModal: NgbActiveModal,
              private domSanitizer: DomSanitizer,
              private translate: TranslateService,
              private alertService: AlertService,
              private appService: AppService) {
    this.orelyAuthUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      environment.ORELY_URL + '/auth/login?reason=LINK&tenantName=' + appService.getUser().tenantName + '&jwt=' + this.appService.get().raw);
  }

  @HostListener('window:message', ['$event']) iFrameEventListener(event: MessageEvent) {
    const data = event.data;
    if (data) {
      if (data.flowStatus === 'USER_CLICKED_CANCELLED') {
        this.activeModal.close();
      } else {
        if (data && data.ssn) {
          this.activeModal.close(data.ssn);
        } else {
          this.activeModal.dismiss();
        }
      }
    }
  }
}
