<div class="container mt-5 mb-5">
<!-- Show the PDF -->
  <app-pdf-viewer
    *ngIf="documentUploaded"
    [document]="documentUploaded"
    [activePdfViewer]="activePdfViewer"
    [sessionId]="sessionId"
    [stepId]="stepId">
  </app-pdf-viewer>
</div>
