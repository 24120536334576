import {Component, OnInit} from '@angular/core';
import {
    SessionService, StepConfigurationData, StepData, StepService, SystemDocumentData
} from "../../../../luxtrust-cosi-api";

import {DocumentationService} from './documentation.service';
import sortBy from 'lodash/sortBy';
import {ModalConfiguratorComponent} from "../../session/step/components/wizard-step-configurator/modal-configurator/modal-configurator.component";
import {ApiError} from "../../error/api-error.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertService} from "../../services/services/alert-service";
import {SESSION_STORAGE_SYSTEM_DOC_KEY} from 'src/app/app.constant';
import {forkJoin} from "rxjs";

@Component({
    selector: 'app-documentations',
    templateUrl: './documentation.component.html',
    styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {


    public sessionSystemDocumentList: SystemDocumentData[] = [];

    constructor(public documentationService: DocumentationService,
                private stepService: StepService,
                private modal: NgbModal,
                private alertService: AlertService,
                private sessionService: SessionService) {
    }

    ngOnInit() {
        // get documentation Doc if sessionStorage is empty
        if (!sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)) {
            this.documentationService.sessionSystemDocumentListSubject.subscribe(data => this.sessionSystemDocumentList = data);
        } else {
            this.sessionSystemDocumentList = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY));
        }
    }

    editDoc(systemDoc) {
        this.openConfiguratorModal(systemDoc);
    }

    openConfiguratorModal(systemDoc: SystemDocumentData) {
        this.stepService.getStepConfiguration(systemDoc.sessionId, systemDoc.stepId).subscribe((stepConfiguration: StepConfigurationData) => {
            if (stepConfiguration.managerConfig) {
                forkJoin([this.sessionService.getSession(systemDoc.sessionId), this.stepService.getStepList(systemDoc.sessionId)]).subscribe(result => {
                    const sessionData = result[0];
                    const stepList = result[1].filter(step_ => step_.category === 'OPERATION');
                    sortBy(stepList, 'id');
                    let page = 0;
                    const stepData: StepData = stepList.find((step_, index) => {
                        const match = step_.id === systemDoc.stepId;
                        if (match) {
                            page = index;
                        }
                        return match;
                    });
                    const modalConfig = this.modal.open(ModalConfiguratorComponent, {backdrop: false, size: 'lg'});
                    modalConfig.componentInstance.session = sessionData;
                    modalConfig.componentInstance.activeStep = stepData;
                    modalConfig.componentInstance.currentConfigStep = stepConfiguration;
                    modalConfig.componentInstance.comingFromSession = true;
                    modalConfig.componentInstance.page = page;
                });
            } else {
                this.alertService.error('ALERT.NOT_ENOUGH_PERMISSIONS');
            }
        },(error: ApiError) => {
            this.alertService.errorApi(error)
        });
    }
}
