<div>
    <h6><strong>{{ fileName }}</strong></h6>
    <hr />
</div>
<div *ngIf="!validationReport.signatures" class="col-12 alert alert alert-danger">
    <h6 class="alert-heading"><strong>{{'VALIDATION.REPORT.MESSAGE.SIGNATURE_NOT_FOUND' | translate}}</strong></h6>
</div>

<app-report-tile-group [type]=reportType.SUCCESS
    [signatures]="validationReport?.signatures | filterByValidationStatus : 'TOTAL-PASSED'">
</app-report-tile-group>
<app-report-tile-group [type]=reportType.WARN
    [signatures]="validationReport?.signatures | filterByValidationStatus : 'INDETERMINATE'">
</app-report-tile-group>
<app-report-tile-group [type]=reportType.ERROR
    [signatures]="validationReport?.signatures | filterByValidationStatus : 'TOTAL-FAILED'">
</app-report-tile-group>