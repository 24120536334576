<table class="custom-table table-dashboard">
  <tr class="table-header">
    <th id="visiteDashboardStep2" class="th-session-container">{{'DASHBOARD.SESSION' | translate}}</th>
    <th id="visiteDashboardStep3" class="th-status-container">{{'DASHBOARD.STATUS' | translate}}</th>
    <th id="visiteDashboardStep4" class="th-actions-container">{{'DASHBOARD.ACTIONS' | translate}}</th>
  </tr>
  <tr *ngFor="let session of sessions"
      class="table-row">
    <td class="table-row-i1">
      <div (click)="emitEditSession(session.id)">
        <a [attr.title]="session.label">
          <lt-text-truncate [label]="session.label"
                            [xsMaxCharacters]="18"
                            [smMaxCharacters]="28"
                            [mdMaxCharacters]="32"
                            [lgMaxCharacters]="36"
                            [xlMaxCharacters]="45"
                            [xxlMaxCharacters]="60"
                            class="table-row-i1-title"></lt-text-truncate>
        </a>

        <ng-container [ngSwitch]="getPriority(session)">
          <!--<span *ngSwitchCase="0"
                [ngbTooltip]="'DASHBOARD.PRIORITY.NORMAL' | translate"
                container="body"
                class="priority-badge badge-success"><i class="far fa-star"></i>
          </span>-->
          <span *ngSwitchCase="1"
                [ngbTooltip]="'DASHBOARD.PRIORITY.MEDIUM' | translate"
                class="badge badge-priority-medium"
                container="body"><i class="fa fa-star-half-alt"></i>
        </span>
          <span *ngSwitchCase="2"
                [ngbTooltip]="'DASHBOARD.PRIORITY.HIGH' | translate"
                class="badge badge-priority-high"
                container="body"><i class="fas fa-star"></i>
        </span>
          <span *ngSwitchCase="3"
                [ngbTooltip]="'DASHBOARD.PRIORITY.CRITICAL' | translate"
                class="badge badge-priority-critical"
                container="body"><i class="fa fa-exclamation"></i>
        </span>
        </ng-container>

        <span *ngIf="getCommentCount(session) > 0"
              [ngbTooltip]="'DASHBOARD.SESSION_COMMENT_FOUND' | translate: {count: getCommentCount(session)}"
              class="badge badge-comment"
              container="body">
          <i class="fa fa-align-left"></i>
        </span>

        <span *ngIf="getDeclined(session).length > 0"
              [ngbTooltip]="'DASHBOARD.SESSION_ONE_DOC_REJECTED' | translate"
              class="badge-declined"
              container="body">
          <span class="refused-sign-status"><i class="fas fa-exclamation"></i></span>
        </span>

        <br>
        <i class="table-row-i1-date">
          ({{getOwnerName(session.id)}} {{session.createdOn | date: 'short' : undefined : translateService.currentLang}})
        </i>
      </div>
    </td>
    <td class="table-row-i2">
      <div class="status-container">
      <span [ngClass]="{
        'status-started': session.status === 'STARTED',
        'status-completed': session.status === 'COMPLETED',
        'status-created': session.status === 'CREATED',
        'status-canceled': session.status === 'CANCELED',
        'status-refused': session.status === 'REFUSED',
        'status-suspended': session.status === 'SUSPENDED'}"
            class="status-sm status text-uppercase">
      {{'SESSION.STATUS.' + session.status | translate}}
      
      </span>
      <span *ngIf="!!session.expired" class="status-expired-sm">
        <i container="body" 
        class="far fa-clock color-orange"
        [ngbTooltip]="'DASHBOARD.EXPIRED' | translate"
        ></i>
      </span>
      
      <span *ngIf="!!session.purgedOn">
        <i class="fas fa-broom color-red margin-left sm-purgedon" container="body"
           [ngbTooltip]="!!session.purgedOn ? ('DASHBOARD.SESSION_PURGED_ON' | translate) + ' ' + (session.purgedOn | date: 'short': undefined : translateService.currentLang) : ''">
        </i>
      </span>
    </div>
    </td>
    <td class="table-row-i3">
      <div class="table-row-i3-btns">
        <button (click)="openSession.emit(session.id)"
                [disabled]="disabled" [class.disabled]="disabled"
                [ngbTooltip]="(session.template ? 'DASHBOARD.OPEN_TEMPLATE' : 'DASHBOARD.OPEN_SESSION') | translate"
                class="action-button i3-edit icon-button"
                container="body">
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button (click)="deleteSession.emit(session)"
                *ngIf="getRequesterManager(session)=== true"
                [disabled]="disabled" [class.disabled]="disabled"
                [class.ml-1]="appService.userHasEntitlement(appService.ent.admin)"
                [ngbTooltip]="(session.template ? 'DASHBOARD.DELETE_TEMPLATE' : 'DASHBOARD.DELETE_SESSION') | translate"
                class="action-button i3-remove icon-button"
                container="body"><i class="fas fa-trash-alt"></i></button>
        <!--        <button (click)="disableSession.emit(session)"-->
        <!--                *ngIf="getRequesterManager(session) === true"-->
        <!--                [ngbTooltip]="'DASHBOARD.DISABLE_SESSION' | translate"-->
        <!--                class="i3-disable icon-button"-->
        <!--                container="body"><i class="fas fa-ban"></i></button>-->
      </div>
    </td>
  </tr>
</table>
