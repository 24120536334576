import {Component, OnInit, Input} from '@angular/core';
import {AppService} from 'src/app/services/services/app.service';
import {DocumentData, DocumentService, SessionData, SystemDocumentData} from "../../../../../luxtrust-cosi-api";
import {SYSTEM_DOCUMENT_TYPE, SESSION_STORAGE_SYSTEM_DOC_KEY} from 'src/app/app.constant';
import {DocumentationTypeEnum} from "../../../admin/documentation/documentation-type.enum";

@Component({
  selector: 'app-app-download-pdf',
  templateUrl: './app-download-pdf.component.html',
  styleUrls: ['./app-download-pdf.component.scss']
})
export class AppDownloadPdfComponent implements OnInit {
  signer;
  designer;
  watcher;
  admin;
  manageDocumentation;
  canUploadSignerPdf = false;
  canUploadWatcherPdf = false;
  canUploadDesignerPdf = false;
  canUploadADMINPdf = false;
  @Input() rightOfUser = [];
  helpSystemDoc: SystemDocumentData;

  constructor(private readonly appService: AppService,
              private documentService: DocumentService) {
  }

  ngOnInit() {
    this.appService.getUser$().subscribe((params) => {
      if (params && params.entitlementNames) {
        this.signer = params.entitlementNames.find(right => right === 'SIGNER');
        this.designer = params.entitlementNames.find(right => right === 'DESIGNER');
        this.watcher = params.entitlementNames.find(right => right === 'WATCHER');
        this.admin = params.entitlementNames.find(right => right === 'ADMIN');
        this.manageDocumentation = params.entitlementNames.find(right => right === 'MANAGE_DOCUMENTATION');
        this.checkRight();
      }
    });
  }
  checkRight() {
    if (this.admin) {
      this.canUploadADMINPdf = true;
    } else {
      if (this.designer) {
        this.canUploadDesignerPdf = true;
      } else {
        if (this.signer) {
          this.canUploadSignerPdf = true;
        } else {
          if (this.watcher) {
            this.canUploadWatcherPdf = true;
          }
        }
      }
    }
  }

  downloadPdf() {
    if (this.canUploadSignerPdf) {
      window.open('../../assets/pdf/DOCUMENT_SIGNATAIRE.pdf', '_blank');
    }
    if (this.canUploadDesignerPdf) {
      window.open('../../assets/pdf/DOCUMENT_DESIGNER.pdf', '_blank');
    }
    if (this.canUploadWatcherPdf) {
      window.open('../../assets/pdf/DOCUMENT_WATCHER.pdf', '_blank');
    }
    if (this.canUploadADMINPdf && !this.manageDocumentation) {
      window.open('../../assets/pdf/DOCUMENT_ADMIN.pdf', '_blank');
    }
    if(this.manageDocumentation){
      this.getHelpDocument();
    }
  }

  getHelpDocument() {
    let sessionSystemDocumentList = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY));
    this.helpSystemDoc = sessionSystemDocumentList.find(x => x.type === DocumentationTypeEnum.HELP);
    this.documentService.downloadDocument(this.helpSystemDoc.documentId, this.helpSystemDoc.sessionId, this.helpSystemDoc.stepId)
      .toPromise().then((fileResource: Blob) => {
      let file = new Blob([fileResource], {type: 'application/pdf'});
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  }

}
