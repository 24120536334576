import {DatePipe} from '@angular/common';
import {
  forwardRef,
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  NgbDatepicker, NgbDatepickerI18n, NgbDateStruct, NgbPopover, NgbPopoverConfig, NgbTimeStruct, Placement
} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import {noop} from 'rxjs';
import {DateTimeModel} from './date-time.model';
import {LangChangeEvent} from "@ngx-translate/core/lib/translate.service";

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [DatePipe, {
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTimePickerComponent), multi: true
  }, {
    provide: NgbDatepickerI18n, useClass: DateTimePickerComponent
  }]
})
export class DateTimePickerComponent extends NgbDatepickerI18n implements ControlValueAccessor, OnInit, AfterViewInit {

  @Input() id: string;
  @Input() dateString: string;
  @Input() hourStep = 1;
  @Input() minuteStep = 15;
  @Input() secondStep = 30;
  @Input() minHours: number;
  @Input() minMinutes: number;
  @Input() minDelta = 5;
  @Input() seconds = true;
  @Input() disabled = false;
  @Input() manualDisable = false;
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  @Input() leftLabel: string;
  @Input() placeholder: string;
  @Input() dateOnly = false;
  @Input() placement: Placement = 'auto';
  @Input() default: DateTimeModel;
  @Input() value: string;
  @Input() noMargin = false;
  @Input() idPostfix = '';
  @Output() errorDateBefore = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter<any>();
  ngControl: NgControl;
  inputDatetimeFormat: string;
  inputDateFormat: string;
  timeZoneFormat: string;
  private showTimePickerToggle = false;
  private datetime: DateTimeModel = new DateTimeModel();
  @ViewChild(NgbDatepicker, {static: false}) private dp: NgbDatepicker;
  @ViewChild(NgbPopover, {static: false}) private popover: NgbPopover;
  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;
  controlDateBefore: FormControl;
  dateToday = new Date();

  constructor(
    private config: NgbPopoverConfig,
    private inj: Injector,
    public translateService: TranslateService,
    private datePipe: DatePipe
  ) {
    super();
    config.autoClose = 'outside';
    config.placement = this.placement;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.prepareLang(event.lang);
    })
    this.prepareLang(this.translateService.currentLang);
  }

  ngOnInit() {
    this.controlDateBefore = new FormControl('', (control: FormControl) => {
      const value = control.value;

      if (!value) {
        return undefined;
      }

      if (this.minHours && value.hour <= this.minHours && value.minute <= this.minMinutes + 1 && (
        this.dateToday.getDate() === this.datetime.day && this.dateToday.getMonth() + 1 === this.datetime.month
        && this.dateToday.getFullYear() && this.datetime.year)) {
        return {tooEarly: true};
      }

      return undefined;
    });
    this.ngControl = this.inj.get(NgControl);
    if (this.default) {
      setTimeout(() => {
        this.datetime = this.default;
        this.dateString = this.datetime.toString();
        this.setDateStringModel();
      });
    }
  }

  ngAfterViewInit() {
    this.popover.hidden.subscribe(() => {
      this.showTimePickerToggle = false;
    });
  }

  prepareLang(lang: string) {
    switch (lang) {
      case 'fr':
      case 'FR':
        this.inputDatetimeFormat = 'dd/MM/yyyy HH:mm';
        this.inputDateFormat = 'dd/MM/yyyy';
        break;
      default:
        this.inputDatetimeFormat = 'MM/dd/yyyy HH:mm';
        this.inputDateFormat = 'MM/dd/yyyy';
        break;
    }
  }

  getWeekdayShortName(weekday: number): string {
    let translate = '';
    this.translateService.get('CALENDAR.SHORT_WEEKS').subscribe((v) => {
      translate = v[weekday - 1];
    });
    return translate;
  }

  getMonthShortName(month: number): string {
    let translate = '';
    this.translateService.get('CALENDAR.SHORT_MONTHS').subscribe((v) => {
      translate = v[month - 1];
    });
    return translate;
  }

  getMonthFullName(month: number): string {
    let translate = '';
    this.translateService.get('CALENDAR.MONTHS').subscribe((v) => {
      translate = v[month - 1];
    });
    return translate;
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return 'e';
  }

  writeValue(newModel: string) {
    if (newModel) {
      this.datetime = Object.assign(this.datetime, DateTimeModel.fromLocalString(newModel, this.translateService.currentLang));
      this.dateString = newModel;
      this.setDateStringModel();
    } else {
      this.datetime = new DateTimeModel();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  toggleDateTimeState($event) {
    this.showTimePickerToggle = !this.showTimePickerToggle;
    $event.stopPropagation();
  }

  setDisabledState(isDisabled: boolean) {
    // nothing to do
  }

  onInputChange($event: any) {
    const value = $event.target.value;
    const dt = DateTimeModel.fromLocalString(value, this.translateService.currentLang);

    if (dt) {
      this.datetime = dt;
      this.setDateStringModel();
    } else if (value.trim() === '') {
      this.datetime = new DateTimeModel();
      this.dateString = '';
    }
  }

  onDateChange($event: any) {
    if ($event.day < 10) {
      $event.day = '0' + $event.day.toString();
    }
    if ($event.year) {
      $event = `${$event.year}-${$event.month}-${$event.day}`;
    }
    const date = DateTimeModel.fromLocalString($event, this.translateService.currentLang);
    if (!date) {
      return;
    }
    if (!this.datetime) {
      this.datetime = date;
    }

    this.datetime.year = date.year;
    this.datetime.month = date.month;
    this.datetime.day = date.day;

    this.initHourOnDateBefore();

    if (!this.dateOnly) {
      this.showTimePickerToggle = true;
      this.setDateStringModel();
    } else {
      this.setDateStringModel();
      this.popover.close();
    }
  }

  controlErrorDateBefore() {
    this.errorDateBefore.emit(!!this.controlDateBefore.errors);
  }

  onTimeChange(event: NgbTimeStruct) {
    this.datetime.hour = event.hour;
    this.datetime.minute = event.minute;
    this.datetime.second = event.second;

    this.setDateStringModel();
  }

  setDateStringModel() {
    this.controlErrorDateBefore();
    this.dateString = this.datetime.toString();
    this.setTimeZoneFormatDate();
    if (this.dateOnly) {
      this.onChange(this.datetime.toDateString());
    } else {
      this.onChange(this.dateString);
    }
  }

  setTimeZoneFormatDate() {
    let positionOnHour = false;
    const sizeDate = new Date(this.dateString).toString().length;
    let foundSpace = false;
    let timeZone = '';
    for (let i = 0; i < new Date(this.dateString).toString().length; i++) {
      if (new Date(this.dateString).toString().charAt(i) === ':' && !positionOnHour) {
        positionOnHour = true;
        const dateWithZoneAndHour = new Date(this.dateString).toString().substring(i + 7, sizeDate);
        for (let j = 0; j < dateWithZoneAndHour.length; j++) {
          if (dateWithZoneAndHour.charAt(j) !== ' ' && !foundSpace) {
            timeZone = timeZone + dateWithZoneAndHour.charAt(j);
          } else {
            foundSpace = true;
          }
        }
      }
    }
    this.timeZoneFormat = timeZone;
  }

  inputBlur() {
    this.onTouched();
    this.onBlur.emit();
  }

  initHourOnDateBefore() {
    if (!this.datetime.hour && !this.datetime.hour && (
      this.dateToday.getDate() === this.datetime.day && this.dateToday.getMonth() + 1 === this.datetime.month
      && this.dateToday.getFullYear() && this.datetime.year) && this.minHours) {
      this.datetime.hour = this.minHours;
      if (this.minMinutes > 55) {
        if (this.datetime.hour < 23) {
          this.datetime.hour++;
        } else {
          this.datetime.hour = 0;
        }
        this.datetime.minute = this.minDelta;
      } else {
        this.datetime.minute = this.minMinutes + this.minDelta;
      }
    }
  }

  getDate() {
    return this.datePipe.transform(this.dateString, this.dateOnly ? this.inputDateFormat : this.inputDatetimeFormat);
  }
}
