import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NavigationExtras, Router} from '@angular/router';
import {TranslateService} from 'src/app/core/service/translate.service';
import cssVars from 'css-vars-ponyfill';
import {PlatformService} from 'luxtrust-cosi-api/api/platform.service';
import {SYSTEM_DOCUMENT_TYPE, CONFIG, SESSION_STORAGE_SYSTEM_DOC_KEY} from './app.constant';
import {AlertService} from './services/services/alert-service';
import {AppService} from './services/services/app.service';
import {ConfigurationService} from './services/services/configuration.service';
import {LanguagesService} from './services/services/languages.service';
import {UrlService} from './services/services/url.service';
import {VisualIdentityService} from './services/services/visual-identity.service';
import {DocumentationService} from "./admin/documentation/documentation.service";
import {DocumentData, SessionData, SystemDocumentData} from "../../luxtrust-cosi-api";
import {DocumentationTypeEnum} from "./admin/documentation/documentation-type.enum";
import {IEService} from "./services/services/ie.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalIsIeComponent} from "./shared/components/modal-is-ie/modal-is-ie.component";

@Component({
  selector: 'app-root', templateUrl: './app.component.html', styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
              private alertService: AlertService,
              public translateService: TranslateService,
              private urlService: UrlService,
              private languagesService: LanguagesService,
              private appService: AppService,
              private visualIdentityService: VisualIdentityService,
              private platformService: PlatformService,
              private configurationService: ConfigurationService,
              private titleService: Title,
              private documentationService: DocumentationService,
              private ieService: IEService,
              private modalService: NgbModal
  ) {
    // FIXME ALERT
    const oldNavigate = this.router.navigate;
    this.router.navigate = (function (commands, navigationExtras: NavigationExtras) {
      navigationExtras = navigationExtras || {};
      navigationExtras.queryParams = navigationExtras.queryParams || {};
      navigationExtras.queryParams['tenantName'] = appService.getUser() && appService.getUser().tenantName || undefined;
      navigationExtras.queryParamsHandling = '';
      return oldNavigate.call(this, commands, navigationExtras);
    }).bind(this.router);

    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang(languagesService.languages[0]);

    const userLang = translateService.getBrowserLang();

    // the lang to use, if the lang isn't available, it will use the default one
    if (languagesService.languages.some(lang => lang === userLang)) {
      translateService.use(userLang);
    } else {
      translateService.use('en');
    }

  }

  ngOnInit() {
    cssVars();
    if(this.ieService.checkIfIE()) {
      const modalRef = this.modalService.open(ModalIsIeComponent, {size: 'lg', backdrop: false});
    }
    this.appService.loadEnduser();
    const tenantName = this.urlService.getQueryParamByName('tenantName');
    this.configurationService.initPlatformConfiguration();
    this.configurationService.initTenantConfiguration(tenantName);
    this.configurationService.tenantConfig.then(tenantConfig => {
      this.appService.initHandlers(tenantConfig[CONFIG.TENANT.FRONTEND_TIMEOUT_MINUTES.KEY]);
      this.setDocumentTitle(tenantConfig);
      const tenantLanguageList = tenantConfig[CONFIG.TENANT.SUPPORTED_LANGUAGE_LIST.KEY];
      const languages = tenantLanguageList ? tenantLanguageList.split('|') : undefined;
      if (languages && languages.length !== 0) {
        this.languagesService.set(languages);
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang(languages[0]);

        const userLang = this.translateService.getBrowserLang();

        // the lang to use, if the lang isn't available, it will use the default one
        if (languages.some(lang => lang === userLang)) {
          this.translateService.use(userLang);
        }
      }
    });
    this.bootstrapSystemDocumentation();
    this.configurationService.platformConfig.then(config => this.visualIdentityService.initVisualIdentity(tenantName));
  }

  /**
   * check :
   * 1 - if session storage is null or
   * 2- if session storage is empty or
   * 3- if session storage don't have all system documentation def on DocumentationTypeEnum ["GDPR", "HELP", "OTHER", "FOOTER"]
   *
   * ==> Rebuild system documentation process
   *
   */
  private bootstrapSystemDocumentation() {
    const systemDocumentationStorage = sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY);
    let systemDocumentationType: SystemDocumentData[] = [];
    let currentDocumentationTypeValues;
    if (!systemDocumentationStorage || systemDocumentationStorage === "[]") {
      this.documentationService.buildSystemDocumentation();
    } else if (systemDocumentationStorage) {
      systemDocumentationType = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY));
      currentDocumentationTypeValues = systemDocumentationType.map(x => x.type);
      // check of sys doc enum length
      if (currentDocumentationTypeValues.length !== Object.keys(DocumentationTypeEnum).length) {
        this.documentationService.buildSystemDocumentation();
      }
    }
  }

  private setDocumentTitle(tenantConfig: { [key: string]: any; }) {
    const documentTitleConfig = tenantConfig[CONFIG.TENANT.FRONTEND_DOCUMENT_TITLE.KEY];
    if (documentTitleConfig) {
      this.titleService.setTitle(documentTitleConfig.trim());
    }
  }
}
