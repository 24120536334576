<div id="visiteDashboardStep5" class="status-counter-container">

  <a class="status-counter status-counter-created" (click)="filterBy(sessionStatusEnum.STARTED, null)">
    <div class="status-counter-icon">
      <i class="fas fa-hourglass"></i>
    </div>
    <div class="status-counter">
      <span>{{statusCounter?.created ? statusCounter.created : '0'}}</span>
      <span>{{'DASHBOARD.STATUS_COUNTER.CREATED' | translate}}</span>
    </div>
  </a>

  <a class="status-counter status-counter-completed" (click)="filterBy(sessionStatusEnum.COMPLETED, null)">
    <div class="status-counter-icon">
      <i class="fas fa-check"></i>
    </div>
    <div class="status-counter">
      <span>{{statusCounter?.completed ? statusCounter.completed : '0'}}</span>
      <span>{{'DASHBOARD.STATUS_COUNTER.COMPLETED' | translate}}</span>
    </div>
  </a>

  <a class="status-counter status-counter-refused" (click)="filterBy(null, signatureStatusEnum.DECLINED)">
    <div class="status-counter-icon">
      <i class="fas fa-times"></i>
    </div>
    <div class="status-counter">
      <span>{{statusCounter?.refused ? statusCounter.refused : '0'}}</span>
      <span>{{'DASHBOARD.STATUS_COUNTER.REFUSED' | translate}}</span>
    </div>
  </a>

  <a class="status-counter status-counter-cancelled" (click)="filterBy(sessionStatusEnum.CANCELED, null, true)">
    <div class="status-counter-icon">
      <i class="fas fa-ban"></i>
    </div>
    <div class="status-counter">
      <span>{{statusCounter?.canceled ? statusCounter.canceled : '0'}}</span>
      <span>{{'DASHBOARD.STATUS_COUNTER.CANCELLED' | translate}}</span>
    </div>
  </a>
</div>
