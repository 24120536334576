import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TagData} from 'luxtrust-cosi-api';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {ConfigurationData} from 'luxtrust-cosi-api/model/configurationData';
import {CreateEnduserPayload} from 'luxtrust-cosi-api/model/createEnduserPayload';
import {CreateStepEnduserPayload} from 'luxtrust-cosi-api/model/createStepEnduserPayload';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {StepTag} from 'src/app/services/enum/step-tag';
import {ApiError} from '../../../../../../error/api-error.model';
import {AlertService} from '../../../../../../services/services/alert-service';
import {AppService} from '../../../../../../services/services/app.service';
import {Status} from '../../../../../../shared/components/input-loader/input-loader.component';
import {StepEnduserComponent} from '../../../../model/stepEnduser.component';
import {wizardStepConfiguratorEnum} from '../../wizardStepConfigurator.enum';
import {ModalNewSignerComponent} from '../../../../../../shared/components/modal-new-signer/modal-new-signer.component';

@Component({
  selector: 'app-modal-configurator-watchers',
  templateUrl: './modal-configurator-watchers.component.html',
  styleUrls: ['./modal-configurator-watchers.component.scss']
})
export class ModalConfiguratorWatchersComponent extends StepEnduserComponent implements OnInit, OnChanges {

  @Input() manager: boolean;
  @Input() signer: boolean;
  @Input() configStep: ConfigurationData;
  @Input() page: number;
  @Input() stepTagList: TagData[];
  @Output() outputAddUser: EventEmitter<StepEnduserData> = new EventEmitter<StepEnduserData>();
  @Output() outputDeleteUser: EventEmitter<number> = new EventEmitter<number>();
  @Output() outputOrderSigner: EventEmitter<EnduserData[]> = new EventEmitter<EnduserData[]>();
  @Output() outputUpdateSigner: EventEmitter<{ expected: number, idCircle: number }> = new EventEmitter<{ expected: number, idCircle: number }>();

  replaceEnduserId: number;
  replaceMode: boolean;

  replaceAloneSigner = false;
  enableInviteNewSigner = true;
  enableInviteNewWatcher = true;
  disableSelectOptionForWatcher=true;
  loaderStatusWatcher: Status;
  availableWatcher: EnduserData[];

  addingUSer = false;
  removingUser = false;
  orderingUser = false;
  addingWatcher = false;
  removingWatcher = false;

  constructor(protected appService: AppService,
              protected stepEnduserService: StepEnduserService,
              protected modalService: NgbModal,
              protected alertService: AlertService,
              protected enduserService: EnduserService) {
    super(modalService, appService, stepEnduserService, alertService, enduserService);
  }

  protected addUsers(user: EnduserData) {
  }

  ngOnInit(): void {
    this.getStepEndusers();
    this.replaceAloneSigner = this.canReplace() && !this.canAdd() && this.stepEndusers.filter(enduser => enduser.watcher && !enduser.signer && !enduser.manager).length === 1
      && this.stepEndusers.filter(enduser => enduser.watcher && !enduser.signer && !enduser.manager)[0].enduser.id !== this.appService.getUser().id;
    this.checkStepTag();
  }

  checkStepTag() {
    this.enableInviteNewSigner = !this.stepTagList.some(tag => tag.alias === StepTag.INVITE_BUTTON_DISABLE);
  }

  showWatcher() {
    return this.stepTagList.some(tag => tag.alias === StepTag.CONFIGURATOR_WATCHER_SHOW);
  }

  getStepEndusers() {
    this.stepEndusers = this.stepEndusers.sort((a, b) => a.orderIndex - b.orderIndex);
    this.getSelectedUsers();
    this.getSelectedWatcher();
    this.getSelectedManager();
  }

  onUpdateExpected(event) {
    const circle = event.user;
    const nb = event.nb;
    this.stepEnduserService.partialUpdateStepEnduser(circle.id, this.sessionId, this.step.id, {
      signer: false,
      expected: nb
    }).subscribe(() => {
      this.outputUpdateSigner.emit({expected: parseInt(nb), idCircle: circle.id});
    });
  }

  openModal(replace?, oldEnduserId?: number ) {
    this.replaceEnduserId = oldEnduserId;
    const modalRef = this.modalService.open(ModalNewSignerComponent, {backdrop:false});
    (<ModalNewSignerComponent>modalRef.componentInstance).step = this.step;
    (<ModalNewSignerComponent>modalRef.componentInstance).enableInviteNewWatcher=true;
    modalRef.componentInstance.newSignerModalResult.subscribe(result => {
      if (replace) {
        this.onReplaceUsers(oldEnduserId, result, this.selectedUsers, () => {
          this.replaceEnduserId = undefined;
          this.replaceMode = this.replaceAloneSigner;
        });
      } else {
        this.addWatcher(result);
      }
    });
  }

  private can(action: wizardStepConfiguratorEnum) {
    return !!(
      this.manager
      &&
      this.configStep.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[action]
      ||
      (
        this.signer
        &&
        this.configStep.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[action]
      ));

  }

  canAdd(): boolean {
    return this.can(wizardStepConfiguratorEnum.ADD);
  }

  // TODO : Feature to add
  canClone() {
    return true;
  }

  canReplace() {
    return this.can(wizardStepConfiguratorEnum.REPLACE);
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  addWatcher(watcher: EnduserData) {
    this.loaderStatusWatcher = Status.LOAD;
    this.addingWatcher = true;
    let createStepEnduserPayload: CreateStepEnduserPayload;
    let createEndUserpayload: CreateEnduserPayload;

    if (watcher.id) {
      createStepEnduserPayload = {
        enduserId: watcher.id, signer: false, manager: false
      };
    } else {
      createEndUserpayload = {
        userId: watcher.email,
        email: watcher.email,
        lastName: watcher.lastName,
        firstName: watcher.firstName,
        phone: watcher.phone,
        directoryAlias: watcher.directoryAlias,
        organisationId: this.appService.getUser().organisation.organisationId
      };
      createStepEnduserPayload = {
        createEnduserPayload: createEndUserpayload, signer: false, manager: false
      };
    }
    this.stepEnduserService.createStepEnduser(this.sessionId, this.step.id, createStepEnduserPayload).subscribe((result) => {
      this.selectedWatcher.push(watcher);
      this.loaderStatusWatcher = Status.SUCCESS;
      this.outputAddUser.emit(result);
      this.addingWatcher = false;
    }, () => {
      this.loaderStatusWatcher = Status.ERROR;
      this.addingWatcher = false;
    });
  }

  onSearchWatcher(searchEndusers) {
    if (searchEndusers && searchEndusers.trim()) {
      this.enduserService.searchEnduser(undefined, undefined, 20, undefined, undefined, undefined, undefined, undefined, undefined, this.step.id, undefined, searchEndusers).subscribe(endusers => {
        this.availableWatcher = this.filterAvailableWatcher(endusers);
      }, (error: ApiError) => this.alertService.errorApi(error));
    } else {
      this.availableWatcher = [];
    }
  }

  private filterAvailableWatcher(endusers: EnduserData[]) {
    return endusers.filter((enduser: EnduserData) => {
      return enduser.id !== this.appService.getCurrentUserId()
        && !this.selectedWatcher.some((addressee: EnduserData) => enduser.id === addressee.id)
        && !this.selectedUsers.some((user: EnduserData) => enduser.id === user.id);
    });
  }

  removeWatcher(addressee: EnduserData) {
    this.removingWatcher = true;
    this.stepEnduserService.deleteStepEnduser(addressee.id, this.sessionId, this.step.id).toPromise().then(() => {
      const index: number = this.selectedWatcher.findIndex((item: EnduserData) => item.id === addressee.id);
      if (index > -1) {
        this.selectedWatcher.splice(index, 1);
        this.outputDeleteUser.emit(addressee.id);
      }
    }, (error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.removingWatcher = false);
  }
}
