import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import find from 'lodash/find';
import {AnnotationService} from 'luxtrust-cosi-api/api/annotation.service';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {PlaceholderService} from 'luxtrust-cosi-api/api/placeholder.service';
import {SprofileService} from 'luxtrust-cosi-api/api/sprofile.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {StepTagService} from 'luxtrust-cosi-api/api/stepTag.service';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {SprofileData} from 'luxtrust-cosi-api/model/sprofileData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {TagData} from 'luxtrust-cosi-api/model/tagData';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {ApiError} from '../../../../error/api-error.model';
import {AlertService} from '../../../../services/services/alert-service';
import {AppService} from '../../../../services/services/app.service';
import {SignerDetails} from '../../../../shared/components/document-item/document-item.component';
import {ModalPdfConsumerComponent} from '../../../../shared/components/modal-pdf/modal-pdf-consumer.component';
import {DocumentState} from '../../model/document-state';
import {DocumentStoreService} from '../../services/document-store.service';
import {StepActions} from '../../wizard/models/wizard.config';
import {EnduserService, SignatureWorkflowService} from '../../../../../../luxtrust-cosi-api';
import {NgxSpinnerService} from 'ngx-spinner';
import {takeUntil} from 'rxjs/operators';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {WizardService} from '../../wizard/services/wizard.service';

@Component({
  templateUrl: './wizard-step-documents.component.html', styleUrls: ['./wizard-step-documents.component.scss']
})
export class WizardStepDocumentsComponent extends ModalPdfConsumerComponent implements OnInit {

  stepActions = StepActions;
  session: SessionData;
  step: StepData;
  documents: DocumentState;
  sprofiles: SprofileData[] = [];
  hasStepTagPositionSignatureLocation = false;
  hasCheckStepTag = false;
  retakingDocument = false;
  cloningDocument = false;
  cloningAppendix = false;
  creatingMutualDocument = false;
  creatingMutualAppendix = false;

  constructor(public modal: NgbModal,
              public appService: AppService,
              public alertService: AlertService,
              public annotationService: AnnotationService,
              public placeholderService: PlaceholderService,
              private route: ActivatedRoute,
              public documentStoreService: DocumentStoreService,
              public enduserService: EnduserService,
              public stepEnduserService: StepEnduserService,
              private stepTagService: StepTagService,
              private stepService: StepService,
              public signatureWorkflowService: SignatureWorkflowService,
              private sprofileService: SprofileService,
              public documentService: DocumentService,
              public spinnerService: NgxSpinnerService,
              public wizardService: WizardService) {
    super(modal, appService, annotationService, alertService, documentService, placeholderService, stepEnduserService, signatureWorkflowService, documentStoreService, wizardService);
    this.documentStoreService.init();
  }

  selectedSigners(document?: DocumentData): EnduserData[] {
    if (!this.hasStepTagPositionSignatureLocation) {
      return this.signers;
    } else {
      const signerByDocument = find(this.signersByDocument, (docSigners) => docSigners.document.id === document.id);
      if (signerByDocument) {
        return signerByDocument.signers;
      }
      return undefined;
    }
    return undefined;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.documentStoreService.reset();
    this.route.parent.data.pipe(take(1)).subscribe((data: Data) => {
      this.session = data['session'];
      this.sessionId = this.session.id;
      this.step = data['step'];
      this.stepId = this.step.id;
      this.stepService.getStepList(this.session.id).subscribe((stepList) => {
        // Refresh step to be sure other wizard changes are fetched.
        // This could be done using the resolvers, and by forcing refresh: runGuardsAndResolvers: 'always'
        // Warning: This increases nb of API calls, as resolver will be called at every wizard page change
        this.step = stepList.find(currentStep => currentStep.id === this.step.id);
      }, (error: ApiError) => this.alertService.errorApi(error));
      this.checkStepTags().subscribe(() => {
        Promise.all([
          this.stepEnduserService.stepEnduserList(this.session.id, this.step.id).toPromise().then(stepEndusers => {
            const stepEnduserFilter = stepEndusers.filter(se => se.signer);
            this.signers = [];
            stepEnduserFilter.forEach(stepEnduser => {
              if (stepEnduser.enduser.circle) {
                this.putSignerCircleIntoList(stepEnduser.expected, stepEnduser.enduser, this.signers);
              } else {
                this.signers.push(stepEnduser.enduser);
              }
            });
            this.checkCurrentUserIntoStepEnduserList(stepEndusers);
          }),
          this.documentStoreService.getAllDocuments(this.session.id, this.step.id)
        ]).then(() => {
          this.refreshDocumentData();
        }).catch((error: ApiError) => {
          this.alertService.errorApi(error);
        });
      });
      this.sprofileService.getSprofileList().toPromise().then((res) => {
        this.sprofiles = res.filter(s => s.format !== 'OTHER');
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
    });
    super.ngOnInit();
  }

  checkStepTags(): Observable<TagData[]> {
    return this.stepTagService.getStepTagList(this.sessionId, this.step.id).pipe(tap(tags => {
      this.hasStepTagPositionSignatureLocation = tags.some(tag => tag.alias.includes('POSITION_SIGNATURE_LOCATION'));
      this.hasCheckStepTag = true;
    }));
  }

  reuploadDocument(data: { document: DocumentData, file: File }) {
    this.reuploadingDocument = true;
    this.wizardService.disableWizardHeaderItems = true;
    this.documentStoreService.reuploadDocument(this.session.id, this.step.id, data, () => {
      this.reuploadingDocument = this.documentStoreService.isStillUploading();
      this.wizardService.disableWizardHeaderItems = this.documentStoreService.isStillUploading();
      this.documentStoreService.getAllDocuments(this.session.id, this.step.id).add(() => {
        this.refreshDocumentData();
      });
    });
  }

  createMutualDocument(data: { document: DocumentData, isMutual: boolean }) {
    this.creatingMutualDocument = true;
    if (data.isMutual) {
      this.documentStoreService.createMutualDocument(this.sessionId, this.stepId, data.document, () => {
        this.creatingMutualDocument = false;
        this.refreshDocumentData();
      });
    } else {
      this.documentStoreService.deleteMutualDocument(this.sessionId, this.stepId, data.document, () => {
        this.creatingMutualDocument = false;
        this.refreshDocumentData();
      });
    }
  }

  createMutualAppendix(data: { document: DocumentData, isMutual: boolean }) {
    this.creatingMutualAppendix = true;
    if (data.isMutual) {
      this.documentStoreService.createMutualAppendix(this.sessionId, this.stepId, data.document, () => {
        this.creatingMutualAppendix = false;
        this.refreshDocumentData();
      });
    } else {
      this.documentStoreService.deleteMutualAppendix(this.sessionId, this.stepId, data.document, () => {
        this.creatingMutualAppendix = false;
        this.refreshDocumentData();
      });
    }
  }

  // todo disable wizard items
  cloneDocument(data: {files: FileList, document: DocumentData}, type: 'document' | 'appendix') {
    this.cloningDocument = true;
    this.documentStoreService.clone(this.sessionId, this.stepId, data, type, () => {
      this.cloningDocument = this.documentStoreService.isStillUploading();
      this.documentStoreService.getAllDocuments(this.sessionId, this.stepId).add(() => {
        this.refreshDocumentData();
      });
    });
  }

  retakeMutualDocument(data: { document: DocumentData, isMutual: boolean }) {
    if (data.isMutual) {
      this.retakingDocument = true;
      this.documentStoreService.retakeMutualDocument(this.session.id, this.step.id, data.document, () => {
        this.retakingDocument = false;
        this.refreshDocumentData();
      });
    }
  }

  retakeMutualAppendix(data: { document: DocumentData, isMutual: boolean }) {
    if (data.isMutual) {
      this.retakingDocument = true;
      this.documentStoreService.retakeMutualAppendix(this.session.id, this.step.id, data.document, () => {
        this.retakingDocument = false;
        this.refreshDocumentData();
      });
    }
  }

  getSignatures(documents: DocumentData[]): { [id: string]: SignerDetails[] } {
    const res = {};
    if (!this.step.skipAcroforms) {
      documents.forEach(d => res[d.name] = this.getSignersDetails(d.name));
    }
    return res;
  }

  pad(n: number) {
    return String(n).padStart(6, ' ');
  }

  onFailed() {
    this.documentStoreService.onFailed();
  }

  get mutualDocumentsSorted() {
    if (this.documents && this.documents.documentMutual) {
      return this.documents.documentMutual.sort((a, b) => a.originalDocumentId > b.originalDocumentId ? 1 : -1);
    }
    return [];
  }

  get documentsToSignSorted() {
    if (this.documents && this.documents.documentToSign) {
      return this.documents.documentToSign.sort((a, b) => a.originalDocumentId > b.originalDocumentId ? 1 : -1);
    }
    return [];
  }

  get appendixToSeeSorted() {
    if (this.documents && this.documents.appendixToSee) {
      return this.documents.appendixToSee.sort((a, b) => a.originalDocumentId > b.originalDocumentId ? 1 : -1);
    }
    return [];
  }
}
