<app-items-table [borderBottom]="borderBottom" [items]="selectedOrganisation" [noResult]="noResult"
    [singleList]="singleList">
    <ng-container action>
        <div class="app-input-text">
          <div #searchList class="form-group">
            <div class="input-group">
              <input #inputSearchOrganisation
                     (focus)="onFocusSearch()"
                     (input)="handleInputChange($event.target.value)"
                     (keydown)="signerKeydown($event)"
                     (ngModelChange)="onFocusSearch()"
                     [(ngModel)]="inputSearch"
                     autocomplete="off"
                     class="form-control"
                     id="inputSearchOrganisation{{idPostfix}}"
                     placeholder="{{placeholder}}">
              <div class="input-group-append">
                <div class="input-group-text">
                  <i class="fas fa-search"></i>
                </div>
              </div>
              <div [hidden]="!searchListOpen" class="search-results">
                <ul class="list-group list-unstyled">
                  <li *ngIf="!availableOrganisation || availableOrganisation?.length === 0" class="empty">
                    {{'WIZARD.ORGANISATION.NO_RESULT_FOUND' | translate}}
                  </li>
                  <li *ngFor="let organisation of availableOrganisation;let i = index"
                      [class.active]="i === activeOrganisationIndex"
                      class="list-group-item result">
                    <div [ngClass]="{'enduserInfo': search}" >
                    <a (click)="handleOnAddOrReplaceEnduser(organisation)">
                      {{organisation?.name}}
                    </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template  #itemsCards let-stepOrganisation>
        <app-organisations-item style="flex: auto;"
                          (delete)="handleOnAddRemove($event)"
                          (onUpdateExpected)="handleOnUpdateExpected($event)"
                          [organisation]="stepOrganisation">
        </app-organisations-item>
      </ng-template>
    </app-items-table>
