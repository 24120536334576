import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {CircleData, CircleOfEndusersService, EnduserData, UpdateCirclePayload} from '../../../../../luxtrust-cosi-api';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {REGEXES} from "../../../app.constant";

@Component({
  selector: 'app-modal-update-circle',
  templateUrl: './modal-update-circle.component.html',
  styleUrls: ['./modal-update-circle.component.scss']
})
export class ModalUpdateCircleComponent implements OnInit{

  @Input() circle: EnduserData;
  updateCircleForm: FormGroup;
  updating = false;

  constructor(public activeModal: NgbActiveModal,
              private circleService: CircleOfEndusersService,
              private alertService: AlertService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.initForms();
  }

  initForms() {
    this.updateCircleForm = this.formBuilder.group({
      circleName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(240)]],
      circleMail: ['', [Validators.pattern(REGEXES.EMAIL)]]
    });

    this.updateCircleNameControl.setValue(this.circle.lastName);
    this.updateCircleMailControl.setValue(this.circle.email);
  }

  get updateCircleNameControl(): FormControl {
    return this.updateCircleForm.get('circleName') as FormControl;
  }

  get updateCircleMailControl(): FormControl {
    return this.updateCircleForm.get('circleMail') as FormControl;
  }

  update() {
    this.updating = true;
    this.circleService.updateCircle(this.circle.id, this.createPayload()).subscribe((updatedCircle) => {
      this.activeModal.close(updatedCircle);
    }, (error: ApiError) => {
      this.updating = false
      this.alertService.errorApi(error);
    });
  }

  private createPayload(): UpdateCirclePayload{
    return {
      email: this.updateCircleMailControl.value,
      name: this.updateCircleNameControl.value
    }
  }
}
