/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateItemTagPayload } from '../model/createItemTagPayload';
import { TagData } from '../model/tagData';
import { UpdateItemTagPayload } from '../model/updateItemTagPayload';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentTagService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create document tag
     * 
     * @param documentId Document
     * @param sessionId Session
     * @param stepId Step
     * @param payload Tag to create
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDocumentTag(documentId: number, sessionId: number, stepId: number, payload?: CreateItemTagPayload, observe?: 'body', reportProgress?: boolean): Observable<TagData>;
    public createDocumentTag(documentId: number, sessionId: number, stepId: number, payload?: CreateItemTagPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TagData>>;
    public createDocumentTag(documentId: number, sessionId: number, stepId: number, payload?: CreateItemTagPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TagData>>;
    public createDocumentTag(documentId: number, sessionId: number, stepId: number, payload?: CreateItemTagPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling createDocumentTag.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling createDocumentTag.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling createDocumentTag.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TagData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/tags`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete document tag
     * 
     * @param documentId Document
     * @param sessionId Session
     * @param stepId Step
     * @param tagId Tag
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDocumentTag(documentId: number, sessionId: number, stepId: number, tagId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDocumentTag(documentId: number, sessionId: number, stepId: number, tagId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDocumentTag(documentId: number, sessionId: number, stepId: number, tagId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDocumentTag(documentId: number, sessionId: number, stepId: number, tagId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling deleteDocumentTag.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling deleteDocumentTag.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling deleteDocumentTag.');
        }

        if (tagId === null || tagId === undefined) {
            throw new Error('Required parameter tagId was null or undefined when calling deleteDocumentTag.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/tags/${encodeURIComponent(String(tagId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get document tag
     * 
     * @param documentId Document
     * @param sessionId Session
     * @param stepId Step
     * @param tagId Tag
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentTag(documentId: number, sessionId: number, stepId: number, tagId: number, observe?: 'body', reportProgress?: boolean): Observable<TagData>;
    public getDocumentTag(documentId: number, sessionId: number, stepId: number, tagId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TagData>>;
    public getDocumentTag(documentId: number, sessionId: number, stepId: number, tagId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TagData>>;
    public getDocumentTag(documentId: number, sessionId: number, stepId: number, tagId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getDocumentTag.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getDocumentTag.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getDocumentTag.');
        }

        if (tagId === null || tagId === undefined) {
            throw new Error('Required parameter tagId was null or undefined when calling getDocumentTag.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TagData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/tags/${encodeURIComponent(String(tagId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of document tags
     * 
     * @param documentId Document
     * @param sessionId Session
     * @param stepId Step
     * @param replacePlaceholders replacePlaceholders
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentTagList(documentId: number, sessionId: number, stepId: number, replacePlaceholders?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<TagData>>;
    public getDocumentTagList(documentId: number, sessionId: number, stepId: number, replacePlaceholders?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TagData>>>;
    public getDocumentTagList(documentId: number, sessionId: number, stepId: number, replacePlaceholders?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TagData>>>;
    public getDocumentTagList(documentId: number, sessionId: number, stepId: number, replacePlaceholders?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getDocumentTagList.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getDocumentTagList.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling getDocumentTagList.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (replacePlaceholders !== undefined && replacePlaceholders !== null) {
            queryParameters = queryParameters.set('replacePlaceholders', <any>replacePlaceholders);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TagData>>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/tags`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a document tag value
     * 
     * @param documentId Document
     * @param sessionId Session
     * @param stepId Step
     * @param payload Tag to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDocumentTagValue(documentId: number, sessionId: number, stepId: number, payload?: UpdateItemTagPayload, observe?: 'body', reportProgress?: boolean): Observable<TagData>;
    public updateDocumentTagValue(documentId: number, sessionId: number, stepId: number, payload?: UpdateItemTagPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TagData>>;
    public updateDocumentTagValue(documentId: number, sessionId: number, stepId: number, payload?: UpdateItemTagPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TagData>>;
    public updateDocumentTagValue(documentId: number, sessionId: number, stepId: number, payload?: UpdateItemTagPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling updateDocumentTagValue.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling updateDocumentTagValue.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling updateDocumentTagValue.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<TagData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/steps/${encodeURIComponent(String(stepId))}/documents/${encodeURIComponent(String(documentId))}/tags`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
