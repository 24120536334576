/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Auth otp payload
 */
export interface OtpPayload { 
    /**
     * OTP Action
     */
    action?: OtpPayload.ActionEnum;
    /**
     * Auth code
     */
    code?: string;
    /**
     * Indicates if the code MUST be sent again or not
     */
    mustSendCode?: boolean;
    /**
     * session Id
     */
    sessionId?: number;
    /**
     * step Id
     */
    stepId?: number;
}
export namespace OtpPayload {
    export type ActionEnum = 'GENERATE' | 'VERIFY';
    export const ActionEnum = {
        GENERATE: 'GENERATE' as ActionEnum,
        VERIFY: 'VERIFY' as ActionEnum
    };
}
