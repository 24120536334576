<app-card [customPadding]="true" *ngIf="step.tags.length > 0">
  <ul class="list-group mt-2">
    <ng-container *ngFor="let tag of step.tags ; trackBy: trackSteptag">
      <li *ngIf="tag?.configurator" class="list-group-item">
        <app-metadata-selector
            [showMode]="true"
            [canHaveNullToggle]="false"
            (onUpdate)="updateStepTagMeta($event)"
            (tagInError)="checkTagError($event)"
            [tag]="tag">
        </app-metadata-selector>
      </li>
    </ng-container>
  </ul>
</app-card>
<span class="text-danger form-text" *ngIf="tagMandatoryHasNoValue">
  {{'WIZARD.CONFIG.MANDATORY_TAGS_ERROR'| translate}}</span>
