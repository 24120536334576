import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { FormElement } from '../../form.element';
export class AutocompleteCustomComponent extends FormElement {
    constructor() {
        super(...arguments);
        // Option change is managed into function "manageValuesFormChanged" into parent
        this.datasProviders = [];
        this.sortAsc = true;
        this.isLangue = false;
        this.isFilter = false;
        this.filterWithInput = true;
        this.addEnduser = false;
        this.focusOnInput = false;
        this.testId = '';
        this.isDisabled = false;
        this.valueInputChange = new EventEmitter();
        this.control = new FormControl();
    }
    set _isDisabled(value) {
        this.isDisabled = value;
        this.isDisabled ? this.control.disable() : this.control.enable();
    }
    ngOnInit() {
        if (this.focusOnInput) {
            setTimeout(() => {
                this.inputSearchUser.nativeElement.focus();
                this.inputAutoComplit.closePanel();
            }, 10);
        }
        if (this.ngControl.value) {
            this.control.setValue(this.ngControl.value);
            this.valueChoose = this.ngControl.value;
        }
        this.ngControl.valueChanges.subscribe(value => {
            this.control.setValue(this.ngControl.value);
            this.valueChoose = this.ngControl.value;
        });
        if (this.filterWithInput) {
            this.setFilteredOptions();
        }
        else {
            this.inputSub = this.control.valueChanges.subscribe((value) => {
                if (value && value.length > 2) {
                    this.valueInputChange.emit(value);
                }
                else {
                    this.datasProviders = [];
                }
            });
        }
    }
    setFilteredOptions() {
        this.filteredOptions = this.control.valueChanges
            .pipe(startWith(''), map(value => this._filter(value)));
    }
    ngOnChanges(changes) {
        if (changes['datasProviders'] && this.filterWithInput) {
            this.setFilteredOptions();
        }
        if (changes['newValue'] && !changes['newValue'].firstChange) {
            this.onChange(this.newValue);
            if (!this.newValue.value) {
                this.valueChoose = undefined;
                this.clearInput(false);
            }
            this.newValue = undefined;
        }
    }
    display(data) {
        if (data) {
            return data.key;
        }
        return '';
    }
    selectOption($event) {
        this.onChange($event.option.value);
        this.valueChoose = $event.option.value;
        if (this.addEnduser) {
            this.clearInput(false);
            this.onChange(undefined);
        }
    }
    _filter(value) {
        let filterValue;
        if (value) {
            if (value.key) {
                filterValue = value.key;
            }
            else {
                filterValue = value;
            }
            return this.datasProviders.filter(option => this.normalize(option.key.toLowerCase()).includes(this.normalize(filterValue.toString().toLowerCase())));
        }
        return this.datasProviders;
    }
    normalize(value) {
        return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    clearInput(open) {
        if (this.control.value) {
            this.control.setValue(undefined);
            if (open) {
                this.inputAutoComplit.openPanel();
            }
        }
    }
    manageLabel() {
        if (this.control && this.control.value && this.control.value.key !== '') {
            return 'always';
        }
        else {
            return 'never';
        }
    }
    checkValue() {
        if (!this.control.value && this.valueChoose && !this.addEnduser) {
            this.control.setValue(this.valueChoose);
        }
    }
}
