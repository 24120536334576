/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Tag to be created
 */
export interface CreateTagPayload { 
    /**
     * Alias
     */
    alias?: string;
    /**
     * Default value
     */
    defaultValue?: string;
    /**
     * Handler description
     */
    handler?: string;
    /**
     * Whether might be mandatory
     */
    mandatory?: boolean;
    /**
     * Input mark
     */
    mask?: string;
    /**
     * Possible values for the tag
     */
    possibleValues?: Array<string>;
    /**
     * Type
     */
    type?: CreateTagPayload.TypeEnum;
}
export namespace CreateTagPayload {
    export type TypeEnum = 'INPUT' | 'LIST' | 'TOGGLE' | 'DATE' | 'DATETIME' | 'PERIOD' | 'LONG' | 'MEMO' | 'LINK' | 'RULE';
    export const TypeEnum = {
        INPUT: 'INPUT' as TypeEnum,
        LIST: 'LIST' as TypeEnum,
        TOGGLE: 'TOGGLE' as TypeEnum,
        DATE: 'DATE' as TypeEnum,
        DATETIME: 'DATETIME' as TypeEnum,
        PERIOD: 'PERIOD' as TypeEnum,
        LONG: 'LONG' as TypeEnum,
        MEMO: 'MEMO' as TypeEnum,
        LINK: 'LINK' as TypeEnum,
        RULE: 'RULE' as TypeEnum
    };
}
