<div class="modal-header">
  <h4 class="modal-title">
    {{'MODAL_TEMPLATE_SESSION.CREATE_SESSION_TEMPLATE' | translate}}
  </h4>
  <button (click)="activeModal.dismiss('cancel')" class="close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="newSessionForm" *ngIf="templates && templates.length !== 0; else noTemplate" class="modal-form">
    <div class="form-group">
      <label>{{'MODAL_TEMPLATE_SESSION.SELECT_SESSION_TEMPLATE' | translate}}</label>
      <div class="position-input-field">
        <mat-form-field class="formFieldCustom">
          <input #template
                  [formControl]="selectedTemplateControl"
                  [value]="selectedTemplateControl"
                  [matAutocomplete]="auto"
                  (click)="clearInput()"
                  tabindex="-1"
                  type="text"
                  aria-label="Number"
                  matInput>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let template of templates | filter : selectedTemplateControl.value : 'label'| orderBy : ['label']"
              (onSelectionChange)="selectTemplate($event, template)" (click)="onOptionClick($event)">
              {{template.label}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <app-input-text [id]="'searchInput'"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="value"
                      [placeholder]="searchInputPlaceholder"
                      icon="search">
      </app-input-text>
    </div>

    <div class="form-group">
      <label #sessionName for="rename-template">{{'MODAL_CREATE_SESSION.TITLE' | translate}}</label>
      <input #sessionNameInput
              [placeholder]="'MODAL_CREATE_SESSION.INPUT' | translate"
              [value]="selectedTemplate?.label"
              [formControl]="sessionNameControl"
              (keyup)="onSessionNameInputKeyUp($event)"
              class="form-control"
              id="rename-template"
              name="editTemplateLabel"
              type="text">
      <div *ngIf="sessionNameControl?.hasError('maxlength')" class="error-message">
        {{'MODAL_CREATE_TEMPLATE.INVALID_MAX_LENGTH' | translate}}
      </div>
    </div>
  </form>


  <ng-template #noTemplate>
    {{'MODAL_TEMPLATE_SESSION.NO_TEMPLATE_FOUND' | translate}}
  </ng-template>
  <div class="d-flex justify-content-between" *ngIf="!selectedTemplateConfiguratorEnabled">
    <div class="switcher switcher-small switcher-right ml-0" *ngIf="!selectedTemplateConfiguratorEnabled">
      <input #startAutoSwitch class="switcher-checkbox ng-untouched ng-pristine ng-valid" id="startAutoSwitch"
        ng-reflect-model="false" ng-reflect-ngb-tooltip="Encrypted" type="checkbox">
      <div class="switcher-toggle"></div>
      <label for="startAutoSwitch">{{'MODAL_TEMPLATE_SESSION.AUTO_START' | translate}}</label>
    </div>
  </div>
</div>


<div class="modal-footer">
  <button (click)="create()" id="configurator-create-new-session" [disabled]="isCreateButtonCurrentlyDisabled()" class="btn btn-primary"
    type="button">{{'MODAL_TEMPLATE_SESSION.CREATE_SESSION' | translate}}</button>

  <button (click)="activeModal.dismiss('cancel')" class="btn btn-cancel"
    type="button">{{'MODAL_TEMPLATE_SESSION.CANCEL' | translate}}</button>
</div>
