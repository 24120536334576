import {Component, OnInit} from '@angular/core';
import {DocumentData, SessionData, DocumentService, SystemDocumentData} from "../../../../../luxtrust-cosi-api";
import {DocumentationService} from "../../../admin/documentation/documentation.service";
import {SYSTEM_DOCUMENT_TYPE, SESSION_STORAGE_SYSTEM_DOC_KEY} from 'src/app/app.constant';
import {DocumentationTypeEnum} from "../../../admin/documentation/documentation-type.enum";
import {AppService} from 'src/app/services/services/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer-component.html',
  styleUrls: ['./footer-component.scss']
})
export class FooterComponent implements OnInit {
  footerSystemDoc: SystemDocumentData ;

  constructor(private documentationService: DocumentationService,
              private documentService: DocumentService,
              public appService: AppService) {

  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    let sessionSystemDocumentList: SystemDocumentData[] = [];
    if (!sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)) {
      this.documentationService.sessionSystemDocumentListSubject.subscribe(data => {
        this.footerSystemDoc = data.find(x => x.type === DocumentationTypeEnum.FOOTER);
      });
    } else {
      sessionSystemDocumentList = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY));
      this.footerSystemDoc = sessionSystemDocumentList.find(x => x.type === DocumentationTypeEnum.FOOTER);
    }
  }

  downloadPdf() {
    this.documentService.downloadDocument(this.footerSystemDoc.documentId, this.footerSystemDoc.sessionId, this.footerSystemDoc.stepId)
      .toPromise().then((fileResource: Blob) => {
      let file = new Blob([fileResource], {type: 'application/pdf'});
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  }

  hasGdpr() {
    if (this.appService.isLoggedIn()) {
      return this.appService.getUser() ? this.appService.getUser().entitlementNames.find(ent => ent === 'GDPR') : false;
    }
    return false;
  }

}
