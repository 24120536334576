/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Enduser attributes
 */
export interface EnduserAttributeData { 
    /**
     * Is the attribute hidden
     */
    hidden?: boolean;
    /**
     * Attribute key
     */
    key?: string;
    /**
     * Is the attribute read only
     */
    readOnly?: boolean;
    /**
     * Attribute type
     */
    type?: EnduserAttributeData.TypeEnum;
    /**
     * Attribute value
     */
    value?: string;
}
export namespace EnduserAttributeData {
    export type TypeEnum = 'INPUT' | 'LIST' | 'TOGGLE' | 'DATE' | 'DATETIME' | 'PERIOD' | 'LONG' | 'MEMO' | 'LINK' | 'RULE';
    export const TypeEnum = {
        INPUT: 'INPUT' as TypeEnum,
        LIST: 'LIST' as TypeEnum,
        TOGGLE: 'TOGGLE' as TypeEnum,
        DATE: 'DATE' as TypeEnum,
        DATETIME: 'DATETIME' as TypeEnum,
        PERIOD: 'PERIOD' as TypeEnum,
        LONG: 'LONG' as TypeEnum,
        MEMO: 'MEMO' as TypeEnum,
        LINK: 'LINK' as TypeEnum,
        RULE: 'RULE' as TypeEnum
    };
}
