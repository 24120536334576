import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';

import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {take} from 'rxjs/operators';
import {StepsName, WizardConfigStep} from '../../models/wizard.config';
import {WizardService} from '../../services/wizard.service';

@Component({
  selector: 'app-wizard-header', templateUrl: './wizard-header.component.html', styleUrls: ['./wizard-header.component.scss']
})
export class WizardHeaderComponent implements OnInit {
  stepsName = StepsName;

  steps: WizardConfigStep[];
  timeOut;

  sessionData: SessionData;
  stepData: StepData;


  constructor(public wizardService: WizardService,
              public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.wizardService.isActive();
    this.steps = this.wizardService.wizardConfig.steps;

    this.route.data.pipe(take(1)).subscribe((data: Data) => {
      this.sessionData = data['session'];
      this.stepData = data['step'];
    });
  }

  goToStep(step) {
    this.wizardService.goToStepByPath(step.path);
  }

  panLeft() {
    clearTimeout(this.timeOut);

    this.timeOut = setTimeout(() => {
      if(!this.wizardService.disableWizardHeaderItems) {
        this.wizardService.previousStep();
      }
    }, 100);
  }

  panRight() {
    clearTimeout(this.timeOut);

    this.timeOut = setTimeout(() => {
      if(!this.wizardService.disableWizardHeaderItems) {
        this.wizardService.previousStep();
      }
    }, 100);
  }

  get filteredSteps() {
    if (!this.wizardService.sessionIsTemplate && !this.sessionData.template) {
      return this.steps.filter(step => step.path !== this.stepsName.config);
    }
    return this.steps;
  }
}
