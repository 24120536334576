<div #header class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'MODAL_DELETE_CIRCLE_MEMBER.TITLE' | translate}}</h4>
  <button (click)="activeModal.dismiss()" class="close">
    <span aria-hidden="true"><i class="fas fa-times"></i></span>
  </button>
</div>

<div class="modal-body break-words">
  {{'MODAL_DELETE_CIRCLE_MEMBER.MESSAGE' | translate}}
</div>

<div class="modal-footer">
  <button (click)="delete()" class="btn btn-primary" [disabled]="deleting"
          type="button">{{'MODAL_DELETE_CIRCLE_MEMBER.DELETE' | translate}}</button>
  <button (click)="activeModal.close()" class="btn btn-cancel"
          type="button">{{'MODAL_DELETE_CIRCLE_MEMBER.CLOSE' | translate}}</button>
</div>
