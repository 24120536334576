<app-tooltip [tooltipText]="'HELP.VALIDATION' | translate"></app-tooltip>

<div class="fixed-container">
    <div *ngIf="!showResult" class="fixed-lg justify-content-center d-flex flex-column">
        <h1 class="mt-4 mb-3">{{'VALIDATION.REPORT.LABEL.TITLE' | translate}}</h1>

        <form [formGroup]="validationForm" class="row pb-3">
            <div class="col-12">
                <!-- document -->
                <app-input-file (ngModelChange)="updateInput($event)"
                    [browseLabel]="'VALIDATION.FORM.BROWSELABEL' | translate" [errors]="{
                        required: 'VALIDATION.ERRORS.REQUIRED' | translate,
                        sizeLimit: 'VALIDATION.ERRORS.SIZELIMIT_EXCEDEED' | translate : {filename: getFilename(), maxSize: this.FILE_SIZE_LIMIT},
                        supportedFormats: 'VALIDATION.ERRORS.INVALID_FILE_EXTENSION' | translate : {supportedFormats: 'pdf'}
                    }" [id]="'input-document'" [label]="'VALIDATION.FORM.LABEL' | translate" [multiple]="false"
                    [placeholder]=placeHolder [tabindex]="0" class="col-12" formControlName="file" icon="image">
                </app-input-file>


                <div class="d-flex">
                    <!-- submit -->
                    <button [disabled]="validationForm.invalid" (click)="onSubmit()"
                        class="btn flex-grow-1 btn-primary mr-2" tabindex="1" id="submitButton"
                        type="submit">{{'VALIDATION.FORM.SUBMIT' | translate}}</button>

                    <!-- clear -->
                    <button [disabled]="false" (click)="onReset()" class="btn flex-grow-1 btn-primary mr-2" tabindex="2"
                        id="resetButton" type="button">{{'VALIDATION.FORM.RESET' | translate}}</button>
                </div>

            </div>
        </form>
    </div>

    <div *ngIf="showResult" class="fixed-lg justify-content-center d-flex flex-column">
        <h1 class="mt-4 mb-3">{{'VALIDATION.REPORT.LABEL.TITLE' | translate}}</h1>

        <app-validation-report [validationReport]='validationReport' [fileName]='getFilename()'></app-validation-report>

        <div class="d-flex mt-4 mb-4">
            <!-- download PDF-->
            <!-- <button [disabled]="false" (click)="onDownloadPDF()" class="btn flex-grow-1 btn-primary mr-2" tabindex="3"
                id="downloadButton" type="button">{{'VALIDATION.REPORT.LABEL.DOWNLOAD' | translate}} PDF</button> -->
            <!-- download XML-->
            <button [disabled]="false" (click)="onDownloadXML()" class="btn flex-grow-1 btn-primary mr-2" tabindex="3"
                id="downloadButton" type="button">{{'VALIDATION.REPORT.LABEL.DOWNLOAD' | translate}} XML</button>
            <!-- close -->
            <button [disabled]="false" (click)="onClose()" class="btn flex-grow-1 btn-secondary mr-2" tabindex="4"
                id="closeButton" type="button">{{'VALIDATION.REPORT.LABEL.CLOSE' | translate}}</button>
        </div>
    </div>
</div>

<!-- loader -->
<ngx-spinner fullScreen="true" size="large" type="ball-clip-rotate">
    <p style="color: white">{{'VALIDATION.SPINNER_TEXT' | translate}}</p>
</ngx-spinner>