import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WizardConfig, WizardConfigStep} from '../models/wizard.config';

@Injectable()
export class WizardService {

  wizardConfig: WizardConfig;
  currentIndex = 0;
  previousIndex = -1;
  nextIndex = 1;
  disableWizardHeaderItems = false;

  sessionIsTemplate: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  /**
   * Check if the last param of the current url is equal to
   * @param step
   */
  isActive() {
    const urlArray = this.router.url.split('?');
    urlArray.pop();
    const currentStep = this.wizardConfig.steps.find(
      (step: WizardConfigStep) => step.path.toLowerCase() === urlArray[0].split('/').pop().toLowerCase());

    if (currentStep) {
      this.currentIndex = currentStep.index;
      this.previousIndex = this.currentIndex - 1;
      this.nextIndex = this.currentIndex + 1;
    }
  }

  /**
   * Go to a step with the path of the param
   * @param path
   */
  goToStepByPath(path: string) {

    const index = this.wizardConfig.steps.findIndex(item => item.path === path);

    if (index >= 0 && index < this.wizardConfig.steps.length) {

      this.currentIndex = index;
      this.previousIndex = index - 1;
      this.nextIndex = index + 1;

      this.navigateByIndex(this.currentIndex);
    }
  }

  /**
   * Go to the next step
   */
  nextStep() {
    if (this.currentIndex + 1 < this.wizardConfig.steps.length) {
      this.currentIndex += 1;
      this.previousIndex = this.currentIndex - 1;
      this.nextIndex = this.currentIndex + 1;
      this.navigateByIndex(this.currentIndex);
    }
  }

  /**
   * Go the the previous step
   */
  previousStep() {
    if (this.currentIndex - 1 >= 0) {
      this.currentIndex -= 1;
      this.previousIndex = this.currentIndex - 1;
      this.nextIndex = this.currentIndex + 1;
      this.navigateByIndex(this.currentIndex);
    }
  }

  /**
   * Navigate to the step locate at the index (param) in this.wizardConfig.steps
   * If the index is valide: >= 0 et < this.wizardConfig.steps.length
   * @param index
   */
  navigateByIndex(index: number) {
    if (index >= 0 && index < this.wizardConfig.steps.length) {
      this.currentIndex = index;
      this.router.navigate([this.wizardConfig.steps[index].path], {relativeTo: this.wizardConfig.relativeTo});
    }
  }
}
