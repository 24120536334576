import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/internal/operators';
import {AccessToken} from '../model/access-token.model';
import {AlertService} from '../services/alert-service';
import {AppService} from '../services/app.service';
import {NexuService} from '../services/nexu.service';

@Injectable()
export class CountDashboardHttpRequestInterceptor implements HttpInterceptor {

  constructor(private appService: AppService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('search-count')) {
      this.appService.numberOfRequest++;
    }
    return next.handle(request).pipe(finalize(()=> {
      if (request.url.includes('search-count')) {
        this.appService.numberOfRequest--;
      }
    }));
  }

}
