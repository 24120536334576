<div class="metadata-selector">
  <div *ngIf="showMode && tag.label != undefined" class="metadata-alias">{{this.tag.label}}</div>
  <div *ngIf="showMode && tag.label == undefined" class="metadata-alias">{{this.tag.alias}}</div>

  <div *ngIf="showInput || !tag?.encrypted && !isWatcher" class="metadata-input">
    <div class="input-group my-0" *ngIf="tag?.type === typeEnum.INPUT"
         [ngClass]="{'disabled-metadata': tag.readOnly || disableAll,
         'in-error': tagValueIsTooLong(tag.defaultValue)}">
      <input *ngIf="tag?.mask"
             type="text"
             class="metadata-input"
             (input)="checkError()"
             [(ngModel)]="showMode ? tag.value  : tag.defaultValue"
             (ngModelChange)="tag.value = $event"
             [disabled]="tag.readOnly || disableAll"
             placeholder="{{'TAGS.DEFAULT_VALUE' | translate}}"
             [mask]="tag?.mask"
             [showMaskTyped]="true"
             (change)="onChange($event.target?.value)"
             (blur)="sendUpdate()">

      <input *ngIf="!tag?.mask"
             type="text"
             class="metadata-input"
             (input)="checkError()"
             [(ngModel)]="showMode ? tag.value  : tag.defaultValue"
             (ngModelChange)="tag.value = $event"
             [disabled]="tag.readOnly || disableAll"
             placeholder="{{'TAGS.DEFAULT_VALUE' | translate}}"
             (change)="onChange($event.target?.value)"
             (blur)="sendUpdate()">
    </div>

    <div class="input-group my-0" *ngIf="tag?.type === typeEnum.LINK"
         [ngClass]="{'disabled-metadata': tag.readOnly || disableAll,
                    'in-error': checkFormatTagLink()}">
      <input type="text"
             class="metadata-input link-name"
             [ngModel]="getLinkName()"
             (input)="checkError()"
             (ngModelChange)="setLinkName($event)"
             [disabled]="tag.readOnly || disableAll"
             placeholder="{{'TAGS.LINK_NAME' | translate}}"
             (change)="onChange($event.target?.value)"
             (blur)="sendUpdate()">

      <input type="text"
             class="metadata-input link-value"
             [ngClass]="{'in-error': checkFormatTagLink()}"
             [ngModel]="getLinkValue()"
             (ngModelChange)="setLinkValue($event)"
             (input)="checkError()"
             [disabled]="tag.readOnly || disableAll"
             placeholder="{{'TAGS.LINK_VALUE' | translate}}"
             (change)="onChange($event.target?.value)"
             (blur)="sendUpdate()">
    </div>
    <label *ngIf="tag?.type === typeEnum.LINK && checkFormatTagLink()" class="error-message">
      {{'TAGS.LINK_FORMAT_ERROR' | translate}}
    </label>

    <label *ngIf="tagLinkValueIsTooLong(tag?.value)"
           class="error-message"> {{'TAGS.MAX_LENGTH' | translate:{maxLength: maxInputLinkLength} }}</label>

    <div *ngIf="showInput || !tag?.encrypted" class="metadata-input">
      <div class="input-group my-0" *ngIf="tag?.type === typeEnum.MEMO"
           [ngClass]="{'disabled-metadata': tag.readOnly || disableAll,
           'in-error': tagValueIsTooLong(tag?.defaultValue)}">
        <textarea class="form-control"
                  placeholder="{{'TAGS.DEFAULT_VALUE' | translate}}"
                  (input)="checkError()"
                  [(ngModel)]="showMode ? tag.value : tag.defaultValue"
                  [disabled]="tag.readOnly || disableAll"
                  (change)="onChange($event.target?.value)"
                  (blur)="sendUpdate()"
                  type="text">
        </textarea>
      </div>
    </div>

    <label
      *ngIf="tag ? tagValueIsTooLong(tag?.defaultValue) && (tag?.type === typeEnum.INPUT || tag?.type === typeEnum.MEMO) : false"
      class="error-message">{{'TAGS.MAX_LENGTH' | translate:{maxLength: maxInputLength} }}</label>

    <div class="input-group my-0" *ngIf="tag?.type === typeEnum.LONG"
         [ngClass]="{'disabled-metadata': tag.readOnly || disableAll}">
      <input type="number"
             class="metadata-input"
             (input)="checkError()"
             [(ngModel)]="showMode ? tag.value : tag.defaultValue"
             (ngModelChange)="tag.value = $event"
             [disabled]="tag.readOnly || disableAll"
             placeholder="{{'TAGS.DEFAULT_VALUE' | translate}}"
             (change)="onChange($event.target?.value)"
             (blur)="sendUpdate()">
    </div>

    <div *ngIf="tag?.type === typeEnum.TOGGLE" class="metadata-toggle"
         [ngClass]="{'disabled-metadata': tag.readOnly || disableAll}">
      <app-toggle-switch
        [switchValue]="showMode ? tag?.value : tag?.defaultValue"
        [canBeNull]="canHaveNullToggle"
        [disabled]="tag?.readOnly || disableAll"
        (onChange)="onChange($event)"
        (onBlur)="sendUpdate()">
      </app-toggle-switch>
    </div>

    <div *ngIf="tag?.type === typeEnum.DATE" [ngClass]="{'disabled-metadata': tag.readOnly || disableAll}">
      <app-date-time-picker [(ngModel)]="showMode ? tag.value : tag.defaultValue"
                            (ngModelChange)="onInput($event)"
                            (input)="checkError()"
                            [idPostfix]="'date-tag-' + tag?.id"
                            [noMargin]="true"
                            [dateOnly]="true"
                            [disabled]="tag.readOnly || disableAll"
                            placeholder="{{'TAGS.DEFAULT_VALUE' | translate}}"
                            [lang]="getCurrentLang()"
                            class="flex-90"
                            (onBlur)="sendUpdate()">
        <ngb-datepicker [lang]="getCurrentLang()"></ngb-datepicker>
      </app-date-time-picker>
    </div>

    <div *ngIf="tag?.type === typeEnum.DATETIME" [ngClass]="{'disabled-metadata': tag.readOnly || disableAll}">
      <app-date-time-picker [(ngModel)]="showMode ? tag.value : tag.defaultValue"
                            (ngModelChange)="onInput($event)"
                            (input)="checkError()"
                            (onBlur)="sendUpdate()"
                            [idPostfix]="'datetime-tag-' + tag?.id"
                            [noMargin]="true"
                            [dateOnly]="false"
                            [disabled]="tag.readOnly || disableAll"
                            placeholder="{{'TAGS.DEFAULT_VALUE' | translate}}"
                            [lang]="getCurrentLang()"
                            class="flex-90">
        <ngb-datepicker [lang]="getCurrentLang()"></ngb-datepicker>
      </app-date-time-picker>
    </div>

    <div *ngIf="tag?.type === typeEnum.PERIOD" class="step-general-container center">
      <div class="select mb-2 form-group">
        <div class="input-datePicker">
          <div class="input-group-prepend fill-group">
            <div class="input-group-text">
              {{'WIZARD.GENERAL.DAYS.NOT_BEFORE' | translate}}
            </div>
          </div>
          <app-input-day-number
            [smallSizeInputDay]="smallSizeInputDay"
            (onChange)="handleTemplateDate($event, 'signNotBefore')"
            [max]="maxDay"
            [min]="0"
            class="w-100"
            (onBlur)="sendUpdate()"
            [type]="'minus'">
          </app-input-day-number>
        </div>
      </div>

      <div class="select mb-2 form-group">
        <div class="input-datePicker">
          <div class="input-group-prepend fill-group">
            <div class="input-group-text">
              {{'WIZARD.GENERAL.DAYS.NOT_AFTER' | translate}}
            </div>
          </div>
          <app-input-day-number
            [smallSizeInputDay]="smallSizeInputDay"
            (onBlur)="sendUpdate()"
            (onChange)="handleTemplateDate($event, 'signNotAfter')"
            [min]="minDay"
            class="w-100"
            [type]="'plus'">
          </app-input-day-number>
        </div>
      </div>
    </div>

    <div *ngIf="tag?.type === typeEnum.LIST" class="metadata-list-container"
         [ngClass]="{'disabled-metadata': tag.readOnly || disableAll}">
      <div *ngIf="!editList && tag?.possibleValues?.length >= 0" class="metadata-list">
        <mat-form-field class="formFieldCustom formFieldChoice" floatLabel="never">
          <mat-select
            class="col form-control input-group selectChoice"
            [disabled]="tag.readOnly || disableAll"
            [tabindex]="5"
            [placeholder]="'TAGS.CHOOSE_OPTION' | translate"
            (selectionChange)="onChange($event.value)"
            [(ngModel)]="tag.defaultValue"
            (blur)="sendUpdate()"
            *ngIf="!showMode">
            <mat-option *ngFor="let value of tag?.possibleValues" [value]="value">
              {{value}}
            </mat-option>
          </mat-select>
          <mat-select
            class="col form-control input-group selectChoice"
            [disabled]="tag.readOnly || disableAll"
            [tabindex]="5"
            [placeholder]="'TAGS.CHOOSE_OPTION' | translate"
            (selectionChange)="onChange($event.value)"
            [(ngModel)]="tag.value"
            (blur)="sendUpdate()"
            *ngIf="showMode">
            <mat-option *ngFor="let value of tag?.possibleValues | orderBy : []" [value]="value">
              <span [title]="value">
                <lt-text-truncate
                  [label]="value"
                  [xsMaxCharacters]="35"
                  [smMaxCharacters]="45"
                  [mdMaxCharacters]="55"
                  [lgMaxCharacters]="65"
                  [xlMaxCharacters]="75"
                  [xxlMaxCharacters]="85">
              </lt-text-truncate>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button *ngIf="tag?.possibleValues?.length >= 0 && canEditList"
              (click)="openEditListModal(tag)"
              class="metadata-list-edit-btn">
        <i class="fa fa-edit w-logo-edit"></i>
      </button>
    </div>
    <p class="info-container" *ngIf="displayInfoOnChange"><i class="fas fa-info-circle"></i>{{'TAGS.INFO_SAVE'| translate}}</p>
  </div>


  <!--display tags values ​​for watchers-->
  <div *ngIf="isWatcher" class="input-group my-0 input-design">
    <div class="pl-2"
         *ngIf="tag?.type !== typeEnum.LINK && tag?.type !== typeEnum.LIST && tag?.type !== typeEnum.PERIOD">
      {{showMode ? tag.value : tag.defaultValue}}
    </div>
    <div *ngIf="tag?.type === typeEnum.LINK">
      <label class="metadata-input link-name pl-2">{{getLinkName()}}</label>
      <label class="metadata-input link-value pl-2">{{getLinkValue()}}</label>
    </div>
    <div class="pl-2" *ngIf="tag?.type === typeEnum.LIST">{{tag.value}}</div>
    <!--To do after the implementation of input-days tags update-->
    <div class="pl-2" *ngIf="tag?.type === typeEnum.PERIOD"></div>
  </div>

  <span *ngIf="!showInput && tag?.encrypted && showMode" class="dots"
        [ngClass]="{'disabled-metadata': tag.readOnly  || disableAll}">
    {{'•'.repeat(7)}}
  </span>

  <div *ngIf="showMode" class="metadata-additional">
    <span *ngIf="tag?.mandatory" class="btn-readOnly">
      <i class="fas fa-exclamation" [ngbTooltip]="'TAGS.REQUIRED' | translate"></i>
    </span>
    <span (click)="showInput = !showInput" *ngIf="tag?.encrypted" class="btn-toggle-showInput">
      <i *ngIf="!showInput" class="far fa-eye" [ngbTooltip]="'TAGS.SHOW_VALUE' | translate"></i>
      <i *ngIf="showInput" class="far fa-eye-slash" [ngbTooltip]="'TAGS.HIDE_VALUE' | translate"></i>
    </span>
  </div>
</div>
