<div class="enduser-card">
  <div class="d-flex justify-content-center align-items-center">
    <div class="flex-grow-1 left">
      <p class="big-text"> {{organisation.name}}</p>
      <span *ngIf="organisation.emailSender" class="small-text"><i>{{organisation.emailSender}}</i></span>
    </div>
    <div class="flex-grow-0 w-trash">
      <button (click)="deleteOrganisation()"
              class="btn-delete icon-button"
              type="button">
        <i aria-hidden="true" class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</div>
