import { DocumentService } from 'luxtrust-cosi-api/api/document.service';
import { DocumentData } from 'luxtrust-cosi-api/model/documentData';
import { catchError, debounceTime, tap } from 'rxjs/operators';
import { SprofileEnum } from '../../../services/constants/signature.constant';
import { AlertService } from '../../../services/services/alert-service';
import { Store } from '../model/store';
var TypologyEnum = DocumentData.TypologyEnum;
import { forkJoin, Observable, Subject, throwError } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FilesUtils } from '../../../shared/utils/files.utils';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../luxtrust-cosi-api/api/document.service";
import * as i2 from "../../../services/services/alert-service";
const DEFAULT_SPROFILE = SprofileEnum.ORELY_PADES;
export class DocumentStoreService extends Store {
    constructor(documentService, alertService) {
        super();
        this.documentService = documentService;
        this.alertService = alertService;
        this.uploadDocumentRequest = new Map();
        this.uploadProgress = new Map();
        this.cancelRequestSubject = new Subject();
        this.documentIdsUploading = [];
        this.documentIdsCreating = [];
    }
    init() {
        if (!!this.getAll()) {
            this.reset();
        }
    }
    reset() {
        this.store({
            documents: [], documentToSign: [], appendixToSee: []
        });
    }
    getAllDocuments(sessionId, stepId) {
        return this.documentService.getAllDocuments(sessionId, stepId).subscribe(documents => {
            this.filterDocuments(documents, stepId);
        }, (error) => {
            this.alertService.errorApi(error);
        });
    }
    uploadDocument(sessionId, stepId, files, globalCb, forEachCb) {
        this.setLoadingToSign(true);
        files.forEach((file) => {
            if (!this.getAll().documentToSign.some(d => files[0] && d.name === files[0].name)) {
                const id = 'd_' + Math.random().toString(36).substr(2, 16) + ';name;' + file.name;
                this.initProgress(id);
                this.documentIdsCreating.push(id);
                this.uploadDocumentRequest.set(id, this.documentService.uploadDocument(sessionId, stepId, file, null, 'events', true).pipe(takeUntil(this.cancelRequestSubject), tap((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.reportProgress(id, Math.round(100 * event.loaded / event.total));
                    }
                    else if (event instanceof HttpResponse) {
                        // this document is a document original it'll never be display on the UI so we add it on documents
                        const createDocumentPayload = {
                            sourceDocumentId: event.body.id,
                            sprofileCode: DEFAULT_SPROFILE
                        };
                        this.setLoadingToSign(false);
                        this.clearProgress(id);
                        this.createDocument(sessionId, stepId, createDocumentPayload, id, globalCb, forEachCb);
                    }
                }), catchError((error) => {
                    this.alertService.errorApi(error);
                    this.clearProgress(id);
                    return throwError(error);
                })));
            }
            else {
                this.setLoadingToSign(false);
            }
        });
        if (this.uploadDocumentRequest.size === 0) {
            globalCb();
        }
        forkJoin(...this.uploadDocumentRequest.values()).subscribe(() => { });
    }
    uploadAppendix(sessionId, stepId, files, globalCb, forEachCb) {
        this.setLoadingAppendix(true);
        files.forEach(file => {
            if (!this.getAll().appendixToSee.some(d => d.name === files[0].name)) {
                const id = 'a_' + Math.random().toString(36).substr(2, 16) + ';name;' + file.name;
                this.initProgress(id);
                this.documentIdsCreating.push(id);
                this.uploadDocumentRequest.set(id, this.documentService.uploadDocument(sessionId, stepId, file, null, 'events', true).pipe(takeUntil(this.cancelRequestSubject), tap((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.reportProgress(id, Math.round(100 * event.loaded / event.total));
                    }
                    else if (event instanceof HttpResponse) {
                        const createDocumentPayload = {
                            sourceDocumentId: event.body.id,
                            typology: TypologyEnum.TOVIEW
                        };
                        this.addAppendixToStore(event.body);
                        this.setLoadingAppendix(false);
                        this.clearProgress(id);
                        this.createAppendix(sessionId, stepId, createDocumentPayload, id, globalCb, forEachCb);
                    }
                }), catchError((error) => {
                    this.alertService.errorApi(error);
                    this.clearProgress(id);
                    return throwError(error);
                })));
            }
            else {
                this.setLoadingAppendix(false);
            }
        });
        if (this.uploadDocumentRequest.size === 0) {
            globalCb();
        }
        forkJoin(...this.uploadDocumentRequest.values()).subscribe(() => { });
    }
    createDocument(sessionId, stepId, createDocumentPayload, requestId, globalCb, forEachCb) {
        this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((document) => {
            this.addDocumentToStore(document, Object.assign({}, document, { displayArrow: true, parentIsMutual: false }));
            forEachCb();
            if (this.isLastCreateRequest(requestId)) {
                globalCb();
            }
        }, (error) => {
            this.alertService.errorApi(error);
        });
    }
    createAppendix(sessionId, stepId, createDocumentPayload, requestId, globalCb, forEachCb) {
        return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((document) => {
            this.addAppendixToStore(document, Object.assign({}, document, { displayArrow: true, parentIsMutual: false }));
            forEachCb();
            if (this.isLastCreateRequest(requestId)) {
                globalCb();
            }
        }, (error) => {
            this.alertService.errorApi(error);
        });
    }
    isLastCreateRequest(id) {
        this.documentIdsCreating = this.documentIdsCreating.filter(val => val !== id);
        return this.documentIdsCreating.length === 0;
    }
    createMutualDocument(sessionId, stepId, document, cb) {
        this.setLoadingToSign(true);
        return this.documentService.deleteDocument(document.id, sessionId, stepId).subscribe(() => {
            // We create a mutual document
            return this.documentService.getMutualDocument(document.sourceDocumentId, sessionId, stepId).subscribe((documentMutual) => {
                const createDocumentPayload = {
                    sourceDocumentId: documentMutual.id, sprofileCode: document.sprofileCode
                };
                // We create a pades from the mutual
                return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((documentChildOfMutual) => {
                    // we remove from the local list of documentToSign the old related document which is replaced by a new with a mutual parent
                    this.getAll().documentToSign.splice((this.getAll().documentToSign.findIndex((documentToDelete) => documentToDelete.id === document.id)), 1);
                    this.addDocumentToStore(documentMutual, Object.assign({}, documentChildOfMutual, { displayArrow: true, parentIsMutual: true, parentMutualOnCurrentStep: true }));
                    this.setLoadingToSign(false);
                    cb();
                });
            });
        }, (error) => {
            this.alertService.errorApi(error);
            cb();
        });
    }
    createMutualAppendix(sessionId, stepId, document, cb) {
        this.setLoadingAppendix(true);
        // We delete the documents
        return this.documentService.deleteDocument(document.id, sessionId, stepId).subscribe(() => {
            // We create a mutual document
            return this.documentService.getMutualDocument(document.sourceDocumentId, sessionId, stepId).subscribe((documentMutual) => {
                const createDocumentPayload = {
                    sourceDocumentId: documentMutual.id, typology: TypologyEnum.TOVIEW
                };
                // We create a to_view from the mutual
                return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((documentChildOfMutual) => {
                    // we remove from the local list of documentToSign the old related document which is replaced by a new with a mutual parent
                    this.getAll().appendixToSee.splice((this.getAll().appendixToSee.findIndex((documentToDelete) => documentToDelete.id === document.id)), 1);
                    this.addAppendixToStore(documentMutual, Object.assign({}, documentChildOfMutual, { displayArrow: true, parentIsMutual: true, parentMutualOnCurrentStep: true }));
                    this.setLoadingAppendix(false);
                    cb();
                });
            });
        }, (error) => {
            this.alertService.errorApi(error);
            cb();
        });
    }
    retakeMutualDocument(sessionId, stepId, document, cb) {
        this.setLoadingMutual(true);
        const createDocumentPayload = { sourceDocumentId: document.id, sprofileCode: DEFAULT_SPROFILE };
        return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((documentChildOfMutual) => {
            this.getAll().documentMutual.splice(this.getAll().documentMutual.findIndex((documentToDelete) => documentToDelete.id === document.id), 1);
            ;
            this.addDocumentToStore(null, Object.assign({}, documentChildOfMutual, { parentIsMutual: true }));
            this.setLoadingMutual(false);
            cb();
        }, (error) => {
            this.alertService.errorApi(error);
            this.setLoadingMutual(false);
            cb();
        });
    }
    retakeMutualAppendix(sessionId, stepId, document, cb) {
        this.setLoadingMutual(true);
        const createDocumentPayload = { sourceDocumentId: document.id, typology: TypologyEnum.TOVIEW };
        return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((documentChildOfMutual) => {
            this.getAll().documentMutual.splice(this.getAll().documentMutual.findIndex((documentToDelete) => documentToDelete.id === document.id), 1);
            this.addAppendixToStore(null, Object.assign({}, documentChildOfMutual, { parentIsMutual: true }));
            this.setLoadingMutual(false);
            cb();
        }, (error) => {
            this.alertService.errorApi(error);
            this.setLoadingMutual(false);
            cb();
        });
    }
    deleteMutualDocumentParentInCurrentStep(sessionId, stepId, document, cb) {
        this.setLoadingToSign(true);
        // We delete the chain o, m , p on the back via the soft delete
        return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).subscribe(() => {
            // document have the original sprofileCode, so we get the mutual one
            // filter the one which is mutual related document
            const idx = this.getAll().documentToSign.findIndex((documentToDelete) => documentToDelete && document && (document.id === documentToDelete.sourceDocumentId));
            this.getAll().documentToSign.splice(idx, 1);
            this.setLoadingToSign(false);
            cb();
        }, (error) => {
            this.alertService.warning('WIZARD.DOCUMENTS.INVOLVE_IN_OTHER_STEP');
            this.setLoadingToSign(false);
            cb();
        });
    }
    deleteMutualAppendixParentInCurrentStep(sessionId, stepId, document, cb) {
        this.setLoadingAppendix(true);
        // We delete the chain o, m , p on the back via the soft delete
        return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).subscribe(() => {
            // document have the original sprofileCode, so we get the mutual one
            // filter the one which is mutual from list of appendix docs shown on the page
            const idx = this.getAll().appendixToSee.findIndex((documentToDelete) => documentToDelete && document && (document.id === documentToDelete.sourceDocumentId));
            this.getAll().appendixToSee.splice(idx, 1);
            this.setLoadingAppendix(false);
            cb();
        }, (error) => {
            this.alertService.warning('WIZARD.DOCUMENTS.INVOLVE_IN_OTHER_STEP');
            this.setLoadingAppendix(false);
            cb();
        });
    }
    deleteMutualDocumentParentInAnotherStep(sessionId, stepId, document, cb) {
        this.setLoadingToSign(true);
        // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
        return this.documentService.deleteDocument(document.id, sessionId, stepId).subscribe(() => {
            const idx = this.getAll().documentToSign.findIndex((documentToDelete) => documentToDelete.id === document.id);
            this.getAll().documentToSign.splice(idx, 1);
            const mutual = this.getParent(document);
            this.addMutualToStore(null, Object.assign({}, mutual, { parentIsMutual: false, parentMutualOnCurrentStep: false, isMutualOnCurrentStep: false, displayArrow: false }));
            this.setLoadingToSign(false);
            cb();
        }, (error) => {
            this.alertService.errorApi(error);
            this.setLoadingToSign(false);
            cb();
        });
    }
    deleteMutualAppendixParentInAnotherStep(sessionId, stepId, document, cb) {
        this.setLoadingAppendix(true);
        // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
        return this.documentService.deleteDocument(document.id, sessionId, stepId).subscribe(() => {
            const idx = this.getAll().appendixToSee.findIndex((documentToDelete) => documentToDelete.id === document.id);
            this.getAll().appendixToSee.splice(idx, 1);
            const mutual = this.getParent(document);
            this.addMutualToStore(null, Object.assign({}, mutual, { parentIsMutual: false, parentMutualOnCurrentStep: false, isMutualOnCurrentStep: false, displayArrow: false }));
            this.setLoadingAppendix(false);
            cb();
        }, (error) => {
            this.alertService.errorApi(error);
            this.setLoadingAppendix(false);
            cb();
        });
    }
    deleteMutualDocument(sessionId, stepId, document, cb) {
        this.setLoadingToSign(true);
        const parentMutualDocument = this.getParent(document);
        const parentOriginalDocument = this.getParent(parentMutualDocument);
        const createDocumentPayload = {
            sourceDocumentId: parentOriginalDocument.id, sprofileCode: document.sprofileCode
        };
        // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
        return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).pipe(debounceTime(300))
            .subscribe(() => {
            // We First create the future child of the original
            this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((newDocument) => {
                const index = this.getAll().documentToSign.findIndex((documentToDelete) => documentToDelete.id === document.id);
                this.getAll().documentToSign[index] = Object.assign({}, newDocument, { displayArrow: true, parentIsMutual: false });
                this.setLoadingToSign(false);
                cb();
            }, (error) => {
                this.alertService.errorApi(error);
                this.setLoadingToSign(false);
                cb();
            });
        }, (error) => {
            this.alertService.errorApi(error);
            this.setLoadingToSign(false);
            this.setHasFailed(true);
            cb();
        });
    }
    deleteMutualAppendix(sessionId, stepId, document, cb) {
        this.setLoadingAppendix(true);
        const parentMutualDocument = this.getParent(document);
        const parentOriginalDocument = this.getParent(parentMutualDocument);
        const createDocumentPayload = {
            sourceDocumentId: parentOriginalDocument.id, typology: TypologyEnum.TOVIEW
        };
        // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
        return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).pipe(debounceTime(300))
            .subscribe(() => {
            // We First create the future child of the original
            this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((newDocument) => {
                const index = this.getAll().appendixToSee.findIndex((documentToDelete) => documentToDelete.id === document.id);
                this.getAll().appendixToSee[index] = Object.assign({}, newDocument, { displayArrow: true, parentIsMutual: false });
                this.setLoadingAppendix(false);
                cb();
            }, (error) => {
                this.alertService.errorApi(error);
                this.setLoadingAppendix(false);
                cb();
            });
        }, (error) => {
            this.alertService.errorApi(error);
            this.setLoadingAppendix(false);
            this.setHasFailed(true);
            cb();
        });
    }
    deleteSimpleDocuments(sessionId, stepId, document, cb) {
        this.setLoadingToSign(true);
        return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).subscribe(() => {
            this.getAll().documents = this.deleteDocumentById(this.getAll().documents, document);
            this.getAll().documentToSign = this.deleteDocumentById(this.getAll().documentToSign, document);
            this.setLoadingToSign(false);
            cb();
        }, (error) => {
            this.alertService.errorApi(error);
            this.setLoadingToSign(false);
            cb();
        });
    }
    deleteSimpleAppendix(sessionId, stepId, document, cb) {
        this.setLoadingAppendix(true);
        return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).subscribe(() => {
            this.getAll().documents = this.deleteDocumentById(this.getAll().documents, document);
            this.getAll().appendixToSee = this.deleteDocumentById(this.getAll().appendixToSee, document);
            this.setLoadingAppendix(false);
            cb();
        }, (error) => {
            this.alertService.errorApi(error);
            this.setLoadingAppendix(false);
            cb();
        });
    }
    deleteDocument(sessionId, stepId, document, cb) {
        if (!this.isParentMutual(this.getAll().documents, document)) {
            return this.deleteSimpleDocuments(sessionId, stepId, document, cb);
        }
        else if (this.isParentMutualAndOnCurrentStep(this.getAll().documents, document, stepId)) {
            const mutual = this.getParent(document);
            return this.deleteMutualDocumentParentInCurrentStep(sessionId, stepId, mutual, cb);
        }
        else {
            return this.deleteMutualDocumentParentInAnotherStep(sessionId, stepId, document, cb);
        }
    }
    deleteAppendix(sessionId, stepId, document, cb) {
        if (!this.isParentMutual(this.getAll().documents, document)) {
            return this.deleteSimpleAppendix(sessionId, stepId, document, cb);
        }
        else if (this.isParentMutualAndOnCurrentStep(this.getAll().documents, document, stepId)) {
            const mutual = this.getParent(document);
            return this.deleteMutualAppendixParentInCurrentStep(sessionId, stepId, mutual, cb);
        }
        else {
            return this.deleteMutualAppendixParentInAnotherStep(sessionId, stepId, document, cb);
        }
    }
    clone(sessionId, stepId, data, type, cb) {
        const fileArray = FilesUtils.convertFileListToFileArray(data.files);
        fileArray.forEach(file => {
            let id = (type === 'document' ? 'd_' : 'a_') + Math.random().toString(36).substr(2, 16) + ';name;' + file.name;
            this.initProgress(id);
            this.uploadDocumentRequest.set(id, this.documentService.cloneUploadDocument(data.document.id, sessionId, stepId, file, 'events', true).pipe(takeUntil(this.cancelRequestSubject), tap((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.reportProgress(id, Math.round(100 * event.loaded / event.total));
                }
                else if (event instanceof HttpResponse) {
                    this.alertService.success('DOCUMENT.REUPLOAD_SUCCESS');
                    this.clearProgress(id);
                }
            }, (error) => {
                this.alertService.error('DOCUMENT.REUPLOAD_FAILED');
                this.clearProgress(id);
            })));
        });
        forkJoin(...this.uploadDocumentRequest.values()).subscribe(() => {
            cb();
        });
    }
    reuploadDocument(sessionId, stepId, data, cb) {
        const id = data.document.id.toString();
        this.initProgress(id);
        this.uploadDocumentRequest.set(id, this.documentService.reuploadDocument(data.document.id, sessionId, stepId, data.file, 'events', true).pipe(takeUntil(this.cancelRequestSubject), tap((event) => {
            if (event.type === HttpEventType.UploadProgress) {
                this.reportProgress(id, Math.round(100 * event.loaded / event.total));
            }
            else if (event instanceof HttpResponse) {
                this.alertService.success('DOCUMENT.REUPLOAD_SUCCESS');
                this.clearProgress(id);
            }
        }, (error) => {
            this.alertService.error('DOCUMENT.REUPLOAD_FAILED');
            this.clearProgress(id);
        })));
        forkJoin([this.uploadDocumentRequest.get(id)]).subscribe(() => {
            cb();
        });
    }
    updateDocument(sessionId, stepId, documentId, updateDocumentPayload) {
        return this.documentService.partialUpdateDocument(documentId, sessionId, stepId, updateDocumentPayload).subscribe((newDocument) => {
            const index = this.getAll().documentToSign.findIndex((document) => document.id === newDocument.id);
            this.getAll().documentToSign[index] = Object.assign({}, this.getAll().documentToSign[index], newDocument);
        }, (error) => this.alertService.errorApi(error));
    }
    updateDocumentName(sessionId, stepId, documentId, updateDocumentPayload) {
        return this.documentService.partialUpdateDocument(documentId, sessionId, stepId, updateDocumentPayload).subscribe(() => {
            this.getAllDocuments(sessionId, stepId);
        }, (error) => {
            this.alertService.errorApi(error);
        });
    }
    onFailed() {
        this.setLoadingToSign(false);
        this.setHasFailed(false);
    }
    getParent(document) {
        return this.getAll().documents.find((potentialParent) => potentialParent.id === document.sourceDocumentId);
    }
    getChildren(document) {
        return this.getAll().documents.find((potentialChild) => potentialChild.sourceDocumentId === document.id);
    }
    isMutualWithNoChild(documents, document) {
        return document.typology === TypologyEnum.MUTUAL && !documents.find((potentialChild) => potentialChild.sourceDocumentId === document.id);
    }
    isDocumentToSign(document) {
        return document.typology === TypologyEnum.SPROFILE;
    }
    isDocumentToSee(document) {
        return document.typology === TypologyEnum.TOVIEW;
    }
    isParentMutual(documents, document) {
        const parent = documents.find((potentialParent) => potentialParent.id === document.sourceDocumentId);
        return parent && parent.typology === TypologyEnum.MUTUAL;
    }
    isParentMutualAndOnCurrentStep(documents, document, stepId) {
        const parent = documents.find((potentialParent) => potentialParent.id === document.sourceDocumentId);
        return parent && parent.typology === TypologyEnum.MUTUAL && parent.stepId === stepId;
    }
    isStillUploading() {
        return this.uploadDocumentRequest.size > 0;
    }
    setHasFailed(value) {
        this.store(Object.assign({}, this.getAll(), { hasFailed: value }));
    }
    setLoadingToSign(value) {
        this.store(Object.assign({}, this.getAll(), { isLoadingToSign: value }));
    }
    setLoadingAppendix(value) {
        this.store(Object.assign({}, this.getAll(), { isLoadingAppendix: value }));
    }
    setLoadingMutual(value) {
        this.store(Object.assign({}, this.getAll(), { isLoadingMutual: value }));
    }
    addDocumentToStore(document, documentWithDetails) {
        this.store(Object.assign({}, this.getAll(), { documents: document ? [document, ...this.getAll().documents] : this.getAll().documents, documentToSign: documentWithDetails ? [documentWithDetails, ...this.getAll().documentToSign] : this.getAll().documentToSign }));
    }
    addAppendixToStore(document, documentWithDetails) {
        this.store(Object.assign({}, this.getAll(), { documents: document ? [document, ...this.getAll().documents] : this.getAll().documents, appendixToSee: documentWithDetails ? [documentWithDetails, ...this.getAll().appendixToSee] : this.getAll().appendixToSee }));
    }
    addMutualToStore(document, documentWithDetails) {
        this.store(Object.assign({}, this.getAll(), { documents: document ? [document, ...this.getAll().documents] : this.getAll().documents, documentMutual: documentWithDetails ? [documentWithDetails, ...this.getAll().documentMutual] : this.getAll().documentMutual }));
    }
    initProgress(id) {
        this.uploadProgress.set(id, 0);
        this.documentIdsUploading.push(id);
    }
    reportProgress(id, progress) {
        this.uploadProgress.set(id, progress);
    }
    clearProgress(id) {
        this.uploadDocumentRequest.delete(id);
        this.uploadProgress.delete(id);
        this.documentIdsUploading = this.documentIdsUploading.filter(idu => idu !== id);
    }
    deleteDocumentById(documents, documentToDelete) {
        documents.splice(documents.findIndex((document) => {
            return document.id === documentToDelete.id;
        }), 1);
        return documents;
    }
    filterDocuments(documents, stepId) {
        const documentToSign = [];
        const appendixToSee = [];
        const documentMutual = [];
        documents.forEach((document) => {
            const toSign = this.isDocumentToSign(document);
            const toSee = this.isDocumentToSee(document);
            const parentMutual = this.isParentMutual(documents, document);
            if (toSign && !parentMutual) {
                documentToSign.push(Object.assign({}, document, { displayArrow: true }));
            }
            else if (toSee && !parentMutual) {
                appendixToSee.push(Object.assign({}, document, { displayArrow: true }));
            }
            else if (toSign && parentMutual) {
                documentToSign.push(Object.assign({}, document, { parentIsMutual: true, parentMutualOnCurrentStep: this.isParentMutualAndOnCurrentStep(documents, document, stepId), displayArrow: true }));
            }
            else if (toSee && parentMutual) {
                appendixToSee.push(Object.assign({}, document, { parentIsMutual: true, parentMutualOnCurrentStep: this.isParentMutualAndOnCurrentStep(documents, document, stepId), displayArrow: true }));
            }
            else if (this.isMutualWithNoChild(documents, document)) {
                documentMutual.push(Object.assign({}, document, { isMutualOnCurrentStep: document.stepId === stepId }));
            }
        });
        this.store({
            documents: documents,
            documentToSign: documentToSign,
            appendixToSee: appendixToSee,
            documentMutual: documentMutual
        });
    }
}
DocumentStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentStoreService_Factory() { return new DocumentStoreService(i0.ɵɵinject(i1.DocumentService), i0.ɵɵinject(i2.AlertService)); }, token: DocumentStoreService, providedIn: "root" });
