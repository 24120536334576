export enum EntitlementsEnum {
  watcher = 'WATCHER',
  signer = 'SIGNER',
  designer = 'DESIGNER',
  admin = 'ADMIN',

  create_from_template = 'CREATE_FROM_TEMPLATE',
  create_standalone = 'CREATE_STANDALONE',
  forbid_wizard = 'FORBID_WIZARD',
  forbid_session= 'FORBID_SESSION',
  order_certificate = 'ORDER_CERTIFICATE',
  signature_book = 'SIGNATURE_BOOK',
  session_translation = 'SESSION_TRANSLATION',
  get_audit_trail = 'GET_AUDIT_TRAIL',
  signature_validation = 'SIGNATURE_VALIDATION',
  template_admin = 'TEMPLATE_ADMIN',
  gdpr = 'GDPR',
  mass_mailing = 'MASS_MAILING',

  manage_circles = 'MANAGE_CIRCLES',
  manage_entitlements = 'MANAGE_ENTITLEMENTS',
  manage_layout = 'MANAGE_LAYOUT',
  manage_legal_notices = 'MANAGE_LEGAL_NOTICES',
  manage_metadata = 'MANAGE_METADATA',
  manage_notifications = 'MANAGE_NOTIFICATIONS',
  manage_templates = 'MANAGE_TEMPLATE',
  manage_translations = 'MANAGE_TRANSLATION',
  manage_users = 'MANAGE_USER',
  manage_organizations = 'MANAGE_ORGANISATIONS',
  manage_directories = 'MANAGE_DIRECTORIES',
  manage_documentation = 'MANAGE_DOCUMENTATION',
  forbid_delegate = 'FORBID_DELEGATE'
}
