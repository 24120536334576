export interface StepNotificationRules {
    title: string;
    value: number;
    isMail: boolean;
    isSms: boolean;
    isApp: boolean;
    templateOverride: boolean;
    isCollapsed: boolean;
}

export const defaultNotificationRules: StepNotificationRules[] = [{
    title: 'STEP_STARTED',
    value: 4,
    isMail: undefined,
    isSms: false,
    isApp: false,
    templateOverride: undefined,
    isCollapsed: false
// }, {
//     title: 'SIGNATURE_SIGNED',
//     value: 6,
//     isMail: undefined,
//     isSms: undefined,
//     isApp: undefined,
//     templateOverride: undefined,
//     isCollapsed: true
}, {
    title: 'STEP_COMPLETED',
    value: 5,
    isMail: undefined,
    isSms: false,
    isApp: false,
    templateOverride: undefined,
    isCollapsed: true
}, {
    title: 'STEP_CANCELED',
    value: 26,
    isMail: undefined,
    isSms: false,
    isApp: false,
    templateOverride: undefined,
    isCollapsed: true
// }, {
//     title: 'SESSION_STARTED',
//     value: 1,
//     isMail: undefined,
//     isSms: false,
//     isApp: false,
//     templateOverride: undefined,
//     isCollapsed: true
}, {
    title: 'SESSION_COMPLETED',
    value: 2,
    isMail: undefined,
    isSms: false,
    isApp: false,
    templateOverride: undefined,
    isCollapsed: true
}, {
    title: 'STEP_COMMENT_ADDED',
    value: 18,
    isMail: undefined,
    isSms: false,
    isApp: false,
    templateOverride: undefined,
    isCollapsed: true
}, {
    title: 'STEP_DECLINED',
    value: 7,
    isMail: undefined,
    isSms: false,
    isApp: false,
    templateOverride: undefined,
    isCollapsed: true
}, {
    title: 'STEP_REINSTATED',
    value: 8,
    isMail: undefined,
    isSms: false,
    isApp: false,
    templateOverride: undefined,
    isCollapsed: true
}, {
    title: 'ENROLMENT_REQUEST',
    value: 14,
    isMail: undefined,
    isSms: false,
    isApp: false,
    templateOverride: undefined,
    isCollapsed: true
}];
