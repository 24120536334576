<div class="document-card">
  <div class="d-flex justify-content-center align-items-center">
    <span class="flex-grow-1 width-wrap">
      <p class="big-text">{{document.name}}</p>
    </span>

    <div class="flex-grow-0 mr-2">
      <button (click)="addAcroform.emit(document)"
              [disabled]="!canAddAcroform"
              [ngClass]="{
             'btn-disable': !canAddAcroform}"
              class="btn-add icon-button"
              id="add-acroform"
              type="button">
        <i aria-hidden="true"
           class="fas fa-file-signature"></i>
      </button>
    </div>

    <div class="flex-grow-0">
      <button (click)="delete.emit(document)"
              class="btn-delete icon-button"
              type="button">
        <i aria-hidden="true"
           class="fa fa-trash"></i>
      </button>
    </div>
  </div>

  <ng-container *ngFor="let signerDetail of signersDetails">
    <p class="my-2 small-text">
      <span class="bold" *ngIf="!signerDetail.signer.circle">
        {{signerDetail.signer.firstName + ' ' + signerDetail.signer.lastName}}
      </span>
      <span class="bold" *ngIf="signerDetail.signer.circle">
        {{signerDetail.signer.lastName}}
      </span>
      &nbsp;{{'NEW_SESSION.DOCUMENTS.SIGNER_DETAILS'| translate}} {{signerDetail.pages}}
    </p>
  </ng-container>
  <div *ngIf="progress[documentUploadingId] > 0" class="mt-4 display-flex">
    <div class="progress margin-right-10 width-80">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        [attr.aria-valuenow]="progress[documentUploadingId]"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{ width: progress[documentUploadingId] + '%' }"
      >
        {{ progress[documentUploadingId] }}%
      </div>
    </div>
    <button (click)="cancelReupload.emit(documentUploadingId)"
            class="btn flex-grow-1 btn-warning mr-2">
      {{'WIZARD.DOCUMENTS.CANCEL' | translate}}
    </button>
  </div>
</div>
