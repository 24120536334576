import {Component, Inject, OnInit, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from 'luxtrust-cosi-api/api/authentication.service';
import {ItsmeAuthenticationService} from 'luxtrust-cosi-api/api/itsmeAuthentication.service';
import {MobileConnectAuthenticationService} from 'luxtrust-cosi-api/api/mobileConnectAuthentication.service';
import {WatermarkService} from 'luxtrust-cosi-api/api/watermark.service';
import {ItsmeAuthorizationDto} from 'luxtrust-cosi-api/model/itsmeAuthorizationDto';
import {SignupPayload} from 'luxtrust-cosi-api/model/signupPayload';
import {WatermarkData} from 'luxtrust-cosi-api/model/watermarkData';
import {BASE_PATH} from 'luxtrust-cosi-api/variables';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {ValidatorHelper} from 'src/app/services/validators/validators.helper';
import {REGEXES} from '../../app.constant';
import {AlertService} from '../../services/services/alert-service';
import {AppService} from '../../services/services/app.service';
import {ItsmeAuthServiceType, ItsmeService} from '../../services/services/itsme.service';
import {ApiError} from '../../error/api-error.model';

@Component({
  templateUrl: './signup.component.html', styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  private static readonly USERNAME_IN_USE_ERROR_KEY = 'usernameInUse';

  readonly allowedSignatureImageExtensions = ['jpeg', 'jpg', 'png'];
  readonly allowedSignatureImageTypeExtensions = ['image/jpeg', 'image/png'];
  registerForm: FormGroup;
  itsmeAuthorization$: Observable<ItsmeAuthorizationDto>;
  mcAuthorization$: Observable<string[]>;
  usernameInUse = false;
  tenantName: string;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private alertService: AlertService,
              private watermarkService: WatermarkService,
              private itsmeAuthenticationService: ItsmeAuthenticationService,
              private mcAuthenticationService: MobileConnectAuthenticationService,
              private itsmeService: ItsmeService,
              private appService: AppService,
              private changeDetectorRef: ChangeDetectorRef,
              @Inject(BASE_PATH) public basePath: string) {
  }

  ngOnInit() {
    const redirectUri = this.itsmeService.getRedirectUrlForAuth(ItsmeAuthServiceType.SIGN_UP);
    this.itsmeAuthorization$ = this.itsmeAuthenticationService.itsmeAuthorizationUrl(ItsmeAuthServiceType.SIGN_UP, redirectUri);
    this.mcAuthorization$ = this.mcAuthenticationService.mcIdentifiers();

    this.tenantName = this.route.snapshot.params['tenantName'];
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['',
        [Validators.required,
          Validators.pattern(REGEXES.USERNAME),
          ValidatorHelper.delegating(() => this.usernameInUse, SignupComponent.USERNAME_IN_USE_ERROR_KEY)
        ]
      ],
      email: ['', [Validators.required, Validators.email]],
      password: ['',
        [Validators.required,
          Validators.minLength(6),
          Validators.pattern(REGEXES.PASSWORD.NUMERIC_CHARS),
          Validators.pattern(REGEXES.PASSWORD.SPECIAL_CHARS),
          Validators.pattern(REGEXES.PASSWORD.NUMERIC_CHARS)
        ]
      ],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      watermark: ['', ValidatorHelper.fileTypeAcceptedValidator(this.allowedSignatureImageTypeExtensions)],
      phone: ['', [Validators.required, Validators.pattern(REGEXES.PHONE)]]
    }, {validator: ValidatorHelper.matchPassword});

    this.registerForm.valueChanges.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  onSubmit() {
    this.registerForm.value.phone = this.registerForm.value.phone.replace(/\s/g, '');
    this.registerForm.markAllAsTouched();
    if (this.registerForm.invalid) {
      return;
    }

    const payload: SignupPayload = {
      tenantName: this.tenantName,
      email: this.registerForm.value.email,
      username: this.registerForm.value.username,
      password: this.registerForm.value.password,
      firstName: this.registerForm.value.firstName,
      lastName: this.registerForm.value.lastName,
      phone: this.registerForm.value.phone,
      preferences: '{"events":[]}'
    };
    this.authenticationService.newUserSignup(payload).pipe(take(1)).subscribe((endUserData) => {
      if (this.registerForm.value.watermark) {
        this.authenticationService.authenticateUser({
          tenantName: this.tenantName, username: payload.username, password: payload.password
        }).pipe(take(1)).subscribe((response: any) => {
          this.appService.refresh(response.access_token);
          this.appService.loadEnduser();
          this.watermarkService.createWatermark(this.registerForm.value.watermark[0], endUserData.id, WatermarkData.TypeEnum.USER)
          .subscribe(() => {
            this.appService.revoke();
            this.appService.logout();
          }, (error: ApiError) => this.alertService.errorApi(error));
        }, (error: ApiError) => this.alertService.errorApi(error));
      }

      this.alertService.success('SIGN_UP.SUCCESS');
      this.router.navigateByUrl(`/login?tenantName=${this.tenantName}`);
    }, error => {
      if (error.status === 400) {
        this.usernameInUse = true;

        const username = this.registerForm.get('username');
        username.updateValueAndValidity();
        username.valueChanges.pipe(take(1)).subscribe(_ => {
          this.usernameInUse = false;
          username.updateValueAndValidity();
        });
      }
    });
  }

  onCancel() {
    const route = this.tenantName ? `/login?tenantName=${this.tenantName}` : `/no-tenant-name`;
    this.router.navigateByUrl(route);
  }

  // Needed to display error immediately if any
  markAsTouched(controlName: string) {
    const control = this.registerForm.get(controlName);
    control.markAsTouched();
  }
}
