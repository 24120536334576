/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../core/pipes/translate.pipe";
import * as i2 from "../../../core/service/translate.service";
import * as i3 from "./modal-apply-visa.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/forms";
import * as i6 from "../../../services/services/alert-service";
import * as i7 from "../../../../../luxtrust-cosi-api/api/sprofile.service";
import * as i8 from "../../../../../luxtrust-cosi-api/api/signature.service";
var styles_ModalApplyVisaComponent = [];
var RenderType_ModalApplyVisaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalApplyVisaComponent, data: {} });
export { RenderType_ModalApplyVisaComponent as RenderType_ModalApplyVisaComponent };
export function View_ModalApplyVisaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close({}) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", "\n"])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService]), (_l()(), i0.ɵeld(10, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["id", "yes"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyVisa() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService]), (_l()(), i0.ɵeld(14, 0, null, null, 2, "button", [["class", "btn btn-outline-secondary"], ["id", "no"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close({}) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("MODAL_VISA.TITLE")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("MODAL_VISA.BODY")); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.submitted; _ck(_v, 11, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("MODAL_VISA.YES")); _ck(_v, 12, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 15, 0, i0.ɵnov(_v, 16).transform("MODAL_VISA.NO")); _ck(_v, 15, 0, currVal_4); }); }
export function View_ModalApplyVisaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ModalApplyVisaComponent_0, RenderType_ModalApplyVisaComponent)), i0.ɵdid(1, 245760, null, 0, i3.ModalApplyVisaComponent, [i4.NgbActiveModal, i5.FormBuilder, i2.TranslateService, i6.AlertService, i7.SprofileService, i8.SignatureService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalApplyVisaComponentNgFactory = i0.ɵccf("ng-component", i3.ModalApplyVisaComponent, View_ModalApplyVisaComponent_Host_0, { sessionId: "sessionId", stepId: "stepId", signatureId: "signatureId", sprofile: "sprofile" }, {}, []);
export { ModalApplyVisaComponentNgFactory as ModalApplyVisaComponentNgFactory };
