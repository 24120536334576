<app-tooltip [tooltipText]="'HELP.REGISTER' | translate"></app-tooltip>

<app-full-screen [title]="'SIGN_UP.TITLE' | translate"
                 mode="container-m">

  <form (ngSubmit)="onSubmit()"
        [formGroup]="registerForm"
        class="row">

    <!-- top left inputs group -->
    <div class="col-12 col-md-6">

      <!-- username-->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('username')"
                        [errors]="{
                            required: 'SIGN_UP.USERNAME.ERRORS.REQUIRED' | translate,
                            usernameInUse: 'SIGN_UP.USERNAME.ERRORS.EXISTS' | translate,
                            pattern: 'SIGN_UP.USERNAME.ERRORS.FORMAT' | translate
                          }"
                        [hasOnInput]="true"
                        [placeholder]="'SIGN_UP.USERNAME.PLACEHOLDER' | translate"
                        class="col"
                        formControlName="username"
                        [idPostfix]="'username'"
                        icon="id-card"></app-input-text>
      </div>

      <!-- password -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('password')"
                        [errors]="{
                              required: 'SIGN_UP.PASSWORD.ERRORS.REQUIRED' | translate,
                              minlength: 'SIGN_UP.PASSWORD.ERRORS.VALID' | translate,
                              pattern: 'SIGN_UP.PASSWORD.ERRORS.FORMAT' | translate
                            }"
                        [hasOnInput]="true"
                        [placeholder]="'SIGN_UP.PASSWORD.PLACEHOLDER' | translate"
                        class="col"
                        formControlName="password"
                        icon="key"
                        [idPostfix]="'password'"
                        type="password"></app-input-text>

      </div>

      <!-- Et re password par derriere -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('confirmPassword')"
                        [errors]="{
                                  required: 'SIGN_UP.CONFIRM_PASSWORD.ERRORS.REQUIRED' | translate,
                                  minlength: 'SIGN_UP.CONFIRM_PASSWORD.ERRORS.VALID' | translate ,
                                  matchPassword: 'SIGN_UP.CONFIRM_PASSWORD.ERRORS.MATCH' | translate
                                }"
                        [hasOnInput]="true"
                        [placeholder]="'SIGN_UP.CONFIRM_PASSWORD.PLACEHOLDER' | translate"
                        class="col"
                        formControlName="confirmPassword"
                        icon="check"
                        [idPostfix]="'confirmPassword'"
                        type="password"></app-input-text>
      </div>

      <!-- watermark -->
      <div class="form-row">
        <app-input-file [accept]="allowedSignatureImageTypeExtensions"
                        [browseLabel]="'SIGN_UP.BROWSE' | translate"
                        [errors]="{
                              supportedFormats: 'SIGN_UP.SIGNATURE_IMAGE.INVALID_FILE_EXTENSION' | translate : {supportedFormats: this.allowedSignatureImageExtensions.join(', ')}
                            }"
                        [id]="'upload-watermark'"
                        [placeholder]="'SIGN_UP.SIGNATURE_IMAGE.PLACEHOLDER' | translate"
                        class="col"
                        formControlName="watermark"
                        icon="image"></app-input-file>
      </div>
    </div>

    <!-- top right inputs group -->
    <div class="col-12 col-md-6">

      <!-- first name -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('firstName')"
                        [errors]="{
                                required: 'SIGN_UP.FIRST_NAME.ERRORS.REQUIRED' | translate
                              }"
                        [hasOnInput]="true"
                        [placeholder]="'SIGN_UP.FIRST_NAME.PLACEHOLDER' | translate"
                        class="col"
                        [idPostfix]="'firstName'"
                        formControlName="firstName"
                        icon="user"></app-input-text>
      </div>

      <!-- last name -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('lastName')"
                        [errors]="{
                                required: 'SIGN_UP.LAST_NAME.ERRORS.REQUIRED' | translate
                              }"
                        [hasOnInput]="true"
                        [placeholder]="'SIGN_UP.LAST_NAME.PLACEHOLDER' | translate"
                        class="col"
                        [idPostfix]="'lastName'"
                        formControlName="lastName"
                        icon="user"></app-input-text>
      </div>

      <!-- email -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('email')"
                        [errors]="{
                              required: 'SIGN_UP.EMAIL.ERRORS.REQUIRED' | translate,
                              email:'SIGN_UP.EMAIL.ERRORS.VALID' | translate
                            }"
                        [hasOnInput]="true"
                        [placeholder]="'SIGN_UP.EMAIL.PLACEHOLDER' | translate"
                        class="col"
                        formControlName="email"
                        icon="envelope"
                        [idPostfix]="'email'"
                        type="email"></app-input-text>
      </div>

      <!-- phone -->
      <div class="form-row">
        <app-input-text (focusout)="markAsTouched('phone')"
                        [errors]="{
                                required: 'SIGN_UP.PHONE.ERRORS.REQUIRED' | translate,
                                pattern: 'SIGN_UP.PHONE.ERRORS.FORMAT' | translate
                              }"
                        [hasOnInput]="true"
                        [placeholder]="'SIGN_UP.PHONE.PLACEHOLDER' | translate"
                        class="col"
                        formControlName="phone"
                        icon="phone"
                        [idPostfix]="'phone'"></app-input-text>
      </div>
    </div>

    <!-- submit / cancel -->
    <div class="col-12 mt-2 bottom-btns">
      <button [disabled]="registerForm.invalid || usernameInUse"
              class="btn flex-grow-1 btn-primary col-6 float-right" id="register"
              type="submit">{{'SIGN_UP.REGISTER' | translate}}</button>
      <button (click)="onCancel()" id="cancel"
              class="btn flex-grow-1 btn-danger col-6"
              type="button">{{'SIGN_UP.CANCEL' | translate}}</button>
    </div>

  </form>
</app-full-screen>
