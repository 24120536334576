import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OtpPayload} from 'luxtrust-cosi-api/model/otpPayload';
import {TranslateService} from 'src/app/core/service/translate.service';
import {AuthenticationService} from 'luxtrust-cosi-api/api/authentication.service';
import {EnduserPreferenceService} from 'luxtrust-cosi-api/api/enduserPreference.service';
import {ItsmeAuthenticationService} from 'luxtrust-cosi-api/api/itsmeAuthentication.service';
import {PlatformService} from 'luxtrust-cosi-api/api/platform.service';
import {TenantService} from 'luxtrust-cosi-api/api/tenant.service';
import {ConfigData} from 'luxtrust-cosi-api/model/configData';
import {AuthCodePayload} from 'luxtrust-cosi-api/model/authCodePayload';
import {ItsmeAuthorizationDto} from 'luxtrust-cosi-api/model/itsmeAuthorizationDto';
import {BASE_PATH} from 'luxtrust-cosi-api/variables';
import {Observer} from 'rxjs';
import {take} from 'rxjs/operators';
import {SprofileWrapperService} from 'src/app/services/services/sprofile-wrapper.service';
import {environment} from '../../../environments/environment';
import {CONFIG, REGEXES, SESSION_STORAGE_SYSTEM_DOC_KEY} from '../../app.constant';
import {ApiError} from '../../error/api-error.model';
import {AlertService} from '../../services/services/alert-service';
import {AppService} from '../../services/services/app.service';
import {ItsmeAuthServiceType, ItsmeService} from '../../services/services/itsme.service';
import {NavigationService} from '../../services/services/navigation.service';
import {VisualIdentityService} from '../../services/services/visual-identity.service';
import {CredentialsPayload} from 'luxtrust-cosi-api/model/credentialsPayload';
import {DocumentationService} from "../../admin/documentation/documentation.service";
import {ValidationCodeError} from '../../services/enum/validation-code-error';
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";

@Component({
  templateUrl: './login.component.html', styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  forgotEmailFormGroup: FormGroup;
  authCodeFormGroup: FormGroup;

  selectedAuthProvider: string;
  orelyAuthUrl: SafeResourceUrl;

  @ViewChild('orelyIframe', { read: ElementRef, static: false }) orelyIframe: ElementRef;
  @ViewChild('authCodeModal', { read: TemplateRef, static: false }) authCodeModal: TemplateRef<any>;

  tenantName: string;
  directoryAlias: string;
  customLogo: string;
  forgotEmailValid = false;
  isAuthenticating = false;
  baWithEmail = false;
  baWithSMS = false;

  passwordEnabled: boolean;
  luxtrustEnabled: boolean;
  itsmeEnabled: boolean;
  mcEnabled: boolean;
  samlEnabled: boolean;
  gardianEnabled: boolean;
  azureEnabled: boolean;
  azureEnrEnabled: boolean;
  adfsEnabled: boolean;
  oidcEnabled: boolean;
  emailEnabled: boolean;
  smsEnabled: boolean;
  selfRegisterEnabled: boolean;
  // Auth factor enabled
  factorsEnabled: number;
  mainConfigAuth: string;
  autoForward: boolean;
  adfsMultiTenant: string;
  authCodeModalTarget: string; // ba or jwt
  authCodeChannel: string; // email or sms
  // defaultPageAuth = false;
  mainLoginMessageContent: string;
  mainLoginMessageNature: string;
  mainLoginMessageTitle: string;
  supportContact: string;
  supportContactRedirection: string;
  needAssistance: string;
  showGenerateBtn = false;
  authCodeModalRef: NgbModalRef;
  signInMandatory = false;
  signUpMandatory = false;
  isFromNotification = false;
  forceAuthMode: string;

  constructor(public appService: AppService,
              private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private authService: AuthenticationService,
              private translate: TranslateService,
              private itsmeAuthenticationService: ItsmeAuthenticationService,
              private itsmeService: ItsmeService,
              private domSanitizer: DomSanitizer,
              private modalService: NgbModal,
              private visualIdentityService: VisualIdentityService,
              private endUserPreferenceService: EnduserPreferenceService,
              private navigationService: NavigationService,
              private platformService: PlatformService,
              private tenantService: TenantService,
              @Inject(BASE_PATH) public basePath: string,
              private sprofileService: SprofileWrapperService,
              private documentationService: DocumentationService) {
  }

  get emailNotificationObserver(): Observer<any> {
    return {
      next: () => this.displayAlertSuccess('ALERT.PASSWORD_RESET_LINK_SENT'),
      error: (error: ApiError) => this.alertService.errorApiWithCustomMessage(`${error.error.code} : ${this.forgotEmailFormGroup.value.email}`, error),
      complete: () => {}
    };
  }

  private loadEndUserIfPossible(sessionId: number, stepId: number, jwt?: string): void {
    if (this.appService.isLoggedIn()) {
      this.appService.loadEnduser().then(user => {
        if ((this.signUpMandatory || this.signInMandatory) && !user.enrolled && jwt) {
          this.navigateInvite(sessionId, stepId, jwt);
        } else if (this.signInMandatory && user.enrolled && this.isFromNotification && jwt) {
          this.appService.setIsAuthenticated('false');
        } else {
          if (!sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)) {
            this.documentationService.buildSystemDocumentation();
          }
          if (sessionId && stepId) {
            this.sprofileService.getSprofileList();
            this.navigationService.goToFirstStartedStepOrDocument(sessionId, stepId);
          } else if (sessionId) {
            this.navigationService.goToDocumentOrFirstStartedStepOrSession(sessionId);
          } else {
            this.leaveLoginPage();
          }
        }
      })
    }
  }

  navigateInvite(sessionId: number, stepId: number, jwt: string) {
    this.router.navigate(['/login/invite'], {queryParams: {sessionId, stepId, jwt}, state: {isFromLogin: true} })
  }

  private setDefaultLoginForm(): void {
    if (this.forceAuthMode) {
      this.selectedAuthProvider = this.forceAuthMode;
    } else if (this.passwordEnabled && this.mainConfigAuth === 'ba') {
      this.selectedAuthProvider = 'ba';
    } else if (this.luxtrustEnabled && this.mainConfigAuth === 'luxtrust') {
      this.selectedAuthProvider = 'luxtrust';
    } else if (this.itsmeEnabled && this.mainConfigAuth === 'itsme') {
      this.selectedAuthProvider = 'itsme';
    } else if (this.gardianEnabled && this.mainConfigAuth === 'gardian') {
      this.selectedAuthProvider = 'gardian';
    } else if (this.samlEnabled && this.mainConfigAuth === 'saml2') {
      this.selectedAuthProvider = 'saml2';
    } else if (this.mcEnabled && this.mainConfigAuth === 'mc') {
      this.selectedAuthProvider = 'mc';
    } else if (this.azureEnabled && this.mainConfigAuth === 'azure') {
      this.selectedAuthProvider = 'azure';
    } else if (this.azureEnrEnabled && this.mainConfigAuth === 'azureenr') {
      this.selectedAuthProvider = 'azureenr';
    } else if (this.emailEnabled && this.mainConfigAuth === 'email') {
      this.selectedAuthProvider = this.authCodeChannel = 'email';
    } else if (this.smsEnabled && this.mainConfigAuth === 'sms') {
      this.selectedAuthProvider = this.authCodeChannel = 'sms';
    } else if (this.adfsEnabled && this.mainConfigAuth === 'adfs') {
      this.selectedAuthProvider = 'adfs';
    } else if (this.oidcEnabled && this.mainConfigAuth === 'oidc') {
      this.selectedAuthProvider = 'oidc';
    } else {
      this.selectedAuthProvider = 'none';
    }
  }

  private configureLoginPage() {
    let platformConfig: ConfigData;
    // FIXME BDX Find a better solution than always fetching configuration, find a solution with configuration.service again
    this.platformService.getConfig().toPromise()
      .then((loadedPlatformConfig) => {
        platformConfig = loadedPlatformConfig;
        if (!!platformConfig.config && !!platformConfig.config.redirectToDefaultTenant) {
          this.tenantName = platformConfig.config.defaultTenantName;
        }
        return this.tenantService.getTenantConfig(this.tenantName).toPromise();
      }).then((tenantConfig) => {
        this.autoForward = !!tenantConfig[CONFIG.TENANT.AUTH.AUTO_FORWARD_KEY];
        this.mainConfigAuth = tenantConfig[CONFIG.TENANT.AUTH.MAIN_MODE_KEY];
        this.adfsMultiTenant = tenantConfig[CONFIG.TENANT.AUTH.ADFS_MULTI_TENANT_KEY];

        if (this.autoForward) {
          switch (this.mainConfigAuth) {
            case 'itsme':
              this.authWithItsme();
              break;
            case 'mc':
              this.authWithMc();
              break;
            case 'gardian':
              this.authWithSamlv2('gardian');
              break;
            case 'saml2':
                this.authWithSamlv2('saml2');
                break;
            case 'azure':
              this.authWithAzure();
              break;
            case 'adfs':
              this.authWithADFS();
              break;
            case 'oidc':
              this.authWithOidc();
              break;
          }
        }

        if (!this.mainConfigAuth) {
          this.mainConfigAuth = 'ba';
        } else if (this.mainConfigAuth === 'email') {
          this.baWithEmail = true;
        } else if (this.mainConfigAuth === 'sms') {
          this.baWithSMS = true;
        }
        const authPossibilities: string[] = tenantConfig[CONFIG.TENANT.AUTH.MODE_LIST_KEY].split('|');
        this.factorsEnabled = authPossibilities.length;
        this.passwordEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.PASSWORD);
        this.luxtrustEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.ORELY);
        this.itsmeEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.ITSME);
        this.mcEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.MOBILE_CONNECT);
        this.samlEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.SAML2);
        this.gardianEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.GARDIAN);
        this.azureEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.AZURE);
        this.azureEnrEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.AZUREENR);
        this.emailEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.EMAIL);
        this.smsEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.SMS);
        this.adfsEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.ADFS);
        this.oidcEnabled = authPossibilities.includes(CONFIG.TENANT.AUTH.VALUES.OIDC);
        this.selfRegisterEnabled = !!+tenantConfig[CONFIG.TENANT.IS_SELF_REGISTER_ACTIVE.KEY];
        const content: string = 'login-message-content-' + this.translate.currentLang.toUpperCase();
        const title: string = 'login-message-title-' + this.translate.currentLang.toUpperCase();
        this.mainLoginMessageContent = tenantConfig[content];
        this.mainLoginMessageTitle = tenantConfig[title];
        this.mainLoginMessageNature = tenantConfig[CONFIG.TENANT.LOGIN_MESSAGE.NATURE];
        this.supportContact = tenantConfig[CONFIG.TENANT.LOGIN_PAGE_SUPPORT_TEXT.KEY] ?
          tenantConfig[CONFIG.TENANT.LOGIN_PAGE_SUPPORT_TEXT.KEY]
          : this.translate.instant('LOGIN.SUPPORT.DEFAULT');
        this.supportContactRedirection = tenantConfig[CONFIG.TENANT.LOGIN_PAGE_SUPPORT_REDIRECT.KEY] ?
          tenantConfig[CONFIG.TENANT.LOGIN_PAGE_SUPPORT_REDIRECT.KEY]
          : this.translate.instant('LOGIN.SUPPORT.DEFAULT_REDIRECT');
        this.needAssistance = tenantConfig[CONFIG.TENANT.NEED_ASSISTANCE.KEY] ?
          tenantConfig[CONFIG.TENANT.NEED_ASSISTANCE.KEY]
          : this.translate.instant('LOGIN.NEED.SUPPORT');
      }).catch(() => {
        this.passwordEnabled = true;
        this.luxtrustEnabled = true;
        this.itsmeEnabled = platformConfig.itsmeAuthActive;
        this.mcEnabled = platformConfig.mcAuthActive;
        this.samlEnabled = platformConfig.saml2AuthActive;
        this.azureEnabled = platformConfig.azureActive;
        this.selfRegisterEnabled = false;
      }).then(() => {
        this.setDefaultLoginForm();
      });
  }

  ngOnInit() {
    this.tenantName = this.route.snapshot.queryParams['tenantName'];
    this.forceAuthMode = this.route.snapshot.queryParams['authenticationMode'];
    this.directoryAlias = this.route.snapshot.queryParams['directoryAlias'];
    this.orelyAuthUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.ORELY_URL + '/auth/login?tenantName='+this.tenantName);

    this.visualIdentityService.logo$.subscribe(logo => {
      this.customLogo = logo;
    });

    this.configureLoginPage();

    // first, try to login with a provided jwt in the url
    const jwt = this.route.snapshot.queryParams['jwt'];
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    this.loginWithJwt(jwt, sessionId, stepId, true, false);

    const error = this.route.snapshot.queryParams['error'];
    if (error) {
      this.alertService.error(error);
    }

    // if the user cannot be logged in with JWT in url, forward or create the login form
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]], password: ['', [Validators.required]]
    });
    this.forgotEmailFormGroup = this.formBuilder.group({
      email: ['', [
          Validators.required,
          Validators.email,
          Validators.pattern(REGEXES.EMAIL)
        ]
      ]
    });
    this.authCodeFormGroup = this.formBuilder.group({
      authCode: ['', [Validators.required, Validators.pattern(REGEXES.AUTH_CODE)]]
    });
  }

  loginWithJwt(jwt: string, sessionId: number, stepId: number, openModal: boolean, mustSendCode: boolean): void {
    if (!jwt) return;

    this.tenantService.getTenantConfig(this.tenantName)
      .pipe(take(1))
      .subscribe((config: any) => {
        const jsonStr = config[CONFIG.TENANT.AUTH.JWT_WITH_OTP_KEY];
        this.signUpMandatory = !!config[CONFIG.TENANT.SIGNUP_MANDATORY.KEY];
        this.signInMandatory = !!config[CONFIG.TENANT.SIGNIN_MANDATORY.KEY];
        this.appService.validateToken(jwt);
        this.isFromNotification = this.appService.get().claims['notification'];
        if (jsonStr && this.isFromNotification) {
          this.showGenerateBtn = true;
          const payload: OtpPayload = {
            action: OtpPayload.ActionEnum.GENERATE,
            mustSendCode: mustSendCode
          };
          this.authService.authenticateWithOtp(payload).pipe(take(1)).subscribe((isCodeSent: any) => {
            this.appService.setIsAuthenticated('false');
            const jsonValue = JSON.parse(jsonStr);
            const channel = jsonValue['channel'];
            this.authCodeChannel = channel.toLowerCase();
            if (openModal) {
              this.authCodeModalRef = this.openModal(this.authCodeModal, 'jwt');
            }
            if (isCodeSent) {
              this.alertService.success('LOGIN.AUTH_CODE.ALERT_SENT');
            }
          }, (err) => {
            this.appService.removeTokenFromStorage();
            if (err && err.error && err.error.code === ValidationCodeError.ACCOUNT_IS_DISABLED) {
              this.alertService.error('LOGIN.ERRORS.DISABLED');
            }
          });
        } else {
          this.appService.refresh(jwt);
          this.loadEndUserIfPossible(sessionId, stepId, jwt);
        }
      });
  }

  generateCode() {
    const jwt = this.route.snapshot.queryParams['jwt'];
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    this.loginWithJwt(jwt, sessionId, stepId, false, true);
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.isAuthenticating = false;
      return;
    }
    let credentialsPayload : CredentialsPayload = {
      tenantName: this.tenantName,
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
    }
    if (!!this.directoryAlias) credentialsPayload = { ...credentialsPayload, directoryAlias: this.directoryAlias}
    if (this.baWithEmail || this.baWithSMS) {
      const channel : AuthCodePayload.ChannelEnum = this.baWithEmail ? AuthCodePayload.ChannelEnum.EMAIL : AuthCodePayload.ChannelEnum.SMS;
      this.authService.authenticateUserWithCode({ ...credentialsPayload, channel}).pipe(take(1)).subscribe((response: any) => {
        this.alertService.success('LOGIN.AUTH_CODE.ALERT_SENT');
        this.authCodeModalRef = this.openModal(this.authCodeModal);
      }, (err) => {
        this.isAuthenticating = false;
        if (err && err.error && err.error.code === ValidationCodeError.ACCOUNT_IS_DISABLED) {
          this.alertService.error('LOGIN.ERRORS.DISABLED');
        } else {
          this.alertService.error('LOGIN.ERRORS.IDENTIFY');
        }
      });
    } else {
      this.isAuthenticating = true;
      this.loginForm.markAllAsTouched();

      this.authService.authenticateUser(credentialsPayload).pipe(take(1)).subscribe((response: any) => {
        this.authenticationSuccess(response.access_token);
        this.sprofileService.getSprofileList();
        this.isAuthenticating = false;
        this.appService.setIsAuthenticated('true');
        // Start system documentation process if session storage is empty
      }, (err) => {
        this.isAuthenticating = false;
        this.authenticationFailure();
        if (err && err.error && err.error.code === ValidationCodeError.ACCOUNT_IS_DISABLED) {
          this.alertService.error('LOGIN.ERRORS.DISABLED');
        } else {
          this.alertService.error('LOGIN.ERRORS.IDENTIFY');
        }
      });
    }
  }

  onAuthCodeSubmit() {
    const authCode: string = this.authCodeFormGroup.value.authCode.trim();
    if (this.authCodeModalTarget === 'ba') {
      const channel : AuthCodePayload.ChannelEnum = this.baWithEmail ?
        AuthCodePayload.ChannelEnum.EMAIL : AuthCodePayload.ChannelEnum.SMS;
      this.authService.verifyCode({
        tenantName: this.tenantName,
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
        channel,
        code: authCode
      }).pipe(take(1)).subscribe((response: any) => {
        this.authenticationSuccess(response.access_token);
        this.isAuthenticating = false;
        this.sprofileService.getSprofileList();
        this.onAuthCodeModalDismiss();
      }, (err) => {
        switch (err.error.code) {
          case ValidationCodeError.OTP_INCORRECT:
            this.alertService.error('LOGIN.AUTH_CODE.INCORRECT');
            break;
          case ValidationCodeError.OTP_LIMIT_EXCEEDED:
            this.alertService.error('LOGIN.AUTH_CODE.LIMIT_EXCEEDED');
            this.onAuthCodeModalDismiss();
            break;
          case ValidationCodeError.OTP_EXPIRED:
            this.alertService.error('LOGIN.AUTH_CODE.EXPIRED');
            break;
          default:
            this.alertService.error('ALERT.ERROR_OCCURRED');
        }
      });
    } else if (this.authCodeModalTarget === 'jwt') {
      const sessionId = this.route.snapshot.queryParams['sessionId'];
      const stepId = this.route.snapshot.queryParams['stepId'];
      const payload: OtpPayload = {
        action: OtpPayload.ActionEnum.VERIFY,
        code: authCode,
        sessionId,
        stepId
      }
      this.authService.authenticateWithOtp(payload).pipe(take(1)).subscribe((response: any) => {
        this.appService.refresh(response['access_token']);
        if (!sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)) {
          this.documentationService.buildSystemDocumentation();
        }
        this.loadEndUserIfPossible(sessionId, stepId, response['access_token']);
        this.onAuthCodeModalDismiss();
      }, (err) => {
        switch (err.error.code) {
          case ValidationCodeError.OTP_INCORRECT:
            this.alertService.error('LOGIN.AUTH_CODE.INCORRECT');
            break;
          case ValidationCodeError.OTP_LIMIT_EXCEEDED:
            this.alertService.error('LOGIN.AUTH_CODE.LIMIT_EXCEEDED');
            this.onAuthCodeModalDismiss();
            break;
          case ValidationCodeError.OTP_EXPIRED:
            this.alertService.error('LOGIN.AUTH_CODE.EXPIRED');
            break;
          default:
            this.alertService.error('ALERT.ERROR_OCCURRED');
        }
      });
    }
  }

  onAuthCodeModalDismiss() {
    this.authCodeFormGroup.reset();
    this.authCodeModalRef.dismiss('normal');
  }

  @HostListener('window:message', ['$event'])
  iFrameEventListener(event: MessageEvent) {
    const data = event.data;
    if (data) {
      if (data.access_token && data.flowStatus === 'success') {
        this.authenticationSuccess(data.access_token);
      } else {
        if (data.flowStatus === 'USER_CLICKED_CANCELLED') {
          this.handleOrelyLoginFailure(undefined);
        } else if (data.flowStatus === 'FAILURE_SSN_NOT_LINKED_WITH_USER') {
          this.handleOrelyLoginFailure('(LOGIN).ORELY.ERRORS.FAILURE_SSN_NOT_LINKED_WITH_USER');
        } else if (data.flowStatus === 'FAILURE_SSN_NOT_REGISTERED') {
          this.handleOrelyLoginFailure('LOGIN.ORELY.ERRORS.FAILURE_SSN_NOT_REGISTERED');
        }
      }
    }
  }

  openModal(content: TemplateRef<any>, target = 'ba') {
    this.authCodeModalTarget = target;
    return this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false });
  }

  sendEmailNotification() {
    this.forgotEmailFormGroup.markAllAsTouched();
    if (!this.forgotEmailFormGroup.valid) {
      return;
    }
    this.authService.emailPasswordReset(this.forgotEmailFormGroup.value.email, this.tenantName)
      .pipe(take(1)).subscribe(this.emailNotificationObserver);
    this.modalService.dismissAll('normal');
  }

  displayAlertSuccess(alertKey: string) {
    this.alertService.success(alertKey);
  }

  authWithBa() {
    this.selectedAuthProvider = 'ba';
    this.baWithEmail = false;
    this.baWithSMS = false;
  }

  authWithItsme() {
    const redirectUri = this.itsmeService.getRedirectUrlForAuth(ItsmeAuthServiceType.LOGIN);
    // this.itsmeAuthorizationUrl(redirectUri, ItsmeAuthServiceType.LOGIN)
    this.itsmeAuthenticationService.itsmeAuthorizationUrl(ItsmeAuthServiceType.LOGIN, redirectUri)
      .pipe(take(1))
      .subscribe((dto: ItsmeAuthorizationDto) => window.location.href = dto.url, (error: ApiError) => this.alertService.errorApi(error));
  }

  authWithMc() {
    window.location.href = this.basePath + '/api/mc/auth/start_discovery?role=login';
  }

  authWithSamlv2(target: string) {
    let url = this.basePath + `/saml/auth/${target}?`;
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    if (!!sessionId) {
      url += `sessionId=${sessionId}&`;
    }
    if (!!stepId) {
      url += `stepId=${stepId}&`;
    }
    window.location.href = url;
  }

  authWithAzure() {
    let url = this.basePath + '/api/azuread/secure/aad/' + this.tenantName + '?';
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    if (!!sessionId) {
      url += 'sessionId='+sessionId+'&';
    }
    if (!!stepId) {
      url += 'stepId='+stepId+'&';
    }
    window.location.href = url;
  }

  authWithADFS() {
    let route = this.basePath + '/adfs/auth/tenant/' + this.tenantName + '?';
    route = route + (!!this.adfsMultiTenant ? 'multitenant='+this.adfsMultiTenant+'&' : '')

    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    if (!!sessionId) {
      route += 'sessionId='+sessionId+'&';
    }
    if (!!stepId) {
      route += 'stepId='+stepId+'&';
    }
    window.location.href = route;
  }

  authWithOidc() {
    let route = this.basePath + '/oidc/auth/' + this.tenantName + '?';
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    if (!!sessionId) {
      route += 'sessionId='+sessionId+'&';
    }
    if (!!stepId) {
      route += 'stepId='+stepId+'&';
    }
    window.location.href = route;
  }

  authWithEmail() {
    this.selectedAuthProvider = this.authCodeChannel = 'email';
    this.baWithEmail = true;
    this.baWithSMS = false;
  }

  authWithSMS() {
    this.selectedAuthProvider = this.authCodeChannel = 'sms';
    this.baWithSMS = true;
    this.baWithEmail = false;
  }

  onRegister() {
    if (this.tenantName) {
      this.router.navigate(['/signup', this.tenantName]);
    } else {
      this.router.navigate(['/signup']);
    }
  }

  private leaveLoginPage() {
    Promise.all(
      [this.appService.loadEnduser(), this.endUserPreferenceService.getEnduserPreferences(this.appService.getCurrentUserId()).toPromise()])
      .then(([_, prefs]) => {
        this.visualIdentityService.loadVisualIdentityWhenLogged();
        if (prefs.preferences.autoSignatureBook) {
          this.router.navigate(['/signature-book']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      });
  }

  private authenticationSuccess(response: string) {
    this.appService.refresh(response);
    this.alertService.success('ALERT.SIGN_IN_SUCCESS');
    if (!sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)) {
      this.documentationService.buildSystemDocumentation();
    }
    this.leaveLoginPage();
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];

    this.loadEndUserIfPossible(sessionId, stepId);
  }

  private authenticationFailure() {
    this.appService.revoke();
    this.appService.logout();
    if (this.tenantName) {
      this.router.navigateByUrl(`/login?tenantName=${this.tenantName}`);
    } else {
      this.router.navigateByUrl(`/no-tenant-name`);
    }
  }

  private handleOrelyLoginFailure(errorKey: string) {
    if (errorKey) {
      this.alertService.error(errorKey);
    }
    this.selectedAuthProvider = this.mainConfigAuth;
    // FIXME This should be done using iframe reloading with a postMessage
    window.location.reload();
  }

  checkAndValidate(event: any) {
    this.forgotEmailValid = event.target.value.match(new RegExp(REGEXES.EMAIL));
  }

  updateAuthCode(val: EventListener) {
    if (!!val && val.length === 3) {
      this.authCodeFormGroup.setValue({authCode: val+' '});
    }
  }

}
