/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./back-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/pipes/translate.pipe";
import * as i3 from "../../../core/service/translate.service";
import * as i4 from "./back-btn.component";
import * as i5 from "@angular/common";
var styles_BackBtnComponent = [i0.styles];
var RenderType_BackBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BackBtnComponent, data: {} });
export { RenderType_BackBtnComponent as RenderType_BackBtnComponent };
export function View_BackBtnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-primary back-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-arrow-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("HEADER.BACK_BTN")); _ck(_v, 2, 0, currVal_0); }); }
export function View_BackBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-back-btn", [], null, null, null, View_BackBtnComponent_0, RenderType_BackBtnComponent)), i1.ɵdid(1, 49152, null, 0, i4.BackBtnComponent, [i5.Location], null, null)], null, null); }
var BackBtnComponentNgFactory = i1.ɵccf("app-back-btn", i4.BackBtnComponent, View_BackBtnComponent_Host_0, {}, {}, []);
export { BackBtnComponentNgFactory as BackBtnComponentNgFactory };
