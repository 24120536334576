/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-delete-tag.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/pipes/translate.pipe";
import * as i3 from "../../../core/service/translate.service";
import * as i4 from "./modal-delete-tag.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalDeleteTagComponent = [i0.styles];
var RenderType_ModalDeleteTagComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalDeleteTagComponent, data: {} });
export { RenderType_ModalDeleteTagComponent as RenderType_ModalDeleteTagComponent };
export function View_ModalDeleteTagComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["header", 1]], null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "modal-body break-words"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpod(9, { tagName: 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.title)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 10).transform(_co.message, _ck(_v, 9, 0, _co.name))); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("MODAL_DELETE_SESSION.DELETE")); _ck(_v, 13, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("MODAL_DELETE_SESSION.CLOSE")); _ck(_v, 16, 0, currVal_3); }); }
export function View_ModalDeleteTagComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-delete-tag", [], null, null, null, View_ModalDeleteTagComponent_0, RenderType_ModalDeleteTagComponent)), i1.ɵdid(1, 49152, null, 0, i4.ModalDeleteTagComponent, [i5.NgbActiveModal], null, null)], null, null); }
var ModalDeleteTagComponentNgFactory = i1.ɵccf("app-modal-delete-tag", i4.ModalDeleteTagComponent, View_ModalDeleteTagComponent_Host_0, { title: "title", message: "message", name: "name" }, {}, []);
export { ModalDeleteTagComponentNgFactory as ModalDeleteTagComponentNgFactory };
