/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CountData } from '../model/countData';
import { CreateSessionPayload } from '../model/createSessionPayload';
import { FlowChartData } from '../model/flowChartData';
import { GateRulesData } from '../model/gateRulesData';
import { SessionData } from '../model/sessionData';
import { SessionExportData } from '../model/sessionExportData';
import { SessionFilter } from '../model/sessionFilter';
import { StepEnduserData } from '../model/stepEnduserData';
import { UpdateSessionPayload } from '../model/updateSessionPayload';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SessionService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Cancel the session
     * 
     * @param sessionId Session to which the workflow applies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelSession(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cancelSession(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cancelSession(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cancelSession(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling cancelSession.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/cancel`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create signature session
     * 
     * @param payload Session to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSession(payload?: CreateSessionPayload, observe?: 'body', reportProgress?: boolean): Observable<SessionData>;
    public createSession(payload?: CreateSessionPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SessionData>>;
    public createSession(payload?: CreateSessionPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SessionData>>;
    public createSession(payload?: CreateSessionPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SessionData>(`${this.basePath}/api/sessions`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete signature session
     * 
     * @param sessionId Session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSession(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSession(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSession(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSession(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling deleteSession.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Disable the session
     * 
     * @param sessionId Session to which the workflow applies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public disableSession(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public disableSession(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public disableSession(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public disableSession(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling disableSession.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/disable`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * dummy
     * 
     * @param sessionId Session identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dummy1(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<StepEnduserData>;
    public dummy1(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StepEnduserData>>;
    public dummy1(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StepEnduserData>>;
    public dummy1(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling dummy1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StepEnduserData>(`${this.basePath}/api/sessions/dummy`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export sessions based on the mapping profile
     * 
     * @param filter Session filter (null values ignored)
     * @param mapping Mapping profile
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportSessionsUsingMapping(filter?: SessionFilter, mapping?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportSessionsUsingMapping(filter?: SessionFilter, mapping?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportSessionsUsingMapping(filter?: SessionFilter, mapping?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportSessionsUsingMapping(filter?: SessionFilter, mapping?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mapping !== undefined && mapping !== null) {
            queryParameters = queryParameters.set('mapping', <any>mapping);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/api/sessions/export`,
            filter,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get signature session
     * 
     * @param sessionId Session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSession(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<SessionData>;
    public getSession(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SessionData>>;
    public getSession(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SessionData>>;
    public getSession(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getSession.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SessionData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get flow chart data of the signature session
     * 
     * @param sessionId Session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSessionFlowChartData(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<FlowChartData>;
    public getSessionFlowChartData(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FlowChartData>>;
    public getSessionFlowChartData(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FlowChartData>>;
    public getSessionFlowChartData(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getSessionFlowChartData.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FlowChartData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/flow-chart-data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get signature sessions matching the filter
     * 
     * @param filter Session filter (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSessionList(filter?: SessionFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<SessionData>>;
    public getSessionList(filter?: SessionFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SessionData>>>;
    public getSessionList(filter?: SessionFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SessionData>>>;
    public getSessionList(filter?: SessionFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<SessionData>>(`${this.basePath}/api/sessions/search`,
            filter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get number of sessions matching the filter
     * 
     * @param filter Session filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSessionSearchCount(filter?: SessionFilter, observe?: 'body', reportProgress?: boolean): Observable<CountData>;
    public getSessionSearchCount(filter?: SessionFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountData>>;
    public getSessionSearchCount(filter?: SessionFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountData>>;
    public getSessionSearchCount(filter?: SessionFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CountData>(`${this.basePath}/api/sessions/search-count`,
            filter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of gate rules
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStepRules(observe?: 'body', reportProgress?: boolean): Observable<GateRulesData>;
    public getStepRules(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GateRulesData>>;
    public getStepRules(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GateRulesData>>;
    public getStepRules(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GateRulesData>(`${this.basePath}/api/sessions/rules`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Go on with the session
     * 
     * @param sessionId Session to which the workflow applies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goonSession(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public goonSession(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public goonSession(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public goonSession(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling goonSession.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/goon`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import sessions based on a csv file
     * 
     * @param mapping Mapping profile
     * @param file CSV file to sync
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importSessions(mapping: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public importSessions(mapping: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public importSessions(mapping: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public importSessions(mapping: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mapping === null || mapping === undefined) {
            throw new Error('Required parameter mapping was null or undefined when calling importSessions.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mapping !== undefined && mapping !== null) {
            queryParameters = queryParameters.set('mapping', <any>mapping);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<number>(`${this.basePath}/api/sessions/mass-import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Make session template
     * 
     * @param sessionId Session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public makeSessionTemplate(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public makeSessionTemplate(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public makeSessionTemplate(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public makeSessionTemplate(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling makeSessionTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/template`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update signature session
     * 
     * @param sessionId Session
     * @param payload Session to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialSessionUpdate(sessionId: number, payload?: UpdateSessionPayload, observe?: 'body', reportProgress?: boolean): Observable<SessionData>;
    public partialSessionUpdate(sessionId: number, payload?: UpdateSessionPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SessionData>>;
    public partialSessionUpdate(sessionId: number, payload?: UpdateSessionPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SessionData>>;
    public partialSessionUpdate(sessionId: number, payload?: UpdateSessionPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling partialSessionUpdate.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<SessionData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export all session information
     * 
     * @param sessionId Session to export
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sessionExport(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<SessionExportData>;
    public sessionExport(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SessionExportData>>;
    public sessionExport(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SessionExportData>>;
    public sessionExport(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling sessionExport.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SessionExportData>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/export`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import and create a new session based on provided information
     * 
     * @param data Information needed to create a session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sessionImport(data?: SessionExportData, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public sessionImport(data?: SessionExportData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public sessionImport(data?: SessionExportData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public sessionImport(data?: SessionExportData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.basePath}/api/sessions/import`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * start a session and step
     * 
     * @param sessionId Session
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startSession(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public startSession(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public startSession(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public startSession(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling startSession.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/start`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get map of end endusers by step
     * 
     * @param sessionId Session identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stepEnduserListByStep(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: Array<StepEnduserData>; }>;
    public stepEnduserListByStep(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: Array<StepEnduserData>; }>>;
    public stepEnduserListByStep(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: Array<StepEnduserData>; }>>;
    public stepEnduserListByStep(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling stepEnduserListByStep.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: Array<StepEnduserData>; }>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/step-endusers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Suspend the session
     * 
     * @param sessionId Session to which the workflow applies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public suspendSession(sessionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public suspendSession(sessionId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public suspendSession(sessionId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public suspendSession(sessionId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling suspendSession.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/suspend`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
