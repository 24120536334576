<app-tooltip [tooltipText]="'HELP.LOGIN' | translate"></app-tooltip>

<app-full-screen mode="container-s">
  <div class="login-container">
    <div class="d-flex justify-content-center">
      <!-- luxtrust image -->
      <img *ngIf="customLogo"
           class="main-logo align-self-center mb-5"
           src="data:image/png;base64,{{customLogo}}">
    </div>

    <div *ngIf="mainLoginMessageContent && mainLoginMessageNature" [ngClass]="{'alert alert-success': mainLoginMessageNature=='success', 'alert alert-info':mainLoginMessageNature=='info', 'alert alert-danger':mainLoginMessageNature=='danger','alert alert-warning':mainLoginMessageNature=='warning' }">
      <strong *ngIf="mainLoginMessageNature === 'success'">{{mainLoginMessageTitle}}</strong>
      <strong *ngIf="mainLoginMessageNature === 'info'">{{mainLoginMessageTitle}}</strong>
      <strong *ngIf="mainLoginMessageNature === 'danger'">{{mainLoginMessageTitle}}</strong>
      <strong *ngIf="mainLoginMessageNature === 'warning'">{{mainLoginMessageTitle}}</strong>
      <p>{{mainLoginMessageContent}}</p>
    </div>

    <form (ngSubmit)="onSubmit()"
          *ngIf="selectedAuthProvider === 'ba' || selectedAuthProvider === 'email' || selectedAuthProvider === 'sms' && (!forceAuthMode || forceAuthMode === 'ba' || forceAuthMode ==='email' || forceAuthMode === 'sms')"
          [formGroup]="loginForm"
          class="row">
      <div class="col-12">
        <!-- User name input -->
        <div class="form-row">
          <app-input-text [errors]="{
                            required: 'LOGIN.ERRORS.USER_NAME' | translate
                          }" [placeholder]="'LOGIN.USER_NAME' | translate" autoFocus class="col"
                          [idPostfix]="'username'" formControlName="username" icon="id-card"
                          type="email"></app-input-text>
        </div>

        <!-- User password input -->
        <div class="form-row">
          <app-input-text [errors]="{
                            required: 'LOGIN.ERRORS.PASSWORD' | translate
                          }" [placeholder]="'LOGIN.PASSWORD' | translate" class="col" formControlName="password"
                          icon="key" [idPostfix]="'password'" type="password"></app-input-text>
        </div>
      </div>

      <!-- connect button -->
      <div class="col-12 d-flex">
        <button [disabled]="isAuthenticating" class="btn btn-primary flex-grow-1"
                type="submit" id="id-login-button">{{'LOGIN.SIGN_IN' | translate}}</button>
      </div>
      <div class="col-12">
        <span (click)="openModal(forgotPassword)" id="forgot-pswd-link" class="forgot-pswd-link">
          <span>
            {{'LOGIN.FORGOT-PASSWORD' | translate}}
          </span>
        </span>
      </div>
    </form>

    <div class="btns-singIn">

      <!-- Itsme signing -->
      <button (click)="authWithItsme()" *ngIf="itsmeEnabled && selectedAuthProvider === 'itsme' && (!forceAuthMode || forceAuthMode === 'itsme')"
              class="btn btn-itsme btn-signIn" href="#"
              [ngClass]="'mainAuth'">
        <img src="assets/img/itsme-logo.png">
      </button>

      <!-- MC signing -->
      <button (click)="authWithMc()" *ngIf="mcEnabled && selectedAuthProvider === 'mc' && (!forceAuthMode || forceAuthMode === 'mc')"
              class="btn btn-mc-connect btn-signIn" href="#"
              [ngClass]="'mainAuth'">
        <img src="assets/img/mobile_connect_icon.png">
      </button>

      <!-- SAMLv2 signing / GARDIAN -->
      <button (click)="authWithSamlv2('gardian')" *ngIf="gardianEnabled && selectedAuthProvider === 'gardian' && (!forceAuthMode || forceAuthMode === 'gardian')"
              class="btn btn-guardian btn-signIn" href="#"
              [ngClass]="'mainAuth'">
        <img src="assets/img/gardian-logo.png">
      </button>

      <!-- SAMLv2 signing / SAML2 -->
      <button (click)="authWithSamlv2('saml2')" *ngIf="samlEnabled && selectedAuthProvider === 'saml2' && (!forceAuthMode || forceAuthMode === 'saml2')"
              class="btn btn-guardian btn-signIn" href="#"
              [ngClass]="'mainAuth'">
        <img src="assets/img/saml2-logo.png">
      </button>

      <!-- Azure signing -->
      <button (click)="authWithAzure()" *ngIf="azureEnabled && selectedAuthProvider === 'azure' && (!forceAuthMode || forceAuthMode === 'azure')"
              class="btn btn-guardian btn-signIn" href="#"
              id="auth-with-azure-main"
              [ngClass]="'mainAuth'">
        <img src="assets/img/azure-logo.png">
      </button>

      <!-- Azureenr signing -->
      <button (click)="authWithAzure()" *ngIf="azureEnrEnabled && selectedAuthProvider === 'azureenr' && (!forceAuthMode || forceAuthMode === 'azureenr')"
              class="btn btn-guardian btn-signIn" href="#"
              [ngClass]="'mainAuth'">
        <img src="assets/img/azureenr-logo.jpg">
      </button>

      <!-- ADFS signing -->
      <button (click)="authWithADFS()" *ngIf="adfsEnabled && selectedAuthProvider === 'adfs' && (!forceAuthMode || forceAuthMode === 'adfs')"
            class="btn btn-guardian btn-signIn" href="#"
            [ngClass]="'mainAuth'">
        <img src="assets/img/adfs-logo.png">
      </button>

      <!-- OIDC signing -->
      <button (click)="authWithOidc()" *ngIf="oidcEnabled && selectedAuthProvider === 'oidc' && (!forceAuthMode || forceAuthMode === 'oidc')"
          class="btn btn-guardian btn-signIn" href="#"
          [ngClass]="'mainAuth'">
        <img src="assets/img/oidc-logo.png">
      </button>
    </div>
    <!-- LuxTrust Orely iframe -->
    <div *ngIf="selectedAuthProvider === 'luxtrust'" class="my-3">
      <iframe #orelyIframe [height]="650" [src]="orelyAuthUrl" frameborder="0" name="orely" scrolling="no"
              target="_parent" width="100%">
      </iframe>
    </div>

    <div class="row mt-sm-3" *ngIf="factorsEnabled > 1">
      <h5 class="login-bot-text">
        {{ 'LOGIN.CONNECT.WITH' | translate}}:
      </h5>
    </div>

    <div class="btns-singIn">
      <!-- LuxTrust Orely signing -->
      <button (click)="selectedAuthProvider = 'luxtrust';"
              *ngIf="luxtrustEnabled && selectedAuthProvider !== 'luxtrust' && (!forceAuthMode || forceAuthMode === 'luxtrust')" class="btn btn-info btn-signIn"
              [ngClass]="'otherAuth'">
        <img src="assets/img/luxtrust_icon.png">
      </button>

      <!-- User / password signing -->
      <button (click)="authWithBa()" *ngIf="passwordEnabled && selectedAuthProvider !== 'ba' && (!forceAuthMode || forceAuthMode === 'ba')"
              class="btn btn-info btn-signIn"
              [ngClass]="'otherAuth'">
        <img src="assets/img/ba.png">
      </button>

      <!-- Itsme signing -->
      <button (click)="authWithItsme()" *ngIf="itsmeEnabled && selectedAuthProvider !== 'itsme' && (!forceAuthMode || forceAuthMode === 'itsme')"
              class="btn btn-itsme btn-signIn"
              [ngClass]="'otherAuth'">
        <img src="assets/img/itsme-logo.png">
      </button>

      <!-- MC signing -->
      <button (click)="authWithMc()" *ngIf="mcEnabled && selectedAuthProvider !== 'mc' && (!forceAuthMode || forceAuthMode === 'mc')"
              class="btn btn-mc-connect btn-signIn"
              [ngClass]="'otherAuth'">
        <img src="assets/img/mobile_connect_icon.png">
      </button>

      <!-- SAMLv2 signing / GARDIAN -->
      <button (click)="authWithSamlv2('gardian')" *ngIf="gardianEnabled && selectedAuthProvider !== 'gardian' && (!forceAuthMode || forceAuthMode === 'gardian')"
              class="btn btn-guardian btn-signIn"
              id="id-gardian-login"
              [ngClass]="'otherAuth'">
        <img src="assets/img/gardian-logo.png">
      </button>

      <!-- SAMLv2 signing / SAML2 -->
      <button (click)="authWithSamlv2('saml2')" *ngIf="samlEnabled && selectedAuthProvider !== 'saml2' && (!forceAuthMode || forceAuthMode === 'saml2')"
              class="btn btn-guardian btn-signIn"
              id="id-saml2-login"
              [ngClass]="'otherAuth'">
        <img src="assets/img/saml2-logo.png">
      </button>

      <!-- Azure signing -->
      <button (click)="authWithAzure()" *ngIf="azureEnabled && selectedAuthProvider !== 'azure' && (!forceAuthMode || forceAuthMode === 'azure')"
              class="btn btn-guardian btn-signIn"
              [ngClass]="'otherAuth'">
        <img src="assets/img/azure-logo.png">
      </button>

      <!-- Azureenr signing -->
      <button (click)="authWithAzure()" *ngIf="azureEnrEnabled && selectedAuthProvider !== 'azureenr' && (!forceAuthMode || forceAuthMode === 'azureenr')"
              class="btn btn-guardian btn-signIn"
              id="auth-with-azure"
              [ngClass]="'otherAuth'">
        <img src="assets/img/azureenr-logo.jpg">
      </button>

      <!-- Email signing -->
      <button (click)="authWithEmail()" *ngIf="emailEnabled && selectedAuthProvider !== 'email' && (!forceAuthMode || forceAuthMode === 'email')"
              class="btn btn-info btn-signIn"
              id="auth-with-email"
              [ngClass]="'otherAuth'">
        <img src="assets/img/email.png">
      </button>

      <!-- SMS signing -->
      <button (click)="authWithSMS()" *ngIf="smsEnabled && selectedAuthProvider !== 'sms' && (!forceAuthMode || forceAuthMode === 'sms')"
            class="btn btn-info btn-signIn"
            id="auth-with-sms"
            [ngClass]="'otherAuth'">
        <img src="assets/img/sms.png">
      </button>

      <!-- ADFS signing -->
      <button (click)="authWithADFS()" *ngIf="adfsEnabled && selectedAuthProvider !== 'adfs' && (!forceAuthMode || forceAuthMode === 'adfs')"
              class="btn btn-guardian btn-signIn"
              id="auth-with-adfs"
              [ngClass]="'otherAuth'">
        <img src="assets/img/adfs-logo.png">
      </button>

      <!-- OIDC signing -->
      <button (click)="authWithOidc()" *ngIf="oidcEnabled && selectedAuthProvider !== 'oidc' && (!forceAuthMode || forceAuthMode === 'oidc')"
              class="btn btn-guardian btn-signIn"
              id="auth-with-oidc"
              [ngClass]="'otherAuth'">
        <img src="assets/img/oidc-logo.png">
      </button>

    </div>

    <!--    <div class='row' *ngIf="mainConfigAuth && mainConfigAuth !== 'ba'">-->
    <!--      <div class='login-bot-text'>-->
    <!--        {{'LOGIN.OTHER_AUTH' | translate}}  <span (click)="changeMethodAuth()" class="login-bot-text-link">{{'LOGIN.CLICK.HERE' | translate}}</span>-->
    <!--      </div>-->
    <!--    </div>-->

    <div *ngIf="selfRegisterEnabled" class="row" id="self-registration">
      <span class="login-bot-text">
        {{'LOGIN.NOT.REGISTERED' | translate}}
        <span id="id-register" (click)="onRegister()" class="login-bot-text-link">{{'LOGIN.CLICK.HERE' | translate}}</span>
      </span>
    </div>

    <div class="row">
      <span class="login-bot-text">
        {{needAssistance}}
        <a class="login-bot-text-link"
           href="{{supportContactRedirection}}">{{supportContact}}</a>
      </span>
    </div>
  </div>

</app-full-screen>

<ng-template #forgotPassword let-modal>
  <div class="modal-header">
    <div class="modal-title">{{'LOGIN.ENTER_EMAIL'| translate}}</div>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form #forgotEmailForm (ngSubmit)="sendEmailNotification()" [formGroup]="forgotEmailFormGroup" class="row">
      <div class="col-12">
        <div class="form-row">
          <app-input-text (keyup)="checkAndValidate($event)" [errors]="{
                            required: 'LOGIN.FORGOT_PASSWORD.ERRORS.EMAIL' | translate,
                            email: 'LOGIN.FORGOT_PASSWORD.ERRORS.EMAIL_FORMAT' | translate,
                            pattern: 'LOGIN.FORGOT_PASSWORD.ERRORS.EMAIL_FORMAT' | translate
                          }" [placeholder]="'LOGIN.FORGOT_PASSWORD.ENTER_EMAIL' | translate" autoFocus class="col"
                          formControlName="email" icon="at" type="email">
          </app-input-text>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button (click)="sendEmailNotification(); modal.dismiss('Cross click')" [disabled]="!forgotEmailValid"
            class="btn btn-primary" id="send" type="submit">
      {{'LOGIN.SEND' | translate}}
    </button>
  </div>
</ng-template>


<ng-template #authCodeModal let-modal>
  <div class="modal-header">
    <div class="modal-title">{{'LOGIN.AUTH_CODE.TITLE'| translate}}</div>
    <button (click)="onAuthCodeModalDismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form (ngSubmit)="onAuthCodeSubmit()" [formGroup]="authCodeFormGroup" class="row">
      <div class="col-12">
        <div class="form-row">
          <app-input-text [errors]="{
                            required: 'LOGIN.AUTH_CODE.REQUIRED' | translate,
                            pattern: 'LOGIN.AUTH_CODE.FORMAT' | translate
                          }"
                          [placeholder]="'LOGIN.AUTH_CODE.ENTER' | translate: {channel: authCodeChannel}"
                          (ngModelChange)="updateAuthCode($event)"
                          autoFocus
                          class="col"
                          formControlName="authCode"
                          [hasOnInput]="true"
                          type="text">
          </app-input-text>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div *ngIf="showGenerateBtn" class="mr-2">
      <button (click)="generateCode()"
              class="btn btn-sm btn-secondary"
              type="button">
        {{'LOGIN.AUTH_CODE.GENERATE' | translate}}
      </button>
    </div>

    <button (click)="onAuthCodeSubmit()" [disabled]="authCodeFormGroup.invalid"
            class="btn btn-primary" type="submit">
      {{'LOGIN.SEND' | translate}}
    </button>
  </div>
</ng-template>
