import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import find from 'lodash/find';
import {DocumentService, EnduserData, SessionData, StepData} from 'luxtrust-cosi-api';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {SprofileData} from 'luxtrust-cosi-api/model/sprofileData';
import {AlertService} from 'src/app/services/services/alert-service';
import {IEService} from 'src/app/services/services/ie.service';
import {SignerDetails} from 'src/app/shared/components/document-item/document-item.component';
import {ModalYesNoComponent} from 'src/app/shared/components/modal-yes-no/modal-yes-no.component';
import {DocumentWithDetails} from '../../../session/step/model/document-with-details';

@Component({
  selector: 'app-document-card', templateUrl: './document-card.component.html', styleUrls: ['./document-card.component.scss']
})
export class DocumentCardComponent implements OnInit, OnChanges {

  constructor(
    private readonly translateService: TranslateService,
    private readonly alertService: AlertService,
    private readonly documentService: DocumentService,
    private readonly modal: NgbModal,
    private readonly ieService: IEService
  ) {}

  public isIE: boolean;
  @Input() session: SessionData;
  @Input() step: StepData;
  @Input() document: DocumentWithDetails;
  @Input() sprofiles: SprofileData[];
  @Input() idSuffix: string;
  @Input() annexe: boolean;
  @Input() mutual: boolean;
  @Input() isLoading: boolean;
  @Input() canSeeSprofile = true;
  @Input() isConfigurator = false;
  @Input() signers: EnduserData[];
  @Input() signersByDocuments: { document: DocumentData, signers: EnduserData[] }[];
  private _hasFailed = false;
  public get hasFailed(): boolean {
    return this._hasFailed;
  }

  @Input()
  public set hasFailed(value: boolean) {
    if (this._hasFailed !== value) {
      this._hasFailed = value;
      if (value && this.mutualBeingChanged) {
        this.document.parentIsMutual = !this.document.parentIsMutual;
        setTimeout(() => {
          this.onFailed.emit(false);
          this.mutualBeingChanged = false;
        }, 5);
      }
    }
  }
  @Input() disabled: boolean;
  canAddAcroform = false;
  @Input() signatures: SignerDetails[];
  @Input() canUpdate = true;
  @Input() canClone = true;
  @Input() canDelete = true;
  @Output() onAddAcroform: EventEmitter<undefined> = new EventEmitter<undefined>();
  @Output() onClone: EventEmitter<{files: FileList, document: DocumentData}> = new EventEmitter<{files: FileList, document: DocumentData}>();
  @Output() onDelete: EventEmitter<DocumentData> = new EventEmitter<DocumentData>();
  @Output() onMutual: EventEmitter<{ document: DocumentData, isMutual: boolean, reload: boolean }> = new EventEmitter<{ document: DocumentData, isMutual: boolean, reload: boolean }>();
  @Output() onMutualDocumentIn: EventEmitter<{ document: DocumentData, isMutual: boolean }> = new EventEmitter<{ document: DocumentData, isMutual: boolean }>();
  @Output() onMutualAppendixIn: EventEmitter<{ document: DocumentData, isMutual: boolean }> = new EventEmitter<{ document: DocumentData, isMutual: boolean }>();
  @Output() onSprofile: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFailed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openModal: EventEmitter<DocumentData> = new EventEmitter<DocumentData>();
  @Output() onReuploadDoc: EventEmitter<File> = new EventEmitter<File>();
  selectedSprofile: string;
  mutualBeingChanged = false;
  isAnnexeDocument = false;
  @Input() hasAtLeast1Signature = false;
  @Input() hasStepTagPositionSignatureLocation = false;
  @Input() uploading = false;
  @Input() progress = 0;
  @Output() onCancelReupload: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('uploadField', {static: false}) uploadFieldRef: ElementRef;
  @ViewChild('inputMutual', {
    read: ElementRef, static: false
  }) inputMutual: ElementRef;
  usedInOtherSteps = false;
  displaySwitcherMutual = false;
  documentCloneSelected: DocumentData;

  public xsMaxCharacters = 12;
  public smMaxCharacters = 13;
  public mdMaxCharacters = 14;
  public lgMaxCharacters = 15;
  public xlMaxCharacters = 16;

  ngOnInit() {
    if (this.isConfigurator) {
      this.mdMaxCharacters = 25;
      this.lgMaxCharacters = 40;
      this.xlMaxCharacters = 40;
    }
    this.isIE = this.ieService.checkIfIE();
    this.checkCanAddAcroform();
    this.selectedSprofile = this.document.sprofileCode;
    this.displaySwitcherMutual = !this.document.parentIsMutual || this.document.parentMutualOnCurrentStep;
    this.usedInOtherSteps = this.document.used;

  }

  checkCanAddAcroform() {
    if (this.signers) {
      this.canAddAcroform = this.signers.length > 0;
    } else if (this.signersByDocuments) {
      const signerByDocument = find(this.signersByDocuments, (docSigners) => docSigners.document.name === this.document.name);
      this.canAddAcroform = signerByDocument && signerByDocument.signers && signerByDocument.signers.length > 0;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['signers'] && !changes['signers'].isFirstChange() ) ||
      (changes['signersByDocuments'] && !changes['signersByDocuments'].isFirstChange()) ) {
      this.checkCanAddAcroform();
    }
  }

  checkForPositionSignatureLocation(): boolean {
    if(DocumentData.TypologyEnum.TOVIEW === this.document.typology ){
      this.isAnnexeDocument = true;
    }
    return this.hasStepTagPositionSignatureLocation && (!this.document.parentIsMutual  || this.document.sprofileCode !== 'VISA');
    // TODO : Methode à supprimer quand l'ajout de document avec POSTION_SIGNATURE_LOCATIION ne sera plus possible
  }

  checkIfNotSharedWithPositionSignatureLocation(): boolean {
    return this.hasStepTagPositionSignatureLocation && !this.document.used;
  }

  enableSignatures(): boolean {
    const needAcroform = !!this.sprofiles && this.sprofiles.find(sprofile => sprofile.code === this.document.sprofileCode).needsAcroform;
    return needAcroform && (!this.step.skipAcroforms && !this.document.shared && this.canAddAcroform && !this.hasAtLeast1Signature ||
      (this.hasStepTagPositionSignatureLocation && this.document.parentIsMutual && this.document.sprofileCode === 'VISA') && !this.checkIfNotSharedWithPositionSignatureLocation());
    // TODO : Condition à supprimer quand l'ajout de document avec POSTION_SIGNATURE_LOCATIION ne sera plus possible, ne laisser que la partie "this.hasPositionSignatureTagAlias"
  }

  change() {
    if (this.inputMutual.nativeElement.checked === true && (this.signatures !== undefined && this.signatures.length > 0)) {
      this.inputMutual.nativeElement.checked = false;
      const modalMutual = this.modal.open(ModalYesNoComponent);
      modalMutual.componentInstance.message = 'MODAL_YES_NO.TITLE';
      modalMutual.result.then(value => {
        if (value === 'yes') {
          this.inputMutual.nativeElement.checked = true;
          this.mutualBeingChanged = true;
          this.onMutual.emit({ document: this.document, isMutual: this.document.parentIsMutual, reload: true });
        }
      });
    } else {
      if (this.inputMutual.nativeElement.checked === false && (this.signatures !== undefined && this.signatures.length > 0)) {
        if (!this.usedInOtherSteps) {
          this.inputMutual.nativeElement.checked = true;
          const modalMutual = this.modal.open(ModalYesNoComponent);
          modalMutual.componentInstance.message = 'MODAL_YES_NO.TITLE_MUTUAL';
          modalMutual.result.then(value => {
            if (value === 'yes') {
              this.inputMutual.nativeElement.checked = false;
              this.mutualBeingChanged = true;
              this.onMutual.emit({ document: this.document, isMutual: this.document.parentIsMutual, reload: true });
            }
          });
        } else {
          this.alertService.error('WIZARD.DOCUMENTS.ERROR');
          this.inputMutual.nativeElement.checked = true;
        }
      } else {
        if (!this.usedInOtherSteps) {
          this.mutualBeingChanged = true;
          this.onMutual.emit({ document: this.document, isMutual: this.document.parentIsMutual, reload: false });
        } else {
          this.alertService.error('WIZARD.DOCUMENTS.ERROR');
          this.inputMutual.nativeElement.checked = true;
        }
      }
    }
    return !this.isLoading;
  }

  onReuploadDocument(event) {
    const files: FileList = event.target.files;
    if (files && files.length === 1) {
      this.onReuploadDoc.emit(files[0]);
    }
  }

  uploadNewFile(document) {
    this.documentCloneSelected = document;
    this.uploadFieldRef.nativeElement.click();
  }

  uploadDocumentClone(uploadedDoc) {
    if (uploadedDoc.target.files && uploadedDoc.target.files.length) {
      const files: FileList = uploadedDoc.target.files;
      const document: DocumentData = this.documentCloneSelected;
      this.onClone.emit({files: files, document});
    }
    this.uploadFieldRef.nativeElement.value = null;
  }
}
