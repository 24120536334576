<ng-container>
  <label *ngIf="mutual" class="title">{{title}}</label>

  <app-items-table [items]="documents" [noResult]="noResult" [singleList]="true">
    <div *ngIf="!mutual" action>
      <label *ngIf="!isConfigurator" class="title">{{title}}</label>
      <button *ngIf="isConfigurator && canAdd" (click)="addDocumentWithConfirmation()" [disabled]="disabled"
              class="btn btn-primary w-100">{{'WIZARD.DOCUMENTS.ADD_DOCUMENT' | translate}}</button>
      <div>
        <input #uploadField (change)="uploadDocument($event)"
               [accept]="acceptedMimeTypes"
               [id]="id" [disabled]="disabled"
               class="input-file" multiple
               type="file"/>
        <label *ngIf="!isConfigurator" [for]="id" class="btn btn-primary btn-block" [class.disabled]="disabled">
          {{buttonLabel}}
        </label>
        <app-input-loader [status]="status"></app-input-loader>
      </div>
      <div *ngIf="uploading">
        <div *ngFor="let uid of getUploadIds()" class="mt-4 display-flex">
          <div class="margin-right-1 width-50">
            {{uid.toString().split(';name;')[1]}}
          </div>
          <div class="progress margin-right-1 width-40">
            <div
              class="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              [attr.aria-valuenow]="progress[uid]"
              aria-valuemin="0"
              aria-valuemax="100"
              [ngStyle]="{ width: progress[uid] + '%' }"
            >
              {{ progress[uid] }}%
            </div>
          </div>
          <button (click)="cancelReupload.emit(uid)"
                  class="btn flex-grow-1 btn-warning mr-2">
            {{'WIZARD.DOCUMENTS.CANCEL' | translate}}
          </button>
        </div>
      </div>
    </div>

    <ng-template #itemsCards let-document let-index>
      <app-document-card
        (onAddAcroform)="onAddAcroform.emit(document)"
        (onDelete)="onDelete.emit($event)"
        (onMutual)="onMutual.emit($event)"
        (onMutualAppendixIn)="onMutualAppendixIn.emit($event)"
        (onMutualDocumentIn)="onMutualDocumentIn.emit($event)"
        (onClone)="onClone.emit($event)"
        (onSprofile)="updateDocument(document, $event)"
        (onFailed)="onFailed.emit($event)"
        (openModal)="openModal.emit($event)"
        (onReuploadDoc)="reuploadDocument(document, $event)"
        (onCancelReupload)="cancelReupload.emit($event)"
        *ngIf="document && (signingUsers || signersByDocuments)"
        [annexe]="annexe"
        [signers]="signingUsers"
        [signersByDocuments]="signersByDocuments"
        [disabled]="disabled"
        [canSeeSprofile]="canChangeSprofile"
        [document]="document"
        [isLoading]="isLoading"
        [hasFailed]="hasFailed"
        [mutual]="mutual"
        [signatures]="signatures[document.name]"
        [session]="session"
        [step]="step"
        [isConfigurator]="isConfigurator"
        [canClone]="canClone"
        [canDelete]="canDelete"
        [canUpdate]="canUpdate"
        [hasAtLeast1Signature]="hasAtLeast1Signature"
        [hasStepTagPositionSignatureLocation]="hasStepTagPositionSignatureLocation"
        [sprofiles]="sprofiles"
        [idSuffix]="idSuffix + '-' + documents.indexOf(document)"
        [uploading]="documentIdsReuploading.includes(document.id.toString())"
        [progress]="progress[document.id]">
      </app-document-card>
    </ng-template>
  </app-items-table>

  <div *ngIf="!isConfigurator" class="custom-margin"></div>
</ng-container>
