/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-configurator-metadatas.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/components/metadata-selector/metadata-selector.component.ngfactory";
import * as i3 from "../../../../../../shared/components/metadata-selector/metadata-selector.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../../../../../core/service/translate.service";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../shared/components/card/card.component.ngfactory";
import * as i8 from "../../../../../../shared/components/card/card.component";
import * as i9 from "../../../../../../core/pipes/translate.pipe";
import * as i10 from "./modal-configurator-metadatas.component";
var styles_ModalConfiguratorMetadatasComponent = [i0.styles];
var RenderType_ModalConfiguratorMetadatasComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalConfiguratorMetadatasComponent, data: {} });
export { RenderType_ModalConfiguratorMetadatasComponent as RenderType_ModalConfiguratorMetadatasComponent };
function View_ModalConfiguratorMetadatasComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-metadata-selector", [], null, [[null, "onUpdate"], [null, "tagInError"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUpdate" === en)) {
        var pd_0 = (_co.updateStepTagMeta($event) !== false);
        ad = (pd_0 && ad);
    } if (("tagInError" === en)) {
        var pd_1 = (_co.checkTagError($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MetadataSelectorComponent_0, i2.RenderType_MetadataSelectorComponent)), i1.ɵdid(2, 638976, null, 0, i3.MetadataSelectorComponent, [i4.NgbModal, i5.TranslateService], { tag: [0, "tag"], showMode: [1, "showMode"], canHaveNullToggle: [2, "canHaveNullToggle"] }, { onUpdate: "onUpdate", tagInError: "tagInError" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; var currVal_1 = true; var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ModalConfiguratorMetadatasComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalConfiguratorMetadatasComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.configurator); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModalConfiguratorMetadatasComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-card", [], null, null, null, i7.View_CardComponent_0, i7.RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i8.CardComponent, [], { customPadding: [0, "customPadding"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ul", [["class", "list-group mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalConfiguratorMetadatasComponent_2)), i1.ɵdid(4, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.step.tags; var currVal_2 = _co.trackSteptag; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_ModalConfiguratorMetadatasComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "text-danger form-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("WIZARD.CONFIG.MANDATORY_TAGS_ERROR")); _ck(_v, 1, 0, currVal_0); }); }
export function View_ModalConfiguratorMetadatasComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalConfiguratorMetadatasComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalConfiguratorMetadatasComponent_4)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.step.tags.length > 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.tagMandatoryHasNoValue; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ModalConfiguratorMetadatasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-configurator-metadatas", [], null, null, null, View_ModalConfiguratorMetadatasComponent_0, RenderType_ModalConfiguratorMetadatasComponent)), i1.ɵdid(1, 573440, null, 0, i10.ModalConfiguratorMetadatasComponent, [], null, null)], null, null); }
var ModalConfiguratorMetadatasComponentNgFactory = i1.ɵccf("app-modal-configurator-metadatas", i10.ModalConfiguratorMetadatasComponent, View_ModalConfiguratorMetadatasComponent_Host_0, { step: "step", sessionId: "sessionId" }, { controlValueTagMandatory: "controlValueTagMandatory", updateTag: "updateTag", errorLinkTag: "errorLinkTag" }, []);
export { ModalConfiguratorMetadatasComponentNgFactory as ModalConfiguratorMetadatasComponentNgFactory };
