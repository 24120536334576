<div
    [ngClass]="['col-12','alert', signature.validationStatus === 'TOTAL-PASSED' ? 'alert-success': signature.validationStatus === 'INDETERMINATE' ? 'alert-warning': 'alert-danger']">

    <div class="row">

        <div class="col-5 col-sm-12 mb-2" *ngFor="let ind of signature.etsiSubInds"> {{'VALIDATION.REPORT.MESSAGE.'+ ind
            |
            translate }}</div>
        <div class="col-7 col-sm-12">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <b> {{'VALIDATION.REPORT.LABEL.CERTIFICAT' | translate}}:
                        {{'VALIDATION.REPORT.STATUS.'+signature.certificatStatus | translate}}</b>
                </div>
                <div class="col-12 col-sm-6">
                    <b> {{'VALIDATION.REPORT.LABEL.QUALITY' | translate}}:
                        {{'VALIDATION.REPORT.QUALITY_LEVEL.'+signature.signatureQuality | translate}}</b>
                    ({{signature.signatureQuality}})
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6">
                    <b> {{'VALIDATION.REPORT.LABEL.SIGNATURE' | translate}}:
                        {{'VALIDATION.REPORT.STATUS.'+signature.status | translate}}</b>
                </div>
                <div class="col-12 col-sm-6">
                    <b> {{'VALIDATION.REPORT.LABEL.SIGNER' | translate}}: {{ signature.signer }}</b>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6">
                    <b> {{'VALIDATION.REPORT.LABEL.VALIDATION_TIME' | translate}}:
                        {{signature.validationSignTime}}</b>
                </div>
                <div class="col-12 col-sm-6">
                    <b> {{'VALIDATION.REPORT.LABEL.CLAIMED_SIGNING_TIME' | translate}}:
                        {{ signature.claimedSigningTime }}</b>
                </div>
            </div>

        </div>

    </div>
</div>
