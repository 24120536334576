import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, ViewChild} from '@angular/core';
import {NgControl} from '@angular/forms';
import {of, BehaviorSubject, Observable} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';

import {FormElement} from '../form.element';

@Component({
  selector: 'app-autocomplete', templateUrl: './autocomplete.component.html', styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent<T> extends FormElement<T> implements OnInit, OnChanges {
  @ViewChild('input', {static: false}) input: ElementRef;

  @Input() models: T[] = [];
  @Input() asyncModelsProvider: (filter: string) => Observable<T[]>;
  @Input() labelProvider: (v: T) => string;
  @Input() valueProvider: (v: T) => any;
  @Input() forMultiValue = false;
  @Input() truncate = false;
  @Input() readonly = true;
  @Input() noResultLabel: string;
  @Input() tabindex: number;
  @Input() focusOnClick = false;
  @Input() openUp = false;
  @Output() valueSet = new EventEmitter<T>();
  @Input() idPostfix = '';
  @Input() orderList = false;
  @Input() refreshData = 0;
  @Input() directoryAlias: string;
  filter$ = new BehaviorSubject<string>('');
  selectedValue: T;
  loading = false;
  isOpen = false;
  private filteredModelsBehaviorSubject$ = new BehaviorSubject<T[]>(null);

  constructor(@Self() @Optional() public ngControl: NgControl) {
    super(ngControl);
  }

  onClick() {
    if (this.focusOnClick) {
      setTimeout(() => this.input.nativeElement.focus());
    }
  }

  ngOnInit() {
    if (this.ngControl && this.ngControl.value) {
      this.selectedValue = this.ngControl.value;
    }
    if (this.asyncModelsProvider) {
      this.loadModels();
    } else {
      this.filter$.pipe(map(filter => this.models.filter(m => this.labelProvider(m).toLowerCase().includes(filter.trim().toLowerCase()))))
      .subscribe(v => this.filteredModelsBehaviorSubject$.next(v));
    }
    if (this.orderList) {
      this.getFilteredModels$().subscribe(model => {
        if (model && model[0]['name']) { model.sort((a, b) => a['name'].localeCompare(b['name'])); }
        if (model && model[0]['code']) { model.sort((a, b) => a['code'].localeCompare(b['code'])); }
      });
    }
  }
  ngOnChanges() {
    if (this.directoryAlias || this.refreshData > 0) {
      this.loadModels();
    }
  }

  private loadModels() {
    this.filter$.pipe(debounceTime(200), distinctUntilChanged(), map(filter => filter.trim()), tap(() => this.loading = true),
      switchMap((filter: string) => this.asyncModelsProvider(filter).pipe(catchError(e => of<T[]>([])))), tap(() => this.loading = false))
      .subscribe(v => this.filteredModelsBehaviorSubject$.next(v));
  }

  getFilteredModels = (): T[] => this.filteredModelsBehaviorSubject$.getValue();
  getFilteredModels$ = (): Observable<T[]> => this.filteredModelsBehaviorSubject$.asObservable();

  open() {
    if (this.ngControl) {
      this.filter$.next('');
      this.selectedValue = this.ngControl.value;
    }
    this.isOpen = !this.isOpen;
  }

  close() {
    this.isOpen = false;
  }

  onArrowUp(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    const actualSelectedModelIndex = this.getFilteredModels()
    .findIndex(m => this.valueProvider(m) === (this.selectedValue ? this.valueProvider(this.selectedValue) : null));
    if (actualSelectedModelIndex === -1) {
      this.selectedValue = this.getFilteredModels()[0];
    } else if (actualSelectedModelIndex !== 0) {
      this.selectedValue = this.getFilteredModels()[actualSelectedModelIndex - 1];
    }
  }

  onArrowDown(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    const actualSelectedModelIndex = this.getFilteredModels()
    .findIndex(m => this.valueProvider(m) === (this.selectedValue ? this.valueProvider(this.selectedValue) : null));
    if (actualSelectedModelIndex === -1) {
      this.selectedValue = this.getFilteredModels()[0];
    } else if (actualSelectedModelIndex !== this.getFilteredModels().length - 1) {
      this.selectedValue = this.getFilteredModels()[actualSelectedModelIndex + 1];
    }
  }

  onFocus(event) {
    if (!this.input.nativeElement.disabled) {
      if (event.srcElement.className.includes('form-control') && event) {
        this.open();
      } else {
        this.input.nativeElement.focus();
        this.input.nativeElement.click();
      }
      if (this.asyncModelsProvider) {
        this.input.nativeElement.value = null;
      }
    }
  }

  inputChangeCheck(value) {
    this.isOpen = !!value;
  }

  onBlur(value) {
      this.onTouched(value);
      this.selectedValue = value;
      this.input.nativeElement.value = (!this.forMultiValue && this.ngControl && this.ngControl.value) ? this.labelProvider(this.ngControl.value) : null;
      this.isOpen ? this.close() : null;
  }

  setValue(event: KeyboardEvent | MouseEvent, value: T) {
    event.preventDefault();
    event.stopPropagation();
    if (this.filteredModelsBehaviorSubject$.getValue()
    .find(v => this.valueProvider(v) === this.valueProvider(value))) {
      this.onChange(value);
      if (!this.forMultiValue) {this.input.nativeElement.blur(); }
    }

    this.valueSet.emit(value);
    this.close();
  }

  addValue(value: T) {
    this.filteredModelsBehaviorSubject$.next(this.filteredModelsBehaviorSubject$.getValue().concat(value));
  }

  removeValue(value: T) {
    this.filteredModelsBehaviorSubject$.next(this.filteredModelsBehaviorSubject$.getValue()
    .filter(v => this.valueProvider(v) !== this.valueProvider(value)));
  }

  sort() {
    this.filteredModelsBehaviorSubject$.next(this.filteredModelsBehaviorSubject$.getValue());
  }

  handleLabelClick(event: MouseEvent) {
    if (!this.focusOnClick) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
