/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-update-circle.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/pipes/translate.pipe";
import * as i3 from "../../../core/service/translate.service";
import * as i4 from "@angular/forms";
import * as i5 from "../form/auto-focus.directive";
import * as i6 from "./modal-update-circle.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../../../luxtrust-cosi-api/api/circleOfEndusers.service";
import * as i9 from "../../../services/services/alert-service";
var styles_ModalUpdateCircleComponent = [i0.styles];
var RenderType_ModalUpdateCircleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalUpdateCircleComponent, data: {} });
export { RenderType_ModalUpdateCircleComponent as RenderType_ModalUpdateCircleComponent };
export function View_ModalUpdateCircleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["header", 1]], null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 23, "div", [["class", "modal-body break-words"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 22, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 11, "div", [["class", "input-group mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "input-group-prepend"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "input-group-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(14, 0, null, null, 6, "input", [["autoFocus", ""], ["class", "form-control"], ["id", "inputName"], ["size", "10"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(17, 540672, null, 0, i4.FormControlDirective, [[8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR], [2, i4.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i4.NgControl, null, [i4.FormControlDirective]), i1.ɵdid(19, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(20, 4210688, null, 0, i5.AutoFocusDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 9, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "input-group-prepend"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "span", [["class", "input-group-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-at"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 5, "input", [["class", "form-control"], ["id", "inputEmail"], ["size", "10"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 26)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 26).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 26)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 26)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(28, 540672, null, 0, i4.FormControlDirective, [[8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR], [2, i4.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i4.NgControl, null, [i4.FormControlDirective]), i1.ɵdid(30, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(31, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["id", "updateCircleButtonId"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.update() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(33, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(35, 0, null, null, 2, "button", [["class", "btn btn-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(36, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.updateCircleNameControl; _ck(_v, 17, 0, currVal_9); var currVal_17 = _co.updateCircleMailControl; _ck(_v, 28, 0, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("MODAL_UPDATE_CIRCLE.TITLE")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("CIRCLE.NAME")); _ck(_v, 12, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 19).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 19).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 19).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 19).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 19).ngClassValid; var currVal_7 = i1.ɵnov(_v, 19).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = i1.ɵnov(_v, 30).ngClassUntouched; var currVal_11 = i1.ɵnov(_v, 30).ngClassTouched; var currVal_12 = i1.ɵnov(_v, 30).ngClassPristine; var currVal_13 = i1.ɵnov(_v, 30).ngClassDirty; var currVal_14 = i1.ɵnov(_v, 30).ngClassValid; var currVal_15 = i1.ɵnov(_v, 30).ngClassInvalid; var currVal_16 = i1.ɵnov(_v, 30).ngClassPending; _ck(_v, 25, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_18 = _co.updating; _ck(_v, 32, 0, currVal_18); var currVal_19 = i1.ɵunv(_v, 33, 0, i1.ɵnov(_v, 34).transform("MODAL_UPDATE_CIRCLE.UPDATE")); _ck(_v, 33, 0, currVal_19); var currVal_20 = i1.ɵunv(_v, 36, 0, i1.ɵnov(_v, 37).transform("MODAL_UPDATE_CIRCLE.CLOSE")); _ck(_v, 36, 0, currVal_20); }); }
export function View_ModalUpdateCircleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-update-circle", [], null, null, null, View_ModalUpdateCircleComponent_0, RenderType_ModalUpdateCircleComponent)), i1.ɵdid(1, 114688, null, 0, i6.ModalUpdateCircleComponent, [i7.NgbActiveModal, i8.CircleOfEndusersService, i9.AlertService, i4.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalUpdateCircleComponentNgFactory = i1.ɵccf("app-modal-update-circle", i6.ModalUpdateCircleComponent, View_ModalUpdateCircleComponent_Host_0, { circle: "circle" }, {}, []);
export { ModalUpdateCircleComponentNgFactory as ModalUpdateCircleComponentNgFactory };
