import { Component, Input, OnInit } from '@angular/core';
import { Report_type } from '../report-type.enum'
@Component({
  selector: 'app-report-tile-group',
  templateUrl: './report-tile-group.component.html',
  styleUrls: ['./report-tile-group.component.scss']
})
export class ReportTileGroupComponent implements OnInit {

  @Input()
  type: Report_type;

  reportType = Report_type;

  @Input()
  signatures: Signature[]

  constructor() { }

  ngOnInit() {
  }

}
