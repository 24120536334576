<app-tooltip [tooltipText]="'HELP.WIZARD_METADATA' | translate"></app-tooltip>

<app-wizard-step>
  <div class="fixed-md" content>
    <div class="text-center">

      <h4 class="mb-4">{{'WIZARD.TAGS.TITLE' | translate}}</h4>

      <div class="metadata-list-container mb-1">
        <div class="metadata-list">
          <div class="input-group">
            <div class="input">
              <select id="tag-type-enum-id" [(ngModel)]="tagType" class="form-control" (ngModelChange)="tagTypeChange()">
                <option *ngFor="let key of tagTypes" [value]="tagTypeEnum[key]">
                  {{'TAGS_TYPE.' + tagTypeEnum[key] | translate}}
                </option>
              </select>
            </div>
            <span class="input-group-text" >
              <i class="pl-2 fas fa-database" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="metadata-list-container" *ngIf="tagType === 'DOCUMENT'">
      <div class="metadata-list">
        <div class="input-group">
          <div class="input">
            <select [(ngModel)]="documentId" class="input form-control" (ngModelChange)="documentChanged()">
              <option *ngFor="let doc of documents" [value]="doc.id">
                {{doc.name}}
              </option>
            </select>
          </div>
          <span class="input-group-text" >
            <i class="pl-2 fas fa-file" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="metadata-list-container" *ngIf="tagType === 'USER'">
      <div class="metadata-list">
        <div class="input-group">
          <div class="input">
            <select [(ngModel)]="signerId" class="form-control" (ngModelChange)="signerChanged()">
              <option *ngFor="let sig of signers" [value]="sig.enduser.id">
                {{sig.enduser.firstName}} {{sig.enduser.lastName}}
              </option>
            </select>
          </div>
          <span class="input-group-text" >
            <i class="pl-2 fas fa-user" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </div>


    <app-items-table [borderBottom]="true"
                     [items]="tags" [noResult]="'WIZARD.TAGS.NO_RESULT' | translate"
                     [singleList]="true">
      <ng-container action>

        <form [formGroup]="tagsForm">
          <div class="form-container container_tags">
            <!-- Holder information -->
            <div class="row align-items-center">
              <div class="col-lg-12" *ngIf="tagsProvider">
                <app-autocomplete-custom [placeholder]="'WIZARD.TAGS.NAME' | translate"
                                         [datasProviders]="tagsProvider"
                                         [tabindex]="0"
                                         [newValue]="inputTagValue"
                                         class="col-12"
                                         icon="key"
                                         formControlName="tag">
                </app-autocomplete-custom>
              </div>
              <div class="col-lg-12">
                <app-metadata-selector
                  [tag]="selectedTag"
                  [smallSizeInputDay]="true"
                  [canHaveNullToggle]="true"
                  (onUpdate)="setTagValue($event)">
                </app-metadata-selector>
              </div>
            </div>
          </div>
        </form>
        <div class="row add-grid">
          <div class="row col col-10 ml-0">
            <div class="col col-4 form-group text-left pl-0">
              <div class="switcher switcher-right ml-0">
                <input [(ngModel)]="encrypt"
                       [checked]="encrypt"
                       [ngbTooltip]="'WIZARD.TAGS.ENCRYPT' | translate"
                       [disabled]="!selectedTag"
                       class="switcher-checkbox"
                       id="checkEnc"
                       type="checkbox">
                <div class="switcher-toggle" [ngClass]="{'disabled': !selectedTag}"></div>
                <label for="checkEnc">{{'WIZARD.TAGS.ENCRYPT' | translate}}</label>
              </div>
            </div>
            <div class="col col-4 form-group pl-0">
              <div class="switcher switcher-right ml-0 max-width">
                <input [(ngModel)]="readOnly"
                       [checked]="readOnly && tagValue !== '' && !!selectedTag && selectedTag.type !== TypeEnum.TOGGLE"
                       [ngbTooltip]="'WIZARD.TAGS.READONLY' | translate"
                       [disabled]="!selectedTag || tagValue === ''"
                       class="switcher-checkbox"
                       id="checkReadOnly"
                       type="checkbox">
                <div class="switcher-toggle" [ngClass]="{'disabled': (!selectedTag || tagValue === '')}"></div>
                <label for="checkReadOnly">{{'WIZARD.TAGS.READONLY' | translate}}</label>
              </div>
            </div>
            <div class="col col-4 form-group text-left pr-0" *ngIf="tagType === 'STEP'">
              <div class="switcher switcher-right ml-0">
                <input [(ngModel)]="configurator"
                       [checked]="configurator"
                       [ngbTooltip]="'WIZARD.TAGS.CONFIGURATOR' | translate"
                       [disabled]="!selectedTag"
                       class="switcher-checkbox"
                       id="checkConfig"
                       type="checkbox">
                <div class="switcher-toggle" [ngClass]="{'disabled': !selectedTag}"></div>
                <label for="checkConfig">{{'WIZARD.TAGS.CONFIGURATOR' | translate}}</label>
              </div>
            </div>
          </div>
          <div class="col-2 pr-0 form-group d-flex align-items-center">
            <button (click)="addTag()"
                    class="btn btn-primary btn-block margin-auto"
                    [disabled]="!selectedTag || isTagInError(selectedTag) || tagValueIsTooLong(selectedTag) || addingTag"
                    type="button"
                    id="add-tag-button">{{'WIZARD.TAGS.ADD' | translate}}</button>
          </div>
        </div>
      </ng-container>

      <ng-template #itemsCards let-stepTag>
        <div class="meta-card p-2 pl-3 mb-2">
          <label class="text-left mb-0 d-flex align-items-center" [ngbTooltip]="getStepTagToolTip(stepTag.alias)">
            {{getStepTagFormatted(stepTag.alias)}}
          </label>
          <label *ngIf="stepTag.encrypted" class="d-flex align-items-center mb-0 text-left">
            <span *ngIf="show[stepTag.value]"
                  [ngbTooltip]="getStepTagToolTip(stepTag.value)">{{stepTag.type === TypeEnum.DATETIME ? (stepTag.value | date: inputDatetimeFormat) : getStepTagFormatted(stepTag.value)}}</span>
            <span *ngIf="!show[stepTag.value]">{{"•".repeat(7)}}</span>
            <i
              (click)="(show[stepTag.value] === undefined) ? show[stepTag.value] = true : show[stepTag.value] = !show[stepTag.value]"
              [class.fa-eye-slash]="show[stepTag.value]"
              [class.fa-eye]="!show[stepTag.value]"
              [class.text-primary]="show[stepTag.value]"
              class="ml-2 far fa-eye cursor-pointer"></i>
          </label>
          <label *ngIf="!stepTag.encrypted" class="d-flex align-items-center mb-0 text-left"
                 [ngbTooltip]="getStepTagToolTip(stepTag.value)">
            {{stepTag.type === TypeEnum.DATETIME ? (stepTag.value | date: inputDatetimeFormat) : getStepTagFormatted(stepTag.value)}}
          </label>
          <div class="text-right d-flex justify-content-end align-items-center">
            <i *ngIf="stepTag.system"
               [ngbTooltip]="'WIZARD.TAGS.SYSTEM' | translate"
               class="fas fa-database mr-2 text-primary"></i>
            <i *ngIf="stepTag.configurator"
               [ngbTooltip]="'WIZARD.TAGS.CONFIGURATOR' | translate"
               class="fas fa-cog mr-2 text-primary"></i>
            <i *ngIf="stepTag.encrypted || stepTag.readOnly || stepTag.mandatory"
               [ngbTooltip]="createTooltip(stepTag)"
               class="fa fa-info-circle mr-2 text-primary">
            </i>
            <button (click)="deleteTag(stepTag)" [disabled]="deletingTag" class="btn btn-delete">
              <i aria-hidden="true" class="fa fa-trash"></i>
            </button>
          </div>
        </div>

        <ng-container>
          <div *ngIf="tags.length > 1 && showSort">
            <button (click)="up(stepTag)"
                    class="btn btn-sorted"
                    [class.btn-sorted]="sortedTag"
                    [disabled]="noUp(stepTag)"><i class="fa fa-arrow-circle-up"></i></button>
            <button (click)="down(stepTag)"
                    [class.btn-sorted]="sortedTag"
                    [disabled]="noDown(stepTag)"
                    class="btn btn-sorted"><i class="fa fa-arrow-circle-down"></i></button>
          </div>
        </ng-container>

      </ng-template>
    </app-items-table>

    <div *ngIf="tags.length > 1" class="pb-link">
      <a (click)="unsort(); showSort = false"
         *ngIf="showSort"
         class="reset-link"
         href="javascript:">{{'RESET_ORDER' | translate}}</a>
      <a (click)="showSort = true" *ngIf="!showSort" class="reset-link"
         href="javascript:">{{'DEFINE_ORDER' | translate }}</a>
    </div>
  </div>

  <div class="fixed-container" footer>
    <div class="fixed-md">
      <app-wizard-step-button [action]="stepActions.PREVIOUS"
                              [label]="'WIZARD.PREVIOUS_STEP' | translate"></app-wizard-step-button>
      <app-wizard-step-button [action]="stepActions.NEXT" [label]="'WIZARD.NEXT_STEP' | translate"
                              class="float-right"></app-wizard-step-button>
    </div>
  </div>
</app-wizard-step>
