<div class="form-group" tabindex="-1" (focusout)="onBlur($event.target?.value)">
  <label (click)="handleLabelClick($event)"
         *ngIf="label" [for]="id">{{label}}</label>
  <div class="input-group">
    <input #input
           (blur)="onBlur($event.target?.value)"
           (click)="onFocus($event)"
            onclick="event.stopPropagation()"
           (input)="filter$.next($event.target?.value);inputChangeCheck($event.target?.value)"
           (keydown.arrowdown)="onArrowDown($event)"
           (keydown.arrowup)="onArrowUp($event)"
           (keydown.enter)="setValue($event, selectedValue)"
           (keydown.esc)="close()"
           [attr.aria-describedby]="id+'Hint'"
           [attr.aria-label]="placeholder"
           [attr.tabindex]="tabindex"
           [disabled]="disabled"
           id="id{{idPostfix}}"
           [placeholder]="placeholder"
           [readonly]="readonly ? 'readonly' : null"
           [value]="ngControl?.value ? this.labelProvider(ngControl?.value) : null"
           autocomplete="off"
           class="form-control"
           type="text">
    <label for="id{{idPostfix}}" class="caret">
      <i aria-hidden="true" class="fas fa-caret-down"></i>
    </label>
    <div *ngIf="loading || icon"
         class="input-group-append">
      <span (mouseup)="onFocus($event)"
            class="input-group-text">
        <div *ngIf="loading"
             class="spinner-border spinner-border-sm text-light"
             role="status">
        </div>
        <i *ngIf="icon"
           [ngClass]="{'pl-2' : loading}"
           aria-hidden="true"
           class="fas fa-{{icon}}"></i>
      </span>
    </div>
    <div class="dropdown">
      <div [ngClass]="{'open' : isOpen, 'close': !isOpen, 'openUp': openUp}"
           class="dropdown-menu">
        <a (mousedown)="setValue($event, model)"
            placement="right" container="body"
            tooltipClass="custom-tooltip"
            [ngbTooltip]="truncate ? this.labelProvider(model) : '' "
           *ngFor="let model of getFilteredModels$() | async"
           [ngClass]="{'active' : (selectedValue ? this.valueProvider(selectedValue) : null) === this.valueProvider(model), 'truncate' : truncate}"
           class="dropdown-item" >{{this.labelProvider(model)}}</a>
        <a *ngIf="!loading && (getFilteredModels$() | async)?.length === 0"
           class="dropdown-item">
          {{noResultLabel}}
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="ngControl?.touched && ngControl?.invalid; else showHint"
       class="invalid-feedback">
    {{getFirstError()}}
  </div>
  <ng-template #showHint>
    <small *ngIf="hint"
           [id]="id+'Hint'"
           class="form-text text-muted">{{hint}}</small>
  </ng-template>
</div>
