<app-wizard-step>
  <h3 class="mb-4">{{'WIZARD.CONFIG.TITLE' | translate}} </h3>
  <div class="buttonsRole">
    <div (click)="changeRole(wizardStepAdminEnum.MANAGER)"
         [ngClass]=" {'active' : role === wizardStepAdminEnum.MANAGER}"
         class="buttonRole">
      {{'WIZARD.CONFIG.MANAGER' | translate}}
    </div>
    <div (click)="changeRole(wizardStepAdminEnum.SIGNER)"
         [ngClass]=" {'active' : role === wizardStepAdminEnum.SIGNER}"
         class="buttonRole">
      {{'WIZARD.CONFIG.SIGNER' | translate}}
    </div>
  </div>
  <form id="adminForm">
    <div class="parts">
      <div class="part">
        <h4 class="mb-4">{{'WIZARD.CONFIG.USERS' | translate}}</h4>
        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-users-replace-switch">{{'WIZARD.CONFIG.REPLACE' | translate}}</label>
          <input [(ngModel)]="config.manager.users.replace"
                 name="managerUserReplace"
                 class="switcher-checkbox"
                 id="input-manager-users-replace-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-users-replace-switch">{{'WIZARD.CONFIG.REPLACE' | translate}}</label>
          <input [(ngModel)]="config.signer.users.replace"
                 name="signerUserReplace"
                 class="switcher-checkbox"
                 id="input-signer-users-replace-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-users-add-switch">{{'WIZARD.CONFIG.ADD' | translate}}</label>
          <input [(ngModel)]="config.signer.users.add"
                 name="signerUserAdd"
                 class="switcher-checkbox"
                 id="input-signer-users-add-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-users-add-switch">{{'WIZARD.CONFIG.ADD' | translate}}</label>
          <input [(ngModel)]="config.manager.users.add"
                 name="managerUserAdd"
                 class="switcher-checkbox"
                 id="input-manager-users-add-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <!--<div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-users-clone-switch">{{'WIZARD.CONFIG.CLONE' | translate}}</label>
          <input [(ngModel)]="config.signer.users.clone"
                 name="signerUserClone"
                 class="switcher-checkbox"
                 id="input-signer-users-clone-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-users-clone-switch">{{'WIZARD.CONFIG.CLONE' | translate}}</label>
          <input [(ngModel)]="config.manager.users.clone"
                 name="managerUserClone"
                 class="switcher-checkbox"
                 id="input-manager-users-clone-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>-->
      </div>

      <div class="part">
        <h4 class="mb-4">{{'WIZARD.CONFIG.WATCHERS' | translate}}</h4>
        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-watcher-replace-switch">{{'WIZARD.CONFIG.REPLACE' | translate}}</label>
          <input [(ngModel)]="config.manager.watchers.replace"
                 name="managerWatcherReplace"
                 class="switcher-checkbox"
                 id="input-manager-watcher-replace-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-watcher-replace-switch">{{'WIZARD.CONFIG.REPLACE' | translate}}</label>
          <input [(ngModel)]="config.signer.watchers.replace"
                 name="signerWatcherReplace"
                 class="switcher-checkbox"
                 id="input-signer-watcher-replace-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-watcher-add-switch">{{'WIZARD.CONFIG.ADD' | translate}}</label>
          <input [(ngModel)]="config.signer.watchers.add"
                 name="signerWatcherAdd"
                 class="switcher-checkbox"
                 id="input-signer-watcher-add-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-watcher-add-switch">{{'WIZARD.CONFIG.ADD' | translate}}</label>
          <input [(ngModel)]="config.manager.watchers.add"
                 name="managerWatcherAdd"
                 class="switcher-checkbox"
                 id="input-manager-watcher-add-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <!--<div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-watcher-clone-switch">{{'WIZARD.CONFIG.CLONE' | translate}}</label>
          <input [(ngModel)]="config.signer.watchers.clone"
                 name="signerWatcherClone"
                 class="switcher-checkbox"
                 id="input-signer-watcher-clone-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-watcher-clone-switch">{{'WIZARD.CONFIG.CLONE' | translate}}</label>
          <input [(ngModel)]="config.manager.watchers.clone"
                 name="managerWatcherClone"
                 class="switcher-checkbox"
                 id="input-manager-watcher-clone-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>-->
      </div>

      <div class="part">
        <h4 class="mb-4"> {{'WIZARD.CONFIG.DOCUMENTS' | translate}} </h4>
        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-document-replace-switch">{{'WIZARD.CONFIG.REPLACE' | translate}}</label>
          <input [(ngModel)]="config.manager.documents.replace"
                 name="managerDocReplace"
                 class="switcher-checkbox"
                 id="input-manager-document-replace-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-document-replace-switch">{{'WIZARD.CONFIG.REPLACE' | translate}}</label>
          <input [(ngModel)]="config.signer.documents.replace"
                 name="signerDocReplace"
                 class="switcher-checkbox"
                 id="input-signer-document-replace-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-document-add-switch">{{'WIZARD.CONFIG.ADD' | translate}}</label>
          <input [(ngModel)]="config.manager.documents.add"
                 (ngModelChange)="handleDelete(config.manager.documents.add)"
                 name="managerDocAdd"
                 class="switcher-checkbox"
                 id="input-manager-document-add-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-document-add-switch">{{'WIZARD.CONFIG.ADD' | translate}}</label>
          <input [(ngModel)]="config.signer.documents.add"
                 (ngModelChange)="handleDelete(config.signer.documents.add)"
                 name="signerDocAdd"
                 class="switcher-checkbox"
                 id="input-signer-document-add-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-document-delete-switch">{{'WIZARD.CONFIG.DELETE' | translate}}</label>
          <input [(ngModel)]="config.signer.documents.delete"
                 (ngModelChange)="handleAdd(config.signer.documents.delete)"
                 name="signerDocDelete"
                 class="switcher-checkbox"
                 id="input-signer-document-delete-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-document-delete-switch">{{'WIZARD.CONFIG.DELETE' | translate}}</label>
          <input [(ngModel)]="config.manager.documents.delete"
                 (ngModelChange)="handleAdd(config.manager.documents.delete)"
                 name="managerDocDelete"
                 class="switcher-checkbox"
                 id="input-manager-document-delete-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-document-clone-switch">{{'WIZARD.CONFIG.CLONE' | translate}}</label>
          <input [(ngModel)]="config.manager.documents.clone"
                 (ngModelChange)="handleDelete(config.signer.documents.clone)"
                 name="managerDocClone"
                 class="switcher-checkbox"
                 id="input-manager-document-clone-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-document-clone-switch">{{'WIZARD.CONFIG.CLONE' | translate}}</label>
          <input [(ngModel)]="config.signer.documents.clone"
                 (ngModelChange)="handleDelete(config.signer.documents.clone)"
                 name="signerDocClone"
                 class="switcher-checkbox"
                 id="input-signer-document-clone-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-document-sprofile-switch">{{'WIZARD.CONFIG.SPROFILE' | translate}}</label>
          <input [(ngModel)]="config.manager.documents.sprofile"
                 name="managerDocSprofile"
                 class="switcher-checkbox"
                 id="input-manager-document-sprofile-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-document-sprofile-switch">{{'WIZARD.CONFIG.SPROFILE' | translate}}</label>
          <input [(ngModel)]="config.signer.documents.sprofile"
                 name="signerDocSprofile"
                 class="switcher-checkbox"
                 id="input-signer-document-sprofile-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>
      </div>

      <div class="part">
        <h4 class="mb-4"> {{'WIZARD.CONFIG.METADATA' | translate}}  </h4>
        <div [hidden]="role == wizardStepAdminEnum.SIGNER " class="switcher switcher-left">
          <label for="input-manager-metadata-update-switch">{{'WIZARD.CONFIG.UPDATE' | translate}}</label>
          <input [(ngModel)]="config.manager.metadata.update"
                 name="managerMetaUpdate"
                 class="switcher-checkbox"
                 id="input-manager-metadata-update-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>

        <div [hidden]="role == wizardStepAdminEnum.MANAGER " class="switcher switcher-left">
          <label for="input-signer-metadata-update-switch">{{'WIZARD.CONFIG.UPDATE' | translate}}</label>
          <input [(ngModel)]="config.signer.metadata.update"
                 name="signerMetaUpdate"
                 class="switcher-checkbox"
                 id="input-signer-metadata-update-switch"
                 type="checkbox">
          <div class="switcher-toggle"></div>
        </div>
      </div>
    </div>

    <div class="flex-space-between">
      <button (click)="handlePreviousStepButton()" [disabled]="submittingForm"
              class="btn btn-primary btn-min-width float-left"
              type="button">{{'WIZARD.PREVIOUS_STEP' | translate}} </button>
      <button (click)="deleteValueToggleAdminIntoSessionStorage()" [disabled]="submittingForm"
              [queryParamsHandling]="'preserve'" [routerLink]="['/session', session.id]"
              class="btn btn-primary btn-min-width float-right" id="validate-button"
              type="button">{{'WIZARD.VALIDATE' | translate}}  </button>
    </div>
  </form>


</app-wizard-step>

