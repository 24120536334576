/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateTenantPayload } from '../model/createTenantPayload';
import { EntitlementData } from '../model/entitlementData';
import { PrivilegeData } from '../model/privilegeData';
import { SynchResult } from '../model/synchResult';
import { SystemDocumentData } from '../model/systemDocumentData';
import { TenantConfigurationPayload } from '../model/tenantConfigurationPayload';
import { TenantData } from '../model/tenantData';
import { TenantDirectoryData } from '../model/tenantDirectoryData';
import { TenantDirectoryPayload } from '../model/tenantDirectoryPayload';
import { TenantEventData } from '../model/tenantEventData';
import { TenantEventPayload } from '../model/tenantEventPayload';
import { TenantRuleData } from '../model/tenantRuleData';
import { TenantRulePayload } from '../model/tenantRulePayload';
import { TenantSprofileData } from '../model/tenantSprofileData';
import { TenantSprofilePayload } from '../model/tenantSprofilePayload';
import { TenantStorageData } from '../model/tenantStorageData';
import { TenantStoragePayload } from '../model/tenantStoragePayload';
import { UpdateTenantPayload } from '../model/updateTenantPayload';
import { UpdateVisualIdentityPayload } from '../model/updateVisualIdentityPayload';
import { VisualIdentityData } from '../model/visualIdentityData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TenantService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a new tenant
     * 
     * @param payload Tenant to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTenant(payload?: CreateTenantPayload, observe?: 'body', reportProgress?: boolean): Observable<TenantData>;
    public createTenant(payload?: CreateTenantPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TenantData>>;
    public createTenant(payload?: CreateTenantPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TenantData>>;
    public createTenant(payload?: CreateTenantPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TenantData>(`${this.basePath}/api/tenants`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create tenant config
     * 
     * @param payload Configuration payload
     * @param tenantId Tenant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTenantConfig(payload: TenantConfigurationPayload, tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createTenantConfig(payload: TenantConfigurationPayload, tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createTenantConfig(payload: TenantConfigurationPayload, tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createTenantConfig(payload: TenantConfigurationPayload, tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling createTenantConfig.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling createTenantConfig.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/config`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a tenant-directory
     * 
     * @param tenantId Tenant
     * @param payload Tenant-sprofile characteristics
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTenantDirectory(tenantId: number, payload?: TenantDirectoryPayload, observe?: 'body', reportProgress?: boolean): Observable<TenantDirectoryData>;
    public createTenantDirectory(tenantId: number, payload?: TenantDirectoryPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TenantDirectoryData>>;
    public createTenantDirectory(tenantId: number, payload?: TenantDirectoryPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TenantDirectoryData>>;
    public createTenantDirectory(tenantId: number, payload?: TenantDirectoryPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling createTenantDirectory.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TenantDirectoryData>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/directories`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a tenant-sprofile
     * 
     * @param eventId Event
     * @param tenantId Tenant
     * @param payload Tenant-event characteristics
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTenantEvent(eventId: number, tenantId: number, payload?: TenantEventPayload, observe?: 'body', reportProgress?: boolean): Observable<TenantEventData>;
    public createTenantEvent(eventId: number, tenantId: number, payload?: TenantEventPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TenantEventData>>;
    public createTenantEvent(eventId: number, tenantId: number, payload?: TenantEventPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TenantEventData>>;
    public createTenantEvent(eventId: number, tenantId: number, payload?: TenantEventPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (eventId === null || eventId === undefined) {
            throw new Error('Required parameter eventId was null or undefined when calling createTenantEvent.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling createTenantEvent.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TenantEventData>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/events/${encodeURIComponent(String(eventId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a tenant-rule
     * 
     * @param tenantId Tenant
     * @param payload Tenant-rule characteristics
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTenantRule(tenantId: number, payload?: TenantRulePayload, observe?: 'body', reportProgress?: boolean): Observable<TenantRuleData>;
    public createTenantRule(tenantId: number, payload?: TenantRulePayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TenantRuleData>>;
    public createTenantRule(tenantId: number, payload?: TenantRulePayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TenantRuleData>>;
    public createTenantRule(tenantId: number, payload?: TenantRulePayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling createTenantRule.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TenantRuleData>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/rules`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a tenant-sprofile
     * 
     * @param tenantId Tenant
     * @param payload Tenant-sprofile characteristics
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTenantSprofile(tenantId: number, payload?: TenantSprofilePayload, observe?: 'body', reportProgress?: boolean): Observable<TenantSprofileData>;
    public createTenantSprofile(tenantId: number, payload?: TenantSprofilePayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TenantSprofileData>>;
    public createTenantSprofile(tenantId: number, payload?: TenantSprofilePayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TenantSprofileData>>;
    public createTenantSprofile(tenantId: number, payload?: TenantSprofilePayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling createTenantSprofile.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TenantSprofileData>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/sprofiles`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a tenant-directory
     * 
     * @param tenantId Tenant
     * @param payload Tenant-storage characteristics
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTenantStorage(tenantId: number, payload?: TenantStoragePayload, observe?: 'body', reportProgress?: boolean): Observable<TenantStorageData>;
    public createTenantStorage(tenantId: number, payload?: TenantStoragePayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TenantStorageData>>;
    public createTenantStorage(tenantId: number, payload?: TenantStoragePayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TenantStorageData>>;
    public createTenantStorage(tenantId: number, payload?: TenantStoragePayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling createTenantStorage.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TenantStorageData>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/storage`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant configuration
     * 
     * @param key Configuration identifier (key)
     * @param tenantId Tenant identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTenantConfig(key: string, tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTenantConfig(key: string, tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTenantConfig(key: string, tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTenantConfig(key: string, tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling deleteTenantConfig.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling deleteTenantConfig.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/config/${encodeURIComponent(String(key))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant directory
     * 
     * @param alias Directory alias
     * @param tenantId Tenant identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTenantDirectory(alias: string, tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTenantDirectory(alias: string, tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTenantDirectory(alias: string, tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTenantDirectory(alias: string, tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling deleteTenantDirectory.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling deleteTenantDirectory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/directories/${encodeURIComponent(String(alias))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant rule
     * 
     * @param eventId Event id
     * @param tenantId Tenant identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTenantEvent(eventId: number, tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTenantEvent(eventId: number, tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTenantEvent(eventId: number, tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTenantEvent(eventId: number, tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (eventId === null || eventId === undefined) {
            throw new Error('Required parameter eventId was null or undefined when calling deleteTenantEvent.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling deleteTenantEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/events/${encodeURIComponent(String(eventId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant rule
     * 
     * @param alias Rule alias
     * @param tenantId Tenant identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTenantRule(alias: string, tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTenantRule(alias: string, tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTenantRule(alias: string, tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTenantRule(alias: string, tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling deleteTenantRule.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling deleteTenantRule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/rules/${encodeURIComponent(String(alias))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant sprofile
     * 
     * @param sprofile Sprofile
     * @param tenantId Tenant identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTenantSprofile(sprofile: string, tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTenantSprofile(sprofile: string, tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTenantSprofile(sprofile: string, tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTenantSprofile(sprofile: string, tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sprofile === null || sprofile === undefined) {
            throw new Error('Required parameter sprofile was null or undefined when calling deleteTenantSprofile.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling deleteTenantSprofile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/sprofiles/${encodeURIComponent(String(sprofile))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant storage
     * 
     * @param alias Directory alias
     * @param tenantId Tenant identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTenantStorage(alias: string, tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTenantStorage(alias: string, tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTenantStorage(alias: string, tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTenantStorage(alias: string, tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling deleteTenantStorage.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling deleteTenantStorage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/storage/${encodeURIComponent(String(alias))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete tenant visual identity
     * 
     * @param tenantId Entitlement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTenantVi(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTenantVi(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTenantVi(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTenantVi(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling deleteTenantVi.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/visual-identity`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * export tenant directory
     * 
     * @param alias Directory alias
     * @param tenantId Tenant identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportTenantDirectory(alias: string, tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportTenantDirectory(alias: string, tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportTenantDirectory(alias: string, tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportTenantDirectory(alias: string, tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling exportTenantDirectory.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling exportTenantDirectory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/csv'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/directories/${encodeURIComponent(String(alias))}/export`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDirectoryList
     * 
     * @param tenantId tenantId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDirectoryListUsingGET(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TenantDirectoryData>>;
    public getDirectoryListUsingGET(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TenantDirectoryData>>>;
    public getDirectoryListUsingGET(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TenantDirectoryData>>>;
    public getDirectoryListUsingGET(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getDirectoryListUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TenantDirectoryData>>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/directories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant entitlements
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrivileges1(observe?: 'body', reportProgress?: boolean): Observable<Array<PrivilegeData>>;
    public getPrivileges1(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PrivilegeData>>>;
    public getPrivileges1(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PrivilegeData>>>;
    public getPrivileges1(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PrivilegeData>>(`${this.basePath}/api/tenants/privileges`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant config
     * 
     * @param tenantId Tenant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSpecificTenantConfig(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public getSpecificTenantConfig(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public getSpecificTenantConfig(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public getSpecificTenantConfig(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getSpecificTenantConfig.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: string; }>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/config`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getStorageList
     * 
     * @param tenantId tenantId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStorageListUsingGET(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TenantStorageData>>;
    public getStorageListUsingGET(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TenantStorageData>>>;
    public getStorageListUsingGET(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TenantStorageData>>>;
    public getStorageListUsingGET(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getStorageListUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TenantStorageData>>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/storage`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of system documents
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSystemDocumentList(observe?: 'body', reportProgress?: boolean): Observable<Array<SystemDocumentData>>;
    public getSystemDocumentList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SystemDocumentData>>>;
    public getSystemDocumentList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SystemDocumentData>>>;
    public getSystemDocumentList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SystemDocumentData>>(`${this.basePath}/api/tenants/system-document`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant
     * 
     * @param alias Tenant alias
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenant(alias: string, observe?: 'body', reportProgress?: boolean): Observable<TenantData>;
    public getTenant(alias: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TenantData>>;
    public getTenant(alias: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TenantData>>;
    public getTenant(alias: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling getTenant.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TenantData>(`${this.basePath}/api/tenants/alias/${encodeURIComponent(String(alias))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant
     * 
     * @param tenantId Tenant id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenant1(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<TenantData>;
    public getTenant1(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TenantData>>;
    public getTenant1(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TenantData>>;
    public getTenant1(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getTenant1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TenantData>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant config
     * 
     * @param filter Tenant name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenantConfig(filter: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public getTenantConfig(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public getTenantConfig(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public getTenantConfig(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling getTenantConfig.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: string; }>(`${this.basePath}/api/tenants/search/config`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant directory list
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenantDirectories(observe?: 'body', reportProgress?: boolean): Observable<Array<TenantDirectoryData>>;
    public getTenantDirectories(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TenantDirectoryData>>>;
    public getTenantDirectories(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TenantDirectoryData>>>;
    public getTenantDirectories(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TenantDirectoryData>>(`${this.basePath}/api/tenants/current/directories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant entitlements
     * 
     * @param tenantId Tenant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenantEntitlements(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<EntitlementData>>;
    public getTenantEntitlements(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EntitlementData>>>;
    public getTenantEntitlements(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EntitlementData>>>;
    public getTenantEntitlements(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getTenantEntitlements.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EntitlementData>>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/entitlements`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant events
     * 
     * @param tenantId Tenant id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenantEvents(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TenantEventData>>;
    public getTenantEvents(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TenantEventData>>>;
    public getTenantEvents(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TenantEventData>>>;
    public getTenantEvents(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getTenantEvents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TenantEventData>>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/events`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant rules
     * 
     * @param tenantId Tenant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenantRules(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TenantRuleData>>;
    public getTenantRules(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TenantRuleData>>>;
    public getTenantRules(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TenantRuleData>>>;
    public getTenantRules(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getTenantRules.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TenantRuleData>>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/rules`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant sprofile list
     * 
     * @param tenantId Tenant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenantSprofiles(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TenantSprofileData>>;
    public getTenantSprofiles(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TenantSprofileData>>>;
    public getTenantSprofiles(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TenantSprofileData>>>;
    public getTenantSprofiles(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getTenantSprofiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TenantSprofileData>>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/sprofiles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant storage list
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenantStorageList(observe?: 'body', reportProgress?: boolean): Observable<Array<TenantStorageData>>;
    public getTenantStorageList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TenantStorageData>>>;
    public getTenantStorageList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TenantStorageData>>>;
    public getTenantStorageList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TenantStorageData>>(`${this.basePath}/api/tenants/current/storage`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant visual identity
     * 
     * @param tenantId Entitlement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenantVi(tenantId: number, observe?: 'body', reportProgress?: boolean): Observable<VisualIdentityData>;
    public getTenantVi(tenantId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisualIdentityData>>;
    public getTenantVi(tenantId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisualIdentityData>>;
    public getTenantVi(tenantId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getTenantVi.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<VisualIdentityData>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/visual-identity`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update tenant
     * 
     * @param tenantId Tenant
     * @param payload Step data to update (updates only defined values)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateTenant(tenantId: number, payload?: UpdateTenantPayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public partialUpdateTenant(tenantId: number, payload?: UpdateTenantPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public partialUpdateTenant(tenantId: number, payload?: UpdateTenantPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public partialUpdateTenant(tenantId: number, payload?: UpdateTenantPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling partialUpdateTenant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update tenant-directory
     * 
     * @param alias Directory alias
     * @param tenantId Tenant
     * @param payload payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateTenantDirectory(alias: string, tenantId: number, payload?: TenantDirectoryPayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public partialUpdateTenantDirectory(alias: string, tenantId: number, payload?: TenantDirectoryPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public partialUpdateTenantDirectory(alias: string, tenantId: number, payload?: TenantDirectoryPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public partialUpdateTenantDirectory(alias: string, tenantId: number, payload?: TenantDirectoryPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling partialUpdateTenantDirectory.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling partialUpdateTenantDirectory.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/directories/${encodeURIComponent(String(alias))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update tenant-event
     * 
     * @param eventId Event id
     * @param tenantId Tenant
     * @param payload payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateTenantEvent(eventId: number, tenantId: number, payload?: TenantEventPayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public partialUpdateTenantEvent(eventId: number, tenantId: number, payload?: TenantEventPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public partialUpdateTenantEvent(eventId: number, tenantId: number, payload?: TenantEventPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public partialUpdateTenantEvent(eventId: number, tenantId: number, payload?: TenantEventPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (eventId === null || eventId === undefined) {
            throw new Error('Required parameter eventId was null or undefined when calling partialUpdateTenantEvent.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling partialUpdateTenantEvent.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/events/${encodeURIComponent(String(eventId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update tenant-rule
     * 
     * @param alias Rule alias
     * @param tenantId Tenant
     * @param payload payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateTenantRule(alias: string, tenantId: number, payload?: TenantRulePayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public partialUpdateTenantRule(alias: string, tenantId: number, payload?: TenantRulePayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public partialUpdateTenantRule(alias: string, tenantId: number, payload?: TenantRulePayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public partialUpdateTenantRule(alias: string, tenantId: number, payload?: TenantRulePayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling partialUpdateTenantRule.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling partialUpdateTenantRule.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/rules/${encodeURIComponent(String(alias))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update tenant-sprofile
     * 
     * @param sprofile Sprofile
     * @param tenantId Tenant
     * @param payload payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateTenantSprofile(sprofile: string, tenantId: number, payload?: TenantSprofilePayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public partialUpdateTenantSprofile(sprofile: string, tenantId: number, payload?: TenantSprofilePayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public partialUpdateTenantSprofile(sprofile: string, tenantId: number, payload?: TenantSprofilePayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public partialUpdateTenantSprofile(sprofile: string, tenantId: number, payload?: TenantSprofilePayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sprofile === null || sprofile === undefined) {
            throw new Error('Required parameter sprofile was null or undefined when calling partialUpdateTenantSprofile.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling partialUpdateTenantSprofile.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/sprofiles/${encodeURIComponent(String(sprofile))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update tenant-storage
     * 
     * @param alias Storage alias
     * @param tenantId Tenant
     * @param payload payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateTenantStorage(alias: string, tenantId: number, payload?: TenantStoragePayload, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public partialUpdateTenantStorage(alias: string, tenantId: number, payload?: TenantStoragePayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public partialUpdateTenantStorage(alias: string, tenantId: number, payload?: TenantStoragePayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public partialUpdateTenantStorage(alias: string, tenantId: number, payload?: TenantStoragePayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling partialUpdateTenantStorage.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling partialUpdateTenantStorage.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/storage/${encodeURIComponent(String(alias))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search tenant visual identity
     * 
     * @param filter Entitlement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTenantVi(filter: string, observe?: 'body', reportProgress?: boolean): Observable<VisualIdentityData>;
    public searchTenantVi(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisualIdentityData>>;
    public searchTenantVi(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisualIdentityData>>;
    public searchTenantVi(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling searchTenantVi.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<VisualIdentityData>(`${this.basePath}/api/tenants/search/visual-identity`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync the tenant directory (requester has to have access)
     * 
     * @param directoryAlias Directory to sync
     * @param file CSV file to sync
     * @param mappingProfile Mapping profile
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncDirectory(directoryAlias?: string, file?: Blob, mappingProfile?: string, observe?: 'body', reportProgress?: boolean): Observable<SynchResult>;
    public syncDirectory(directoryAlias?: string, file?: Blob, mappingProfile?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SynchResult>>;
    public syncDirectory(directoryAlias?: string, file?: Blob, mappingProfile?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SynchResult>>;
    public syncDirectory(directoryAlias?: string, file?: Blob, mappingProfile?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (directoryAlias !== undefined && directoryAlias !== null) {
            queryParameters = queryParameters.set('directoryAlias', <any>directoryAlias);
        }
        if (mappingProfile !== undefined && mappingProfile !== null) {
            queryParameters = queryParameters.set('mappingProfile', <any>mappingProfile);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<SynchResult>(`${this.basePath}/api/tenants/synch-directory`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update visual identity of tenant
     * 
     * @param payload Specific variables
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTenant(payload?: UpdateVisualIdentityPayload, observe?: 'body', reportProgress?: boolean): Observable<VisualIdentityData>;
    public updateTenant(payload?: UpdateVisualIdentityPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisualIdentityData>>;
    public updateTenant(payload?: UpdateVisualIdentityPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisualIdentityData>>;
    public updateTenant(payload?: UpdateVisualIdentityPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VisualIdentityData>(`${this.basePath}/api/tenants/visual-identity`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
