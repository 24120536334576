/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../core/pipes/translate.pipe";
import * as i4 from "../../../core/service/translate.service";
import * as i5 from "./document-item.component";
var styles_DocumentItemComponent = [i0.styles];
var RenderType_DocumentItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentItemComponent, data: {} });
export { RenderType_DocumentItemComponent as RenderType_DocumentItemComponent };
function View_DocumentItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.signer.firstName + " ") + _v.parent.context.$implicit.signer.lastName); _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.signer.lastName; _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "p", [["class", "my-2 small-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentItemComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentItemComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(6, null, [" \u00A0", " ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService])], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.signer.circle; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.signer.circle; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("NEW_SESSION.DOCUMENTS.SIGNER_DETAILS")); var currVal_3 = _v.context.$implicit.pages; _ck(_v, 6, 0, currVal_2, currVal_3); }); }
function View_DocumentItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "mt-4 display-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "progress margin-right-10 width-80"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "progress-bar progress-bar-info progress-bar-striped"], ["role", "progressbar"]], [[1, "aria-valuenow", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { width: 0 }), (_l()(), i1.ɵted(6, null, [" ", "% "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "btn flex-grow-1 btn-warning mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelReupload.emit(_co.documentUploadingId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 5, 0, (_co.progress[_co.documentUploadingId] + "%")); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.progress[_co.documentUploadingId]; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.progress[_co.documentUploadingId]; _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("WIZARD.DOCUMENTS.CANCEL")); _ck(_v, 8, 0, currVal_3); }); }
export function View_DocumentItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "document-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "d-flex justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "flex-grow-1 width-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "big-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "flex-grow-0 mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "btn-add icon-button"], ["id", "add-acroform"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addAcroform.emit(_co.document) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "btn-disable": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-file-signature"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "flex-grow-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn-delete icon-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete.emit(_co.document) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentItemComponent_1)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentItemComponent_4)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "btn-add icon-button"; var currVal_3 = _ck(_v, 9, 0, !_co.canAddAcroform); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = _co.signersDetails; _ck(_v, 15, 0, currVal_4); var currVal_5 = (_co.progress[_co.documentUploadingId] > 0); _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.document.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.canAddAcroform; _ck(_v, 6, 0, currVal_1); }); }
export function View_DocumentItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-document-item", [], null, null, null, View_DocumentItemComponent_0, RenderType_DocumentItemComponent)), i1.ɵdid(1, 49152, null, 0, i5.DocumentItemComponent, [], null, null)], null, null); }
var DocumentItemComponentNgFactory = i1.ɵccf("app-document-item", i5.DocumentItemComponent, View_DocumentItemComponent_Host_0, { document: "document", canAddAcroform: "canAddAcroform", signersDetails: "signersDetails", progress: "progress", documentUploadingId: "documentUploadingId" }, { cancelReupload: "cancelReupload", addAcroform: "addAcroform", delete: "delete" }, []);
export { DocumentItemComponentNgFactory as DocumentItemComponentNgFactory };
