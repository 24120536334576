import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import {
  EnduserCircleService,
  SessionEnduserService,
  StepEnduserService
} from 'luxtrust-cosi-api';
import { EnduserData } from 'luxtrust-cosi-api/model/enduserData';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from "../../../services/services/app.service";

@Component({
  selector: 'app-enduser-item', templateUrl: './enduser-item.component.html', styleUrls: ['./enduser-item.component.scss']
})
export class EnduserItemComponent implements OnInit, AfterViewInit {

  @Input() enduser: EnduserData;
  @Input() disabled: boolean;
  @Input() disabledSelectCircle = true;
  @Input() intoWizardGeneral = false;
  @Input() idReplace: number;
  @Input() replaceMode = false;
  @Input() canReplace = false;
  @Input() canAdd = true;
  @Input() replaceAloneSigner = false;
  @Input() isConfigurator = false;
  @Input() sessionId: number;
  @Input() disableSelectOptionForWatcher: boolean;
  @Input() stepId: number;
  @Output() delete = new EventEmitter<EnduserData>();
  @Output() onUpdateExpected = new EventEmitter<{}>();
  @Output() onReplaceEnduserId = new EventEmitter();
  displayIconUser = false;

  session;
  step;
  expected = 1;
  tabTest = [];


  constructor(private route: ActivatedRoute,
              public appService: AppService,
              private alertService: AlertService,
              private circleEnduserService: EnduserCircleService,
              private sessionEnduserService: SessionEnduserService,
              private stepEnduserService: StepEnduserService) { }

  ngOnInit() {
    if(this.enduser.userId != null && this.enduser.userId.startsWith('PLACEHOLDER[') && this.enduser.userId.endsWith(']')){
      this.displayIconUser= true;
    }
    if (this.enduser.circle) {
      if(this.route.parent){
        this.route.parent.data.pipe().subscribe((data: Data) => {
          this.session = data['session'];
          this.step = data['step'];
          if (this.step && this.session) {
            if (this.intoWizardGeneral) {
              this.sessionEnduserService.getSessionManager(this.enduser.id, this.session.id).subscribe((res) => {
                this.expected = res.enduser.id;
              }, (error: ApiError) => this.alertService.errorApi(error));
            } else {
            this.getExpectedUser(this.session.id, this.step.id);
            }
          }
        });
      }
      this.circleEnduserService.getCircleEnduserList(this.enduser.id).toPromise().then((endusers) => {
        for (let i = 1; i <= endusers.length; i++) {
          this.tabTest[i] = i;
        }
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
      if(this.isConfigurator){
        this.getExpectedUser(this.sessionId, this.stepId);
      }
    }
  }

  getExpectedUser(sessionId, stepId) {
    this.stepEnduserService.getStepEndUser(this.enduser.id, sessionId, stepId).subscribe((res) => {
      this.expected = res.expected;
    }, (error: ApiError) => this.alertService.errorApi(error));
  }
  isConfigAndCanJustReplace() {
    return this.canReplace && !this.canAdd;
  }

  ngAfterViewInit() {
    if (this.replaceAloneSigner) {
      setTimeout (() => {
        this.onReplaceEnduserId.emit(this.enduser.id);
     });
    }
  }
  patchCircleName(name: string) {
    return name.replace(/{[(\w+\-)]+}/g, '');
  }

  deleteEnduser() {
    this.delete.emit(this.enduser);
  }

  replaceEnduser() {
    this.onReplaceEnduserId.emit(this.enduser.id);
  }

  updateNbOfSigners() {
    this.onUpdateExpected.emit({ user: this.enduser, nb: this.expected });
  }
}
