import {EventEmitter, Input, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StepData} from 'luxtrust-cosi-api';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {CreateEnduserPayload} from 'luxtrust-cosi-api/model/createEnduserPayload';
import {CreateStepEnduserPayload} from 'luxtrust-cosi-api/model/createStepEnduserPayload';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';
import {Status} from '../../../shared/components/input-loader/input-loader.component';
import {ModalNewSignerComponent} from '../../../shared/components/modal-new-signer/modal-new-signer.component';

export abstract class StepEnduserComponent {

  selectedManager: EnduserData[] = [];
  selectedUsers: EnduserData[] = [];
  selectedWatcher: EnduserData[] = [];
  availableUsers: EnduserData[];
  loaderStatusUsers: Status;
  @Input() stepEndusers: StepEnduserData[];
  @Input() public sessionId: number;
  @Input() step: StepData;
  sortedUsers = false;
  @Output() orderAfterReplace: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortMode: EventEmitter<boolean> = new EventEmitter<boolean>();

  replacingUser = false;

  constructor(protected modalService: NgbModal,
              protected appService: AppService,
              protected stepEnduserService: StepEnduserService,
              protected alertService: AlertService,
              protected enduserService: EnduserService) {
  }

  protected replaceUsers(oldEndUserId, newEndUserId, usersList, cb?: Function) {
    this.replacingUser = true;
    this.stepEnduserService.replaceStepEnduser(oldEndUserId, newEndUserId, this.sessionId, this.step.id).subscribe(() => {
      this.enduserService.getEnduser(newEndUserId).subscribe((newEnduser) => {
        const indexToReplace = usersList.findIndex(user => user.id === oldEndUserId);
        usersList.splice(indexToReplace, 1, newEnduser);
        this.replacingUser = false;
        this.orderAfterReplace.emit(usersList);
        this.loaderStatusUsers = Status.SUCCESS;
        if (cb) {
          cb();
        }
      }, (error: ApiError) => {
        this.replacingUser = false;
        this.alertService.errorApi(error);
        this.loaderStatusUsers = Status.ERROR;
        if (cb) {
          cb();
        }
      });
    }, (error: ApiError) => {
      this.replacingUser = false;
      this.alertService.errorApi(error);
      if (cb) {
        cb();
      }
    });
  }

  public onReplaceUsers(oldEndUserId, newEnduser: EnduserData, usersList, cb?: Function) {
    const enduserPayload: CreateEnduserPayload = this.createPayloadForSigner(newEnduser).enduserPayload;
    // Cas d'un invité
    if (!newEnduser.id) {
      this.enduserService.createEnduser(enduserPayload).subscribe((result) => {
        this.replaceUsers(oldEndUserId, result.id, usersList, cb);
      }, (error: ApiError) => {
        this.alertService.errorApi(error);
      });
    } else {
      this.replaceUsers(oldEndUserId, newEnduser.id, usersList, cb);
    }
  }

  protected createPayloadForSigner(user: EnduserData): {stepEnduserPayload: CreateStepEnduserPayload, enduserPayload: CreateEnduserPayload} {
    const alreadyManager = !!this.selectedManager.find(enduser => enduser.id === user.id);
    let stepEnduserPayload: CreateStepEnduserPayload;
    let createEndUserpayload: CreateEnduserPayload;
    if (user.id) {
      stepEnduserPayload = {
        enduserId: user.id, signer: true, manager: user.id === this.appService.getCurrentUserId() || alreadyManager
      };
    } else {
      createEndUserpayload = {
        userId: user.email,
        email: user.email,
        lastName: user.lastName,
        firstName: user.firstName,
        phone: user.phone,
        directoryAlias: user.directoryAlias,
        organisationId: this.appService.getUser().organisation.organisationId
      };
      stepEnduserPayload = {
        createEnduserPayload: createEndUserpayload, signer: true
      };
    }
    return {stepEnduserPayload: stepEnduserPayload, enduserPayload: createEndUserpayload};
  }

  getSelectedWatcher() {
    this.selectedWatcher = this.stepEndusers
      .filter((stepEnduser: StepEnduserData) => !stepEnduser.manager && !stepEnduser.signer)
      .map((stepEnduserWatcher: StepEnduserData) => stepEnduserWatcher.enduser);
  }

  getSelectedManager() {
    this.selectedManager = this.stepEndusers
      .filter((stepEnduser: StepEnduserData) => stepEnduser.manager && stepEnduser.enduser.id !== this.appService.getCurrentUserId())
      .map((user: StepEnduserData) => user.enduser);
  }

  getSelectedUsers() {
    this.selectedUsers = this.stepEndusers
      .filter((stepEnduser: StepEnduserData) => stepEnduser.signer)
      .map((user: StepEnduserData) => user.enduser);
    for (let i = 0; i < this.stepEndusers.length; i++) {
      if (this.stepEndusers[i].orderIndex && this.stepEndusers[i].orderIndex > 0) {
        this.sortedUsers = true;
        this.sortMode.emit(this.sortedUsers);
        break;
      }
    }
  }

  openModal(replace?, oldEnduserId?: number ) {
    const modalRef = this.modalService.open(ModalNewSignerComponent, {backdrop:false});
    (<ModalNewSignerComponent>modalRef.componentInstance).step = this.step;
    modalRef.componentInstance.newSignerModalResult.subscribe(result => {
      if (replace) {
        this.onReplaceUsers(oldEnduserId, result, this.selectedUsers);
      } else {
        this.addUsers(result);
      }
    });
  }

  protected abstract addUsers(user: EnduserData) ;

  protected filterAvailableUsers(endusers: EnduserData[]) {
    return endusers.filter((enduser: EnduserData) => {
      return !this.selectedWatcher.some((addressee: EnduserData) => enduser.id === addressee.id) && !this.selectedUsers.some(
        (user: EnduserData) => enduser.id === user.id);
    });
  }

  onSearchUsers(searchEndusers) {
    this.getAvailableUsers(searchEndusers);
  }

  getAvailableUsers(searchEnduser) {
    if (searchEnduser && searchEnduser.trim()) {
      this.enduserService.searchEnduser(
        undefined,
        undefined,
        20,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.step.id,
        undefined,
        searchEnduser).subscribe(endusers => {
        this.availableUsers = this.filterAvailableUsers(endusers);
      }, (error: ApiError) => this.alertService.errorApi(error));
    } else {
      this.availableUsers = [];
    }
  }

}
