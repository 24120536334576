import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AppService} from '../../../services/services/app.service';

@Component({
  selector: 'app-modal-import-user',
  templateUrl: 'modal-import-user.component.html',
  styleUrls: ['modal-import-user.component.scss']
})
export class ModalImportUserComponent {
  @Input() directories: string[] = [];
  file: File;
  selectedDirectory: string;

  constructor(public activeModal: NgbActiveModal, private appService: AppService) {
  }

  valueProvider = (v) => v;

  get allowedMimeTypes() {
    if (this.appService.config) {
      const defaultConfig = this.appService.config;
      if (defaultConfig) {
        return defaultConfig.allowedMime.join(',');
      }
    }
    return '*';
  }

  saveFile(files: FileList) {
    this.file = files.item(0);
  }
}
