/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./step.metadata.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../metadata-selector/metadata-selector.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "ngx-mask";
import * as i5 from "@angular/common";
import * as i6 from "../metadata-selector/metadata-selector.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../core/service/translate.service";
import * as i9 from "../card/card.component.ngfactory";
import * as i10 from "../card/card.component";
import * as i11 from "../../../core/pipes/translate.pipe";
import * as i12 from "./step.metadata.component";
import * as i13 from "../../../../../luxtrust-cosi-api/api/stepTag.service";
var styles_StepMetadataComponent = [i0.styles];
var RenderType_StepMetadataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StepMetadataComponent, data: {} });
export { RenderType_StepMetadataComponent as RenderType_StepMetadataComponent };
function View_StepMetadataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "app-metadata-selector", [], null, [[null, "tagInError"], [null, "onUpdate"], [null, "input"], [null, "blur"], [null, "click"], [null, "keydown"], [null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onInput($event) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onBlur() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5).onFocus($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5).onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (i1.ɵnov(_v, 5).onModelChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("tagInError" === en)) {
        var pd_5 = ((_co.tagLinkHasError = true) !== false);
        ad = (pd_5 && ad);
    } if (("onUpdate" === en)) {
        var pd_6 = (_co.updateStepTagMeta($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, i2.View_MetadataSelectorComponent_0, i2.RenderType_MetadataSelectorComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MaskDirective]), i1.ɵprd(5120, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.MaskDirective]), i1.ɵprd(512, null, i4.MaskService, i4.MaskService, [i5.DOCUMENT, i4.config, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 540672, null, 0, i4.MaskDirective, [i5.DOCUMENT, i4.MaskService, i4.config], { maskExpression: [0, "maskExpression"] }, null), i1.ɵdid(6, 638976, [["metadataSelector", 4]], 0, i6.MetadataSelectorComponent, [i7.NgbModal, i8.TranslateService], { tag: [0, "tag"], showMode: [1, "showMode"], canHaveNullToggle: [2, "canHaveNullToggle"], disableAll: [3, "disableAll"], isWatcher: [4, "isWatcher"] }, { onUpdate: "onUpdate", tagInError: "tagInError" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.mask; _ck(_v, 5, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit; var currVal_2 = true; var currVal_3 = false; var currVal_4 = _co.disableAllMetadata; var currVal_5 = _co.isWatcher; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_StepMetadataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepMetadataComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.system) === false); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StepMetadataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-card", [], null, null, null, i9.View_CardComponent_0, i9.RenderType_CardComponent)), i1.ɵdid(1, 49152, null, 0, i10.CardComponent, [], { collapsible: [0, "collapsible"], title: [1, "title"] }, null), i1.ɵpid(131072, i11.TranslatePipe, [i1.ChangeDetectorRef, i8.TranslateService]), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "ul", [["class", "list-group mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepMetadataComponent_1)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform("WIZARD.TAGS.REVIEW")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.stepTags; var currVal_3 = _co.trackSteptag; _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
export function View_StepMetadataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "step-metadata", [], null, null, null, View_StepMetadataComponent_0, RenderType_StepMetadataComponent)), i1.ɵdid(1, 114688, null, 0, i12.StepMetadataComponent, [i13.StepTagService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StepMetadataComponentNgFactory = i1.ɵccf("step-metadata", i12.StepMetadataComponent, View_StepMetadataComponent_Host_0, { stepTags: "stepTags", sessionId: "sessionId", stepId: "stepId", alreadySigned: "alreadySigned", disableAllMetadata: "disableAllMetadata", isWatcher: "isWatcher" }, { onChangeMetadata: "onChangeMetadata" }, []);
export { StepMetadataComponentNgFactory as StepMetadataComponentNgFactory };
