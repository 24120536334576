<ng-container *ngIf="signatures?.length > 0">
    <mat-expansion-panel
        [ngClass]="{'mb-2':true,'message-warn': type === reportType.WARN, 'message-success': type === reportType.SUCCESS, 'message-error': type === reportType.ERROR}">
        <mat-expansion-panel-header>
            <mat-panel-title
                [ngClass]="{'title-warn': type === reportType.WARN, 'title-success': type === reportType.SUCCESS, 'title-error': type ===reportType.ERROR}">

                <ng-container *ngIf="type === reportType.WARN">
                    <i class="fas fa-exclamation-triangle fa-xl header-icon"></i>
                    {{'VALIDATION.INDETERMINATE' | translate}}
                </ng-container>

                <ng-container *ngIf="type === reportType.ERROR ">
                    <i class="fas fa-times-circle fa-xl header-icon"></i>
                    {{'VALIDATION.TOTAL-FAILED' | translate}}
                </ng-container>

                <ng-container *ngIf="type === reportType.SUCCESS">
                    <i class=" fas fa-check-circle fa-xl header-icon"></i>
                    {{'VALIDATION.TOTAL-PASSED' | translate}}
                </ng-container>
            </mat-panel-title>
            <mat-panel-description>
                {{signatures.length}}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <app-report-tile *ngFor="let signature of signatures" [signature]='signature'></app-report-tile>
    </mat-expansion-panel>
</ng-container>