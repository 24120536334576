import {Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import find from 'lodash/find';
import {AnnotationService} from 'luxtrust-cosi-api/api/annotation.service';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {PlaceholderService} from 'luxtrust-cosi-api/api/placeholder.service';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {SignatureWorkflowService} from 'luxtrust-cosi-api/api/signatureWorkflow.service';
import {SprofileService} from 'luxtrust-cosi-api/api/sprofile.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {TagData} from 'luxtrust-cosi-api/model/tagData';
import {ApiError} from '../../../../../../error/api-error.model';
import {AlertService} from '../../../../../../services/services/alert-service';
import {AppService} from '../../../../../../services/services/app.service';
import {ModalPdfConsumerComponent} from '../../../../../../shared/components/modal-pdf/modal-pdf-consumer.component';
import {DocumentStoreService} from '../../../../services/document-store.service';
import {wizardStepConfiguratorEnum} from '../../wizardStepConfigurator.enum';
import {SignaturePlaceholderData, StepEnduserService} from "../../../../../../../../luxtrust-cosi-api";
import {SignerDetails} from '../../../../../../shared/components/document-item/document-item.component';
import {DocumentState} from '../../../../model/document-state';
import {takeUntil} from 'rxjs/operators';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {WizardService} from '../../../../wizard/services/wizard.service';

@Component({
  selector: 'app-modal-configurator-documents',
  templateUrl: './modal-configurator-documents.component.html',
  styleUrls: ['./modal-configurator-documents.component.scss']
})
export class ModalConfiguratorDocumentsComponent extends ModalPdfConsumerComponent implements OnInit, OnChanges {

  @Input() step: StepData;
  @Input() session: SessionData;
  @Input() manager;
  @Input() signer;
  @Input() configStep;
  @Input() stepEndusers: StepEnduserData[];
  @Input() oneStepConfigurator = false;
  @Input() stepTagList: TagData[];
  @Output() updateMetadata: EventEmitter<any> = new EventEmitter();

  documents: DocumentState;
  hasAtLeast1Signature = false;

  cloningDocument = false;

  constructor(
    public modal: NgbModal,
    public appService: AppService,
    public annotationService: AnnotationService,
    public placeholderService: PlaceholderService,
    public alertService: AlertService,
    private stepService: StepService,
    private sessionService: SessionService,
    private sprofileService: SprofileService,
    public documentService: DocumentService,
    public stepEnduserService: StepEnduserService,
    public enduserService: EnduserService,
    private workflowService: SignatureWorkflowService,
    public documentStoreService: DocumentStoreService,
    public wizardService: WizardService) {
    super(modal, appService, annotationService, alertService, documentService, placeholderService,stepEnduserService, workflowService, documentStoreService, wizardService);

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit() {
    this.sessionId = this.session.id;
    this.stepId = this.step.id;
    this.documentStoreService.init();
    this.checkCurrentUserIntoStepEnduserList(this.stepEndusers);
    this.checkSignatures();
    this.getSprofile();
    this.checkStepTags();
    this.documentStoreService.getAllDocuments(this.session.id, this.step.id).add(() => {
      this.refreshDocumentData();
    });
  }

  fillSignerList() {
    const stepEnduserFilter = this.stepEndusers.filter(se => se.signer);
    this.signers = [];
    stepEnduserFilter.forEach(stepEnduser => {
      if (stepEnduser.enduser.circle) {
        this.putSignerCircleIntoList(stepEnduser.expected, stepEnduser.enduser, this.signers);
      } else {
        this.signers.push(stepEnduser.enduser);
      }
    });
  }

  checkStepTags() {
    this.hasStepTagPositionSignatureLocation = this.stepTagList.some(tag => tag.alias.includes('POSITION_SIGNATURE_LOCATION'));
    if (!this.hasStepTagPositionSignatureLocation) {
      this.fillSignerList();
    }
  }

  getSprofile() {
    this.sprofileService.getSprofileList().subscribe((res) => {
      this.sprofiles = res.filter(s => s.format !== 'OTHER');
    });
  }

  onFailed() {
    this.documentStoreService.onFailed();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['stepEndusers'] && changes['stepEndusers'].previousValue && !changes['configStep']) {
      if (!this.hasStepTagPositionSignatureLocation) {
        this.fillSignerList();
        const docsThatNeedAcroforms = this.documents.documentToSign.filter(doc => doc.stepId === this.stepId && this.sprofiles.find(sprofile => sprofile.code === doc.sprofileCode).needsAcroform);
        this.documentService.getSignaturePlaceholderListPerDocument(docsThatNeedAcroforms.map(doc => doc.id), this.sessionId, this.stepId).subscribe((placeholders: {[key: string]: Array<SignaturePlaceholderData>}) => {
          for(const key in placeholders) {
            this.showSigners(this.documents.documentToSign.find(doc => doc.id === +key), null , placeholders[key]);
          }
        });
      }
    }
    if (changes['step'] && changes['step'].previousValue) {
      this.documents = undefined;
      this.ngOnInit();
    }
    if (changes['stepTagList'] && changes['stepTagList'].previousValue) {
      this.checkStepTags();
    }
  }

  checkSignatures() {
    this.workflowService.getWorkflowList(this.session.id, this.step.id).subscribe(workflowlist => {
      if (workflowlist.find(workflow => workflow.signatureStatus === 'SIGNED')) {
        this.hasAtLeast1Signature = true;
      }
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  selectedSigners(document: DocumentData): EnduserData[] {
    if (!this.hasStepTagPositionSignatureLocation) {
      return this.signers;
    } else {
      const signerByDocument = find(this.signersByDocument, (docSigners) => docSigners.document.id === document.id);
      if (signerByDocument) {
        return signerByDocument.signers;
      }
      return undefined;
    }
  }

  deleteDocument(document: DocumentData) {
    this.filesAcroformsPositions.set(document.name, new Array());
    this.deletingDocument = true;
    this.documentStoreService.deleteDocument(this.session.id, this.step.id, document, () => this.deletingDocument = false);
  }

  reuploadDocument(data: { document: DocumentData, file: File }) {
    this.reuploadingDocument = true;
    this.documentStoreService.reuploadDocument(this.session.id, this.step.id, data, () => {
      this.reuploadingDocument = false
      if (this.hasStepTagPositionSignatureLocation) {
        const oldDoc = find(this.signersByDocument, (signerDoc) => signerDoc.document.name === data.document.name);
        oldDoc.document = data.file.name;
      }
      this.documentStoreService.getAllDocuments(this.session.id, this.step.id).add(() => {
        this.refreshDocumentData();
        this.updateMetadata.emit();
      });
    });
  }

  cloneAndReupload(data: { files: FileList, document: DocumentData }, type: 'document' | 'appendix') {
    this.cloningDocument = true;
    this.documentStoreService.clone(this.sessionId, this.stepId, data, type, () => {
      this.cloningDocument = this.documentStoreService.isStillUploading();
      this.documentStoreService.getAllDocuments(this.sessionId, this.stepId).add(() => {
        this.refreshDocumentData();
      });
    });
  }

  getSignatures(documents: DocumentData[]): { [id: string]: SignerDetails[] } {
    if (this.step && this.step.skipAcroforms && !this.hasStepTagPositionSignatureLocation) {
      return {};
    }
    const res = {};
    documents.forEach(d => res[d.name] = this.getSignersDetails(d.name));
    return res;
  }

  private can(action: wizardStepConfiguratorEnum) {
    return !!(
        this.manager
        &&
        this.configStep.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[action]
      ) ||
      (
        this.signer
        &&
        this.configStep.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[action]
      );
  }

  canClone(): boolean {
    return this.can(wizardStepConfiguratorEnum.CLONE);
  }

  canAdd(): boolean {
    return this.can(wizardStepConfiguratorEnum.ADD);
  }

  canDelete(): boolean {
    return this.can(wizardStepConfiguratorEnum.DELETE);
  }

  canChangeSprofile(): boolean {
    return this.can(wizardStepConfiguratorEnum.SPROFILE);
  }

  canUpdate(): boolean {
    return this.can(wizardStepConfiguratorEnum.REPLACE);
  }

  get documentsToSignSorted() {
    if(this.documents && this.documents.documentToSign) {
      return this.documents.documentToSign.sort((a, b) => a.originalDocumentId > b.originalDocumentId ? 1 : -1);
    }
    return [];
  }

  get appendixToSeeSorted() {
    if(this.documents && this.documents.appendixToSee) {
      return this.documents.appendixToSee.sort((a, b) => a.originalDocumentId > b.originalDocumentId ? 1 : -1);
    }
    return [];
  }
}
