<div #header class="modal-header">
  <h4 class="modal-title" id="modal-basic-title underline">{{'MODAL_IS_IE.TITLE' | translate}}</h4>
  <button (click)="activeModal.dismiss()" class="close">
    <span aria-hidden="true"><i class="fas fa-times"></i></span>
  </button>
</div>

<div class="modal-body break-words">
  {{'MODAL_IS_IE.BODY' | translate}}
</div>

<div class="modal-footer">
  <button (click)="activeModal.close()" class="btn btn-cancel"
          type="button">{{'MODAL_IS_IE.CLOSE' | translate}}</button>
</div>
