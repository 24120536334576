/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./doc-personal-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/pdf-viewer/pdf-viewer.component.ngfactory";
import * as i3 from "../../../shared/components/pdf-viewer/pdf-viewer.component";
import * as i4 from "../../../services/services/app.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../../../luxtrust-cosi-api/api/watermark.service";
import * as i7 from "../../../../../luxtrust-cosi-api/api/document.service";
import * as i8 from "../../../services/services/alert-service";
import * as i9 from "../../../../../luxtrust-cosi-api/api/placeholder.service";
import * as i10 from "../documentation.service";
import * as i11 from "../../../shared/components/back-btn/back-btn.component.ngfactory";
import * as i12 from "../../../shared/components/back-btn/back-btn.component";
import * as i13 from "@angular/common";
import * as i14 from "./doc-personal-data.component";
import * as i15 from "@angular/router";
import * as i16 from "../../../services/services/url.service";
import * as i17 from "../../../../../luxtrust-cosi-api/api/tenant.service";
var styles_DocPersonalDataComponent = [i0.styles];
var RenderType_DocPersonalDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocPersonalDataComponent, data: {} });
export { RenderType_DocPersonalDataComponent as RenderType_DocPersonalDataComponent };
function View_DocPersonalDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-viewer", [], null, null, null, i2.View_PdfViewerComponent_0, i2.RenderType_PdfViewerComponent)), i1.ɵdid(1, 638976, null, 0, i3.PdfViewerComponent, [i4.AppService, i5.NgbModal, i6.WatermarkService, i7.DocumentService, i8.AlertService, i9.PlaceholderService, i10.DocumentationService], { sessionId: [0, "sessionId"], stepId: [1, "stepId"], activePdfViewer: [2, "activePdfViewer"], document: [3, "document"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sessionId; var currVal_1 = _co.stepId; var currVal_2 = true; var currVal_3 = _co.documentUploaded; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_DocPersonalDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-back-btn", [], null, null, null, i11.View_BackBtnComponent_0, i11.RenderType_BackBtnComponent)), i1.ɵdid(1, 49152, null, 0, i12.BackBtnComponent, [i13.Location], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "container mt-5 mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocPersonalDataComponent_1)), i1.ɵdid(4, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documentUploaded; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_DocPersonalDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-doc-personal-data", [], null, null, null, View_DocPersonalDataComponent_0, RenderType_DocPersonalDataComponent)), i1.ɵdid(1, 114688, null, 0, i14.DocPersonalDataComponent, [i15.ActivatedRoute, i16.UrlService, i17.TenantService, i7.DocumentService, i8.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocPersonalDataComponentNgFactory = i1.ɵccf("app-doc-personal-data", i14.DocPersonalDataComponent, View_DocPersonalDataComponent_Host_0, {}, {}, []);
export { DocPersonalDataComponentNgFactory as DocPersonalDataComponentNgFactory };
