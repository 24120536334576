import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import {StepTagService, TagData, UpdateItemTagPayload} from 'luxtrust-cosi-api';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {StepEnduserService} from 'luxtrust-cosi-api/api/stepEnduser.service';
import {ConfigurationData} from 'luxtrust-cosi-api/model/configurationData';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {forkJoin, of, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {CONFIG, PLACEHOLDER_NAME} from 'src/app/app.constant';
import {ConfigurationService} from 'src/app/services/services/configuration.service';
import {ApiError} from '../../../../../error/api-error.model';
import {AlertService} from '../../../../../services/services/alert-service';
import {AppService} from '../../../../../services/services/app.service';
import {NavigationService} from '../../../../../services/services/navigation.service';
import {ModalYesNoComponent} from '../../../../../shared/components/modal-yes-no/modal-yes-no.component';
import {ModalConfiguratorDocumentsComponent} from './modal-configurator-documents/modal-configurator-documents.component';
import {wizardStepConfiguratorEnum} from '../wizardStepConfigurator.enum';
import {ConfigByStep} from './configByStep';
import StatusEnum = StepData.StatusEnum;

@Component({
  selector: 'app-modal-configurator',
  templateUrl: './modal-configurator.component.html',
  styleUrls: ['./modal-configurator.component.scss']
})
export class ModalConfiguratorComponent implements OnInit {

  page;
  activeStep: StepData;
  steps: StepData[];
  session: SessionData;
  currentStepEndusers: StepEnduserData[];
  currentUserManager = false;
  currentUserSigner = false;
  redirectLocation: string;
  comingFromSession: boolean;
  comingFromStep: boolean;
  public disabledSaveAndCreate = false;
  configByStep: ConfigByStep[] = [];
  currentConfigStep: ConfigurationData;
  public listStepWithAccessMeta = [];
  public listStepWithoutError = [];
  public listStepWithError = [];
  public listNameStepWithError = [];
  public tagMandatoryHasNoValue = false;
  public hasErrorWithLinkTag = false;
  sortMode = false;
  savingTag = false;
  configuratorCreationMode = true;

  public hasVisitedAllStepWithAccess = true;
  public listStepWithAccessNovisited = [];
  public oneStepConfigurator = false;
  public stepTagList: TagData[];
  deletingSession = false;
  lastActiveStepPage;

  constructor(
    public activeModal: NgbActiveModal,
    private stepService: StepService,
    private sessionService: SessionService,
    private appService: AppService,
    private router: Router,
    private alertService: AlertService,
    private configurationService: ConfigurationService,
    private navigationService: NavigationService,
    private modal: NgbModal,
    private translate: TranslateService,
    private stepEnduserService: StepEnduserService,
    private stepTagService: StepTagService) {
  }

  ngOnInit() {
    this.getAllConfigAllSteps();
    if (!this.page) {
      this.page = 0;
    }
    this.configuratorCreationMode = !(this.comingFromSession || this.comingFromStep);
  }

  getAllStepEndusers(stepId): Observable<StepEnduserData[]> {
    return this.stepEnduserService.stepEnduserList(this.session.id, stepId).pipe(map((stepEndusers: StepEnduserData[]) => {
      return stepEndusers;
    }));
  }

  getAllConfigAllSteps() {
    this.listStepWithAccessMeta = [];
    let requests: Observable<any>[] = [];
    const endusersByStep: { stepId: number, endusers: StepEnduserData[] }[] = [];
    this.stepService.getStepWithConfigList(this.session.id).subscribe((steps: StepData[]) => {
      this.steps = steps.filter(step => step.type === 'PREPARATION' || step.type === 'SIGNATURE');
      requests.push(
        this.sessionService.stepEnduserListByStep(this.session.id).pipe(map(result => {
            Object.keys(result).forEach(key => {
              endusersByStep.push({stepId: +key, endusers: result[key]});
            });
        })));
      this.steps.forEach(step => {
        if (step.configuration.managerConfig) {
          let existingConfigForStep = this.configByStep.find(config => config.stepId === step.id);
          if (!existingConfigForStep) {
            this.configByStep.push({
              config: step.configuration,
              stepId: step.id,
              stepName: step.label,
              stepEndusers: undefined
            })
          } else {
           this.configByStep[this.configByStep.indexOf(existingConfigForStep)] = {
             config: step.configuration,
             stepId: step.id,
             stepName: step.label,
             stepEndusers: undefined
           };
          }
        }
      });
      forkJoin(requests).subscribe(() => {
        if (!this.activeStep) {
          this.activeStep = this.steps.find(step => step.id === this.configByStep[0].stepId);
          this.stepTagList = this.steps.find(step => step.id === this.activeStep.id).tags;
          this.computeLastActiveStep();
          this.nextGetAllConfigsAllSteps(endusersByStep);
        } else {
          this.stepTagList = this.steps.find(step => step.id === this.activeStep.id).tags;
          this.computeLastActiveStep();
          this.nextGetAllConfigsAllSteps(endusersByStep);
        }
      }, (error: ApiError) => this.alertService.errorApi(error));
    }, (error: ApiError) => this.alertService.errorApi(error))
  }

  getStepTagList(from?: 'previous' | 'next') {
    this.stepService.getStepWithConfigList(this.session.id).subscribe(steps => {
      this.steps = steps.filter(step => step.type === 'PREPARATION' || step.type === 'SIGNATURE');
      this.stepTagList = this.steps.find(step => step.id === this.activeStep.id).tags;
      if (from && from === 'previous' && this.isStepInactive()) {
        this.previousStep();
      }
      if (from && from === 'next' && this.isStepInactive()) {
        this.nextStep();
      }
      this.computeLastActiveStep();
    });
  }

  computeLastActiveStep() {
    this.configByStep.forEach((config, idx) => {
      let isInactive = this.steps.find(step => step.id === config.stepId).tags
        .filter((tag: TagData) => tag.alias === 'STEP_ACTIVE' && tag.value === 'false').length === 1
      if (!isInactive) {
        this.lastActiveStepPage = idx;
      }
    })
  }

  private nextGetAllConfigsAllSteps(endusersByStep: { stepId: number, endusers: StepEnduserData[] }[]) {
    this.currentConfigStep = this.getConfig();
    this.stockEndusersIntoConfigByStep(endusersByStep);
    this.getStepEndusers();
    this.configByStep.forEach(configByStep => {
      if (this.canSeeMetadata(configByStep.stepId)) {
        this.listStepWithAccessMeta.push(configByStep.stepId);
      }
    });
    this.oneStepConfigurator = this.configByStep.length === 1
      || this.configByStep
        .filter(currentConfig => this.canSee(currentConfig.stepId, wizardStepConfiguratorEnum.DOCUMENT))
        .length === 1;
  }

  private stockEndusersIntoConfigByStep(endusersByStep: { stepId: number, endusers: StepEnduserData[] }[]) {
    this.configByStep.forEach((confByStep) => {
      const endusers = find(endusersByStep, endByStep => confByStep.stepId === endByStep.stepId)
      confByStep.stepEndusers = endusers ? endusers.endusers : undefined;
    });
  }

  private reloadData(from?: 'previous' | 'next') {
    this.activeStep = this.steps.find(step => step.id === this.configByStep[this.page].stepId);
    this.getStepEndusers();
    this.getStepTagList(from);
  }

  changePage(event) {
    this.page = findIndex(this.configByStep, {stepId: event});
    this.reloadData();
  }

  previousStep() {
    this.page--;
    this.reloadData('previous');
  }

  nextStep() {
    this.page++;
    this.reloadData('next');
  }

  // EventEmitter from component modal-configurator-signers
  addUser(stepEnduser: StepEnduserData) {
    const changeStepUser = find(this.currentStepEndusers, (stepUser => stepUser.enduser.id === stepEnduser.enduser.id));
    if (changeStepUser) {
      if (this.sortMode) {
        stepEnduser.orderIndex = this.currentStepEndusers.length - 1;
      }
      remove(this.currentStepEndusers, (stepUser => stepUser.enduser.id === changeStepUser.enduser.id));
    } else {
      if (this.sortMode) {
        stepEnduser.orderIndex = this.currentStepEndusers.length;
      }
    }
    this.currentStepEndusers = [...this.currentStepEndusers, stepEnduser];
    this.configByStep[this.page].stepEndusers = this.currentStepEndusers;
  }

  updateTag(tag: TagData) {
    this.savingTag = true;
    const body: UpdateItemTagPayload = {
      tagId: tag.id,
      value: tag.value,
      encrypt: tag.encrypted,
      orderIndex: tag.orderIndex,
      configurator: tag.configurator
    } as UpdateItemTagPayload;
    this.stepTagService.updateStepTag(this.session.id, this.activeStep.id, body).subscribe(value => {
      this.savingTag = false;
    }, (error) => {
      this.alertService.errorApi(error);
      this.savingTag = false;
    })
  }

  orderSigner() {
    // TODO (SSC) : Voir pour récuperer la liste des stepEndusers avec orderIndex avec output de modal-configurator-signer et sans call http ( si possible)
    this.getAllStepEndusers(this.activeStep.id).subscribe(stepEndusers => {
      this.currentStepEndusers = stepEndusers;
      this.configByStep[this.page].stepEndusers = this.currentStepEndusers;
    });

  }

  updateCircleSigner(circle: { expected: number, idCircle: number }) {
    const circle_ = this.currentStepEndusers.find(stepEnduser => stepEnduser.enduser.id === circle.idCircle);
    circle_.expected = circle.expected;
    this.currentStepEndusers = [...this.currentStepEndusers];
  }

  // EventEmitter from component modal-configurator-signers
  deleteUser(stepEnduserId: number) {
    remove(this.currentStepEndusers, (user) => user.enduser.id === stepEnduserId);
    this.currentStepEndusers = [...this.currentStepEndusers];
    this.configByStep[this.page].stepEndusers = this.currentStepEndusers;
  }

  private canSee(stepId: number, rule: wizardStepConfiguratorEnum) {
    const findC = find(this.configByStep, {stepId: stepId});
    if (findC) {
      const config = findC.config;
      let b = !!((
          (this.currentUserManager &&
            config &&
            config.configurationMap &&
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER] &&
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap &&
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule] &&
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap
          ) &&
          (
            // Update for TAG
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.UPDATE] ||
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.ADD] ||
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.REPLACE] ||
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.CLONE] ||
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.SPROFILE] ||
            config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.DELETE]
          )
        ) ||
        (
          (this.currentUserSigner &&
            config &&
            config.configurationMap &&
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER] &&
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap &&
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule] &&
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap
          ) &&
          (
            // Update for TAG
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.ADD] ||
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.REPLACE] ||
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.CLONE] ||
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.SPROFILE] ||
            config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[rule].configurationMap[wizardStepConfiguratorEnum.DELETE]
          )
        ));
      return b;
    }
    return false;
  }

  canSeeDocuments(): boolean {
    return this.canSee(this.activeStep.id, wizardStepConfiguratorEnum.DOCUMENT);
  }

  canSeeSigners(): boolean {
    return this.canSee(this.activeStep.id, wizardStepConfiguratorEnum.USER);
  }

  canSeeWatchers(): boolean {
    return this.canSee(this.activeStep.id, wizardStepConfiguratorEnum.WATCHER);
  }

  canSeeMetadata(stepId: number): boolean {
    return this.canSee(stepId, wizardStepConfiguratorEnum.TAG);
  }

  closeModal(start: boolean) {
    this.disabledSaveAndCreate = true;
    if (start) {
      this.sessionService.startSession(this.session.id)
        .pipe(switchMap(() => this.sessionService.getSession(this.session.id)), tap((_session: SessionData) => this.session = _session))
        .subscribe(() => {
          if (this.session.status !== StatusEnum.STARTED && this.session.status !== StatusEnum.COMPLETED) {
            this.alertService.error('SESSION.CANNOT_START');
            this.disabledSaveAndCreate = false;
          } else {
            this.activeModal.close();
            this.redirectAfterClose(true);
          }
        }, (error: ApiError) => {
          this.alertService.errorApiWithCustomMessage('SESSION.CANNOT_START', error);
          this.disabledSaveAndCreate = false;
          this.redirectAfterClose(false);
          this.activeModal.close()
        });
    } else {
      this.activeModal.close();
      this.redirectAfterClose(false);
    }
  }

  openModalDeleteSession() {
    const modalDelete = this.modal.open(ModalYesNoComponent, {
      backdrop: false, centered: true
    });
    modalDelete.componentInstance.message = 'MODAL_TEMPLATE_SESSION.CONFIGURATOR.DELETE_SESSION_CONFIRMATION';
    modalDelete.componentInstance.noIsDanger = true;
    modalDelete.result.then(value => {
      if (value === 'yes') {
        this.deleteSessionBeforeCloseModal();
        modalDelete.close();
      } else {
        modalDelete.close();
        this.activeModal.close();
      }
    });
  }

  redirectAfterClose(start: boolean) {
    if (!this.comingFromSession) {
      this.configurationService.tenantConfig
        .then(tenantConfig => {
          const redirectConfiguration = tenantConfig[CONFIG.TENANT.CONFIGURATOR_REDIRECT_LOCATION_AFTER_CREATION.KEY];
          this.redirectLocation = (typeof redirectConfiguration === 'string' || redirectConfiguration instanceof String) ? redirectConfiguration.toLowerCase() : undefined;
          if (this.redirectLocation && this.redirectLocation.includes(CONFIG.TENANT.CONFIGURATOR_REDIRECT_LOCATION_AFTER_CREATION.VALUES.DASHBOARD)) {
            this.router.navigate(['/dashboard']);
          } else if (this.redirectLocation && this.redirectLocation.includes(CONFIG.TENANT.CONFIGURATOR_REDIRECT_LOCATION_AFTER_CREATION.VALUES.STEP)) {
            this.navigationService.goToFirstStartedStep(this.session.id);
          } else if (this.redirectLocation && this.redirectLocation.includes(CONFIG.TENANT.CONFIGURATOR_REDIRECT_LOCATION_AFTER_CREATION.VALUES.DOCUMENT)) {
            this.navigationService.goToFirstDocument(this.session.id);
          } else {
            this.router.navigate(['/session', this.session.id]);
          }
        });
    } else {
      if (start) {
        // FIXME : Reload data in case to reload page
        window.location.reload();
      }
    }
  }

  getConfig(): ConfigurationData {
    return find(this.configByStep, {stepId: this.activeStep.id}).config;
  }

  getStepEndusers() {
    this.currentStepEndusers = find(this.configByStep, {stepId: this.activeStep.id}).stepEndusers;
    this.currentStepEndusers.forEach(stepEnduser => {
      if (stepEnduser.enduser.id === this.appService.getCurrentUserId() || stepEnduser.requester) {
        this.currentUserManager = this.currentUserManager || stepEnduser.manager;
        this.currentUserSigner = this.currentUserSigner || stepEnduser.signer;
      }
    });
  }

  deleteSessionBeforeCloseModal() {
    this.deletingSession = true;
    this.sessionService.deleteSession(this.session.id).toPromise()
      .then(() => {
        this.alertService.success('ALERT.SESSION_DELETED', {sessionName: this.session.label});
        this.activeModal.close();
      }, (error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.deletingSession = false);
  }

  controlValueTagMandatory(mandatoryTagWithoutValue: boolean) {
    if (mandatoryTagWithoutValue) {
      if (!this.listStepWithError.find(stepId => stepId === this.activeStep.id)) {
        this.listStepWithError.push(this.activeStep.id);
      }
      this.spliceListAccordingToTagError(this.listStepWithoutError, this.activeStep.id);
    } else if (!this.listStepWithoutError.find(stepId => stepId === this.activeStep.id)) {
      this.listStepWithoutError.push(this.activeStep.id);
      this.spliceListAccordingToTagError(this.listStepWithError, this.activeStep.id);
    }
    this.listStepWithErrorMetadata();
    this.hasVisitedAllStepWithAccess = (this.listNameStepWithError.length + this.listStepWithoutError.length) === this.listStepWithAccessMeta.length;
    this.tagMandatoryHasNoValue = mandatoryTagWithoutValue;
    this.checkVisitAllPage();
  }

  canStartSession(): boolean {
    return this.listStepWithAccessMeta.length === this.listStepWithoutError.length;
  }

  listStepWithErrorMetadata() {
    this.configByStep.forEach(configStep => {
      if (this.listStepWithError.find(stepId => stepId === configStep.stepId) && !this.listNameStepWithError.find(stepName => stepName === configStep.stepName)) {
        this.listNameStepWithError.push(configStep.stepName);
      }
      if (this.listStepWithoutError.find(stepId => stepId === configStep.stepId)) {
        this.spliceListAccordingToTagError(this.listNameStepWithError, configStep.stepName);
      }
    });
  }

  spliceListAccordingToTagError(listTag = [], elementSearch) {
    const index: number = listTag.indexOf(elementSearch);
    if (index !== -1) {
      listTag.splice(index, 1);
    }
  }

  checkVisitAllPage() {
    this.configByStep.forEach(config => {
      if (!this.listStepWithError.some(step => step === config.stepId) &&
        !this.listStepWithoutError.some(step => step === config.stepId)) {
        if (!this.listStepWithAccessNovisited.some(stepVisited => stepVisited === config.stepName)
          && config.stepId !== this.configByStep[this.configByStep.length - 1].stepId) {
          this.listStepWithAccessNovisited.push(config.stepName);
        }
      } else {
        this.spliceListAccordingToTagError(this.listStepWithAccessNovisited, config.stepName);
      }
    });
  }

  orderAfterReplace() {
    this.configByStep.forEach(config => {
      this.stepEnduserService.stepEnduserList(this.session.id, config.stepId).subscribe(stepEndusers => {
        config.stepEndusers = stepEndusers;
        this.currentStepEndusers = find(this.configByStep, {stepId: this.activeStep.id}).stepEndusers;
      });
    })
  }

  detectSortMode(sort: boolean) {
    this.sortMode = sort;
  }

  closeConfigurator() {
    this.activeModal.close();
  }

  checkErrorLinkTag(error: boolean) {
    this.hasErrorWithLinkTag = error;
  }

  isStepInactive(): boolean {
    if (this.stepTagList == null || this.stepTagList.length == 0) {
      return false;
    } else {
      return this.stepTagList.filter((tag: TagData) => tag.alias === 'STEP_ACTIVE' && tag.value === 'false').length === 1
    }
  }

  isSignatureStepInvalid(): boolean {
    return !!this.currentStepEndusers.find(stepEndUser => stepEndUser.enduser.userId.includes(PLACEHOLDER_NAME) && stepEndUser.signer && !stepEndUser.manager) && !this.isStepInactive();
  }

  updateMetadata() {
    this.stepService.getStep(this.session.id, this.configByStep[this.page].stepId).subscribe((step) => {
      this.activeStep = step;
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  isLastActiveStep() {
    return this.page === this.lastActiveStepPage || this.page >= this.configByStep.length - 1;
  }

  displayErrorMessage() {
    return !this.hasVisitedAllStepWithAccess && this.isLastActiveStep() && this.configByStep.length > 2
  }
}
