/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-is-ie.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/pipes/translate.pipe";
import * as i3 from "../../../core/service/translate.service";
import * as i4 from "./modal-is-ie.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalIsIeComponent = [i0.styles];
var RenderType_ModalIsIeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalIsIeComponent, data: {} });
export { RenderType_ModalIsIeComponent as RenderType_ModalIsIeComponent };
export function View_ModalIsIeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["header", 1]], null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"], ["id", "modal-basic-title underline"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-body break-words"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("MODAL_IS_IE.TITLE")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("MODAL_IS_IE.BODY")); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("MODAL_IS_IE.CLOSE")); _ck(_v, 12, 0, currVal_2); }); }
export function View_ModalIsIeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-is-ie", [], null, null, null, View_ModalIsIeComponent_0, RenderType_ModalIsIeComponent)), i1.ɵdid(1, 49152, null, 0, i4.ModalIsIeComponent, [i5.NgbActiveModal], null, null)], null, null); }
var ModalIsIeComponentNgFactory = i1.ɵccf("app-modal-is-ie", i4.ModalIsIeComponent, View_ModalIsIeComponent_Host_0, {}, {}, []);
export { ModalIsIeComponentNgFactory as ModalIsIeComponentNgFactory };
