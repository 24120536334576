/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-yes-no.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/pipes/translate.pipe";
import * as i3 from "../../../core/service/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "./modal-yes-no.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalYesNoComponent = [i0.styles];
var RenderType_ModalYesNoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalYesNoComponent, data: {} });
export { RenderType_ModalYesNoComponent as RenderType_ModalYesNoComponent };
export function View_ModalYesNoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["center", ""], ["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "text-center mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "btn mr-3"], ["id", "modal-yes-id"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close("yes") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService]), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "btn mr-3"], ["id", "modal-no-id"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close("no") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i1.ChangeDetectorRef, i3.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn mr-3"; var currVal_2 = (_co.yesIsDanger ? "btn-danger" : "btn-primary"); _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_4 = "btn mr-3"; var currVal_5 = (_co.noIsDanger ? "btn-danger" : "btn-primary"); _ck(_v, 13, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.message)); _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("MODAL_YES_NO.YES")); _ck(_v, 9, 0, currVal_3); var currVal_6 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("MODAL_YES_NO.NO")); _ck(_v, 14, 0, currVal_6); }); }
export function View_ModalYesNoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-yes-no", [], null, null, null, View_ModalYesNoComponent_0, RenderType_ModalYesNoComponent)), i1.ɵdid(1, 114688, null, 0, i5.ModalYesNoComponent, [i6.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalYesNoComponentNgFactory = i1.ɵccf("app-modal-yes-no", i5.ModalYesNoComponent, View_ModalYesNoComponent_Host_0, {}, {}, []);
export { ModalYesNoComponentNgFactory as ModalYesNoComponentNgFactory };
