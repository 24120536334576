import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TagData } from 'luxtrust-cosi-api/model/tagData';
import { CloneUtils } from 'src/app/services/utils/clone.utils';

@Component({
  templateUrl: './modal-manage-tag-list.component.html',
  styleUrls: ['./modal-manage-tag-list.component.scss']
})
export class ModalManageTagListComponent {

  tag: TagData;
  updateMode = false;

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  setTag(tag: TagData) {
    this.tag = CloneUtils.cloneObject(tag);
    this.tag.possibleValues = CloneUtils.cloneStringArray(tag.possibleValues);
    if (this.tag.defaultValue === '') {
      this.tag.defaultValue = undefined;
    }
  }

  setUpdateMode(updateMode: boolean) {
    this.updateMode = updateMode;
  }

  onItemValueChange(value: string, index: number) {
    if (this.isDefaultValue(index)) {
      this.tag.defaultValue = value;
    }
    this.tag.possibleValues[index] = value;
  }

  removeFromList(index: number) {
    this.tag.possibleValues.splice(index, 1);
    const defaultIsPresent = this.tag.possibleValues.includes(this.tag.defaultValue);
    if (!defaultIsPresent) {
      this.tag.defaultValue = undefined;
    }
  }

  onDefaultSelect(index: number) {
    if (this.tag.defaultValue !== this.tag.possibleValues[index]) {
      this.tag.defaultValue = this.tag.possibleValues[index];
    } else {
      this.tag.defaultValue = undefined;
    }
  }

  isDefaultValue(index: number): boolean {
    return this.tag.defaultValue === this.tag.possibleValues[index];
  }

  addToList() {
    this.tag.possibleValues.push('');
  }

  onCancel() {
    this.activeModal.close();
  }

  onValidate() {
    this.activeModal.close(this.tag);
  }

  onMoveUp(index: number) {
    if (this.canMoveUp(index)) {
      this.tag.possibleValues[index - 1] = this.tag.possibleValues.splice(index, 1, this.tag.possibleValues[index - 1])[0];
    }
  }

  onMoveDown(index: number) {
    if (index < this.tag.possibleValues.length - 1) {
      this.tag.possibleValues[index + 1] = this.tag.possibleValues.splice(index, 1, this.tag.possibleValues[index + 1])[0];
    }
  }

  canMoveUp(index: number): boolean {
    return index >= 1;
  }

  canMoveDown(index: number): boolean {
    return index < this.tag.possibleValues.length - 1;
  }

  canDeleteItem() {
    return this.tag.possibleValues.length > 1;
  }

  isListSizeInError(): boolean {
    return this.tag.possibleValues.length < 1;
  }

  isValueInError(index: number): boolean {
    return this.tag.possibleValues[index].length > 255;
  }

  isListInError(): boolean {
    return this.isListSizeInError() || this.tag.possibleValues.some(value => value.length > 255);
  }

  trackByPossibleValues(index: number) {
    return index;
  }

  errorValueEmpty() {
    return this.tag.possibleValues.filter(tag => tag === '').length >= 1;
  }
}
