<div #header class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'MODAL_UPDATE_CIRCLE.TITLE' | translate}}</h4>
  <button (click)="activeModal.dismiss()" class="close">
    <span aria-hidden="true"><i class="fas fa-times"></i></span>
  </button>
</div>

<div class="modal-body break-words">
  <div class="form-group">
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <span class="input-group-text">{{'CIRCLE.NAME' | translate}}</span>
      </div>
      <input class="form-control" id="inputName" autoFocus size="10" type="text" [formControl]="updateCircleNameControl">
    </div>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i aria-hidden="true" class="fas fa-at"></i></span>
      </div>
      <input class="form-control" id="inputEmail" size="10" type="text" [formControl]="updateCircleMailControl">
    </div>
  </div>
</div>

<div class="modal-footer">
  <button id="updateCircleButtonId" (click)="update()" class="btn btn-primary" [disabled]="updating"
          type="button">{{'MODAL_UPDATE_CIRCLE.UPDATE' | translate}}</button>
  <button (click)="activeModal.close()" class="btn btn-cancel"
          type="button">{{'MODAL_UPDATE_CIRCLE.CLOSE' | translate}}</button>
</div>
