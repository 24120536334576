import {Component, OnInit} from '@angular/core';
import {
  DocumentData,
  DocumentService,
  SessionData,
  SystemDocumentData,
  TenantService
} from "../../../../../luxtrust-cosi-api";
import {AlertService} from "../../../services/services/alert-service";
import {SYSTEM_DOCUMENT_TYPE, SESSION_STORAGE_SYSTEM_DOC_KEY} from "../../../app.constant";
import {UrlService} from "../../../services/services/url.service";
import {DocumentationService} from "../../../admin/documentation/documentation.service";
import {DocumentationTypeEnum} from "../../../admin/documentation/documentation-type.enum";

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {

  documentUploaded: DocumentData;
  sessionId: number;
  stepId: number;
  activePdfViewer = true;
  gdprSystemDoc: SystemDocumentData;

  constructor(public documentService: DocumentService,
              public alertService: AlertService,
              private tenantService: TenantService,
              private urlService: UrlService,
              private documentationService: DocumentationService) {}

  ngOnInit() {
    this.loadSystemDocumentation();
  }

  private loadSystemDocumentation() {
    let sessionSystemDocumentList: SystemDocumentData[] = [];
    if (!sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)) {
      this.documentationService.sessionSystemDocumentListSubject.subscribe(data => {
        this.gdprSystemDoc = data.find(x => x.type === DocumentationTypeEnum.GDPR);
        this.getDocumentToView();
      });
    } else {
      sessionSystemDocumentList = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY));
      this.gdprSystemDoc = sessionSystemDocumentList.find(x => x.type === DocumentationTypeEnum.GDPR);
      this.getDocumentToView();
    }
  }

  private getDocumentToView() {
    this.sessionId = this.gdprSystemDoc.sessionId;
    this.stepId = this.gdprSystemDoc.stepId;
    let documentId = this.gdprSystemDoc.documentId;
    this.documentService.getDocument(documentId, this.sessionId, this.stepId).subscribe((doc) => {
      doc.id = documentId;
      this.documentUploaded = doc;
    });
  }
}
