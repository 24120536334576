import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {PlaceholderService} from 'luxtrust-cosi-api/api/placeholder.service';
import {WatermarkService} from 'luxtrust-cosi-api/api/watermark.service';
import {AnnotationData} from 'luxtrust-cosi-api/model/annotationData';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {WorkflowData} from 'luxtrust-cosi-api/model/workflowData';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';
import {PdfPositionCapturerComponent} from './pdf-position-capturer.component';
import {SystemDocumentData} from "../../../../../luxtrust-cosi-api";
import {SESSION_STORAGE_SYSTEM_DOC_KEY} from "../../../app.constant";
import {DocumentationTypeEnum} from "../../../admin/documentation/documentation-type.enum";
import {DocumentationService} from "../../../admin/documentation/documentation.service";
import {Observable, of} from "rxjs";
import {map} from "rxjs/internal/operators";
import TypologyEnum = DocumentData.TypologyEnum;

const annotationIcon = 'assets/img/chat-bubble.png';

declare var DigitalSignature: any;

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PdfViewerComponent extends PdfPositionCapturerComponent implements OnInit, OnChanges {

  @Input() forbidDownload;
  @Input() forbidReUpload;
  @Input() sessionId: number;
  @Input() purgedOn: Date;
  @Input() stepId: number;
  @Input() activePdfViewer:boolean;
  @Input() workflows: WorkflowData[];
  @Input() document: DocumentData;
  @Input() annotations: AnnotationData[];
  @Input() askPosition = false;
  @Input() disabledAnnotation = false;
  @Input() disabledDownload = false;
  @Input() action = '';
  @Output() init = new EventEmitter();


  @Input()
  set documentUploaded(document: DocumentData) {
    if (document) {
      this.document = document;
      this.loadDocument();
    }
  }

  @Output() dataOnDocument = new EventEmitter<any>();
  @ViewChild('documentContainer', {
    read: ElementRef, static: false
  }) documentContainer: ElementRef;
  pdfBlob: Blob;
  isLoading = false;
  isRejectedOnce = false;
  defaultSystemDoc: SystemDocumentData;


  // fixme: to be fixed by Intech, to be removed

  constructor(public appService: AppService,
              public modal: NgbModal,
              public watermarkService: WatermarkService,
              private documentService: DocumentService,
              public alertService: AlertService,
              public placeholderService: PlaceholderService,
              private documentationService: DocumentationService
  ) {
    super(appService, modal, watermarkService, placeholderService, alertService);
  }

  static annotationPerPage(annotations) {
    const res = {};
    for (let i = 0; i < annotations; i++) {
      res[annotations[i].page] = res[annotations[i].page] || [];
      res[annotations[i].page].push(annotations[i]);
    }
    return Object.getOwnPropertyNames(res).map(k => res[k]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['document'] && !changes['document'].isFirstChange())
      || (changes['action'] && !changes['action'].isFirstChange())
    ) {
      if (!this.isLoading) {
        this.loadDocument();
      }
    }
  }

  ngOnInit(): void {
    this.loadDefaultData().subscribe(data => {
      this.defaultSystemDoc = data;
      this.loadDocument();
    }, () => {
      this.loadDocument();
    });
  }

  loadDocument() {
    if ((this.activePdfViewer || this.document.typology !== TypologyEnum.SIGNATURE) ) { // quickfix quick and dirty
      this.isLoading = true;
      let docIdToLoad = this.document.id, sessionIdToLoad = this.sessionId, stepIdToLoad = this.stepId;
      if (!!this.purgedOn) {
        sessionIdToLoad = this.defaultSystemDoc.sessionId;
        stepIdToLoad = this.defaultSystemDoc.stepId;
        docIdToLoad = this.defaultSystemDoc.documentId;
      }
      this.documentService.downloadDocument(docIdToLoad, sessionIdToLoad, stepIdToLoad).subscribe((fileResource: Blob) => {
        const fileReader: FileReader = new FileReader();
        fileReader.onload = () => {
          const config = this.pdfConfig(fileReader.result);
          DigitalSignature.build(this.documentContainer.nativeElement, config);
        };
        fileReader.readAsArrayBuffer(fileResource);
        this.isLoading = false;
        this.pdfBlob = fileResource;
        this.dataOnDocument.emit({blob: this.pdfBlob, document: this.document});
        this.init.emit(true);
      }, (error: ApiError) => {
        this.isLoading = false;
        this.init.emit(true);
        this.alertService.errorApi(error);
      });
    }
    this.isRejectedOnce = this.workflows ?
      this.workflows.some(workflow =>
        workflow.signatureStatus === WorkflowData.SignatureStatusEnum.DECLINED
        && <any>this.document.id === workflow.documentId
      ) : false;
  }

  pdfConfig(pdf) {
    const config = {
      file: pdf, onLoadPdf: (a) => this.digitalSignatureLoaded(a, undefined, undefined), clickedEnabled: false
    };
    if (!this.disabledAnnotation) {
      return {
        ...config,
        annotations: PdfViewerComponent.annotationPerPage(this.annotations),
        annotationReadOnly: true,
        annotationPicture: annotationIcon
      };
    }
    return config;
  }

  disableSignatureSetSlider(img: any) {
  }

  disableSignatureResetSlider() {
    this.disableSignature = true;
  }

  private loadDefaultData(): Observable<SystemDocumentData> {
    if (!sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)) {
      return this.documentationService.sessionSystemDocumentListSubject.pipe(map((data: SystemDocumentData[]) => {
        return data.find(x => x.type === DocumentationTypeEnum.PURGED);
      }));
    } else {
      return of(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)).find(x => x.type === DocumentationTypeEnum.PURGED));
    }
  }
}
