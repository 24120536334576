/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-templates-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../shared/components/template-editor/template-editor.component.ngfactory";
import * as i6 from "../../../../../shared/components/template-editor/template-editor.component";
import * as i7 from "../../../../../core/pipes/translate.pipe";
import * as i8 from "../../../../../core/service/translate.service";
import * as i9 from "../../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i10 from "./notification-templates-editor.component";
import * as i11 from "../../../../../../../luxtrust-cosi-api/api/pattern.service";
import * as i12 from "../../../../../services/services/alert-service";
import * as i13 from "../../../../../services/services/ie.service";
import * as i14 from "../../../../../services/services/languages.service";
var styles_NotificationTemplatesEditorComponent = [i0.styles];
var RenderType_NotificationTemplatesEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationTemplatesEditorComponent, data: {} });
export { RenderType_NotificationTemplatesEditorComponent as RenderType_NotificationTemplatesEditorComponent };
function View_NotificationTemplatesEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "span", [["class", "tab-available fa fa-language"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.availableLanguagesTooltip(_v.parent.parent.context.$implicit.value); _ck(_v, 1, 0, currVal_0); }, null); }
function View_NotificationTemplatesEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationTemplatesEditorComponent_3)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "span", [["class", "tab-title"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "current-changed": 0 }), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.availableLanguages(_v.parent.context.$implicit.value); _ck(_v, 1, 0, currVal_0); var currVal_1 = "tab-title"; var currVal_2 = _ck(_v, 5, 0, _co.checkIfChanges(_v.parent.context.$implicit.value)); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.key; _ck(_v, 6, 0, currVal_3); }); }
function View_NotificationTemplatesEditorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i4.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i4.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i4.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 3, 0, currVal_2); }); }
function View_NotificationTemplatesEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-template-editor", [], null, null, null, i5.View_TemplateEditorComponent_0, i5.RenderType_TemplateEditorComponent)), i1.ɵdid(2, 49152, null, 0, i6.TemplateEditorComponent, [], { displayCloseButton: [0, "displayCloseButton"], patternData: [1, "patternData"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 22, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "language-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "select", [["class", "input form-control"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.language = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i4.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.SelectControlValueAccessor]), i1.ɵdid(10, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(12, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationTemplatesEditorComponent_6)), i1.ɵdid(14, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "input-group-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "pl-2 fas fa-language"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-danger"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete(_v.parent.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, [" ", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i1.ChangeDetectorRef, i8.TranslateService]), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave(_v.parent.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(21, null, [" ", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i1.ChangeDetectorRef, i8.TranslateService]), (_l()(), i1.ɵeld(23, 0, null, null, 2, "button", [["class", "btn btn-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(24, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i1.ChangeDetectorRef, i8.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.getPatternByTagValue(_v.parent.parent.context.$implicit.value, _co.patterns); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_9 = _co.language; _ck(_v, 10, 0, currVal_9); var currVal_10 = _co.languageService.languages; _ck(_v, 14, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 12).ngClassValid; var currVal_7 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = (_co.removingPattern || _co.savingPattern); _ck(_v, 17, 0, currVal_11); var currVal_12 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("MODAL_TEMPLATE_BUILDER.DELETE")); _ck(_v, 18, 0, currVal_12); var currVal_13 = _co.canNotSave(_v.parent.parent.context.$implicit.value); var currVal_14 = (_co.removingPattern || _co.savingPattern); _ck(_v, 20, 0, currVal_13, currVal_14); var currVal_15 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("MODAL_TEMPLATE_BUILDER.SAVE")); _ck(_v, 21, 0, currVal_15); var currVal_16 = i1.ɵunv(_v, 24, 0, i1.ɵnov(_v, 25).transform("MODAL_TEMPLATE_BUILDER.CLOSE")); _ck(_v, 24, 0, currVal_16); }); }
function View_NotificationTemplatesEditorComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i4.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i4.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i4.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 3, 0, currVal_2); }); }
function View_NotificationTemplatesEditorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "space-filler"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i1.ChangeDetectorRef, i8.TranslateService]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.findBestSuitedTemplateForTagValue(_v.parent.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i1.ChangeDetectorRef, i8.TranslateService]), (_l()(), i1.ɵeld(7, 0, null, null, 16, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 12, "div", [["class", "language-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 11, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 7, "select", [["class", "input form-control"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.language = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = (_co.languageChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i4.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.SelectControlValueAccessor]), i1.ɵdid(14, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(16, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationTemplatesEditorComponent_8)), i1.ɵdid(18, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["class", "input-group-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "pl-2 fas fa-language"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["class", "btn btn-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(22, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i1.ChangeDetectorRef, i8.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.language; _ck(_v, 14, 0, currVal_10); var currVal_11 = _co.languageService.languages; _ck(_v, 18, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("WIZARD.NOTIFICATIONS.TEMPLATE.NOT_FOUND")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.creatingPattern; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("WIZARD.NOTIFICATIONS.TEMPLATE.FIND")); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 16).ngClassValid; var currVal_8 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_12 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("MODAL_TEMPLATE_BUILDER.CLOSE")); _ck(_v, 22, 0, currVal_12); }); }
function View_NotificationTemplatesEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationTemplatesEditorComponent_5)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noTemplate", 2]], null, 0, null, View_NotificationTemplatesEditorComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPatternByTagValue(_v.parent.context.$implicit.value, _co.patterns); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_NotificationTemplatesEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ngb-tab", [], null, null, null, null, null)), i1.ɵdid(1, 2113536, [[1, 4]], 2, i2.NgbTab, [], null, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_NotificationTemplatesEditorComponent_2)), i1.ɵdid(5, 16384, [[2, 4]], 0, i2.NgbTabTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_NotificationTemplatesEditorComponent_4)), i1.ɵdid(7, 16384, [[3, 4]], 0, i2.NgbTabContent, [i1.TemplateRef], null, null)], null, null); }
export function View_NotificationTemplatesEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ngb-tabset", [["class", "nav-fill"]], null, null, null, i9.View_NgbTabset_0, i9.RenderType_NgbTabset)), i1.ɵdid(1, 2146304, null, 1, i2.NgbTabset, [i2.NgbTabsetConfig], null, null), i1.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationTemplatesEditorComponent_1)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.TAG_VALUES; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_NotificationTemplatesEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotificationTemplatesEditorComponent_0, RenderType_NotificationTemplatesEditorComponent)), i1.ɵdid(1, 49152, null, 0, i10.NotificationTemplatesEditorComponent, [i2.NgbActiveModal, i11.PatternService, i12.AlertService, i8.TranslateService, i2.NgbModal, i13.IEService, i14.LanguagesService], null, null)], null, null); }
var NotificationTemplatesEditorComponentNgFactory = i1.ɵccf("ng-component", i10.NotificationTemplatesEditorComponent, View_NotificationTemplatesEditorComponent_Host_0, {}, {}, []);
export { NotificationTemplatesEditorComponentNgFactory as NotificationTemplatesEditorComponentNgFactory };
