/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-day-number.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../core/pipes/translate.pipe";
import * as i4 from "../../../core/service/translate.service";
import * as i5 from "./input-day-number.component";
var styles_InputDayNumberComponent = [i0.styles];
var RenderType_InputDayNumberComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputDayNumberComponent, data: {} });
export { RenderType_InputDayNumberComponent as RenderType_InputDayNumberComponent };
export function View_InputDayNumberComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "input-day-number input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [["inputDayNumber", 1]], null, 3, "input", [["class", "form-control"], ["type", "number"]], [[8, "max", 0], [8, "min", 0], [8, "value", 0]], [[null, "input"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.onInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.onBlur.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "sizeInputMetadataPage": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService]), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "clearBtn icon-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        i1.ɵnov(_v, 3).value = "";
        var pd_0 = (_co.onInput(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(13, { "changePaddingMetadata": 0 }), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-backspace"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "form-control"; var currVal_4 = _ck(_v, 6, 0, _co.smallSizeInputDay); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_6 = "clearBtn icon-button"; var currVal_7 = _ck(_v, 13, 0, _co.metadataPage); _ck(_v, 12, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.max; var currVal_1 = _co.min; var currVal_2 = _co.defaultValue; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("WIZARD.GENERAL.DAYS")); _ck(_v, 8, 0, currVal_5); }); }
export function View_InputDayNumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-input-day-number", [], null, null, null, View_InputDayNumberComponent_0, RenderType_InputDayNumberComponent)), i1.ɵdid(1, 114688, null, 0, i5.InputDayNumberComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputDayNumberComponentNgFactory = i1.ɵccf("app-input-day-number", i5.InputDayNumberComponent, View_InputDayNumberComponent_Host_0, { defaultValue: "defaultValue", type: "type", min: "min", max: "max", metadataPage: "metadataPage", notBefore: "notBefore", smallSizeInputDay: "smallSizeInputDay" }, { onChange: "onChange", onBlur: "onBlur" }, []);
export { InputDayNumberComponentNgFactory as InputDayNumberComponentNgFactory };
