/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-screen.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "../../pipes/translate.pipe";
import * as i5 from "../../service/translate.service";
import * as i6 from "./full-screen.component";
var styles_FullScreenComponent = [i0.styles];
var RenderType_FullScreenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullScreenComponent, data: {} });
export { RenderType_FullScreenComponent as RenderType_FullScreenComponent };
function View_FullScreenComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "h6", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService]), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.tooltip)); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "fa fa-", _co.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_FullScreenComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullScreenComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.icon && _co.tooltip); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_FullScreenComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i1.ChangeDetectorRef, i5.TranslateService]), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "custom-text font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("INVITE.INFORMATION_MESSAGE")); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.user == null) ? null : _co.user.userId); _ck(_v, 5, 0, currVal_1); }); }
export function View_FullScreenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "full-screen container"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullScreenComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullScreenComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "col-12 col-lg mt-3"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "col-12 col-lg-3 mt-3"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "col-12 col-lg mt-3"]], null, null, null, null, null)), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "col-12 col-lg-3 mt-3"]], null, null, null, null, null)), i1.ɵncd(null, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "full-screen container"; var currVal_1 = _co.mode; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.title; _ck(_v, 4, 0, currVal_2); var currVal_3 = ((_co.authMode === "ba") && ((_co.user == null) ? null : _co.user.userId)); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_FullScreenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-screen", [], null, null, null, View_FullScreenComponent_0, RenderType_FullScreenComponent)), i1.ɵdid(1, 49152, null, 0, i6.FullScreenComponent, [], null, null)], null, null); }
var FullScreenComponentNgFactory = i1.ɵccf("app-full-screen", i6.FullScreenComponent, View_FullScreenComponent_Host_0, { mode: "mode", title: "title", icon: "icon", tooltip: "tooltip", user: "user", authMode: "authMode" }, {}, ["[top-panel]", "[left-panel]", "*", "[right-panel]"]);
export { FullScreenComponentNgFactory as FullScreenComponentNgFactory };
