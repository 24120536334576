import {Component, EventEmitter, NgZone, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {EnduserPreferenceService} from 'luxtrust-cosi-api/api/enduserPreference.service';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {NotificationData} from 'luxtrust-cosi-api/model/notificationData';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {NotifierService} from 'src/app/services/services/notifier.service';
import {SprofileWrapperService} from 'src/app/services/services/sprofile-wrapper.service';
import {environment} from '../../../../environments/environment';
import {CONFIG, SESSION_STORAGE_SYSTEM_DOC_KEY} from '../../../app.constant';
import {AppService} from '../../../services/services/app.service';
import {ConfigurationService} from '../../../services/services/configuration.service';
import {LanguagesService} from '../../../services/services/languages.service';
import {VisualIdentityService} from '../../../services/services/visual-identity.service';
import {ModalEventNotificationsComponent} from '../notifications/modal-event-notifications.component';
import {AlertService} from "../../../services/services/alert-service";
import {SessionService} from "../../../../../luxtrust-cosi-api";
import {ApiError} from "../../../error/api-error.model";
import {DocumentationService} from "../../../admin/documentation/documentation.service";

@Component({
  selector: 'app-header', templateUrl: './header.component.html', styleUrls: ['./header.component.scss'], host: {
    '(document:mouseover)': 'globalMouseOver($event)', '(document:mouseout)': 'globalMouseOut($event)'
  }
})
export class HeaderComponent implements OnInit {

  static URL_NOTIFICATIONS_SPECIFIC = '/notifications';
  over1: boolean;
  over2: boolean;
  over3: boolean;
  over4: boolean;
  isOpen: boolean;
  selectedLanguage: string;
  enduser$: Observable<EnduserData>;
  emails: NotificationData[] = [];
  displayHome = false;
  private readingEmails = false;
  displayApiButton = false;
  displayRolesTemplatesButton = false;
  csvFile: File;

  constructor(public appService: AppService,
              public visualIdentityService: VisualIdentityService,
              private enduserService: EnduserService,
              private router: Router,
              private route: ActivatedRoute,
              private preferencesService: EnduserPreferenceService,
              private languagesService: LanguagesService,
              private translateService: TranslateService,
              private configurationService: ConfigurationService,
              private modal: NgbModal, private notifierService: NotifierService,
              private sprofileService: SprofileWrapperService,
              private sessionService: SessionService,
              private documentationService: DocumentationService,
              private alertService: AlertService,
              private ngZone: NgZone) {
    if (this.isLoggedIn()) {
      this.sprofileService.getSprofileList();
    }
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        // No polling in e2e tests
        if(window['disableTimers'] || document.hidden){
          return;
        }
        this.ngZone.run(() => {
          if (!this.isLoggedIn() || this.readingEmails) {
            return;
          }
          this.readingEmails = true;
          this.enduserService.getCurrentEnduserNotifications().toPromise().then(notifs => {
            this.readingEmails = false;
            this.emails = notifs;
          }, () => this.readingEmails = false);
        });
      }, 12000);
    });
  }

  globalMouseOver(event: any) {
    const el = event.target;
    if (el.hasAttribute('data-tooltip-anchor')) {
      if (document.querySelector('.' + el.getAttribute('data-tooltip-anchor'))) {
        const child = <any>document.querySelector('.' + el.getAttribute('data-tooltip-anchor'));
        child.style.top = event.clientY + 'px';
        child.style.left = event.clientX + 'px';
        child.style.display = 'block';
      }
    }
  }

  globalMouseOut(event: any) {
    const el = event.target;
    if (el.hasAttribute('data-tooltip-anchor')) {
      if (document.querySelector('.' + el.getAttribute('data-tooltip-anchor'))) {
        const child = <any>document.querySelector('.' + el.getAttribute('data-tooltip-anchor'));
        child.style.display = 'none';
      }
    }
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });

    this.notifierService.subscribeToDetectPage(
      isDetected => {
        if (isDetected === 'displayHome') {
          this.displayHome = true;
        }
      });
    this.enduser$ = this.appService.getUser$();
    this.configurationService.tenantConfig
      .then(tenantConfig => {
        this.displayApiButton = !!+tenantConfig[CONFIG.TENANT.DISPLAY_API_BUTTON_HEADER.KEY];
        this.displayRolesTemplatesButton = !!+tenantConfig[CONFIG.TENANT.DISPLAY_ROLESTEMPLATES_BUTTON_HEADER.KEY];
      });
  }

  removeNotif(index: number) {
    this.enduserService.removeCurrentEnduserNotification(this.emails[index].id)
      .pipe(take(1)).subscribe(() => this.emails.splice(index, 1));
  }

  changeLang(lang) {
    this.translateService.use(lang);
    if (this.isLoggedIn()) {
      this.preferencesService.updateEnduserPreferences(this.appService.getCurrentUserId(), {lang: lang.toUpperCase()})
        .pipe(take(1)).subscribe(() =>{
          sessionStorage.removeItem(SESSION_STORAGE_SYSTEM_DOC_KEY);
          this.documentationService.buildSystemDocumentation();
      }
        , () => undefined);

    }
    else {
      window.location.reload();
    }
  }

  isLoggedIn(): boolean {
    return !!(this.appService.get());
  }

  logout() {
    sessionStorage.removeItem(SESSION_STORAGE_SYSTEM_DOC_KEY);
    this.router.navigate(['/logout']);
  }

  toApi() {
    window.open(environment.BASE_URL + '/swagger-ui.html');
  }

  toRoute(route: string) {
    if (route === '/dashboard') {
      if ((<any>this.route.snapshot)._routerState.url.indexOf('dashboard') > -1) {
        return this.router.navigate(['/profile']).then(() => this.router.navigate(['/dashboard']));
      }
    }
    this.router.navigate([route]);
  }

  openCreateModal() {
    this.modal.open(ModalEventNotificationsComponent, {backdrop: false});
  }

  closeAll() {
    this.isOpen = false;
    this.over1 = false;
    this.over2 = false;
    this.over3 = false;
    this.over4 = false;

    setTimeout(() => {
      this.ifOpen();
    }, 100);
  }

  ifOpen() {
    this.isOpen = !!(this.over1 || this.over2 || this.over3 || this.over4);
  }
}
