import {Injectable} from '@angular/core';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {CreateDocumentPayload} from 'luxtrust-cosi-api/model/createDocumentPayload';
import {DocumentData} from 'luxtrust-cosi-api/model/documentData';
import {UpdateDocumentPayload} from 'luxtrust-cosi-api/model/updateDocumentPayload';
import {catchError, debounceTime, take, tap} from 'rxjs/operators';
import {ApiError} from '../../../error/api-error.model';
import {SprofileEnum} from '../../../services/constants/signature.constant';
import {AlertService} from '../../../services/services/alert-service';
import {Store} from '../model/store';
import {DocumentState} from '../model/document-state';
import TypologyEnum = DocumentData.TypologyEnum;
import {forkJoin, merge, Observable, of, Subject, throwError} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {DocumentWithDetails} from '../model/document-with-details';
import {FilesUtils} from '../../../shared/utils/files.utils';
import {map} from "rxjs/internal/operators";
import {Subscription} from "rxjs/index";

const DEFAULT_SPROFILE = SprofileEnum.ORELY_PADES;

@Injectable({
  providedIn: 'root'
})
export class DocumentStoreService extends Store<DocumentState> {

  public uploadDocumentRequest: Map<string, Observable<any>> = new Map();
  public uploadProgress: Map<string, number> = new Map();
  public cancelRequestSubject: Subject<void> = new Subject<void>();
  public documentIdsUploading = [];
  public documentIdsCreating = [];

  constructor(private documentService: DocumentService,
              private alertService: AlertService) {
    super();
  }

  init() {
    if (!!this.getAll()) {
      this.reset();
    }
  }

  reset() {
    this.store({
      documents: [], documentToSign: [], appendixToSee: []
    });
  }

  getAllDocuments(sessionId: number, stepId: number) {
    return this.documentService.getAllDocuments(sessionId, stepId).subscribe(documents => {
      this.filterDocuments(documents, stepId);
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  uploadDocument(sessionId: number, stepId: number, files: File[], globalCb: Function, forEachCb: Function): void {
    this.setLoadingToSign(true);
    files.forEach((file: File) => {
      if (!this.getAll().documentToSign.some(d => files[0] && d.name === files[0].name)) {
        const id = 'd_' + Math.random().toString(36).substr(2, 16) + ';name;' + file.name;
        this.initProgress(id);
        this.documentIdsCreating.push(id);
        this.uploadDocumentRequest.set(id, this.documentService.uploadDocument(sessionId, stepId, file, null, 'events', true).pipe(
          takeUntil(this.cancelRequestSubject),
          tap((event: HttpEvent<DocumentData>) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.reportProgress(id, Math.round(100 * event.loaded / event.total));
            } else if (event instanceof HttpResponse) {
              // this document is a document original it'll never be display on the UI so we add it on documents
              const createDocumentPayload: CreateDocumentPayload = {
                sourceDocumentId: event.body.id,
                sprofileCode: DEFAULT_SPROFILE
              };
              this.setLoadingToSign(false);
              this.clearProgress(id);
              this.createDocument(sessionId, stepId, createDocumentPayload, id, globalCb, forEachCb);
            }
          }), catchError((error: ApiError) => {
            this.alertService.errorApi(error);
            this.clearProgress(id);
            return throwError(error);
          })));
      } else {
        this.setLoadingToSign(false);
      }
    });
    if (this.uploadDocumentRequest.size === 0) {
      globalCb();
    }
    forkJoin(...this.uploadDocumentRequest.values()).subscribe(() => {});
  }

  uploadAppendix(sessionId: number, stepId: number, files: File[], globalCb: Function, forEachCb: Function) {
    this.setLoadingAppendix(true);
    files.forEach(file => {
      if (!this.getAll().appendixToSee.some(d => d.name === files[0].name)) {
        const id = 'a_' + Math.random().toString(36).substr(2, 16) + ';name;' + file.name;
        this.initProgress(id);
        this.documentIdsCreating.push(id);
        this.uploadDocumentRequest.set(id, this.documentService.uploadDocument(sessionId, stepId, file, null, 'events', true).pipe(
          takeUntil(this.cancelRequestSubject),
          tap((event: HttpEvent<DocumentData>) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.reportProgress(id, Math.round(100 * event.loaded / event.total));
            } else if (event instanceof HttpResponse) {
              const createDocumentPayload: CreateDocumentPayload = {
                sourceDocumentId: event.body.id,
                typology: TypologyEnum.TOVIEW
              };
              this.addAppendixToStore(event.body)
              this.setLoadingAppendix(false);
              this.clearProgress(id);
              this.createAppendix(sessionId, stepId, createDocumentPayload, id, globalCb, forEachCb);
            }
          }),
          catchError((error: ApiError) => {
            this.alertService.errorApi(error);
            this.clearProgress(id)
            return throwError(error);
          })));
      } else {
        this.setLoadingAppendix(false);
      }
    });
    if (this.uploadDocumentRequest.size === 0) {
      globalCb();
    }
    forkJoin(...this.uploadDocumentRequest.values()).subscribe(() => {});
  }

  createDocument(sessionId: number, stepId: number, createDocumentPayload: CreateDocumentPayload, requestId: string, globalCb: Function, forEachCb: Function) {
    this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((document: DocumentData) => {
      this.addDocumentToStore(document, {...document, displayArrow: true, parentIsMutual: false});
      forEachCb();
      if (this.isLastCreateRequest(requestId)) {
        globalCb();
      }
    }, (error: ApiError) => {
      this.alertService.errorApi(error)
    });
  }

  createAppendix(sessionId: number, stepId: number, createDocumentPayload: CreateDocumentPayload, requestId: string, globalCb: Function, forEachCb: Function) {
    return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((document: DocumentData) => {
      this.addAppendixToStore(document, {...document, displayArrow: true, parentIsMutual: false});
      forEachCb();
      if (this.isLastCreateRequest(requestId)) {
        globalCb();
      }
    }, (error: ApiError) => {
      this.alertService.errorApi(error)
    });
  }

  isLastCreateRequest(id: string): boolean {
    this.documentIdsCreating = this.documentIdsCreating.filter(val => val !== id);
    return this.documentIdsCreating.length === 0;
  }

  createMutualDocument(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingToSign(true);
    return this.documentService.deleteDocument(document.id, sessionId, stepId).subscribe(() => {
      // We create a mutual document
      return this.documentService.getMutualDocument(document.sourceDocumentId, sessionId, stepId).subscribe((documentMutual: DocumentData) => {
        const createDocumentPayload: CreateDocumentPayload = {
          sourceDocumentId: documentMutual.id, sprofileCode: document.sprofileCode
        };
        // We create a pades from the mutual
        return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((documentChildOfMutual: DocumentData) => {
          // we remove from the local list of documentToSign the old related document which is replaced by a new with a mutual parent
          this.getAll().documentToSign.splice((this.getAll().documentToSign.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id)), 1);
          this.addDocumentToStore(documentMutual, {...documentChildOfMutual, displayArrow: true, parentIsMutual: true, parentMutualOnCurrentStep: true});
          this.setLoadingToSign(false);
          cb();
        });
      });
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      cb();
    });
  }

  createMutualAppendix(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingAppendix(true);
    // We delete the documents
    return this.documentService.deleteDocument(document.id, sessionId, stepId).subscribe(() => {
      // We create a mutual document
      return this.documentService.getMutualDocument(document.sourceDocumentId, sessionId, stepId).subscribe((documentMutual: DocumentData) => {
        const createDocumentPayload: CreateDocumentPayload = {
          sourceDocumentId: documentMutual.id, typology: TypologyEnum.TOVIEW
        };
        // We create a to_view from the mutual
        return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((documentChildOfMutual: DocumentData) => {
          // we remove from the local list of documentToSign the old related document which is replaced by a new with a mutual parent
          this.getAll().appendixToSee.splice((this.getAll().appendixToSee.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id)), 1);
          this.addAppendixToStore(documentMutual, {...documentChildOfMutual, displayArrow: true, parentIsMutual: true, parentMutualOnCurrentStep: true});
          this.setLoadingAppendix(false);
          cb();
        });
      });
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      cb();
    });
  }

  retakeMutualDocument(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingMutual(true);
    const createDocumentPayload: CreateDocumentPayload = {sourceDocumentId: document.id, sprofileCode: DEFAULT_SPROFILE};
    return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((documentChildOfMutual: DocumentData) => {
      this.getAll().documentMutual.splice(this.getAll().documentMutual.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id), 1);;
      this.addDocumentToStore(null, {...documentChildOfMutual, parentIsMutual: true});
      this.setLoadingMutual(false);
      cb();
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      this.setLoadingMutual(false);
      cb();
    });
  }

  retakeMutualAppendix(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingMutual(true);
    const createDocumentPayload: CreateDocumentPayload = {sourceDocumentId: document.id, typology: TypologyEnum.TOVIEW};
    return this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((documentChildOfMutual: DocumentData) => {
      this.getAll().documentMutual.splice(this.getAll().documentMutual.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id), 1);
      this.addAppendixToStore(null, {...documentChildOfMutual, parentIsMutual: true});
      this.setLoadingMutual(false);
      cb();
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      this.setLoadingMutual(false);
      cb();
    });
  }

  deleteMutualDocumentParentInCurrentStep(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingToSign(true);
    // We delete the chain o, m , p on the back via the soft delete
    return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).subscribe(() => {
      // document have the original sprofileCode, so we get the mutual one
      // filter the one which is mutual related document
      const idx = this.getAll().documentToSign.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete && document && (document.id === documentToDelete.sourceDocumentId));
      this.getAll().documentToSign.splice(idx, 1);
      this.setLoadingToSign(false);
      cb();
    }, (error) => {
      this.alertService.warning('WIZARD.DOCUMENTS.INVOLVE_IN_OTHER_STEP');
      this.setLoadingToSign(false);
      cb();
    });
  }

  deleteMutualAppendixParentInCurrentStep(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingAppendix(true);
    // We delete the chain o, m , p on the back via the soft delete
    return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).subscribe(() => {
      // document have the original sprofileCode, so we get the mutual one
      // filter the one which is mutual from list of appendix docs shown on the page
      const idx = this.getAll().appendixToSee.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete && document && (document.id === documentToDelete.sourceDocumentId));
      this.getAll().appendixToSee.splice(idx, 1);
      this.setLoadingAppendix(false);
      cb();
    }, (error) => {
      this.alertService.warning('WIZARD.DOCUMENTS.INVOLVE_IN_OTHER_STEP');
      this.setLoadingAppendix(false);
      cb();
    });
  }

  deleteMutualDocumentParentInAnotherStep(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingToSign(true);
    // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
    return this.documentService.deleteDocument(document.id, sessionId, stepId).subscribe(() => {
      const idx = this.getAll().documentToSign.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id);
      this.getAll().documentToSign.splice(idx, 1);
      const mutual = this.getParent(document);
      this.addMutualToStore(null, {...mutual, parentIsMutual: false, parentMutualOnCurrentStep: false, isMutualOnCurrentStep: false, displayArrow: false})
      this.setLoadingToSign(false);
      cb();
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      this.setLoadingToSign(false);
      cb();
    });
  }

  deleteMutualAppendixParentInAnotherStep(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingAppendix(true);
    // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
    return this.documentService.deleteDocument(document.id, sessionId, stepId).subscribe(() => {
      const idx = this.getAll().appendixToSee.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id);
      this.getAll().appendixToSee.splice(idx, 1);
      const mutual = this.getParent(document);
      this.addMutualToStore(null, {...mutual, parentIsMutual: false, parentMutualOnCurrentStep: false, isMutualOnCurrentStep: false, displayArrow: false});
      this.setLoadingAppendix(false);
      cb();
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      this.setLoadingAppendix(false);
      cb();
    });
  }

  deleteMutualDocument(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingToSign(true);
    const parentMutualDocument = this.getParent(document);
    const parentOriginalDocument = this.getParent(parentMutualDocument);
    const createDocumentPayload: CreateDocumentPayload = {
      sourceDocumentId: parentOriginalDocument.id, sprofileCode: document.sprofileCode
    };
    // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
    return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).pipe(debounceTime(300))
      .subscribe(() => {
        // We First create the future child of the original
        this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((newDocument: DocumentData) => {
          const index = this.getAll().documentToSign.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id);
          this.getAll().documentToSign[index] = {...newDocument, displayArrow: true, parentIsMutual: false};
          this.setLoadingToSign(false)
          cb();
        }, (error: ApiError) => {
          this.alertService.errorApi(error);
          this.setLoadingToSign(false);
          cb();
        });
      }, (error) => {
        this.alertService.errorApi(error);
        this.setLoadingToSign(false);
        this.setHasFailed(true);
        cb();
      });
  }

  deleteMutualAppendix(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingAppendix(true);
    const parentMutualDocument = this.getParent(document);
    const parentOriginalDocument = this.getParent(parentMutualDocument);
    const createDocumentPayload: CreateDocumentPayload = {
      sourceDocumentId: parentOriginalDocument.id, typology: TypologyEnum.TOVIEW
    };
    // We delete the child of the mutual, if it's not involved in another step c.f softDeleteDocument
    return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).pipe(debounceTime(300))
      .subscribe(() => {
        // We First create the future child of the original
        this.documentService.createDocument(sessionId, stepId, createDocumentPayload).subscribe((newDocument: DocumentData) => {
          const index = this.getAll().appendixToSee.findIndex((documentToDelete: DocumentWithDetails) => documentToDelete.id === document.id);
          this.getAll().appendixToSee[index] = {...newDocument, displayArrow: true, parentIsMutual: false};
          this.setLoadingAppendix(false);
          cb();
        }, (error: ApiError) => {
          this.alertService.errorApi(error);
          this.setLoadingAppendix(false);
          cb();
        });
      }, (error: ApiError) => {
        this.alertService.errorApi(error);
        this.setLoadingAppendix(false);
        this.setHasFailed(true);
        cb();
      });
  }

  deleteSimpleDocuments(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingToSign(true);
    return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).subscribe(() => {
      this.getAll().documents = this.deleteDocumentById<DocumentData>(this.getAll().documents, document);
      this.getAll().documentToSign = this.deleteDocumentById<DocumentWithDetails>(this.getAll().documentToSign, document);
      this.setLoadingToSign(false);
      cb();
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      this.setLoadingToSign(false);
      cb();
    });
  }

  deleteSimpleAppendix(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    this.setLoadingAppendix(true);
    return this.documentService.softDeleteDocument(document.sourceDocumentId, sessionId, stepId).subscribe(() => {
      this.getAll().documents = this.deleteDocumentById<DocumentData>(this.getAll().documents, document);
      this.getAll().appendixToSee = this.deleteDocumentById<DocumentWithDetails>(this.getAll().appendixToSee, document);
      this.setLoadingAppendix(false);
      cb();
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
      this.setLoadingAppendix(false);
      cb();
    });
  }

  deleteDocument(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    if (!this.isParentMutual(this.getAll().documents, document)) {
      return this.deleteSimpleDocuments(sessionId, stepId, document, cb);
    } else if (this.isParentMutualAndOnCurrentStep(this.getAll().documents, document, stepId)) {
      const mutual = this.getParent(document);
      return this.deleteMutualDocumentParentInCurrentStep(sessionId, stepId, mutual, cb);
    } else {
      return this.deleteMutualDocumentParentInAnotherStep(sessionId, stepId, document, cb);
    }
  }

  deleteAppendix(sessionId: number, stepId: number, document: DocumentData, cb: Function) {
    if (!this.isParentMutual(this.getAll().documents, document)) {
      return this.deleteSimpleAppendix(sessionId, stepId, document, cb);
    } else if (this.isParentMutualAndOnCurrentStep(this.getAll().documents, document, stepId)) {
      const mutual = this.getParent(document);
      return this.deleteMutualAppendixParentInCurrentStep(sessionId, stepId, mutual, cb);
    } else {
      return this.deleteMutualAppendixParentInAnotherStep(sessionId, stepId, document, cb);
    }
  }

  clone(sessionId: number, stepId: number, data: { files: FileList, document: DocumentData }, type: 'document' | 'appendix', cb: Function) {
    const fileArray = FilesUtils.convertFileListToFileArray(data.files);
    fileArray.forEach(file => {
      let id = (type === 'document' ? 'd_' : 'a_') + Math.random().toString(36).substr(2, 16) + ';name;' + file.name;
      this.initProgress(id);
      this.uploadDocumentRequest.set(id, this.documentService.cloneUploadDocument(data.document.id, sessionId, stepId, file, 'events', true).pipe(
        takeUntil(this.cancelRequestSubject),
        tap((event: HttpEvent<string>) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.reportProgress(id, Math.round(100 * event.loaded / event.total));
          } else if (event instanceof HttpResponse) {
            this.alertService.success('DOCUMENT.REUPLOAD_SUCCESS');
            this.clearProgress(id);
          }
        }, (error: ApiError) => {
          this.alertService.error('DOCUMENT.REUPLOAD_FAILED');
          this.clearProgress(id);
        }
      )));
    });
    forkJoin(...this.uploadDocumentRequest.values()).subscribe(() => {
      cb();
    });
  }

  reuploadDocument(sessionId: number, stepId: number, data: { document: DocumentData, file: File }, cb: Function) {
    const id = data.document.id.toString();
    this.initProgress(id);
    this.uploadDocumentRequest.set(id, this.documentService.reuploadDocument(data.document.id, sessionId, stepId, data.file, 'events', true).pipe(
      takeUntil(this.cancelRequestSubject),
      tap((event: HttpEvent<string>) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.reportProgress(id, Math.round(100 * event.loaded / event.total));
        } else if (event instanceof HttpResponse) {
          this.alertService.success('DOCUMENT.REUPLOAD_SUCCESS');
          this.clearProgress(id);
        }
      }, (error: ApiError) => {
        this.alertService.error('DOCUMENT.REUPLOAD_FAILED');
        this.clearProgress(id);
      })));
    forkJoin([this.uploadDocumentRequest.get(id)]).subscribe(() => {
      cb();
    });
  }

  updateDocument(sessionId: number, stepId: number, documentId, updateDocumentPayload: UpdateDocumentPayload) {
    return this.documentService.partialUpdateDocument(documentId, sessionId, stepId, updateDocumentPayload).subscribe((newDocument: DocumentData) => {
        const index = this.getAll().documentToSign.findIndex((document: DocumentWithDetails) => document.id === newDocument.id);
        this.getAll().documentToSign[index] = {...this.getAll().documentToSign[index], ...newDocument};
      }, (error: ApiError) => this.alertService.errorApi(error));
  }

  updateDocumentName(sessionId: number, stepId: number, documentId, updateDocumentPayload: UpdateDocumentPayload) {
    return this.documentService.partialUpdateDocument(documentId, sessionId, stepId, updateDocumentPayload).subscribe(() => {
      this.getAllDocuments(sessionId, stepId);
    }, (error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  onFailed() {
    this.setLoadingToSign(false);
    this.setHasFailed(false);
  }

  getParent(document: DocumentData) {
    return this.getAll().documents.find((potentialParent: DocumentData) => potentialParent.id === document.sourceDocumentId);
  }

  getChildren(document: DocumentData) {
    return this.getAll().documents.find((potentialChild: DocumentData) => potentialChild.sourceDocumentId === document.id);
  }

  isMutualWithNoChild(documents: DocumentData[], document: DocumentData) {
    return document.typology === TypologyEnum.MUTUAL && !documents.find(
      (potentialChild: DocumentData) => potentialChild.sourceDocumentId === document.id);
  }

  isDocumentToSign(document: DocumentData): boolean {
    return document.typology === TypologyEnum.SPROFILE;
  }

  isDocumentToSee(document: DocumentData): boolean {
    return document.typology === TypologyEnum.TOVIEW;
  }

  isParentMutual(documents: DocumentData[], document: DocumentData): boolean {
    const parent = documents.find((potentialParent: DocumentData) => potentialParent.id === document.sourceDocumentId);
    return parent && parent.typology === TypologyEnum.MUTUAL;
  }

  isParentMutualAndOnCurrentStep(documents: DocumentData[], document: DocumentData, stepId: number): boolean {
    const parent = documents.find((potentialParent: DocumentData) => potentialParent.id === document.sourceDocumentId);
    return parent && parent.typology === TypologyEnum.MUTUAL && parent.stepId === stepId;

  }

  isStillUploading() {
    return this.uploadDocumentRequest.size > 0;
  }

  private setHasFailed(value: boolean) {
    this.store({
      ...this.getAll(), hasFailed: value
    });
  }

  private setLoadingToSign(value: boolean) {
    this.store({
      ...this.getAll(), isLoadingToSign: value
    });
  }

  private setLoadingAppendix(value: boolean) {
    this.store({
      ...this.getAll(), isLoadingAppendix: value
    });
  }

  private setLoadingMutual(value: boolean) {
    this.store({
      ...this.getAll(), isLoadingMutual: value
    });
  }

  private addDocumentToStore(document?: DocumentData, documentWithDetails?: DocumentWithDetails) {
    this.store({
      ...this.getAll(),
      documents: document ? [document, ...this.getAll().documents] : this.getAll().documents,
      documentToSign: documentWithDetails ? [documentWithDetails, ...this.getAll().documentToSign] : this.getAll().documentToSign,
    });
  }

  private addAppendixToStore(document?: DocumentData, documentWithDetails?: DocumentWithDetails) {
    this.store({
      ...this.getAll(),
      documents: document ? [document, ...this.getAll().documents] : this.getAll().documents,
      appendixToSee: documentWithDetails ? [documentWithDetails, ...this.getAll().appendixToSee] : this.getAll().appendixToSee,
    });
  }

  private addMutualToStore(document?: DocumentData, documentWithDetails?: DocumentWithDetails) {
    this.store({
      ...this.getAll(),
      documents: document ? [document, ...this.getAll().documents] : this.getAll().documents,
      documentMutual: documentWithDetails ? [documentWithDetails, ...this.getAll().documentMutual] : this.getAll().documentMutual,
    });
  }

  initProgress(id: string) {
    this.uploadProgress.set(id, 0);
    this.documentIdsUploading.push(id);
  }

  reportProgress(id: string, progress: number) {
    this.uploadProgress.set(id, progress);
  }

  clearProgress(id: string) {
    this.uploadDocumentRequest.delete(id);
    this.uploadProgress.delete(id);
    this.documentIdsUploading = this.documentIdsUploading.filter(idu => idu !== id);
  }

  private deleteDocumentById<T>(documents: T[], documentToDelete: DocumentData): T[] {
    documents.splice(documents.findIndex((document: any) => {
      return document.id === documentToDelete.id;
    }), 1);

    return documents;
  }

  private filterDocuments(documents: DocumentData[], stepId: number) {
    const documentToSign: DocumentWithDetails[] = [];
    const appendixToSee: DocumentWithDetails[] = [];
    const documentMutual: DocumentWithDetails[] = [];

    documents.forEach((document: DocumentData) => {
      const toSign = this.isDocumentToSign(document);
      const toSee = this.isDocumentToSee(document);
      const parentMutual = this.isParentMutual(documents, document);
      if (toSign && !parentMutual) {
        documentToSign.push({...document, displayArrow: true});
      } else if (toSee && !parentMutual) {
        appendixToSee.push({...document, displayArrow: true});
      } else if (toSign && parentMutual) {
        documentToSign.push({
          ...document,
          parentIsMutual: true,
          parentMutualOnCurrentStep: this.isParentMutualAndOnCurrentStep(documents, document, stepId),
          displayArrow: true
        });
      } else if (toSee && parentMutual) {
        appendixToSee.push({
          ...document,
          parentIsMutual: true,
          parentMutualOnCurrentStep: this.isParentMutualAndOnCurrentStep(documents, document, stepId),
          displayArrow: true
        });
      } else if (this.isMutualWithNoChild(documents, document)) {
        documentMutual.push({
          ...document, isMutualOnCurrentStep: document.stepId === stepId
        });
      }
    });
    this.store({
      documents: documents,
      documentToSign: documentToSign,
      appendixToSee: appendixToSee,
      documentMutual: documentMutual
    });
  }
}
