<div class="modal-header">
  <div class="modal-title">{{'MODAL_IMPORT_USER.TITLE'| translate}}</div>
  <button (click)="activeModal.close()"
          aria-label="Close"
          class="close"
          type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div class="row">
    <app-autocomplete [disabled]="directories?.length === 0"
                      [labelProvider]="valueProvider"
                      [valueProvider]="valueProvider"
                      [placeholder]="'MODAL_IMPORT_USER.SELECT_DIRECTORY' | translate"
                      [models]="directories"
                      [(ngModel)]="selectedDirectory"
                      class="col">
    </app-autocomplete>
  </div>

  <div>
    <input (change)="saveFile($event.target.files)"
           [accept]="allowedMimeTypes"
           id="select-file"
           [multiple]="false"
           class="input-file"
           type="file"
           style="display: none;"/>
    <label for="select-file" class="btn btn-primary btn-block">
      {{'MODAL_IMPORT_USER.SELECT_FILE' | translate}}
    </label>
  </div>
  <div *ngIf="file">
    {{'MODAL_IMPORT_USER.SELECTED_FILE' | translate}} : {{file.name}}
  </div>
  <div *ngIf="!file">
    {{'MODAL_IMPORT_USER.NO_SELECTED_FILE' | translate}}
  </div>
</div>

<div class="modal-footer">
  <button (click)="activeModal.close()" class="btn btn-light mr-1" type="button">
    {{'MODAL_IMPORT_USER.CANCEL' | translate}}
  </button>
  <button id="import-button-id" (click)="activeModal.close({file: file, directory: selectedDirectory})" class="btn btn-primary mr-1" type="button" [disabled]="!file || !selectedDirectory">
    {{'MODAL_IMPORT_USER.ACTION' | translate}}
  </button>
</div>
