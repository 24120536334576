import { DocumentService, TenantService } from "../../../../luxtrust-cosi-api";
import { Subject } from "rxjs";
import { AlertService } from "../../services/services/alert-service";
import { DocumentationTypeEnum } from "./documentation-type.enum";
import { SESSION_STORAGE_SYSTEM_DOC_KEY } from 'src/app/app.constant';
import * as i0 from "@angular/core";
import * as i1 from "../../../../luxtrust-cosi-api/api/document.service";
import * as i2 from "../../services/services/alert-service";
import * as i3 from "../../../../luxtrust-cosi-api/api/tenant.service";
export class DocumentationService {
    constructor(documentService, alertService, tenantService) {
        this.documentService = documentService;
        this.alertService = alertService;
        this.tenantService = tenantService;
        this.sessionSystemDocumentListResult = [];
        this.sessionSystemDocumentListSubject = new Subject();
    }
    buildSystemDocumentation() {
        this.sessionSystemDocumentListResult = [];
        this.tenantService.getSystemDocumentList().subscribe(systemDocuments => {
            this.sessionSystemDocumentListResult = systemDocuments.sort((a, b) => this.setOrder(a) - this.setOrder(b));
            sessionStorage.setItem(SESSION_STORAGE_SYSTEM_DOC_KEY, JSON.stringify(this.sessionSystemDocumentListResult));
            this.sessionSystemDocumentListSubject.next(this.sessionSystemDocumentListResult);
        }, (error) => {
            this.alertService.errorApi(error);
            this.sessionSystemDocumentListSubject.next([]);
        });
    }
    /**
     * set Order
     * @param sessionDoc
     */
    setOrder(sessionDoc) {
        let indexOrder = 0;
        const systemDocumentTypeTag = sessionDoc.type;
        if (systemDocumentTypeTag) {
            switch (systemDocumentTypeTag.toUpperCase()) {
                case DocumentationTypeEnum.GDPR:
                    indexOrder = 0;
                    break;
                case DocumentationTypeEnum.HELP:
                    indexOrder = 1;
                    break;
                case DocumentationTypeEnum.OTHER:
                    indexOrder = 2;
                    break;
                case DocumentationTypeEnum.FOOTER:
                    indexOrder = 3;
                    break;
                case DocumentationTypeEnum.PURGED:
                    indexOrder = 4;
                    break;
                default:
                    console.log("case no yet implemented");
                    break;
            }
        }
        return indexOrder;
    }
}
DocumentationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentationService_Factory() { return new DocumentationService(i0.ɵɵinject(i1.DocumentService), i0.ɵɵinject(i2.AlertService), i0.ɵɵinject(i3.TenantService)); }, token: DocumentationService, providedIn: "root" });
