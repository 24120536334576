<div [ngClass]="{'borderBottom': borderBottom, 'row pad-lr-0 mrg-lr-0': (canAdd || canReplace) && isConfigurator}" class='items-table-container'>
  <div [ngClass]="{'col-11 pad-lr-0': (canAdd || canReplace) && isConfigurator && enableInviteNewSigner , 'form-group' : !isConfigurator,
                  'w-100': ((!canAdd && !canReplace) || !enableInviteNewSigner) && isConfigurator}">
    <ng-content select="[action]"></ng-content>
  </div>

  <div class="icon-add col-1" *ngIf="(canAdd || canReplace) && isConfigurator && enableInviteNewSigner" [ngClass]="{'form-group': !isConfigurator}">
    <i class="fa fa-user-plus" tooltipClass="custom-tooltip" container="body"
    [ngbTooltip]=" (enableInviteNewWatcher ? 'NEW_SESSION.WATCHERS.ADD' :'NEW_SESSION.SIGNERS.NEW_SIGNERS.ADD') | translate" (click)="onOpenModal()"></i>
  </div>

  <div *ngIf="items?.length > 0; else empty" [class.single-list]="singleList" class="table">
    <div *ngFor="let item of items" [class.single-list-item]="singleList" class="item">
      <ng-container *ngTemplateOutlet="itemsTemplate, context: { $implicit: item }"></ng-container>
    </div>
  </div>

  <ng-template #empty>
    <div class="empty-table w-100">
      {{noResult}}
    </div>
  </ng-template>
</div>
