import { Component, Input, OnInit } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Report_type } from './report-type.enum';

@Component({
  selector: 'app-validation-report',
  templateUrl: './validation-report.component.html',
  styleUrls: ['./validation-report.component.scss']
})
export class ValidationReportComponent implements OnInit {

  constructor() { }

  @Input()
  validationReport: ValidationReport;

  @Input()
  fileName: string;

  reportType = Report_type;

  ngOnInit() {


  }


}
