/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateEnduserPayload } from '../model/createEnduserPayload';
import { EnduserData } from '../model/enduserData';
import { LinkEnduserPayload } from '../model/linkEnduserPayload';
import { NotificationData } from '../model/notificationData';
import { UpdateEnduserAdminPayload } from '../model/updateEnduserAdminPayload';
import { UpdateEnduserPayload } from '../model/updateEnduserPayload';
import { VisualIdentityData } from '../model/visualIdentityData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EnduserService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create enduser
     * 
     * @param payload Enduser to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEnduser(payload?: CreateEnduserPayload, observe?: 'body', reportProgress?: boolean): Observable<EnduserData>;
    public createEnduser(payload?: CreateEnduserPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnduserData>>;
    public createEnduser(payload?: CreateEnduserPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnduserData>>;
    public createEnduser(payload?: CreateEnduserPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EnduserData>(`${this.basePath}/api/endusers`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete enduser
     * 
     * @param enduserId Enduser
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEnduser(enduserId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteEnduser(enduserId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteEnduser(enduserId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteEnduser(enduserId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling deleteEnduser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/endusers/${encodeURIComponent(String(enduserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete enduser as admin
     * 
     * @param enduserId Enduser
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEnduserAdmin(enduserId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteEnduserAdmin(enduserId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteEnduserAdmin(enduserId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteEnduserAdmin(enduserId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling deleteEnduserAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/endusers/admin/${encodeURIComponent(String(enduserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get enduser as admin
     * 
     * @param enduserId Enduser
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminEnduser(enduserId: string, observe?: 'body', reportProgress?: boolean): Observable<EnduserData>;
    public getAdminEnduser(enduserId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnduserData>>;
    public getAdminEnduser(enduserId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnduserData>>;
    public getAdminEnduser(enduserId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling getAdminEnduser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EnduserData>(`${this.basePath}/api/endusers/admin/${encodeURIComponent(String(enduserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get current enduser
     * 
     * @param timeZone browser time zone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentEnduser(timeZone?: string, observe?: 'body', reportProgress?: boolean): Observable<EnduserData>;
    public getCurrentEnduser(timeZone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnduserData>>;
    public getCurrentEnduser(timeZone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnduserData>>;
    public getCurrentEnduser(timeZone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EnduserData>(`${this.basePath}/api/endusers/current`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get current enduser notifications
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentEnduserNotifications(observe?: 'body', reportProgress?: boolean): Observable<Array<NotificationData>>;
    public getCurrentEnduserNotifications(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotificationData>>>;
    public getCurrentEnduserNotifications(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotificationData>>>;
    public getCurrentEnduserNotifications(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NotificationData>>(`${this.basePath}/api/endusers/current/notifications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get current enduser visual parameters
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentEnduserVi(observe?: 'body', reportProgress?: boolean): Observable<VisualIdentityData>;
    public getCurrentEnduserVi(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisualIdentityData>>;
    public getCurrentEnduserVi(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisualIdentityData>>;
    public getCurrentEnduserVi(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<VisualIdentityData>(`${this.basePath}/api/endusers/current/visual-identity`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get enduser
     * 
     * @param enduserId Enduser
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEnduser(enduserId: string, observe?: 'body', reportProgress?: boolean): Observable<EnduserData>;
    public getEnduser(enduserId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnduserData>>;
    public getEnduser(enduserId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnduserData>>;
    public getEnduser(enduserId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling getEnduser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EnduserData>(`${this.basePath}/api/endusers/${encodeURIComponent(String(enduserId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update enduser as admin
     * 
     * @param enduserId Enduser
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public linkAcrossTenant(enduserId: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public linkAcrossTenant(enduserId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public linkAcrossTenant(enduserId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public linkAcrossTenant(enduserId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling linkAcrossTenant.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<number>(`${this.basePath}/api/endusers/admin/${encodeURIComponent(String(enduserId))}/link`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Link a user to another platform
     * 
     * @param authenticated 
     * @param authorities0Authority 
     * @param credentials 
     * @param details 
     * @param linkEnduserPayload Obtained account to link
     * @param principal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public linkUserPlatform(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, linkEnduserPayload?: LinkEnduserPayload, principal?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public linkUserPlatform(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, linkEnduserPayload?: LinkEnduserPayload, principal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public linkUserPlatform(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, linkEnduserPayload?: LinkEnduserPayload, principal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public linkUserPlatform(authenticated?: boolean, authorities0Authority?: string, credentials?: string, details?: string, linkEnduserPayload?: LinkEnduserPayload, principal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (authenticated !== undefined && authenticated !== null) {
            queryParameters = queryParameters.set('authenticated', <any>authenticated);
        }
        if (authorities0Authority !== undefined && authorities0Authority !== null) {
            queryParameters = queryParameters.set('authorities[0].authority', <any>authorities0Authority);
        }
        if (credentials !== undefined && credentials !== null) {
            queryParameters = queryParameters.set('credentials', <any>credentials);
        }
        if (details !== undefined && details !== null) {
            queryParameters = queryParameters.set('details', <any>details);
        }
        if (principal !== undefined && principal !== null) {
            queryParameters = queryParameters.set('principal', <any>principal);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/endusers/current/link`,
            linkEnduserPayload,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial update enduser
     * 
     * @param enduserId Enduser
     * @param payload Enduser to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateEnduser(enduserId: string, payload?: UpdateEnduserPayload, observe?: 'body', reportProgress?: boolean): Observable<EnduserData>;
    public partialUpdateEnduser(enduserId: string, payload?: UpdateEnduserPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnduserData>>;
    public partialUpdateEnduser(enduserId: string, payload?: UpdateEnduserPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnduserData>>;
    public partialUpdateEnduser(enduserId: string, payload?: UpdateEnduserPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling partialUpdateEnduser.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<EnduserData>(`${this.basePath}/api/endusers/${encodeURIComponent(String(enduserId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update enduser as admin
     * 
     * @param enduserId Enduser
     * @param payload Enduser to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialUpdateEnduserAdmin(enduserId: string, payload?: UpdateEnduserAdminPayload, observe?: 'body', reportProgress?: boolean): Observable<EnduserData>;
    public partialUpdateEnduserAdmin(enduserId: string, payload?: UpdateEnduserAdminPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnduserData>>;
    public partialUpdateEnduserAdmin(enduserId: string, payload?: UpdateEnduserAdminPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnduserData>>;
    public partialUpdateEnduserAdmin(enduserId: string, payload?: UpdateEnduserAdminPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling partialUpdateEnduserAdmin.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<EnduserData>(`${this.basePath}/api/endusers/admin/${encodeURIComponent(String(enduserId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove current enduser notification
     * 
     * @param notificationId notificationId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeCurrentEnduserNotification(notificationId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeCurrentEnduserNotification(notificationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeCurrentEnduserNotification(notificationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeCurrentEnduserNotification(notificationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notificationId === null || notificationId === undefined) {
            throw new Error('Required parameter notificationId was null or undefined when calling removeCurrentEnduserNotification.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/endusers/current/notifications/${encodeURIComponent(String(notificationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search endusers matching the filter
     * 
     * @param directoryAlias Directory alias. If null the value is set to that in the requester context
     * @param like Indicate if a &#39;like&#39; search style should be used
     * @param limit Number of results
     * @param noMerge Do not apply enduser merge
     * @param offset Last enduser id
     * @param organisationId Organisation
     * @param showNonModifiableCircles Show non modifiable circle
     * @param showNonReentrantCircles Show reentrant circle
     * @param showOnlyCircles Circles only
     * @param stepId Step in context of which the search is carried out
     * @param tenantId Tenant id. Auto-filled using the requester context
     * @param text Content to be filtered
     * @param userIdList User external id list
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchEnduser(directoryAlias?: string, like?: boolean, limit?: number, noMerge?: boolean, offset?: number, organisationId?: number, showNonModifiableCircles?: boolean, showNonReentrantCircles?: boolean, showOnlyCircles?: boolean, stepId?: number, tenantId?: number, text?: string, userIdList?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<EnduserData>>;
    public searchEnduser(directoryAlias?: string, like?: boolean, limit?: number, noMerge?: boolean, offset?: number, organisationId?: number, showNonModifiableCircles?: boolean, showNonReentrantCircles?: boolean, showOnlyCircles?: boolean, stepId?: number, tenantId?: number, text?: string, userIdList?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EnduserData>>>;
    public searchEnduser(directoryAlias?: string, like?: boolean, limit?: number, noMerge?: boolean, offset?: number, organisationId?: number, showNonModifiableCircles?: boolean, showNonReentrantCircles?: boolean, showOnlyCircles?: boolean, stepId?: number, tenantId?: number, text?: string, userIdList?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EnduserData>>>;
    public searchEnduser(directoryAlias?: string, like?: boolean, limit?: number, noMerge?: boolean, offset?: number, organisationId?: number, showNonModifiableCircles?: boolean, showNonReentrantCircles?: boolean, showOnlyCircles?: boolean, stepId?: number, tenantId?: number, text?: string, userIdList?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {














        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (directoryAlias !== undefined && directoryAlias !== null) {
            queryParameters = queryParameters.set('directoryAlias', <any>directoryAlias);
        }
        if (like !== undefined && like !== null) {
            queryParameters = queryParameters.set('like', <any>like);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (noMerge !== undefined && noMerge !== null) {
            queryParameters = queryParameters.set('noMerge', <any>noMerge);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (organisationId !== undefined && organisationId !== null) {
            queryParameters = queryParameters.set('organisationId', <any>organisationId);
        }
        if (showNonModifiableCircles !== undefined && showNonModifiableCircles !== null) {
            queryParameters = queryParameters.set('showNonModifiableCircles', <any>showNonModifiableCircles);
        }
        if (showNonReentrantCircles !== undefined && showNonReentrantCircles !== null) {
            queryParameters = queryParameters.set('showNonReentrantCircles', <any>showNonReentrantCircles);
        }
        if (showOnlyCircles !== undefined && showOnlyCircles !== null) {
            queryParameters = queryParameters.set('showOnlyCircles', <any>showOnlyCircles);
        }
        if (stepId !== undefined && stepId !== null) {
            queryParameters = queryParameters.set('stepId', <any>stepId);
        }
        if (tenantId !== undefined && tenantId !== null) {
            queryParameters = queryParameters.set('tenantId', <any>tenantId);
        }
        if (text !== undefined && text !== null) {
            queryParameters = queryParameters.set('text', <any>text);
        }
        if (userIdList) {
            userIdList.forEach((element) => {
                queryParameters = queryParameters.append('userIdList', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EnduserData>>(`${this.basePath}/api/endusers/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search endusers matching the filter
     * 
     * @param directoryAlias Directory alias. If null the value is set to that in the requester context
     * @param like Indicate if a &#39;like&#39; search style should be used
     * @param limit Number of results
     * @param noMerge Do not apply enduser merge
     * @param offset Last enduser id
     * @param organisationId Organisation
     * @param showNonModifiableCircles Show non modifiable circle
     * @param showNonReentrantCircles Show reentrant circle
     * @param showOnlyCircles Circles only
     * @param stepId Step in context of which the search is carried out
     * @param tenantId Tenant id. Auto-filled using the requester context
     * @param text Content to be filtered
     * @param userIdList User external id list
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchEnduser1(directoryAlias?: string, like?: boolean, limit?: number, noMerge?: boolean, offset?: number, organisationId?: number, showNonModifiableCircles?: boolean, showNonReentrantCircles?: boolean, showOnlyCircles?: boolean, stepId?: number, tenantId?: number, text?: string, userIdList?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<EnduserData>>;
    public searchEnduser1(directoryAlias?: string, like?: boolean, limit?: number, noMerge?: boolean, offset?: number, organisationId?: number, showNonModifiableCircles?: boolean, showNonReentrantCircles?: boolean, showOnlyCircles?: boolean, stepId?: number, tenantId?: number, text?: string, userIdList?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EnduserData>>>;
    public searchEnduser1(directoryAlias?: string, like?: boolean, limit?: number, noMerge?: boolean, offset?: number, organisationId?: number, showNonModifiableCircles?: boolean, showNonReentrantCircles?: boolean, showOnlyCircles?: boolean, stepId?: number, tenantId?: number, text?: string, userIdList?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EnduserData>>>;
    public searchEnduser1(directoryAlias?: string, like?: boolean, limit?: number, noMerge?: boolean, offset?: number, organisationId?: number, showNonModifiableCircles?: boolean, showNonReentrantCircles?: boolean, showOnlyCircles?: boolean, stepId?: number, tenantId?: number, text?: string, userIdList?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {














        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (directoryAlias !== undefined && directoryAlias !== null) {
            queryParameters = queryParameters.set('directoryAlias', <any>directoryAlias);
        }
        if (like !== undefined && like !== null) {
            queryParameters = queryParameters.set('like', <any>like);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (noMerge !== undefined && noMerge !== null) {
            queryParameters = queryParameters.set('noMerge', <any>noMerge);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (organisationId !== undefined && organisationId !== null) {
            queryParameters = queryParameters.set('organisationId', <any>organisationId);
        }
        if (showNonModifiableCircles !== undefined && showNonModifiableCircles !== null) {
            queryParameters = queryParameters.set('showNonModifiableCircles', <any>showNonModifiableCircles);
        }
        if (showNonReentrantCircles !== undefined && showNonReentrantCircles !== null) {
            queryParameters = queryParameters.set('showNonReentrantCircles', <any>showNonReentrantCircles);
        }
        if (showOnlyCircles !== undefined && showOnlyCircles !== null) {
            queryParameters = queryParameters.set('showOnlyCircles', <any>showOnlyCircles);
        }
        if (stepId !== undefined && stepId !== null) {
            queryParameters = queryParameters.set('stepId', <any>stepId);
        }
        if (tenantId !== undefined && tenantId !== null) {
            queryParameters = queryParameters.set('tenantId', <any>tenantId);
        }
        if (text !== undefined && text !== null) {
            queryParameters = queryParameters.set('text', <any>text);
        }
        if (userIdList) {
            userIdList.forEach((element) => {
                queryParameters = queryParameters.append('userIdList', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<EnduserData>>(`${this.basePath}/api/endusers/search`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update enduser
     * 
     * @param enduserId Enduser
     * @param payload Enduser to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEnduser(enduserId: string, payload?: UpdateEnduserPayload, observe?: 'body', reportProgress?: boolean): Observable<EnduserData>;
    public updateEnduser(enduserId: string, payload?: UpdateEnduserPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnduserData>>;
    public updateEnduser(enduserId: string, payload?: UpdateEnduserPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnduserData>>;
    public updateEnduser(enduserId: string, payload?: UpdateEnduserPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling updateEnduser.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<EnduserData>(`${this.basePath}/api/endusers/${encodeURIComponent(String(enduserId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update enduser as admin
     * 
     * @param enduserId Enduser
     * @param payload Enduser to update (null values ignored)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEnduserAdmin(enduserId: string, payload?: UpdateEnduserAdminPayload, observe?: 'body', reportProgress?: boolean): Observable<EnduserData>;
    public updateEnduserAdmin(enduserId: string, payload?: UpdateEnduserAdminPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnduserData>>;
    public updateEnduserAdmin(enduserId: string, payload?: UpdateEnduserAdminPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnduserData>>;
    public updateEnduserAdmin(enduserId: string, payload?: UpdateEnduserAdminPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling updateEnduserAdmin.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<EnduserData>(`${this.basePath}/api/endusers/admin/${encodeURIComponent(String(enduserId))}`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
