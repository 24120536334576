<form [formGroup]="newSignerForm" (ngSubmit)="onSubmit()">

  <div class="modal-header">
    <div class="modal-title">{{title| translate}}</div>
    <button (click)="activeModal.close('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <!-- New signer first name -->
    <div class="form-row">
      <app-input-text (focusout)="markAsTouched('newSignerFirstName')" [errors]="{
                    required: 'NEW_SESSION.SIGNERS.NEW_SIGNERS.FIRST_NAME.REQUIRED' | translate
                  }" [hasOnInput]="true" [id]="'input-new-signer-first-name'"
                      [idPostfix]="'nameLabel'"
                      [label]="'NEW_SESSION.SIGNERS.NEW_SIGNERS.FIRST_NAME.LABEL' | translate"
                      autoFocus
                      [placeholder]="'NEW_SESSION.SIGNERS.NEW_SIGNERS.FIRST_NAME.PLACEHOLDER' | translate" class="col"
                      formControlName="newSignerFirstName" icon="user" inputTextPosition="right"
                      type="text"></app-input-text>
    </div>

    <!-- New signer last name -->
    <div class="form-row">
      <app-input-text (focusout)="markAsTouched('newSignerLastName')" [errors]="{
                    required: 'NEW_SESSION.SIGNERS.NEW_SIGNERS.LAST_NAME.REQUIRED' | translate
                  }" [hasOnInput]="true" [id]="'input-new-signer-last-name'"
                      [idPostfix]="'lastNameLabel'"
                      [label]="'NEW_SESSION.SIGNERS.NEW_SIGNERS.LAST_NAME.LABEL' | translate"
                      [placeholder]="'NEW_SESSION.SIGNERS.NEW_SIGNERS.LAST_NAME.PLACEHOLDER' | translate" class="col"
                      formControlName="newSignerLastName" icon="user" inputTextPosition="right"
                      type="text"></app-input-text>
    </div>

    <!-- New signer email -->
    <div class="form-row">
      <app-input-text (focusout)="markAsTouched('newSignerEmail')" [errors]="{
                              required: 'NEW_SESSION.SIGNERS.NEW_SIGNERS.EMAIL.REQUIRED' | translate,
                              email:'NEW_SESSION.SIGNERS.NEW_SIGNERS.EMAIL.INVALID' | translate
                            }" [hasOnInput]="true" [id]="'input-new-signer-email'"
                      [idPostfix]="'emailLabel'"
                      [label]="'NEW_SESSION.SIGNERS.NEW_SIGNERS.EMAIL.LABEL' | translate"
                      [placeholder]="'NEW_SESSION.SIGNERS.NEW_SIGNERS.EMAIL.PLACEHOLDER' | translate" class="col"
                      formControlName="newSignerEmail" icon="envelope" inputTextPosition="right"
                      type="email"></app-input-text>
    </div>

    <!-- New Signer Phone -->
    <div class="form-row">
      <app-input-text (focusout)="markAsTouched('newSignerPhone')" [errors]="{
                                required: 'NEW_SESSION.SIGNERS.NEW_SIGNERS.TELEPHONE.REQUIRED' | translate,
                                pattern: 'NEW_SESSION.SIGNERS.NEW_SIGNERS.TELEPHONE.FORMAT' | translate,
                                minlength: 'NEW_SESSION.SIGNERS.NEW_SIGNERS.TELEPHONE.FORMAT' | translate,
                                maxlength: 'NEW_SESSION.SIGNERS.NEW_SIGNERS.TELEPHONE.FORMAT' | translate
                              }" [hasOnInput]="true" [id]="'input-new-signer-phone'"
                      [idPostfix]="'phoneLabel'"
                      [label]="'NEW_SESSION.SIGNERS.NEW_SIGNERS.TELEPHONE.LABEL' | translate"
                      [placeholder]="'NEW_SESSION.SIGNERS.NEW_SIGNERS.TELEPHONE.PLACEHOLDER' | translate" class="col"
                      formControlName="newSignerPhone" icon="phone" inputTextPosition="right"
                      type="text"></app-input-text>
    </div>

    <!-- New signer Directory List-->
    <div class="form-row" *ngIf="userDirectories?.length > 1 && directoryProvider">
      <app-autocomplete-custom [placeholder]="'NEW_SESSION.SIGNERS.NEW_SIGNERS.DIRECTORIES.PLACEHOLDER' | translate"
                               [datasProviders]="directoryProvider"
                               class="col"
                               sortAsc="false"
                               icon="address-book"
                               formControlName="newSignerDirectory">
      </app-autocomplete-custom>

    </div>


    <!-- New signer organisation-->
    <div class="form-row pt-4" *ngIf="!useOrgnisationSigner && organisationProvider">

      <app-autocomplete-custom [placeholder]="'NEW_SESSION.SIGNERS.NEW_SIGNERS.ORGANISATION.PLACEHOLDER' | translate"
                               [datasProviders]="organisationProvider"
                               [newValue]="valueOrgaSelected"
                               class="col"
                               icon="image"
                               formControlName="newSignerOrganisation">
      </app-autocomplete-custom>

    </div>

    <div class="pull-right">
      <label class="control-label col-xs-4 required">
        {{'NEW_SESSION.SIGNERS.NEW_SIGNERS.ORGANISATION_INVITE' | translate}}
      </label>
      <div class="switcher switcher-right">
        <label for="sessionEditToggle"></label>
        <input (click)="changeOrganisation()" [checked]="useOrgnisationSigner" class="switcher-checkbox"
               id="sessionEditToggle" type="checkbox">
        <div class="switcher-toggle"></div>
      </div>
    </div>
  </div>


  <div class="modal-footer">
    <button [disabled]="newSignerForm.invalid " class="btn btn-primary" type="submit" id="send-invite">
      {{'NEW_SESSION.SIGNERS.NEW_SIGNERS.SEND' | translate}}
    </button>
  </div>
</form>
