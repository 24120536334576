import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';
import {OrelyService} from '../../../services/services/orely-service';

@Component({
  templateUrl: './modal-orely.component.html'
})
export class ModalOrelyComponent implements OnInit, OnDestroy {

  signatureId: string;
  sprofile: string;
  @ViewChild('signaturePdfIframe', {
    read: ElementRef, static: false
  }) iframe: ElementRef;
  allowIFrame = false;
  private subscriptions: Subscription[] = [];

  constructor(public activeModal: NgbActiveModal,
              private appService: AppService,
              private orelyService: OrelyService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.orelyService.sign(this.signatureId, this.appService.getCurrentUserId()).pipe(take(1)).subscribe(c => {
      const doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
      doc.open();
      doc.write(c);
      doc.close();
      this.allowIFrame = true;
    });
  }

  ngOnDestroy() {
    this.allowIFrame = false;
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  @HostListener('window:message', ['$event']) iFrameEventListener(event: MessageEvent) {
    const data = event.data;
    this.allowIFrame = false;
    if (data) {
      if (data.status === 'SUCCEEDED') {
        this.activeModal.close({status : data.status, message : 'ALERT.SIGNATURE_SUCCESS'});
      } else if (data.status === 'CANCELLED') {
        this.activeModal.close({status : data.status, message : 'ALERT.SIGNATURE_CANCELLED'});
      } else if (data.status === 'FAILED') {
        this.activeModal.close({status : data.status, message : 'ALERT.SIGNATURE_FAILED'});
      }
    }
  }
}
