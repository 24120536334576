import {Component, Input, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SessionService} from 'luxtrust-cosi-api/api/session.service';
import {SessionData} from 'luxtrust-cosi-api/model/sessionData';
import {Subscription} from 'rxjs';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';

@Component({
  templateUrl: './modal-delete-session.component.html'
})
export class ModalDeleteSessionComponent implements OnDestroy {

  @Input() session: SessionData;
  @Input() title: string;
  @Input() message: string;
  private subscriptions: Subscription[] = [];
  deleting = false;

  constructor(
    public activeModal: NgbActiveModal,
    private sessionService: SessionService,
    private alertService: AlertService
  ) {
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  delete() {
    this.deleting = true;
    this.subscriptions.push(this.sessionService.deleteSession(this.session.id)
      .subscribe(() => this.activeModal.close('session_deleted'),
        (error: ApiError) => {
          this.deleting = false
          this.alertService.errorApi(error);
        }));
  }
}
