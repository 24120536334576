import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import find from 'lodash/find';
import {
  DocumentData,
  EnduserData,
  EnduserService,
  PlaceholderService, SprofileService, StepEnduserService
} from 'luxtrust-cosi-api';
import {AnnotationService} from 'luxtrust-cosi-api/api/annotation.service';
import {DocumentService} from 'luxtrust-cosi-api/api/document.service';
import {SignatureWorkflowService} from 'luxtrust-cosi-api/api/signatureWorkflow.service';
import {StepEndUserDocumentService} from 'luxtrust-cosi-api/api/stepEndUserDocument.service';
import {StepLegalEnduserService} from 'luxtrust-cosi-api/api/stepLegalEnduser.service';
import {StepPropertiesService} from 'luxtrust-cosi-api/api/stepProperties.service';
import {AnnotationData} from 'luxtrust-cosi-api/model/annotationData';
import {CreateCommentPayload} from 'luxtrust-cosi-api/model/createCommentPayload';
import {SignatureBookData} from 'luxtrust-cosi-api/model/signatureBookData';
import {SignatureBookSignerData} from 'luxtrust-cosi-api/model/signatureBookSignerData';
import {StepData} from 'luxtrust-cosi-api/model/stepData';
import {StepDocumentEnduserData} from 'luxtrust-cosi-api/model/stepDocumentEnduserData';
import {StepEnduserData} from 'luxtrust-cosi-api/model/stepEnduserData';
import {StepLegalEnduserData} from 'luxtrust-cosi-api/model/stepLegalEnduserData';
import {TagData} from 'luxtrust-cosi-api/model/tagData';
import {WorkflowData} from 'luxtrust-cosi-api/model/workflowData';
import {take} from 'rxjs/operators';
import {DownloadService} from 'src/app/services/services/download.service';
import {ApiError} from '../../../error/api-error.model';
import {SprofileKey} from '../../../services/enum/sprofile-key';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';
import {SignDatesCheckService} from '../../../services/services/sign-dates-check.service';
import {Objects} from '../../../services/utils/objects';
import {Strings} from '../../../services/utils/strings';
import {ModalPdfConsumerComponent} from '../../../shared/components/modal-pdf/modal-pdf-consumer.component';
import {DocumentStoreService} from '../../../session/step/services/document-store.service';
import {DocumentActionEnum} from '../../../services/enum/document-action.enum';
import {WizardService} from '../../../session/step/wizard/services/wizard.service';

@Component({
  selector: 'document', templateUrl: './document.component.html', styleUrls: ['./document.component.scss']
})
export class DocumentComponent extends ModalPdfConsumerComponent implements OnChanges, OnInit {

  @Input() selectedSignatureBook: SignatureBookData;
  @Input() step: StepData;
  @Output() addComment: EventEmitter<CreateCommentPayload> = new EventEmitter();
  @Output() signOrVisaDocument: EventEmitter<SignatureBookData> = new EventEmitter();
  @Output() suspendDocument: EventEmitter<SignatureBookData> = new EventEmitter();
  @Output() rejectDocument: EventEmitter<SignatureBookData> = new EventEmitter();
  @Output() reinstateDocument: EventEmitter<SignatureBookData> = new EventEmitter();
  @Output() cancelDocument: EventEmitter<SignatureBookData> = new EventEmitter();
  @Output() deleteComment: EventEmitter<void> = new EventEmitter();
  isBefore = false;
  isAfter = false;
  isBeforeOrAfterDate: Date;
  page: number;
  hasNonSystemTags: boolean;

  @ViewChild('documentContainer', {
    read: ElementRef, static: false
  }) documentContainer: ElementRef;

  stepLegalEndusers: StepLegalEnduserData[];
  annotations: AnnotationData[];
  sprofileCode: string;

  isRejectedOnce = false;
  alreadySignedStep = false;
  isSignDisabled = false;
  missingMandatoryValues: TagData[];
  pdfBlob: Blob;
  currentSigner;
  documentRejectActionButtonEnabled = true;
  documentCancelActionButtonEnabled = true;
  documentSuspendActionButtonEnabled = true;
  refreshComment = 0;
  documentUploaded: DocumentData;

  constructor(public documentService: DocumentService,
              public enduserService: EnduserService,
              public placeholderService: PlaceholderService,
              public annotationService: AnnotationService,
              public modal: NgbModal,
              private stepLegalEnduserService: StepLegalEnduserService,
              public appService: AppService,
              public alertService: AlertService,
              private downloadService: DownloadService,
              private stepEndUserDocumentService: StepEndUserDocumentService,
              public workflowService: SignatureWorkflowService,
              private stepPropertyService: StepPropertiesService,
              private sprofileService: SprofileService,
              public documentStoreService: DocumentStoreService,
              public stepEnduserService: StepEnduserService,
              private signDateCheckService: SignDatesCheckService,
              public wizardService: WizardService) {
    super(modal, appService, annotationService, alertService, documentService, placeholderService, stepEnduserService, workflowService, documentStoreService, wizardService);
  }

  ngOnInit(): void {
    this.init();
    this.enduserService.getEnduser(this.appService.getCurrentUserId()).subscribe((signer) => {
      this.currentSigner = signer;
    }, (error: ApiError) => this.alertService.errorApi(error));
    super.ngOnInit();
  }

  init() {
    this.page = 1;
    this.acroformDefined = false;
    this.sessionId = this.selectedSignatureBook.sessionId;
    this.stepId = this.selectedSignatureBook.stepId;
    this.getSprofileValues();
    this.getStepTags();
    this.getAnnotation();
    this.getStepLegalEnduser();

    // to check whether document can be signed before or after to enable the sign buttons
    this.stepPropertyService.getStepProperties(this.selectedSignatureBook.sessionId, this.selectedSignatureBook.stepId).subscribe(stepProps => {
      this.isBefore = this.signDateCheckService.isSignNotBefore(stepProps.signNotBefore);
      this.isAfter = this.signDateCheckService.isSignNotAfter(stepProps.signNotAfter);
      this.isBeforeOrAfterDate = this.isBefore ? stepProps.signNotBefore : this.isAfter ? stepProps.signNotAfter : undefined;
    }, (error: ApiError) => this.alertService.errorApi(error));

    this.stepEndUserDocumentService.getStepEnduserDocument(this.selectedSignatureBook.sessionId, this.selectedSignatureBook.stepId)
      .subscribe(stepDocumentEnduserDataList => {
        let stepDocumentEnduserData: StepDocumentEnduserData;
        if (stepDocumentEnduserDataList && stepDocumentEnduserDataList.length > 0) {
          stepDocumentEnduserData = stepDocumentEnduserDataList.find(item => item.documentId === this.selectedSignatureBook.document.id && item.enduserId == this.appService.getCurrentUserId());
        }

        this.sprofileCode = (stepDocumentEnduserData && stepDocumentEnduserData.sprofileCode) || this.selectedSignatureBook.document.sprofileCode;
      }, (error: ApiError) => this.alertService.errorApi(error));

    this.workflowService.searchWorkflows(this.selectedSignatureBook.sessionId, this.selectedSignatureBook.stepId, {
      enduserId: this.appService.getCurrentUserId()
    }).pipe(take(1))
      .subscribe((workflows: WorkflowData[]) => {
        this.alreadySignedStep = false;
        for (let i = 0; i < workflows.length; i++) {
          if (workflows[i].signatureStatus !== 'STARTED') {
            this.alreadySignedStep = true;
          }
        }
      }, (error: ApiError) => this.alertService.errorApi(error));
    this.workflowService.getWorkflowList(this.selectedSignatureBook.sessionId, this.selectedSignatureBook.stepId).toPromise().then(workflowList => {
      this.isRejectedOnce = workflowList.some(workflow => workflow.signatureStatus === WorkflowData.SignatureStatusEnum.DECLINED && <any>
        this.selectedSignatureBook.document.id === workflow.documentId
      );
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  selectedSigners(document?: DocumentData): EnduserData[] {
    if (!this.hasStepTagPositionSignatureLocation) {
      return this.signers;
    } else {
      const signerByDocument = find(this.signersByDocument, (docSigners) => docSigners.document.id === document.id);
      if (signerByDocument) {
        return signerByDocument.signers;
      }
      return undefined;
    }
  }

  get currentStepEnduser(): StepEnduserData {
    const signer = this.currentSignerData;
    return signer && signer.stepEnduser;
  }

  get currentSignerData(): SignatureBookSignerData {
    return this.selectedSignatureBook.signatureBookSignerList.find(
      (signerData: SignatureBookSignerData) => signerData.stepEnduser.enduser.id === this.appService.getCurrentUserId()) || this.selectedSignatureBook.signatureBookSignerList.find(
      (signerData: SignatureBookSignerData) => signerData.stepEnduser.enduser.circle);
  }

  changePage(page) {
    this.page = page;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['step'] && !changes['step'].isFirstChange()) {
      this.init();
    }
  }

  getSprofileValues() {
    this.sprofileService.getSprofileKeyvalValList(this.selectedSignatureBook.stepId).toPromise()
      .then(sprofileMap => {
        const sprofileCode = Object.getOwnPropertyNames(sprofileMap)[0];

        let value = new Objects(sprofileMap[sprofileCode]).valueForKey(SprofileKey.DOCUMENT_REJECT_BUTTON_ACTION_ENABLED);
        if (value) {
          this.documentRejectActionButtonEnabled = new Strings(value).asBoolean();
        }
        value = new Objects(sprofileMap[sprofileCode]).valueForKey(SprofileKey.DOCUMENT_CANCEL_BUTTON_ACTION_ENABLED);
        if (value) {
          this.documentCancelActionButtonEnabled = new Strings(value).asBoolean();
        }
        value = new Objects(sprofileMap[sprofileCode]).valueForKey(SprofileKey.DOCUMENT_SUSPEND_BUTTON_ACTION_ENABLED);
        if (value) {
          this.documentSuspendActionButtonEnabled = new Strings(value).asBoolean();
        }
      });
  }

  getStepLegalEnduser() {
    this.stepLegalEnduserService.getStepLegalEndusers(this.selectedSignatureBook.sessionId, this.selectedSignatureBook.stepId).pipe(take(1)).subscribe(
      (value: StepLegalEnduserData[]) => this.stepLegalEndusers = value,
      (error: ApiError) => this.alertService.errorApi(error)
    );
  }

  getAnnotation() {
    this.annotationService.getAnnotationList(this.selectedSignatureBook.document.id, this.selectedSignatureBook.sessionId, this.selectedSignatureBook.stepId)
      .pipe(take(1)).subscribe(
      (value) => this.annotations = value,
      (error: ApiError) => this.alertService.errorApi(error)
    );
  }

  notifyWhenAddComment(comment?: CreateCommentPayload) {
    this.addComment.emit(comment);
  }

  notifyWhenActionOnDocument(action) {
    switch (action) {
      case DocumentActionEnum.SIGN:
        this.signOrVisaDocument.emit(this.selectedSignatureBook);
        this.currentSignerData.signatureStatus = 'SIGNED';
        break;
      case DocumentActionEnum.VISA:
        this.signOrVisaDocument.emit(this.selectedSignatureBook);
        this.currentSignerData.signatureStatus = 'SIGNED';
        break;
      case DocumentActionEnum.NEXT:
        this.signOrVisaDocument.emit(this.selectedSignatureBook);
        this.currentSignerData.signatureStatus = 'SIGNED';
        break;
      case DocumentActionEnum.REJECT:
        this.notifyWhenAddComment();
        this.refreshAfterRejectAndReinstate();
        this.rejectDocument.emit(this.selectedSignatureBook);
        break;
      case DocumentActionEnum.REINSTATE:
        this.notifyWhenAddComment();
        this.refreshAfterRejectAndReinstate();
        this.reinstateDocument.emit(this.selectedSignatureBook);
        break;
      case DocumentActionEnum.SUSPEND:
        this.suspendDocument.emit(this.selectedSignatureBook);
        break;
      case DocumentActionEnum.CANCEL:
        this.cancelDocument.emit(this.selectedSignatureBook);
        break;
    }
  }

  refreshAfterRejectAndReinstate() {
    this.refreshComment++;
    this.isRejectedOnce = !this.isRejectedOnce;
  }

  onCheckLegalNotices(checked: boolean) {
    this.isSignDisabled = !checked;
  }

  getStepTags() {
    this.hasStepTagPositionSignatureLocation = this.step.tags.some(tag => tag.alias.includes('POSITION_SIGNATURE_LOCATION'));
    if (this.hasStepTagPositionSignatureLocation) {
      this.signersByDocument = [];
    }
    this.getSigners(this.selectedSignatureBook.document);
    this.checkMissingMandatoryValues();
  }

  checkMissingMandatoryValues() {
    // check if mandatory tags have value
    if (this.step.tags.length > 0) {
      this.hasNonSystemTags = this.step.tags.filter(tag => !tag.system).length > 0;
      this.missingMandatoryValues = this.step.tags.filter(x => x.mandatory && (x.value === '' || x.value === null || x.value === undefined || (x.value === '' && x.defaultValue === '')));
    }
  }

  dataOnDocument(event) {
    this.pdfBlob = event.blob;
  }

  downloadDocument() {
    this.downloadService.download(this.pdfBlob, this.selectedSignatureBook.document.name);
  }

  removeComment() {
    this.deleteComment.emit();
  }

  reuploadDocument(file: File) {
    this.uploadingDocument = true;
    this.documentStoreService.reuploadDocument(this.sessionId, this.stepId, {
      document: this.selectedSignatureBook.document,
      file
    }, () => {
      this.uploadingDocument = false;
      this.documentService.getDocument(this.selectedSignatureBook.document.id, this.sessionId, this.step.id).subscribe((document: DocumentData) => {
        this.documentUploaded = document;
      });
    });
  }
}
