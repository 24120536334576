import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute, Data} from '@angular/router';
import {TranslateService} from 'src/app/core/service/translate.service';
import {StepService} from 'luxtrust-cosi-api/api/step.service';
import {StepConfigurationData} from 'luxtrust-cosi-api/model/stepConfigurationData';
import {take} from 'rxjs/operators';
import {AlertService} from '../../../../services/services/alert-service';
import {WizardService} from '../../wizard/services/wizard.service';
import {defaultConfig} from './defaultConfigConfigurator.constant';
import {wizardStepConfiguratorEnum} from './wizardStepConfigurator.enum';
import {ApiError} from '../../../../error/api-error.model';

@Component({
  templateUrl: './wizard-step-configurator.component.html',
  styleUrls: ['./wizard-step-configurator.component.scss']
})
export class WizardStepConfiguratorComponent implements OnInit, OnDestroy {

  public wizardStepAdminEnum = wizardStepConfiguratorEnum;
  config = defaultConfig;

  adminForm: FormGroup;
  role = wizardStepConfiguratorEnum.MANAGER;
  session;
  step;
  submittingForm = false;

  constructor(
              private readonly stepService: StepService,
              private readonly route: ActivatedRoute,
              private readonly alertService: AlertService,
              private readonly translateService: TranslateService,
              private readonly wizardService: WizardService) { }

  ngOnInit() {
    if (sessionStorage.getItem('toggleAdminSession')) {
      this.role = sessionStorage.getItem('toggleAdminSession') as wizardStepConfiguratorEnum;
    }
    this.route.parent.data.pipe(take(1)).subscribe((data: Data) => {
      this.session = data['session'];
      this.step = data['step'];
      this.haveStepConfiguration();
    });
  }

  private haveStepConfiguration() {
    this.stepService.getStepConfiguration(this.session.id, this.step.id).subscribe((config) => {
      this.transformConfigDataForFront(config);
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  // TODO : A voir pour optimiser ?
  // TODO : Plus tard : roles et actions dynamiquement en fonction du retour du back ?
  private transformConfigDataForFront(config: StepConfigurationData) {
    // Rules of MANAGER
    if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER]) {
      if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap) {
        // MANAGER : Replace, Add, Delete, Clone or SPROFILE Document
        if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
          profileMap[wizardStepConfiguratorEnum.DOCUMENT] && config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
          profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap) {
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.ADD] != undefined) {
            this.config.manager.documents.add = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.ADD];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.REPLACE] != undefined) {
            this.config.manager.documents.replace = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.REPLACE];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.DELETE] != undefined) {
            this.config.manager.documents.delete = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.DELETE];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.CLONE] != undefined) {
            this.config.manager.documents.clone = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.CLONE];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.SPROFILE] != undefined) {
            this.config.manager.documents.sprofile = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.SPROFILE];
          }
        }

        // MANAGER : Replace, Add or Clone Users
        if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
          profileMap[wizardStepConfiguratorEnum.USER] && config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.USER].configurationMap) {
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.ADD] != undefined) {
            this.config.manager.users.add = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.ADD];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.REPLACE] != undefined) {
            this.config.manager.users.replace = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.REPLACE];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.CLONE] != undefined) {
            this.config.manager.users.clone = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.CLONE];
          }
        }

        // MANAGER : Replace, Add or Clone Watchers
        if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
          profileMap[wizardStepConfiguratorEnum.WATCHER] && config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap) {
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.ADD] != undefined) {
            this.config.manager.watchers.add = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.ADD];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.REPLACE] != undefined) {
            this.config.manager.watchers.replace = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.REPLACE];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.CLONE] != undefined) {
            this.config.manager.watchers.clone = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.CLONE];
          }
        }

        if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.TAG]) {
          if (config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
            profileMap[wizardStepConfiguratorEnum.TAG].configurationMap[wizardStepConfiguratorEnum.UPDATE] != undefined) {
            this.config.manager.metadata.update = config.configurationMap[wizardStepConfiguratorEnum.MANAGER].
              profileMap[wizardStepConfiguratorEnum.TAG].configurationMap[wizardStepConfiguratorEnum.UPDATE];
          }
        }
      }
    }
    if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER]) {

      // Rules of SIGNERS
      if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap) {
        // SIGNERS : Replace, Delete, Add, Clone or SPROFILE Document
        if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.DOCUMENT] &&
          config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap) {
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.ADD] != undefined) {
            this.config.signer.documents.add = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.ADD];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.DELETE] != undefined) {
            this.config.signer.documents.delete = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.DELETE];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.REPLACE] != undefined) {
            this.config.signer.documents.replace = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.REPLACE];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.CLONE] != undefined) {
            this.config.signer.documents.clone = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.CLONE];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.SPROFILE] != undefined) {
            this.config.signer.documents.sprofile = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.SPROFILE];
          }
        }

        // SIGNERS : Replace, Add or Clone Users
        if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
          profileMap[wizardStepConfiguratorEnum.USER] && config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.USER].configurationMap) {
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.ADD] != undefined) {
            this.config.signer.users.add = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.ADD];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.REPLACE] != undefined) {
            this.config.signer.users.replace = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.REPLACE];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.CLONE] != undefined) {
            this.config.signer.users.clone = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.CLONE];
          }
        }

        // SIGNERS : Replace, Add or Clone Watchers
        if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
          profileMap[wizardStepConfiguratorEnum.WATCHER] && config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap) {
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.ADD] != undefined) {
            this.config.signer.watchers.add = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.ADD];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.REPLACE] != undefined) {
            this.config.signer.watchers.replace = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.REPLACE];
          }
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
            profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.CLONE] != undefined) {
            this.config.signer.watchers.clone = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.CLONE];
          }
        }

        // SIGNERS : Update Metadata
        if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
          profileMap[wizardStepConfiguratorEnum.TAG] && config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.TAG].configurationMap) {
          if (config.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.TAG].
            configurationMap[wizardStepConfiguratorEnum.UPDATE] != undefined) {
            this.config.signer.metadata.update = config.configurationMap[wizardStepConfiguratorEnum.SIGNER].
              profileMap[wizardStepConfiguratorEnum.TAG].configurationMap[wizardStepConfiguratorEnum.UPDATE];
          }
        }
      }
    }
  }

  changeRole(role) {
    this.role = role;
    // To keep the switcher when we change wizard
    sessionStorage.setItem('toggleAdminSession', this.role);
  }
  handlePreviousStepButton() {
    this.wizardService.previousStep();
  }

  onFormSubmit() {
    const configBack: StepConfigurationData = this.transformConfigDataForBack();
    this.stepService.setStepConfiguration(this.session.id, this.step.id, configBack)
      .toPromise()
      .catch((error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.submittingForm = false);
    this.alertService.success('WIZARD.CONFIG.TOAST_DONE');

  }

  deleteValueToggleAdminIntoSessionStorage() {
    this.submittingForm = true;
    sessionStorage.removeItem('toggleAdminSession');
  }

  private transformConfigDataForBack(): StepConfigurationData {
    const configBack: StepConfigurationData = this.initConfigBack();

    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.ADD] = this.config.manager.documents.add;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.DELETE] = this.config.manager.documents.delete;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.REPLACE] = this.config.manager.documents.replace;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.CLONE] = this.config.manager.documents.clone;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.SPROFILE] = this.config.manager.documents.sprofile;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.ADD] = this.config.manager.users.add;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.REPLACE] = this.config.manager.users.replace;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.CLONE] = this.config.manager.users.clone;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.ADD] = this.config.manager.watchers.add;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.REPLACE] = this.config.manager.watchers.replace;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.CLONE] = this.config.manager.watchers.clone;
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].
      profileMap[wizardStepConfiguratorEnum.TAG].configurationMap[wizardStepConfiguratorEnum.UPDATE] = this.config.manager.metadata.update;

    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.ADD] = this.config.signer.documents.add;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.DELETE] = this.config.signer.documents.delete;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.REPLACE] = this.config.signer.documents.replace;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.CLONE] = this.config.signer.documents.clone;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap[wizardStepConfiguratorEnum.SPROFILE] = this.config.signer.documents.sprofile;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.ADD] = this.config.signer.users.add;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.REPLACE] = this.config.signer.users.replace;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.USER].configurationMap[wizardStepConfiguratorEnum.CLONE] = this.config.signer.users.clone;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.ADD] = this.config.signer.watchers.add;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.REPLACE] = this.config.signer.watchers.replace;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap[wizardStepConfiguratorEnum.CLONE] = this.config.signer.watchers.clone;
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].
      profileMap[wizardStepConfiguratorEnum.TAG].configurationMap[wizardStepConfiguratorEnum.UPDATE] = this.config.signer.metadata.update;

    return configBack;

  }

  private initConfigBack(): StepConfigurationData {
    const configBack: StepConfigurationData = {};
    configBack.configurationMap = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER] = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.DOCUMENT] = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.USER] = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.USER].configurationMap = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.WATCHER] = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.TAG] = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.MANAGER].profileMap[wizardStepConfiguratorEnum.TAG].configurationMap = {};

    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER] = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.DOCUMENT] = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.DOCUMENT].configurationMap = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.USER] = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.USER].configurationMap = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.WATCHER] = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.WATCHER].configurationMap = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.TAG] = {};
    configBack.configurationMap[wizardStepConfiguratorEnum.SIGNER].profileMap[wizardStepConfiguratorEnum.TAG].configurationMap = {};

    return configBack;
  }

  ngOnDestroy(): void {
    this.onFormSubmit();
  }

  handleDelete(value: boolean) {
    const role = this.role.toLowerCase();
    if (!value && this.config[role].documents.delete) {
      this.config[role].documents.delete = this.config[role].documents.clone || this.config[role].documents.add;
    }
  }

  handleAdd(value: boolean) {
    const role = this.role.toLowerCase();
    if (value) {
      this.config[role].documents.add = value;
    }
  }

}
