import { Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-delete-tag',
  templateUrl: './modal-delete-tag.component.html',
  styleUrls: ['./modal-delete-tag.component.scss']
})
export class ModalDeleteTagComponent {

  @Input() title: string;
  @Input() message: string;
  @Input() name: string;
  constructor(public activeModal: NgbActiveModal) {
   }

  delete() {
    this.activeModal.close('deleted');
  }
}
