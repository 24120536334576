<app-tooltip [tooltipText]="'HELP.REGISTER' | translate"></app-tooltip>

<app-full-screen [title]="'INVITE.TITLE' | translate"
                 [user]="this.user"
                 [authMode]="authMode"
                 mode="container-m">

  <form [formGroup]="registerForm">
    <div class="row">
      <div class="col-12 mb-2">
        <app-input-text (focusout)="markAsTouched('firstName')"
                        [idPostfix]="'firstName'"
                        formControlName="firstName"
                        icon="user"></app-input-text>
      </div>
      <div class="col-12 mb-2">
        <app-input-text (focusout)="markAsTouched('lastName')"
                        [idPostfix]="'lastName'"
                        formControlName="lastName"
                        icon="user"></app-input-text>
      </div>
      <div class="col-12 mb-2">
        <app-input-text (focusout)="markAsTouched('email')"
                        [idPostfix]="'email'"
                        formControlName="email"
                        icon="envelope"
                        type="email"></app-input-text>
      </div>
      <div class="col-12 col-md-6" *ngIf="authMode === 'ba'">
        <app-input-text (focusout)="markAsTouched('password')"
                        [errors]="{required: 'INVITE.ERRORS.REQUIRED' | translate, pattern: 'INVITE.ERRORS.FORMAT' | translate}"
                        [placeholder]="'SIGN_UP.PASSWORD.PLACEHOLDER' | translate"
                        [hasOnInput]="true"
                        [idPostfix]="'input-new-password'"
                        formControlName="password"
                        icon="key"
                        type="password">
        </app-input-text>
      </div>
      <div class="col-12 col-md-6" *ngIf="authMode === 'ba'">
        <app-input-text (focusout)="markAsTouched('confirmPassword')"
                        [errors]="{required: 'INVITE.ERRORS.REQUIRED' | translate, matchPassword: 'INVITE.ERRORS.MATCH' | translate}"
                        [placeholder]="'SIGN_UP.CONFIRM_PASSWORD.PLACEHOLDER' | translate"
                        [hasOnInput]="true"
                        [idPostfix]="'input-confirm-new-password'"
                        formControlName="confirmPassword"
                        icon="check"
                        type="password">
        </app-input-text>
      </div>
    </div>
  </form>

  <div *ngIf="authMode === 'luxtrust'" class="my-3">
    <iframe [height]="650" [src]="orelyAuthUrl" frameborder="0" name="orely" scrolling="no" target="_parent" width="100%">
    </iframe>
  </div>

  <div class="mt-3" *ngIf="authMode === 'adfs'">
    <p style="text-align: center">{{'INVITE.LINK_MESSAGE' | translate: {mode:this.authMode}  }}</p>
    <div class="btn-sign-wrapper">
      <button (click)="authWithADFS()"
          class="btn btn-sign" href="#"
          [ngClass]="'otherAuth'">
          <img src="assets/img/adfs-logo.png">
      </button>
    </div>    
  </div>

  <div class="mt-3" *ngIf="authMode === 'oidc'">
    <p style="text-align: center">{{'INVITE.LINK_MESSAGE' | translate: {mode:this.authMode}  }}</p>
    <div class="btn-sign-wrapper">
      <button (click)="authWithOidc()"
          class="btn btn-sign" href="#"
          [ngClass]="'otherAuth'">
          <img src="assets/img/oidc-logo.png">
      </button>
    </div>    
  </div>

  <div class="mt-3" *ngIf="authMode === 'azure'">
    <p style="text-align: center">{{'INVITE.LINK_MESSAGE' | translate: {mode:this.authMode}  }}</p>
    <div class="btn-sign-wrapper">
      <button (click)="authWithAzure()"
          class="btn btn-sign" href="#"
          [ngClass]="'otherAuth'">
          <img src="assets/img/azure-logo.png">
      </button>
    </div>    
  </div>
  
  <div class="mt-3" *ngIf="authMode === 'saml2'">
    <p style="text-align: center">{{'INVITE.LINK_MESSAGE' | translate: {mode:this.authMode}  }}</p>
    <div class="btn-sign-wrapper">
      <button (click)="authWithSamlv2('saml2')"
          class="btn btn-sign" href="#"
          [ngClass]="'otherAuth'">
          <img src="assets/img/saml2-logo.png">
      </button>
    </div>    
  </div>

  <div class="mt-3" *ngIf="authMode === 'gardian'">
    <p style="text-align: center">{{'INVITE.LINK_MESSAGE' | translate: {mode:this.authMode}  }}</p>
    <div class="btn-sign-wrapper">
      <button (click)="authWithSamlv2('gardian')"
          class="btn btn-sign" href="#"
          [ngClass]="'otherAuth'">
          <img src="assets/img/gardian-logo.png">
      </button>
    </div>    
  </div>

  <div class="mt-3" *ngIf="authMode === 'mc'">
    <p style="text-align: center">{{'INVITE.LINK_MESSAGE' | translate: {mode:this.authMode}  }}</p>
    <div class="btn-sign-wrapper">
      <button (click)="authWithMc()"
          class="btn btn-sign" href="#"
          [ngClass]="'otherAuth'">
          <img src="assets/img/mobile_connect_icon.png">
      </button>
    </div>    
  </div>

  <div class="mt-3" *ngIf="authMode === 'itsme'">
    <p style="text-align: center">{{'INVITE.LINK_MESSAGE' | translate: {mode:this.authMode}  }}</p>
    <div class="btn-sign-wrapper">
      <button (click)="authWithItsme()"
          class="btn btn-sign" href="#"
          [ngClass]="'otherAuth'">
          <img src="assets/img/itsme-logo.png">
      </button>
    </div>    
  </div>

  
  <div class="row">
    <div class="col-12 col-md-6 mt-2">
      <button class="btn btn-primary"  *ngIf="authMode === 'ba'"
              (click)="onSubmit()"
              type="submit" [disabled]="!registerForm.valid">{{'INVITE.REGISTER' | translate}}</button>
    </div>
    <div class="col-12 col-md-6 mt-2">
      <button (click)="onProceed()" [hidden]="signUpMandatory"
              class="btn float-right btn-danger"
              type="button">{{'INVITE.PROCEED' | translate}}</button>
    </div>
  </div>
</app-full-screen>


<ng-template #authCodeModal let-modal>
  <div class="modal-header">
    <div class="modal-title">{{'LOGIN.AUTH_CODE.TITLE'| translate}}</div>
    <button (click)="onAuthCodeModalDismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form (ngSubmit)="onAuthCodeSubmit()" [formGroup]="authCodeFormGroup" class="row">
      <div class="col-12">
        <div class="form-row">
          <app-input-text [errors]="{
                            required: 'LOGIN.AUTH_CODE.REQUIRED' | translate,
                            pattern: 'LOGIN.AUTH_CODE.FORMAT' | translate
                          }"
                          [placeholder]="'LOGIN.AUTH_CODE.ENTER' | translate: {channel: authCodeChannel}"
                          (ngModelChange)="updateAuthCode($event)"
                          autoFocus
                          class="col"
                          formControlName="authCode"
                          [hasOnInput]="true"
                          type="text">
          </app-input-text>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <div class="mr-2">
      <button (click)="generateCode()"
              class="btn btn-sm btn-secondary"
              type="button">
        {{'LOGIN.AUTH_CODE.GENERATE' | translate}}
      </button>
    </div>
    <button (click)="onAuthCodeSubmit()" [disabled]="authCodeFormGroup.invalid"
            class="btn btn-primary" type="submit">
      {{'LOGIN.SEND' | translate}}
    </button>
  </div>
</ng-template>
