<ngb-tabset class="nav-fill">
  <ngb-tab *ngFor="let tag of TAG_VALUES; let i = index">
    <ng-template ngbTabTitle>
      <span class="tab-available fa fa-language" *ngIf="!!availableLanguages(tag.value)" [ngbTooltip]="availableLanguagesTooltip(tag.value)"></span>
      <span class="tab-title" [ngClass]="{'current-changed': checkIfChanges(tag.value)}">{{tag.key}}</span>
    </ng-template>
    <ng-template ngbTabContent>
      <button (click)="onClose()" class="close">
        <span aria-hidden="true">&times;</span>
      </button>
      <ng-container *ngIf="getPatternByTagValue(tag.value, patterns); else noTemplate">
        <app-template-editor [patternData]="getPatternByTagValue(tag.value, patterns)" [displayCloseButton]="false">
        </app-template-editor>
        <div class="modal-footer">
          <div class="language-list">
            <div class="input-group">
              <div class="input">
                <select [(ngModel)]="language" class="input form-control">
                  <option *ngFor="let lang of languageService.languages" [value]="lang">
                    {{lang}}
                  </option>
                </select>
              </div>
              <span class="input-group-text" >
                <i class="pl-2 fas fa-language" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <button (click)="onDelete(tag.value)" class="btn btn-danger" type="button" [disabled]="removingPattern || savingPattern">
            {{'MODAL_TEMPLATE_BUILDER.DELETE' | translate}}</button>
          <button (click)="onSave(tag.value)" [disabled]="canNotSave(tag.value)" class="btn btn-primary" type="button" [disabled]="removingPattern || savingPattern">
            {{'MODAL_TEMPLATE_BUILDER.SAVE' | translate}}</button>
          <button (click)="onClose()" class="btn btn-cancel" type="button">
            {{'MODAL_TEMPLATE_BUILDER.CLOSE' | translate}}
          </button>
        </div>
      </ng-container>
      <ng-template #noTemplate>
        <div class="space-filler">
          <h2>{{'WIZARD.NOTIFICATIONS.TEMPLATE.NOT_FOUND' | translate}}</h2>
          <button  class="btn btn-primary" type="button" (click)="findBestSuitedTemplateForTagValue(tag.value)" [disabled]="creatingPattern">
            {{'WIZARD.NOTIFICATIONS.TEMPLATE.FIND' | translate}}
          </button>
        </div>
        <div class="modal-footer">
          <div class="language-list">
            <div class="input-group">
              <div class="input">
                <select [(ngModel)]="language" class="input form-control" (ngModelChange)="languageChange()">
                  <option *ngFor="let lang of languageService.languages" [value]="lang">
                    {{lang}}
                  </option>
                </select>
              </div>
              <span class="input-group-text" >
                <i class="pl-2 fas fa-language" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <button (click)="onClose()" class="btn btn-cancel" type="button">
            {{'MODAL_TEMPLATE_BUILDER.CLOSE' | translate}}
          </button>
        </div>
      </ng-template>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
