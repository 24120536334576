import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByValidationStatus'
})
export class ValidationStatusFilterPipe implements PipeTransform {

    transform(signatures: Signature[], status: string): any {
        return signatures ? signatures.filter(signature => {
            return signature.validationStatus === status
        }) : [];
    }
}
