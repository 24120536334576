import {Component, Input} from '@angular/core';
import {EnduserData} from "../../../../../luxtrust-cosi-api";

@Component({
  selector: 'app-full-screen', templateUrl: './full-screen.component.html', styleUrls: ['./full-screen.component.scss']
})
export class FullScreenComponent {
  @Input() mode: 'container-s' | 'container-m' | 'container-l' | 'container-xl' | '' = '';
  @Input() title: string;
  @Input() icon: string;
  @Input() tooltip: string;
  @Input() user : EnduserData;
  @Input() authMode : string;
}
