import {Component, Input, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {CircleData, CircleOfEndusersService, EnduserCircleService, EnduserData} from "../../../../../luxtrust-cosi-api";

@Component({
  selector: 'app-modal-delete-circle-member',
  templateUrl: './modal-delete-circle-member.component.html',
  styleUrls: ['./modal-delete-circle-member.component.scss']
})
export class ModalDeleteCircleMemberComponent {

  @Input() circle: CircleData;
  @Input() member: EnduserData;
  private subscriptions: Subscription[] = [];
  deleting = false;

  constructor(public activeModal: NgbActiveModal, private circleEnduserService: EnduserCircleService, private alertService: AlertService) {
  }

  delete() {
    this.deleting = true;
    this.circleEnduserService.deleteCircleEnduser(this.circle.id, this.member.id).subscribe(() => {
      this.activeModal.close('circle_member_deleted');
      },(error: ApiError) => {
        this.deleting = false
        this.alertService.errorApi(error);
      });
  }
}
