import {TranslateLoader} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export class CustomLoader implements TranslateLoader {

  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const translationFiles = [this.http.get(`./assets/i18n/common/${lang}.json`)];

    // This method is used because at the runtime, the tenantName parameter is not set in the activeRoute
    // object when the method getTranslation is called.
    let tenantName = this.getTenantValueFromUrl(window.location.href);

    if (tenantName) {
      // Take care with this method, an error will be added into the console log if the link doesn't exists.
      translationFiles.push(
        this.http.get(`./assets/i18n/${tenantName.toUpperCase()}/${lang}.json`));
    }

    if (translationFiles.length === 1) return translationFiles[0];
    else {
      return forkJoin(translationFiles).pipe(map(responses => {
          const output = {};
          responses.forEach((response) => {
            Object.assign(output, response);
          });
          return output;
        }),
        catchError((err) => {
          console.error(err);
          return translationFiles[0];
        }));
    }
  }

  /**
   * Return the list of parameters present in the url.
   * @param url the url.
   */
  getTenantValueFromUrl(url) {
    let parameters = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      parameters[key] = value;
    });
    return parameters['tenantName'];
  }

}
