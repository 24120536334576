/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../core/pipes/translate.pipe";
import * as i2 from "../../../core/service/translate.service";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./modal-comment.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
var styles_ModalCommentComponent = [];
var RenderType_ModalCommentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalCommentComponent, data: {} });
export { RenderType_ModalCommentComponent as RenderType_ModalCommentComponent };
export function View_ModalCommentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService]), (_l()(), i0.ɵeld(4, 0, null, null, 13, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 12, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "label", [["for", "commentModalTextarea"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService]), (_l()(), i0.ɵeld(9, 0, null, null, 8, "textarea", [["class", "form-control fixed-textarea"], ["id", "commentModalTextarea"], ["maxlength", "255"], ["rows", "5"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 10)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 10)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(11, 540672, null, 0, i3.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i0.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.MaxLengthValidator]), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(14, 540672, null, 0, i3.FormControlDirective, [[6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlDirective]), i0.ɵdid(16, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, ["    "])), (_l()(), i0.ɵeld(18, 0, null, null, 9, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 2, "button", [["class", "btn btn-light mr-1"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(20, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService]), (_l()(), i0.ɵeld(22, 0, null, null, 5, "button", [["class", "btn btn-primary mr-1"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(24, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(25, { "disabled": 0 }), (_l()(), i0.ɵted(26, null, ["", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService])], function (_ck, _v) { var _co = _v.component; var currVal_10 = "255"; _ck(_v, 11, 0, currVal_10); var currVal_11 = _co.comment; _ck(_v, 14, 0, currVal_11); var currVal_13 = "btn btn-primary mr-1"; var currVal_14 = _ck(_v, 25, 0, !_co.comment.value.trim()); _ck(_v, 24, 0, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.title)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.label)); _ck(_v, 7, 0, currVal_1); var currVal_2 = (i0.ɵnov(_v, 11).maxlength ? i0.ɵnov(_v, 11).maxlength : null); var currVal_3 = i0.ɵnov(_v, 16).ngClassUntouched; var currVal_4 = i0.ɵnov(_v, 16).ngClassTouched; var currVal_5 = i0.ɵnov(_v, 16).ngClassPristine; var currVal_6 = i0.ɵnov(_v, 16).ngClassDirty; var currVal_7 = i0.ɵnov(_v, 16).ngClassValid; var currVal_8 = i0.ɵnov(_v, 16).ngClassInvalid; var currVal_9 = i0.ɵnov(_v, 16).ngClassPending; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_12 = i0.ɵunv(_v, 20, 0, i0.ɵnov(_v, 21).transform(_co.close)); _ck(_v, 20, 0, currVal_12); var currVal_15 = i0.ɵunv(_v, 26, 0, i0.ɵnov(_v, 27).transform(_co.action)); _ck(_v, 26, 0, currVal_15); }); }
export function View_ModalCommentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ModalCommentComponent_0, RenderType_ModalCommentComponent)), i0.ɵdid(1, 49152, null, 0, i5.ModalCommentComponent, [i6.NgbActiveModal], null, null)], null, null); }
var ModalCommentComponentNgFactory = i0.ɵccf("ng-component", i5.ModalCommentComponent, View_ModalCommentComponent_Host_0, { title: "title", label: "label", close: "close", action: "action", placeholder: "placeholder" }, { onAction: "onAction", onCancel: "onCancel" }, []);
export { ModalCommentComponentNgFactory as ModalCommentComponentNgFactory };
