import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from 'src/app/core/service/translate.service';
import { CreateTagPayload } from 'luxtrust-cosi-api/model/createTagPayload';
import { TagData } from 'luxtrust-cosi-api/model/tagData';
import TypeEnum = CreateTagPayload.TypeEnum;
import { REGEXES, LIMITS } from 'src/app/app.constant';
import { CloneUtils } from 'src/app/services/utils/clone.utils';
import { ModalManageTagListComponent } from '../modal-manage-tag-list/modal-manage-tag-list.component';

// TODO BDX Review all of this. See changes related to CI-540.
class LinkMetadata {
  name: string;
  value: string;

  constructor(name: string, value: string) {
    this.name = name === undefined ? '' : name;
    this.value = value === undefined ? '' : value;
  }

  static ofFullValue(fullValue: string) {
    const splittedValue: string[] = fullValue.split('|');
    const name = splittedValue[0];
    const value = splittedValue[1];
    return new LinkMetadata(name, value);
  }

  getFullValue() {
    return `${this.name}|${this.value}`;
  }
}

@Component({
  selector: 'app-metadata-selector',
  templateUrl: './metadata-selector.component.html',
  styleUrls: ['./metadata-selector.component.scss']
})
export class MetadataSelectorComponent implements OnInit, OnChanges {
  @Input() set tag(tagData: TagData) {
   this.tag_ = tagData;
   if (!!this.tag_) {
     this.value = this.tag_.value;
   }
  }
  @Input() customModel: string;
  @Input() showMode = false;
  @Input() canEditList = false;
  @Input() canHaveNullToggle = false;
  @Input() disableAll = false;
  @Input() smallSizeInputDay = false;
  @Input() editMode = false;
  @Input() isWatcher = false;
  @Output() onUpdate = new EventEmitter<TagData>();
  @Output() tagInError = new EventEmitter<boolean>();
  // display information to save value.
  displayInfoOnChange = false;

  get tag() {
    return this.tag_;
  }

  private tag_: TagData;
  public editList = false;
  typeEnum = TypeEnum;
  showInput = false;
  value: string;
  timeOut;
  date;
  minDay = 0;
  maxDay = 0;
  linkMetadataName = '';
  linkMetadataValue = '';
  public maxInputLength = LIMITS.MAX_TAG_VALUE_LENGTH;
  public maxInputLinkLength = LIMITS.MAX_TAG_VALUE_LENGTH;
  constructor(
    private readonly modal: NgbModal,
    private readonly translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.initialiseLinkMetadataValues();
  }

  ngOnChanges() {
    this.initialiseLinkMetadataValues();
  }

  onChange(value) {
    this.displayInfoOnChange= !this.editList ? true : false;
    let valueToUse: any = value;
    if (this.tag.type === TypeEnum.LINK) {
      valueToUse = new LinkMetadata(this.linkMetadataName, this.linkMetadataValue).getFullValue();
    }
    this.tag.value = valueToUse;
  }

  checkError() {
    if (this.tag.type === TypeEnum.LINK) {
      this.tagInError.emit(this.checkFormatTagLink() || this.tagLinkValueIsTooLong(this.tag.value));
    } else {
      this.displayInfoOnChange = true;
      this.tagInError.emit(false);
    }
  }

  onInput(value) {
    if (this.tag.type === TypeEnum.LINK) {
      this.linkMetadataName = '';
      this.linkMetadataValue = '';
    }
    if (this.tag.type === TypeEnum.DATETIME || this.tag.type === TypeEnum.DATE || this.tag.type === TypeEnum.PERIOD) {
      if (value !== this.tag.value) {
        this.tag.value = value;
      }
    } else {
      if (this.showMode) {
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
          this.tag.value = value;
        }, 2000);
      } else {
        this.tag.value = value;
      }
    }
  }

  getCurrentLang(): string {
    return this.translateService.currentLang;
  }

  getLinkName() {
    return this.linkMetadataName;
  }

  setLinkName(name: string) {
    this.linkMetadataName = name;
    const newFullValue = new LinkMetadata(name, this.linkMetadataValue).getFullValue();
    if (this.showMode) {
      this.tag.value = newFullValue;
    } else {
      this.tag.defaultValue = newFullValue;
    }
  }

  getLinkValue() {
    return this.linkMetadataValue;
  }

  setLinkValue(value: string) {
    this.linkMetadataValue = value;
    const newFullValue = new LinkMetadata(this.linkMetadataName, value).getFullValue();
    if (this.showMode) {
      this.tag.value = newFullValue;
    } else {
      this.tag.defaultValue = newFullValue;
    }
  }

  handleTemplateDate(date: Date, dateType: string) {
    if (dateType === 'signNotBefore') {
      this.minDay = this.diffDate(date) + 1;

    } else if (dateType === 'signNotAfter') {
      this.maxDay = this.diffDate(date) !== 0 ? this.diffDate(date) - 1 : 0;
    }
  }

  diffDate(defaultVal): number {
    if (defaultVal) {
      return Number(Math.ceil(Math.abs(defaultVal - new Date(0).getTime()) / (1000 * 60 * 60 * 24)));
    } else {
      return undefined;
    }
  }

  checkFormatTagLink(): boolean {
    if (this.tag && this.tag.type === this.typeEnum.LINK) {
      if (this.showMode) {
        const linkValue = this.tag.value.split('|')[1];
        const linkName = this.tag.value.split('|')[0];
        if (linkName && !linkValue) {
          return true;
        }
        if (linkValue) {
          return !REGEXES.LINK.test(linkValue);
        }
      } else {
        const linkValue = this.tag.defaultValue.split('|')[1];
        const linkName = this.tag.defaultValue.split('|')[0];
        if (linkName && !linkValue) {
          return true;
        }
        if (linkValue) {
          return !REGEXES.LINK.test(linkValue);
        }
      }
    }
    return false;
  }

  tagValueIsTooLong(tagValue: string): boolean {
    return (tagValue && tagValue.length > this.maxInputLength);
  }

  tagLinkValueIsTooLong(tagValue: string): boolean {
    return (tagValue && tagValue.length > this.maxInputLinkLength);
  }

  openEditListModal(tag: TagData) {
    const modalRef = this.modal.open(ModalManageTagListComponent, {
      size: 'lg', backdrop: false, centered: true
    });
    modalRef.componentInstance.setTag(tag);
    modalRef.componentInstance.setUpdateMode(this.editMode);
    modalRef.result.then((updatedTag) => {
      if (updatedTag) {
        tag.defaultValue = updatedTag.defaultValue;
        tag.possibleValues = CloneUtils.cloneStringArray(updatedTag.possibleValues);
      }
    });
  }

  public sendUpdate() {
    this.displayInfoOnChange= false;
    if (this.tag.value !== this.value) {
      this.onUpdate.emit(this.tag);
    }
  }

  private initialiseLinkMetadataValues() {
    if (this.tag && this.tag.type === TypeEnum.LINK) {
      const valueToUse: string = this.showMode ? this.tag.value  : this.tag.defaultValue;
      const linkMetadata = LinkMetadata.ofFullValue(valueToUse);
      this.linkMetadataName = linkMetadata.name;
      this.linkMetadataValue = linkMetadata.value;
    }
  }

}
