/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TagData } from './tagData';


/**
 * Session details
 */
export interface SessionData { 
    /**
     * True if there is any configuration
     */
    configuration?: boolean;
    /**
     * Creation date
     */
    createdOn?: Date;
    /**
     * Indicates if the session is expired regarding notAfter date
     */
    expired?: boolean;
    /**
     * External identifier if has a remote parent
     */
    externalId?: string;
    /**
     * Session
     */
    id?: number;
    /**
     * Session label (in the enduser language)
     */
    label?: string;
    /**
     * Last modification date
     */
    modifiedOn?: Date;
    /**
     * Organisation
     */
    organisationId?: number;
    /**
     * Session owner id
     */
    ownerId?: any;
    /**
     * Purge date
     */
    purgedOn?: Date;
    /**
     * True if the requester is the manager of the session
     */
    requesterManager?: boolean;
    /**
     * True if the requester is signer of at least one of the steps
     */
    requesterSigner?: boolean;
    /**
     * Session status
     */
    status?: SessionData.StatusEnum;
    /**
     * Tags associated
     */
    tags?: Array<TagData>;
    /**
     * True if the session is a template
     */
    template?: boolean;
    /**
     * The id of the template if the session was created from this template
     */
    templateId?: number;
    tenantId?: number;
}
export namespace SessionData {
    export type StatusEnum = 'CREATED' | 'STARTED' | 'COMPLETED' | 'DISABLED' | 'DECLINED' | 'CANCELED' | 'SUSPENDED';
    export const StatusEnum = {
        CREATED: 'CREATED' as StatusEnum,
        STARTED: 'STARTED' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum,
        DISABLED: 'DISABLED' as StatusEnum,
        DECLINED: 'DECLINED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum,
        SUSPENDED: 'SUSPENDED' as StatusEnum
    };
}
