/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-tenant-directories.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../core/pipes/translate.pipe";
import * as i4 from "../../../core/service/translate.service";
import * as i5 from "./modal-tenant-directories.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@angular/forms";
var styles_ModalTenantDirectoriesComponent = [i0.styles];
var RenderType_ModalTenantDirectoriesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalTenantDirectoriesComponent, data: {} });
export { RenderType_ModalTenantDirectoriesComponent as RenderType_ModalTenantDirectoriesComponent };
function View_ModalTenantDirectoriesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-check"]], null, null, null, null, null))], null, null); }
function View_ModalTenantDirectoriesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "tr", [["class", "table-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "td", [["class", "table-row-i1"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalTenantDirectoriesComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "td", [["class", "table-row-i2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "switcher switcher-right"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "disabled-switcher": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 0, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "input", [["class", "switcher-checkbox"], ["type", "checkbox"]], [[8, "checked", 0], [8, "disabled", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange($event.target.checked, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "switcher-toggle"]], [[2, "disabled", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.$implicit === _co.defaultDirectory); _ck(_v, 4, 0, currVal_1); var currVal_2 = "switcher switcher-right"; var currVal_3 = _ck(_v, 9, 0, ((_v.context.$implicit === _co.defaultDirectory) && (_co.activeDirectories.length > 1))); _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_4 = i1.ɵinlineInterpolate(1, "switch-dir-", _v.context.index, ""); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.isInActiveArray(_v.context.$implicit); var currVal_6 = (((_v.context.$implicit === _co.defaultDirectory) && (_co.activeDirectories.length > 1)) || _co.disabled); var currVal_7 = i1.ɵinlineInterpolate(1, "switch-dir-", _v.context.index, ""); _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.disabled; _ck(_v, 12, 0, currVal_8); }); }
export function View_ModalTenantDirectoriesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "modal-directories"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.onClose.emit();
        var pd_0 = (_co.activeModal.close("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 9, "table", [["class", "custom-table table-directories"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "tr", [["class", "table-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService]), (_l()(), i1.ɵeld(13, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i1.ChangeDetectorRef, i4.TranslateService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalTenantDirectoriesComponent_1)), i1.ɵdid(17, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.tenantDirectories; _ck(_v, 17, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("USER.DEFAULT_DIRECTORY")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("DIRECTORY.ALIAS")); _ck(_v, 11, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("DIRECTORY.ACCESS")); _ck(_v, 14, 0, currVal_2); }); }
export function View_ModalTenantDirectoriesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ModalTenantDirectoriesComponent_0, RenderType_ModalTenantDirectoriesComponent)), i1.ɵdid(1, 114688, null, 0, i5.ModalTenantDirectoriesComponent, [i6.NgbActiveModal, i7.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalTenantDirectoriesComponentNgFactory = i1.ɵccf("ng-component", i5.ModalTenantDirectoriesComponent, View_ModalTenantDirectoriesComponent_Host_0, { tenantDirectories: "tenantDirectories", activeTenantDirectories: "activeTenantDirectories", defaultDirectory: "defaultDirectory", disabled: "disabled" }, { tenantDirectoryResult: "tenantDirectoryResult", onClose: "onClose" }, []);
export { ModalTenantDirectoriesComponentNgFactory as ModalTenantDirectoriesComponentNgFactory };
