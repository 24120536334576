/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SortingFilter } from './sortingFilter';


/**
 * Session filter (null values ignored)
 */
export interface SessionFilter { 
    /**
     * Created after
     */
    after?: Date;
    /**
     * Created before
     */
    before?: Date;
    /**
     * Document name
     */
    documentName?: string;
    /**
     * List of involved enduser
     */
    enduserIds?: Array<number>;
    /**
     * Signature status
     */
    expired?: boolean;
    /**
     * List of identifiers
     */
    ids?: Array<number>;
    /**
     * Number of rows to return from the offset
     */
    limit?: number;
    /**
     * Offset of the first row to return
     */
    offset?: number;
    /**
     * Search for an organisation
     */
    organisationId?: number;
    /**
     * Priority
     */
    priority?: number;
    /**
     * Signature status
     */
    signatureStatus?: SessionFilter.SignatureStatusEnum;
    /**
     * Signature status list
     */
    signatureStatusList?: Array<SessionFilter.SignatureStatusListEnum>;
    /**
     * List of field used to order the data
     */
    sortingFilters?: Array<SortingFilter>;
    /**
     * Session status
     */
    status?: SessionFilter.StatusEnum;
    /**
     * Tag identifier
     */
    tagId?: number;
    /**
     * Tag value
     */
    tagValue?: string;
    /**
     * Content to be filtered
     */
    text?: string;
}
export namespace SessionFilter {
    export type SignatureStatusEnum = 'STARTED' | 'DECLINED' | 'SIGNED';
    export const SignatureStatusEnum = {
        STARTED: 'STARTED' as SignatureStatusEnum,
        DECLINED: 'DECLINED' as SignatureStatusEnum,
        SIGNED: 'SIGNED' as SignatureStatusEnum
    };
    export type SignatureStatusListEnum = 'STARTED' | 'DECLINED' | 'SIGNED';
    export const SignatureStatusListEnum = {
        STARTED: 'STARTED' as SignatureStatusListEnum,
        DECLINED: 'DECLINED' as SignatureStatusListEnum,
        SIGNED: 'SIGNED' as SignatureStatusListEnum
    };
    export type StatusEnum = 'CREATED' | 'STARTED' | 'COMPLETED' | 'DISABLED' | 'DECLINED' | 'CANCELED' | 'SUSPENDED';
    export const StatusEnum = {
        CREATED: 'CREATED' as StatusEnum,
        STARTED: 'STARTED' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum,
        DISABLED: 'DISABLED' as StatusEnum,
        DECLINED: 'DECLINED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum,
        SUSPENDED: 'SUSPENDED' as StatusEnum
    };
}
