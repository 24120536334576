/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../core/pipes/translate.pipe";
import * as i2 from "../../../core/service/translate.service";
import * as i3 from "./modal-delete-session.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../../../../luxtrust-cosi-api/api/session.service";
import * as i6 from "../../../services/services/alert-service";
var styles_ModalDeleteSessionComponent = [];
var RenderType_ModalDeleteSessionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalDeleteSessionComponent, data: {} });
export { RenderType_ModalDeleteSessionComponent as RenderType_ModalDeleteSessionComponent };
export function View_ModalDeleteSessionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["header", 1]], null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "modal-body break-words"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", "\n"])), i0.ɵpod(9, { sessionName: 0 }), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService]), (_l()(), i0.ɵeld(11, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService]), (_l()(), i0.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(16, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i0.ChangeDetectorRef, i2.TranslateService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.title)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 10).transform(_co.message, _ck(_v, 9, 0, ((_co.session == null) ? null : _co.session.label)))); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.deleting; _ck(_v, 12, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform("MODAL_DELETE_SESSION.DELETE")); _ck(_v, 13, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 16, 0, i0.ɵnov(_v, 17).transform("MODAL_DELETE_SESSION.CLOSE")); _ck(_v, 16, 0, currVal_4); }); }
export function View_ModalDeleteSessionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ModalDeleteSessionComponent_0, RenderType_ModalDeleteSessionComponent)), i0.ɵdid(1, 180224, null, 0, i3.ModalDeleteSessionComponent, [i4.NgbActiveModal, i5.SessionService, i6.AlertService], null, null)], null, null); }
var ModalDeleteSessionComponentNgFactory = i0.ɵccf("ng-component", i3.ModalDeleteSessionComponent, View_ModalDeleteSessionComponent_Host_0, { session: "session", title: "title", message: "message" }, {}, []);
export { ModalDeleteSessionComponentNgFactory as ModalDeleteSessionComponentNgFactory };
