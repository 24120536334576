<div class="form-group custom-autocomplete">
  <mat-form-field class="formFieldCustom" [floatLabel]="manageLabel()">
    <div class="input-group" [ngClass]="{'disabled': isDisabled}">
      <div class="input-group-prepend" *ngIf="formLabel">
        <span class="input-group-text">{{ formLabel | translate}}</span>
      </div>
      <div class="input">
        <input #inputAutoComplit (click)="clearInput(true)"
                type="text"
                class="inputSelect"
                [tabIndex]="tabIndex"
                [placeholder]='placeholder'
                aria-label="Number"
                matInput
                [id]="testId"
                autocomplete="off"
                [formControl]="control"
                [matAutocomplete]="auto">
      </div>
      <span class="caret" (click)="clearInput(true)">
        <i aria-hidden="true" class="fas fa-caret-down"></i>
      </span>
      <span *ngIf="icon" class="input-group-text" >
        <i class='pl-2' aria-hidden="true" class="fas fa-{{icon}}"></i>
      </span>
      <mat-autocomplete ngbAutofocus  (closed)="checkValue()"   #auto="matAutocomplete" (optionSelected)="selectOption($event)"[displayWith]="display">
        <ng-container *ngIf="filterWithInput && !isDisabled">
          <mat-option *ngFor="let option of filteredOptions  | async | orderBy: [sortAsc ?  'key' : '']| orderLanguage : isLangue | searchFilter : isFilter"
                      [value]="option" [ngbTooltip]="option.key"
                      [ngClass]="{'keyChoose' : option?.key === valueChoose?.key} "
                      placement="right"
                      container="body"
                      tooltipClass="custom-tooltip" >
            {{option.key}}
          </mat-option>
        </ng-container>

        <ng-container *ngIf="!filterWithInput && datasProviders.length > 0 && !isDisabled">
          <mat-option *ngFor="let option of datasProviders  | orderBy: [sortAsc ?  'key' : '']"
                      [value]="option" [ngbTooltip]="option.key"
                      placement="right"
                      container="body"
                      tooltipClass="custom-tooltip" >
            {{option.key}}
          </mat-option>
        </ng-container>
        <ng-container *ngIf="!filterWithInput && datasProviders.length === 0 && !isDisabled">
          <mat-option class="noResult"> {{'NEW_SESSION.SIGNERS.NO_RESULT' | translate}}</mat-option>
        </ng-container>
        <ng-container *ngIf="isDisabled">
        </ng-container>

      </mat-autocomplete>
    </div>
  </mat-form-field>
</div>
