import { ElementRef, HostListener, ViewChild } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganisationData, StepData, WorkflowData } from 'luxtrust-cosi-api';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { KEYBOARD } from 'src/app/app.constant';

@Component({
  selector: 'app-organisations-table',
  templateUrl: './organisations-table.component.html',
  styleUrls: ['./organisations-table.component.scss']
})
export class OrganisationsTableComponent implements OnInit {
  @Input() noResult: string;
  @Input() placeholder: string;
  @Input() selectedOrganisation: OrganisationData[] = [];
  @Input() availableOrganisation: OrganisationData[] = [];
  @Input() singleList: boolean;
  @Input() borderBottom = true;
  @Input() search = false;
  @Input() idPostfix = '';
  @Output() outputSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() addOrganisation: EventEmitter<OrganisationData> = new EventEmitter<OrganisationData>();
  @Output() removeOrganisation: EventEmitter<OrganisationData> = new EventEmitter<OrganisationData>();
  @Output() outputUpdateExpected: EventEmitter<{}> = new EventEmitter<{}>();
  inputSearch: string;
  debouncer: Subject<string> = new Subject();
  searchListOpen = false;
  activeOrganisationIndex = -1;

  @ViewChild('searchList', {
    read: ElementRef, static: false
  }) searchList;

  @ViewChild('inputSearchOrganisation', {
    read: ElementRef, static: false
  }) inputSearchUser;

  focusOnSerachOrganisation(){
    this.inputSearchUser.nativeElement.focus();
  }

  constructor() {
    this.registerInputDebouncer();
  }

  ngOnInit() {
  }
    /**
   * listen where the user is clicking. If he is clicking outside this component
   * the list box will close if it's opened.
   * @param targetElement
   */
  @HostListener('document:click', ['$event.target']) onClick(targetElement) {
    let insideSearchList;
    if (this.searchList) {
      insideSearchList = this.searchList.nativeElement.contains(targetElement);
    }
    if (!insideSearchList) {
      this.onBlurSearch();
    }
  }

  handleInputChange(value: string) {
    const trimmedValue = value.trim();
    if (trimmedValue) {
      this.debouncer.next(value);
    }
  }

  registerInputDebouncer() {
    this.debouncer.pipe(debounceTime(300))
      .subscribe((value) => this.outputSearch.emit(value));
  }

  onFocusSearch() {
    this.searchListOpen = true;
    this.outputSearch.emit('');
  }

  onBlurSearch() {
    this.searchListOpen = false;
    this.inputSearch = '';
  }


  handleOnAddOrReplaceEnduser(organisationData : OrganisationData) {
    this.addOrganisation.emit(organisationData);
    this.inputSearch = '';
    this.searchListOpen = false;
    this.focusOnSerachOrganisation();
  }

  handleOnUpdateExpected(event) {
    this.outputUpdateExpected.emit(event);
  }

  signerKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEYBOARD.ARROW_UP:
        if (this.activeOrganisationIndex > -1) {
          this.activeOrganisationIndex--;
        }
        break;
      case KEYBOARD.ARROW_DOWN:
        if (this.activeOrganisationIndex < this.availableOrganisation.length - 1) {
          this.activeOrganisationIndex++;
        }
        break;
      case KEYBOARD.ENTER:
        if (this.activeOrganisationIndex !== -1) {
          this.handleOnAddOrReplaceEnduser(this.availableOrganisation[this.activeOrganisationIndex]);
        }
        break;
      default:
        break;
    }
  }

  handleOnAddRemove(organisation : OrganisationData) {
    this.removeOrganisation.emit(organisation);
  }


}
