/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuditTrailService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Generate an audit trail report
     * 
     * @param sessionId Signature session for which the report is generated
     * @param seal seal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAudittrail(sessionId: number, seal?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadAudittrail(sessionId: number, seal?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadAudittrail(sessionId: number, seal?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadAudittrail(sessionId: number, seal?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling downloadAudittrail.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (seal !== undefined && seal !== null) {
            queryParameters = queryParameters.set('seal', <any>seal);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/audit/direct`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a the audit trail of a signature session
     * Returns the audit-trail of a signature session based on the provided sessionID
     * @param applicationId application identifier consuming this API
     * @param applicationSecret application secret consuming this API (RSA signed - Public key must be shared with LuxTrust Signature Platform)
     * @param sessionId ID of session to return
     * @param tenantName Name of the tenant related to your subscription
     * @param seal Boolean allowing that allows when set to true to apply a server seal on the audit-trail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAuditTrailBySessionId(applicationId: string, applicationSecret: string, sessionId: number, tenantName: string, seal?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAuditTrailBySessionId(applicationId: string, applicationSecret: string, sessionId: number, tenantName: string, seal?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAuditTrailBySessionId(applicationId: string, applicationSecret: string, sessionId: number, tenantName: string, seal?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAuditTrailBySessionId(applicationId: string, applicationSecret: string, sessionId: number, tenantName: string, seal?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling getAuditTrailBySessionId.');
        }

        if (applicationSecret === null || applicationSecret === undefined) {
            throw new Error('Required parameter applicationSecret was null or undefined when calling getAuditTrailBySessionId.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getAuditTrailBySessionId.');
        }

        if (tenantName === null || tenantName === undefined) {
            throw new Error('Required parameter tenantName was null or undefined when calling getAuditTrailBySessionId.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (seal !== undefined && seal !== null) {
            queryParameters = queryParameters.set('seal', <any>seal);
        }

        let headers = this.defaultHeaders;
        if (applicationId !== undefined && applicationId !== null) {
            headers = headers.set('applicationId', String(applicationId));
        }
        if (applicationSecret !== undefined && applicationSecret !== null) {
            headers = headers.set('applicationSecret', String(applicationSecret));
        }
        if (tenantName !== undefined && tenantName !== null) {
            headers = headers.set('tenantName', String(tenantName));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/directsign/v1/audittrail/${encodeURIComponent(String(sessionId))}`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate an audit trail report
     * 
     * @param sessionId Signature session for which the report is generated
     * @param seal seal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAudittrail(sessionId: number, seal?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAudittrail(sessionId: number, seal?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAudittrail(sessionId: number, seal?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAudittrail(sessionId: number, seal?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getAudittrail.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (seal !== undefined && seal !== null) {
            queryParameters = queryParameters.set('seal', <any>seal);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/sessions/${encodeURIComponent(String(sessionId))}/audit`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all the audit trails associated to the uploaded document
     * 
     * @param file file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveFromDocument(file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public retrieveFromDocument(file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public retrieveFromDocument(file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public retrieveFromDocument(file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post(`${this.basePath}/api/audit/retrieve-from-document`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
