import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PlatformService} from 'luxtrust-cosi-api/api/platform.service';
import {TenantService} from 'luxtrust-cosi-api/api/tenant.service';
import {ConfigData} from 'luxtrust-cosi-api/model/configData';
import {AppService} from "./app.service";

/**
 * Service used to initialize and get preloaded configurations.
 */
@Injectable()
export class ConfigurationService {

  platformConfig: Promise<ConfigData>;
  configData: ConfigData;
  tenantConfig: Promise<any>;

  constructor(private router: Router,
              private tenantService: TenantService,
              private platformService: PlatformService,
              private appService: AppService
  ) {
  }

  initPlatformConfiguration() {
    this.platformConfig = this.platformService.getConfig().toPromise().then((configData: ConfigData) => {
      this.configData = configData;
      this.appService.config = configData;
      return configData;
    });
  }

  initTenantConfiguration(tenantName: string) {
    if (tenantName) {
      this.tenantConfig = this.tenantService.getTenantConfig(tenantName).toPromise()
        .then((configuration: { [key: string]: any; }) => this.sanitizeTenantConfigurationKeys(configuration))
        .catch(() => {
          this.platformConfig = this.platformService.getConfig().toPromise().then((configData: ConfigData) => {
            this.configData = configData
            if (!!this.configData && !!this.configData.config && !!this.configData.config.redirectToDefaultTenant) {
              this.router.navigateByUrl(`/login?tenantName=${this.configData.config.defaultTenantName}`, {replaceUrl: true});
              throw new Error(`Cannot get tenant for: ${tenantName}, redirecting to default tenant`);
            } else {
              this.router.navigate(['/no-tenant-name']);
              throw new Error('Cannot get tenant configuration: Unknown tenant');
            }

          });
        });
    } else {
      this.tenantConfig = Promise.reject('Cannot get tenant configuration: No tenant information');
    }
  }

  private sanitizeTenantConfigurationKeys(configuration: { [key: string]: any; }) {
    const sanitizedConfiguration = {};
    // tslint:disable-next-line:forin
    for (const configurationKey of Object.keys(configuration)) {
      sanitizedConfiguration[configurationKey.trim()] = configuration[configurationKey];
    }
    return sanitizedConfiguration;
  }
}
