import {Injectable} from '@angular/core';
import cssVars from 'css-vars-ponyfill';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {OrganisationService} from 'luxtrust-cosi-api/api/organisation.service';
import {TenantService} from 'luxtrust-cosi-api/api/tenant.service';
import {ConfigData} from 'luxtrust-cosi-api/model/configData';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {UpdateVisualIdentityPayload} from 'luxtrust-cosi-api/model/updateVisualIdentityPayload';
import {VisualIdentityData} from 'luxtrust-cosi-api/model/visualIdentityData';
import {BehaviorSubject, Observable} from 'rxjs';
import {IMAGES} from '../../app.constant';
import {ApiError} from '../../error/api-error.model';
import {AlertService} from './alert-service';
import {AppService} from './app.service';
import {ConfigurationService} from './configuration.service';

@Injectable()
export class VisualIdentityService {

  enduser$: Observable<EnduserData>;
  endUser: EnduserData;

  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  borderRadius: string;
  logo: string;
  background: string;
  mailServerAlias: string;
  smsServerAlias: string;

  logo$ = new BehaviorSubject<string>(null);

  private defaultVisualIdentity: VisualIdentityData = {
    primaryColor: '#025072',
    secondaryColor: '#70963E',
    tertiaryColor: '#B31B29',
    borderRadius: '4px',
    logo: IMAGES.DEFAULT_LUXTRUST_COSI_LOGO,
    background: IMAGES.DEFAULT_LUXTRUST_COSI_BACKGROUND,
    smsServerAlias: null,
    mailServerAlias: null
  };

  constructor(private organisationService: OrganisationService,
              private alertService: AlertService,
              private configurationService: ConfigurationService,
              private tenantService: TenantService,
              private endUserService: EnduserService,
              private appService: AppService
  ) {
  }

  getEndUser() {
    this.enduser$ = this.appService.getUser$();
    this.enduser$.subscribe((endUser: EnduserData) => {
      this.endUser = endUser;
    });
  }

  saveVisualIdentity(body: UpdateVisualIdentityPayload, forTenant: boolean) {
    this.getEndUser();

    body.primaryColor = body.primaryColor ? body.primaryColor : this.primaryColor;
    body.secondaryColor = body.secondaryColor ? body.secondaryColor : this.secondaryColor;
    body.tertiaryColor = body.tertiaryColor ? body.tertiaryColor : this.tertiaryColor;
    body.borderRadius = body.borderRadius ? body.borderRadius : this.borderRadius;
    body.logo = body.logo ? body.logo : this.logo;
    body.background = body.background ? body.background : this.background;
    body.mailServerAlias = body.mailServerAlias ? body.mailServerAlias : this.mailServerAlias;
    body.smsServerAlias = body.smsServerAlias ? body.smsServerAlias : this.smsServerAlias;

    localStorage.setItem('visualIdentity', JSON.stringify(body));
    if (forTenant) {
      return this.tenantService.updateTenant(body).toPromise().then(() => {
        location.reload();
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
    } else {
      return this.organisationService.updateOrganisation(this.endUser.organisation.organisationId, body).toPromise().then(() => {
        location.reload();
      }).catch((error: ApiError) => {
        this.alertService.errorApi(error);
      });
    }
  }

  loadVisualIdentityWhenLogged() {
    this.endUserService.getCurrentEnduserVi().toPromise().then((load: VisualIdentityData) => {
      this.setPrefs(load);
      const base64img = IMAGES.BASE_64_PREFIX + this.background;
      cssVars({
        variables: {
          '--customPrimary': this.primaryColor,
          '--customSecondary': this.secondaryColor,
          '--customTertiary': this.tertiaryColor,
          '--customRadius': this.borderRadius,
          'background-image': `url(${base64img})`
        }
      });

      this.setDocumentDomProperties();

      const body: UpdateVisualIdentityPayload = {
        primaryColor: this.primaryColor,
        secondaryColor: this.secondaryColor,
        tertiaryColor: this.tertiaryColor,
        borderRadius: this.borderRadius,
        logo: this.logo,
        background: this.background,
        mailServerAlias: this.mailServerAlias,
        smsServerAlias: this.smsServerAlias
      };
      localStorage.setItem('visualIdentity', JSON.stringify(body));
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
    this.setDocumentDomProperties();
  }

  initVisualIdentity(tenantName) {
    if (tenantName !== '') {
      this.tenantService.searchTenantVi(tenantName).toPromise()
        .then((load: VisualIdentityData) => {
          if (load !== null) {
            for (const prop in load) {
              if (load[prop] !== null) {
                return Promise.resolve(load);
              }
            }
          }
          return this.configurationService.configData.visualIdentity;
        }).catch(() => {
        if (this.configurationService.configData.visualIdentity) {
          return this.configurationService.configData.visualIdentity;
        }
        return this.defaultVisualIdentity;
      }).then((load: VisualIdentityData) => {
        this.setPrefs(load);
        this.setDocumentDomProperties();
      });
    } else {
      this.configurationService.platformConfig
        .then((configData: ConfigData) => configData.visualIdentity)
        .catch(() => this.defaultVisualIdentity)
        .then((load: VisualIdentityData) => {
          this.setPrefs(load);
          this.setDocumentDomProperties();
        });

    }
  }

  private setPrefs(visualIdentity: VisualIdentityData) {
    this.primaryColor = visualIdentity.primaryColor !== null ? visualIdentity.primaryColor : this.defaultVisualIdentity.primaryColor;
    this.secondaryColor = visualIdentity.secondaryColor !== null ? visualIdentity.secondaryColor : this.defaultVisualIdentity.secondaryColor;
    this.tertiaryColor = visualIdentity.tertiaryColor !== null ? visualIdentity.tertiaryColor : this.defaultVisualIdentity.tertiaryColor;
    this.borderRadius = visualIdentity.borderRadius !== null ? visualIdentity.borderRadius : this.defaultVisualIdentity.borderRadius;
    this.logo = visualIdentity.logo !== null && visualIdentity.logo !== '' ? visualIdentity.logo : this.defaultVisualIdentity.logo;
    this.background = visualIdentity.background !== null && visualIdentity.background !== '' ? visualIdentity.background : this.defaultVisualIdentity.background;
    this.mailServerAlias = visualIdentity.mailServerAlias !== null ? visualIdentity.mailServerAlias : null;
    this.smsServerAlias = visualIdentity.smsServerAlias !== null ? visualIdentity.smsServerAlias : null;

    this.logo$.next(this.logo);
  }

  private setDocumentDomProperties() {
    const base64img = IMAGES.BASE_64_PREFIX + this.background;
    document.body.style.setProperty('--customPrimary', this.primaryColor);
    document.body.style.setProperty('--customSecondary', this.secondaryColor);
    document.body.style.setProperty('--customTertiary', this.tertiaryColor);
    document.body.style.setProperty('--customRadius', this.borderRadius);
    document.body.style.setProperty('background-image', `url(${base64img})`);
  }

  resetDefaultVisualIdentity(forTenant: boolean) {
    const body: UpdateVisualIdentityPayload = {
      primaryColor: this.defaultVisualIdentity.primaryColor,
      secondaryColor: this.defaultVisualIdentity.secondaryColor,
      tertiaryColor: this.defaultVisualIdentity.tertiaryColor,
      borderRadius: this.defaultVisualIdentity.borderRadius,
      logo: this.defaultVisualIdentity.logo,
      background: this.defaultVisualIdentity.background,
      mailServerAlias: null,
      smsServerAlias: null
    };

    localStorage.removeItem('visualIdentity');
    return this.saveVisualIdentity(body, forTenant);
  }
}
