import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from 'src/app/core/service/translate.service';
import * as introJs from 'intro.js/intro.js';
import {AuthenticationService} from 'luxtrust-cosi-api/api/authentication.service';
import {DelegateService} from 'luxtrust-cosi-api/api/delegate.service';
import {EnduserService} from 'luxtrust-cosi-api/api/enduser.service';
import {ItsmeAuthenticationService} from 'luxtrust-cosi-api/api/itsmeAuthentication.service';
import {OrelyAuthenticationService} from 'luxtrust-cosi-api/api/orelyAuthentication.service';
import {WatermarkService} from 'luxtrust-cosi-api/api/watermark.service';
import {CreateEnduserDelegatePayload} from 'luxtrust-cosi-api/model/createEnduserDelegatePayload';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {EnduserDelegateData} from 'luxtrust-cosi-api/model/enduserDelegateData';
import {ItsmeAuthorizationDto} from 'luxtrust-cosi-api/model/itsmeAuthorizationDto';
import {ItsmeInfoDto} from 'luxtrust-cosi-api/model/itsmeInfoDto';
import {WatermarkData} from 'luxtrust-cosi-api/model/watermarkData';
import {TenantService} from 'luxtrust-cosi-api/api/tenant.service';
import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/internal/operators';
import {ValidatorHelper} from 'src/app/services/validators/validators.helper';
import {KEYBOARD, REGEXES} from '../../../app.constant';
import {ApiError} from '../../../error/api-error.model';
import {ModalOrelyLinkComponent} from '../../../login/modal-orely-link/modal-orely-link.component';
import {AlertService} from '../../../services/services/alert-service';
import {AppService} from '../../../services/services/app.service';
import {ItsmeAuthServiceType, ItsmeService} from '../../../services/services/itsme.service';
import {DateTimeModel} from '../date-time-picker/date-time.model';
import {EnduserAttributeData} from 'luxtrust-cosi-api/model/enduserAttributeData';
import {ConfigurationService} from "../../../services/services/configuration.service";
import {Strings} from '../../../services/utils/strings';

@Component({
  templateUrl: './user-profile.component.html', styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @ViewChild('phoneNumber', {static: false}) phoneNumber: ElementRef;

  currentlyLoggedUser: EnduserData;
  currentSsn: string;

  newWatermarkFile: File;
  newWatermarkPreview: string;
  currentWatermark: WatermarkData;

  itsmeInfo: ItsmeInfoDto;
  itsmeAuthorization$: Observable<ItsmeAuthorizationDto>;
  orelyAuthId: number;

  availableSigners: EnduserData[];
  inputSigner: string;
  activeSignerIndex = -1;

  signerDelegated: EnduserData; // valid signer ready to be added
  signersDelegated: EnduserDelegateData[] = []; // Signers added to be delegates
  signersEnduser: EnduserData[] = []; // For card-info.component
  delegatedUntil: string;
  delegatedFrom: string;
  dates: { from: string; to: string; }[];
  labels: { step: string; session: string; }[];
  now: NgbDateStruct;
  tomorrow: NgbDateStruct;
  minHours = new Date().getHours();
  minMinutes = new Date().getMinutes();
  inputSignerOpen = false;

  showDescription: Boolean = false;
  wrongPeriodNotification = false;
  timeNotCorrectNotification = false;
  defaultDate: DateTimeModel;
  defaultWeekDate: DateTimeModel;
  introJSVisitProfile = introJs();
  currentUser;
  currentPasswordVisible: boolean;
  newPasswordVisible: boolean;
  confirmPasswordVisible: boolean;
  resetForm: FormGroup;
  currentPasswordControl: FormControl;
  newPasswordControl: FormControl;
  confirmPasswordControl: FormControl;
  updateNumberPhoned: FormGroup;
  phoneNumberHasChange = false;
  eventInputPassword = false;
  errorDateBefore = false;
  public displayValidationNewPassword = false;
  private readonly allowedSignatureImageExtensions = ['jpeg', 'jpg', 'png'];
  updatingPhoneNumber = false;
  addingDelegate = false;
  removingDelegate = false;
  uploadingWatermark = false;
  removingWatermark = false;
  changingPassword = false;
  hasAnAttributeChanged = false;
  userAttributes: Array<EnduserAttributeData> = [];
  isDirectoryAlterable = true;
  currentPhoneNumber: string;
  displayResetPassword = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly watermarkService: WatermarkService,
    public readonly appService: AppService,
    private readonly formBuilder: FormBuilder,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly orelyAuthenticationService: OrelyAuthenticationService,
    private readonly itsmeAuthenticationService: ItsmeAuthenticationService,
    private readonly itsmeService: ItsmeService,
    private readonly endUserService: EnduserService,
    private readonly modal: NgbModal,
    private readonly delegateService: DelegateService,
    private readonly tenantService: TenantService,
    private readonly configurationService: ConfigurationService
  ) {
    this.introJSVisiteProfileSetup();
    this.currentPasswordVisible = false;
    this.newPasswordVisible = false;
    this.confirmPasswordVisible = false;
  }

  initTuto() {
    this.translateService.get('USER_PROFILE.TUTORIAL.INTRO').subscribe((res) => {
      this.introJSVisiteProfileSetup();
    });
    // this.currentUser = this.appService.getCurrentUserId();
    // let valueLSVisitProfil = JSON.parse(localStorage.getItem('tutoVisitProfil'+this.currentUser));
    // if ( !valueLSVisitProfil ){
    // this.launchTuto();
    // localStorage.setItem('tutoVisitProfil' + this.currentUser, 'true' );
    // }
    this.translateService.onLangChange.subscribe(() => {
      this.introJSVisiteProfileSetup();
    });

  }

  ngOnInit() {
    this.initFormsGroup();
    this.initTuto();
    this.configurationService.platformConfig.then(data => {
      if (data.itsmeAuthActive) {
        this.itsmeAuthorization$ = this.itsmeAuthenticationService.itsmeInfo()
          .pipe(
            take(1),
            tap((info: ItsmeInfoDto) => this.itsmeInfo = info),
            switchMap((info: ItsmeInfoDto) => {
              let service: ItsmeAuthServiceType;
              if (info.hasAccount) {
                service = ItsmeAuthServiceType.UNLINK;
              } else {
                service = ItsmeAuthServiceType.LINK;
              }
              const redirectUri = this.itsmeService.getRedirectUrlForAuth(service);
              return this.itsmeAuthenticationService.itsmeAuthorizationUrl(service, redirectUri);
            }),
            catchError((error: ApiError) => undefined)
          );
      }
    });

    this.getOrelySsn();

    this.appService.getUser$().pipe(filter(x => !!x)).subscribe(currentlyLoggedUser => {
      if (currentlyLoggedUser) {
        this.tenantService.getTenantConfig(currentlyLoggedUser.tenantName).toPromise().then(tenantConfig => {
          const key = 'directory.' + currentlyLoggedUser.directoryAlias + '.is-alterable';
          this.isDirectoryAlterable = !(tenantConfig[key] === '0' || tenantConfig[key] === 'false');
          const key2 = 'directory.' + currentlyLoggedUser.directoryAlias + '.is-credential-reset-available';
          this.displayResetPassword = new Strings(tenantConfig[key2]).asBoolean();
        });

        this.updateNumberPhoned.controls['phoneNumber'].setValue(currentlyLoggedUser.phone);
        this.currentlyLoggedUser = currentlyLoggedUser;
        this.userAttributes = currentlyLoggedUser.attributesV2;
        this.watermarkService.getWatermarkList(undefined, currentlyLoggedUser.id, WatermarkData.TypeEnum.USER)
          .toPromise().then(watermarks =>
          this.currentWatermark = watermarks.length && watermarks[0]
        ).catch((error: ApiError) => {
          this.alertService.errorApi(error);
        });

        // Add the delegates of the current enduser.
        this.delegateService.getEnduserDelegateList().toPromise()
          .then((delegates: EnduserDelegateData[]) => {
            this.signerDelegated = null;
            this.signersEnduser = [];
            this.labels = [];
            if (delegates) {
              delegates.forEach((delegate: EnduserDelegateData) => {
                // Don't display old delegations.
                if (new Date(delegate.end) >= new Date() || !delegate.end) {
                  this.signersEnduser.push(delegate.delegate);
                  this.signersDelegated.push(delegate);
                  this.dates.push({
                    from: new Date(delegate.begin).toLocaleString(this.translateService.currentLang),
                    to: delegate.end ? new Date(delegate.end).toLocaleString(this.translateService.currentLang) : undefined
                  });
                  this.labels.push({step: delegate.stepLabel, session: delegate.sessionLabel});
                }
              });
              this.showDescription = this.signersDelegated.length > 0;
            }
          }).catch((error: ApiError) => this.alertService.errorApi(error));
      }
    });

    this.inputSigner = '';
    this.availableSigners = [];
    // Manage date constraints.
    this.dates = [];
    const date = new Date();
    this.now = {
      day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear()
    };
    this.tomorrow = {
      day: date.getDate() + 1, month: date.getMonth() + 1, year: date.getFullYear()
    };
    this.defaultDate = DateTimeModel.fromLocalString(new Date(new Date().getTime() + 60 * 60 * 1000).toLocaleString(), this.translateService.currentLang);
    this.defaultWeekDate = DateTimeModel.fromLocalString(new Date(new Date().getTime() + 24 * 7 * 3600 * 1000).toLocaleString(), this.translateService.currentLang);
  }

  getOrelySsn() {
    this.orelyAuthenticationService.getOrelySsn().subscribe(data => {
      this.orelyAuthId = data.id;
      this.currentSsn = data.ssn;
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  initFormsGroup() {
    this.updateNumberPhoned = this.formBuilder.group({
      phoneNumber: [{value: '', disabled: true}, [Validators.required, Validators.pattern(REGEXES.PHONE),
        Validators.minLength(6),
        Validators.maxLength(20)]]
    });
    this.currentPasswordControl = this.formBuilder.control('', [Validators.required]);
    this.newPasswordControl = this.formBuilder.control('', [Validators.required, Validators.maxLength(255), ValidatorHelper.passwordIsValid]);
    this.confirmPasswordControl = this.formBuilder.control('', [Validators.required]);
    this.resetForm = this.formBuilder.group({
      currentPassword: this.currentPasswordControl,
      password: this.newPasswordControl,
      confirmPassword: this.confirmPasswordControl
    }, {
      validator: ValidatorHelper.matchPassword
    });
  }

  showCurrentPassword(value: boolean) {
    this.currentPasswordVisible = value;
  }

  showNewPassword(value: boolean) {
    this.newPasswordVisible = value;
  }

  showConfirmPassword(value: boolean) {
    this.confirmPasswordVisible = value;
  }

  changePassword() {
    this.changingPassword = true;
    this.authService.resetPassword({
      newPassword: this.newPasswordControl.value,
      currentPassword: this.currentPasswordControl.value
    }).toPromise().then(() => {
      this.resetForm.reset();
      this.eventInputPassword = false;
      this.alertService.success('USER_PROFILE.LUXTRUST.PASSWORD.PASSWORD_SUCCESS');
      this.changingPassword = false
    }).catch((error: ApiError) => {
      this.alertService.error('USER_PROFILE.LUXTRUST.PASSWORD.WRONG_PASSWORD');
      this.changingPassword = false
    })
  }

  launchTuto() {
    this.introJSVisitProfile.start();
  }

  introJSVisiteProfileSetup() {
    this.introJSVisitProfile.setOptions({
      overlayOpacity: 0.5,
      exitOnOverlayClick: false,
      prevLabel: this.translateService.instant('NEW_SESSION.TUTORIAL.PREVLABEL'),
      nextLabel: this.translateService.instant('NEW_SESSION.TUTORIAL.NEXTLABEL'),
      skipLabel: this.translateService.instant('NEW_SESSION.TUTORIAL.SKIPLABEL'),
      doneLabel: this.translateService.instant('NEW_SESSION.TUTORIAL.DONELABEL'),
      steps: [
        {
          intro: this.translateService.instant('USER_PROFILE.TUTORIAL.INTRO')
        },
        {
          element: '#visiteProfilStep1',
          position: 'top',
          intro: this.translateService.instant('USER_PROFILE.TUTORIAL.FIRSTSTEP')
        },
        {
          element: '#visiteProfilStep2',
          position: 'top',
          intro: this.translateService.instant('USER_PROFILE.TUTORIAL.SECONDSTEP')
        },
        {
          element: '#visiteProfilStep3',
          position: 'top',
          intro: this.translateService.instant('USER_PROFILE.TUTORIAL.THIRDSTEP')
        }, {
          element: '#visiteProfilStep4',
          position: 'top',
          intro: this.translateService.instant('USER_PROFILE.TUTORIAL.FOURTHSTEP')
        }
        , {
          element: '#visiteProfilStep5',
          position: 'top',
          intro: this.translateService.instant('USER_PROFILE.TUTORIAL.FIFTHSTEP')
        }
      ]
    });
  }

  onWatermarkChange($event) {
    if ($event.target.files && $event.target.files[0]) {
      const file = $event.target.files[0] as File;
      if (!this.allowedSignatureImageExtensions.some((fileExtension: string) => fileExtension === file.name.split('.').pop())) {
        this.alertService.error('SIGN_UP.SIGNATURE_IMAGE.INVALID_FILE_EXTENSION', {supportedFormats: this.allowedSignatureImageExtensions.join(', ')});
      } else {
        this.newWatermarkFile = $event.target.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => this.newWatermarkPreview = <any>fileReader.result;
        fileReader.readAsDataURL(this.newWatermarkFile);
      }
      (<any>document.getElementById('input-document')).value = '';
    }
  }

  uploadWatermark() {
    let promise: Promise<WatermarkData>;
    this.uploadingWatermark = true;
    if (this.currentWatermark) {
      promise = this.watermarkService.updateWatermark(this.currentWatermark.id, this.newWatermarkFile, this.currentlyLoggedUser.id,
        WatermarkData.TypeEnum.USER).toPromise();
    } else {
      promise = this.watermarkService.createWatermark(this.newWatermarkFile, this.currentlyLoggedUser.id, WatermarkData.TypeEnum.USER)
        .toPromise();
    }
    promise.then(watermarkInformation => {
      this.currentWatermark = {id: watermarkInformation.id, image: this.newWatermarkPreview.split(',')[1]};
      this.newWatermarkFile = undefined;
      this.newWatermarkPreview = undefined;
      this.alertService.success('USER_PROFILE.WATERMARK.UPLOAD_SUCCESS');
    }, (error: ApiError) => this.alertService.errorApiWithCustomMessage('USER_PROFILE.WATERMARK.UPLOAD_ERROR', error))
      .then(value => this.uploadingWatermark = false);
  }

  removeWatermark() {
    this.removingWatermark = true;
    this.watermarkService.deleteWatermark(this.currentWatermark.id).toPromise().then(() => {
      this.alertService.success('USER_PROFILE.WATERMARK.DELETE_SUCCESS');
      this.currentWatermark = undefined;
      this.newWatermarkFile = undefined;
    }, (error: ApiError) => this.alertService.errorApiWithCustomMessage('USER_PROFILE.WATERMARK.DELETE_ERROR', error))
      .then(value => this.removingWatermark = false);
  }

  openOrelyModal() {
    this.modal.open(ModalOrelyLinkComponent, {
      size: 'lg', backdrop: false
    }).result.then(value => {
      if (value) {
        this.getOrelySsn();
        this.alertService.success('USER_PROFILE.LUXTRUST.SSN_FOUND');
      }
    }).catch(() => this.alertService.error('USER_PROFILE.LUXTRUST.SSN_NOT_FOUND'));
  }

  deleteSsn() {
    this.orelyAuthenticationService.deleteOrelyAuthentication(this.orelyAuthId).toPromise().then(() => {
      this.getOrelySsn();
      this.alertService.success('USER_PROFILE.LUXTRUST.DELETE_SUCCESS');
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  onFocusSignerInput(text: string) {
    if (text && text.trim().length !== 0) {
      this.endUserService.searchEnduser(undefined, undefined, 20, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        text)
        .subscribe((endusers: EnduserData[]) => {
          this.inputSignerOpen = true;
          this.availableSigners = this.filterAvailableSigners(endusers);
        }, (error: ApiError) => this.alertService.errorApi(error));
    } else {
      this.availableSigners = [];
    }
  }

  onChangeSignerInput(text: string) {
    this.activeSignerIndex = -1;
    this.signerDelegated = null;
    this.onFocusSignerInput(text);
  }

  signerKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEYBOARD.ARROW_UP:
        if (this.activeSignerIndex > -1) {
          this.activeSignerIndex--;
        }
        break;
      case KEYBOARD.ARROW_DOWN:
        if (this.activeSignerIndex < this.availableSigners.length - 1) {
          this.activeSignerIndex++;
        }
        break;
      case KEYBOARD.ENTER:
        if (this.activeSignerIndex !== -1) {
          this.signerDelegated = this.availableSigners[this.activeSignerIndex];
          this.inputSigner = this.getNameSigner(this.signerDelegated);
          this.inputSignerOpen = false;
        }
        break;
      default:
        break;
    }
  }

  addSigner(signer: EnduserData) {
    this.inputSignerOpen = false;
    this.inputSigner = this.getNameSigner(signer);
    this.activeSignerIndex = -1;
    this.signerDelegated = signer;
  }

  cancelInputSigner() {
    this.timeNotCorrectNotification = false;
    this.wrongPeriodNotification = false;
    this.signerDelegated = null;
    this.inputSigner = '';
    this.delegatedUntil = null;
    this.delegatedFrom = null;
  }

  addDelegate() {
    const from: Date = new Date(this.delegatedFrom);
    const to: Date = new Date(this.delegatedUntil);

    if (this.checkPerdiodDelegation(from, to) && this.checkTime(from, to)) {
      const payload: CreateEnduserDelegatePayload = {
        beginOn: from, endOn: to, delegateId: this.signerDelegated.id
      };
      this.addingDelegate = true;
      this.delegateService.createEnduserDelegate(payload).toPromise()
        .then((delegate: EnduserDelegateData) => {
          if (delegate) {
            this.showDescription = true;
            this.signersDelegated.push(delegate);
            this.signersEnduser.push(delegate.delegate);
            this.dates.push({
              from: new Date(from).toLocaleString(this.translateService.currentLang),
              to: new Date(to).toLocaleString(this.translateService.currentLang)
            });
            this.labels.push({step: '', session: ''});
            this.cancelInputSigner();
          }
        }).catch((error: ApiError) => this.alertService.errorApi(error))
        .then(value => this.addingDelegate = false);
    }
  }

  deleteDelegate(index: number) {
    this.removingDelegate = true;
    this.delegateService.deleteEnduserDelegate(new Date(this.signersDelegated[index].begin).toISOString()).toPromise()
      .then(() => {
        this.signersDelegated.splice(index, 1);
        this.signersEnduser.splice(index, 1);
        if (this.signersDelegated.length === 0) {
          this.showDescription = false;
        }
        this.dates.splice(index, 1);
        this.labels.splice(index, 1);
      }).catch((error: ApiError) => this.alertService.errorApi(error))
      .then(value => this.removingDelegate = false);
  }

  toDateStruct(val: string): NgbDateStruct {
    return {
      year: new Date(val).getFullYear(), month: new Date(val).getMonth() + 1, day: new Date(val).getDate()
    };
  }

  private getNameSigner(enduser: EnduserData): string {
    return enduser.firstName.concat(' ' + enduser.lastName);
  }

  private checkTime(begin: Date, to: Date): boolean {
    this.timeNotCorrectNotification = !(begin.getTime() < to.getTime());
    return !this.timeNotCorrectNotification;
  }

  private checkPerdiodDelegation(begin: Date, end: Date): boolean {
    this.wrongPeriodNotification = false;
    if (this.signersDelegated) {
      this.signersDelegated.forEach((delegate: EnduserDelegateData) => {
        const oldBegin: number = new Date(delegate.begin).getTime();
        const newBegin: number = new Date(begin).getTime();

        const oldEnd: number = new Date(delegate.end).getTime();
        const newEnd: number = new Date(end).getTime();

        if ((newBegin < oldBegin && newEnd >= oldBegin) || (newBegin > oldBegin && newBegin <= oldEnd) || newBegin === oldBegin) {
          this.wrongPeriodNotification = true;
        }
      });
    }
    return !this.wrongPeriodNotification;
  }

  private filterAvailableSigners(endusers: EnduserData[]): EnduserData[] {
    // Remove the logged user from the users list proposed for the delegation.
    endusers.forEach((enduser: EnduserData, index: number) => {
      if (enduser.id === this.currentlyLoggedUser.id) {
        endusers.splice(index, 1);
      }
    });
    return endusers;
  }

  getErrorMessageUpdatePhoneNumber() {
    return this.updateNumberPhoned.controls.phoneNumber.hasError('required') ? this.translateService.instant('USER_PROFILE.LUXTRUST.PHONE_REQUIRED') :
      this.updateNumberPhoned.controls.phoneNumber.hasError('pattern') ? this.translateService.instant('USER_PROFILE.LUXTRUST.PHONE_PATTERN') :
        this.updateNumberPhoned.controls.phoneNumber.hasError('minlength') ? this.translateService.instant('USER_PROFILE.LUXTRUST.PHONE_PATTERN') :
          this.updateNumberPhoned.controls.phoneNumber.hasError('maxlength') ? this.translateService.instant('USER_PROFILE.LUXTRUST.PHONE_PATTERN') : '';
  }

  getErrorMessageCurrentPassword() {
    return this.currentPasswordControl.hasError('required') ? this.translateService.instant('USER_PROFILE.LUXTRUST.PASSWORD.REQUIRED') : '';
  }

  // Needed to display error immediately if any
  markAsTouched(controlName: string) {
    const control = this.resetForm.get(controlName);
    control.markAsTouched();
  }

  editPhoneNumber() {
    this.updateNumberPhoned.enable();
  }

  updatePhoneNumber() {
    if (this.updateNumberPhoned.valid && this.phoneNumberHasChange) {
      this.updatingPhoneNumber = true
      this.endUserService.partialUpdateEnduser(this.appService.getCurrentUserId(), {
        phone: this.updateNumberPhoned.value.phoneNumber
        // tslint:disable-next-line: deprecation
      }).subscribe(() => {
        this.alertService.success('USER_PROFILE.LUXTRUST.UPDATE_PHONE');
        this.phoneNumberHasChange = false;
        this.appService.loadEnduser();
        this.phoneNumber.nativeElement.blur();
        this.updatingPhoneNumber = false;
      }, (error: ApiError) => {
        this.alertService.errorApi(error);
        this.updatingPhoneNumber = false;
      });
    }
    this.updateNumberPhoned.disable();
  }

  updatePhone() {
    this.phoneNumberHasChange = true;
    this.updateNumberPhoned.controls['phoneNumber'].setValue(this.updateNumberPhoned.value.phoneNumber);
  }

  detectChangePassord() {
    this.eventInputPassword = true;
  }

  private fieldErrors(formControl: FormControl): string[] {
    if (!formControl) {
      return [];
    }
    if (!formControl.pristine && !formControl.valid) {
      return Object.keys(formControl.errors);
    }
    return [];
  }

  private fieldContainsErrors(formControl: FormControl, errorToCheck: string | string[]): boolean {
    const formErrors = this.fieldErrors(formControl);
    if (formErrors && formErrors.length > 0) {
      if (Array.isArray(errorToCheck)) {
        return formErrors.some(r => errorToCheck.includes(r));
      } else {
        return formErrors.includes(errorToCheck);
      }
    }
    return false;
  }

  private getColorOrIcon(formControl: FormControl, errorToCheck: string, isIcon: boolean): string {
    if (formControl.pristine) {
      return isIcon ? 'fa-info-circle' : '';
    } else {
      if (isIcon) {
        return this.fieldContainsErrors(formControl, ['required', errorToCheck]) ? 'fa-times' : 'fa-check';
      } else {
        return this.fieldContainsErrors(formControl, ['required', errorToCheck]) ? 'invalid-rule' : 'valid-rule';
      }
    }
  }

  newPasswordFieldColorIf(errorToCheck: string): string {
    return this.getColorOrIcon(this.newPasswordControl, errorToCheck, false);
  }

  newPasswordFieldIconIf(errorToCheck: string): string {
    return this.getColorOrIcon(this.newPasswordControl, errorToCheck, true);
  }

  confirmPasswordFieldColorIf(errorToCheck: string): string {
    return this.getColorOrIcon(this.confirmPasswordControl, errorToCheck, false);
  }

  confirmPasswordFieldIconIf(errorToCheck: string): string {
    return this.getColorOrIcon(this.confirmPasswordControl, errorToCheck, true);
  }

  displayRulesForValidationPassword() {
    this.displayValidationNewPassword = true;
  }

  controlErrorDateBefore(error: boolean) {
    if (error !== this.errorDateBefore) {
      setTimeout(() => {
        this.errorDateBefore = error;
      });
    }
  }

  attributeChanged(userAttribute: EnduserAttributeData, value) {
    userAttribute.value = value;
    this.hasAnAttributeChanged = true;
  }

  updateUserAttribute() {
    let attributes = this.currentlyLoggedUser.attributes;
    for (const key in attributes) {
      attributes[key] = this.userAttributes.find(userAttribute => userAttribute.key === key).value;
    }
    this.endUserService.partialUpdateEnduser(this.appService.getCurrentUserId(), {
      attributes: attributes,
      attributesV2: this.userAttributes
      // tslint:disable-next-line: deprecation
    }).subscribe(() => {
      this.hasAnAttributeChanged = false;
      this.alertService.success('USER_PROFILE.LUXTRUST.USER_ATTRIBUTES');
      this.appService.loadEnduser();
    }, (error: ApiError) => {
      this.hasAnAttributeChanged = false;
      this.alertService.errorApi(error);
    });
  }
}
