<span *ngFor="let signer of signers let i = index" class="d-inline-block mr-2">
  <span class="box-info mt-2 p-2 d-flex justify-content-center align-items-center">
    <span class="align-self-center text-secondary flex-grow-1 left">
      {{'USER_PROFILE.SIGNATURE_DELEGATION.DELEGATED_TO' | translate}}
      <span class="text-body">{{(signer.circle) ? patchCircleName(signer.lastName) : signer.firstName + ' ' + signer.lastName}}</span> <br/>

      <ng-container *ngIf="!!dates[i].to">
        {{'USER_PROFILE.SIGNATURE_DELEGATION.FROM' | translate}}
        <span class="text-body">{{dates[i].from}}</span>
        {{'USER_PROFILE.SIGNATURE_DELEGATION.TO' | translate}}
        <span class="text-body">{{dates[i].to}}</span>.
      </ng-container>

      <ng-container *ngIf="labels[i].session!==''">
        {{'USER_PROFILE.SIGNATURE_DELEGATION.SESSION' | translate}}
        <span class="text-body">: {{labels[i].session}}</span>.
        <br />
      </ng-container>
            
      <ng-container *ngIf="labels[i].step!==''">
        {{'USER_PROFILE.SIGNATURE_DELEGATION.STEP' | translate}}
        <span class="text-body">: {{labels[i].step}}</span>.
      </ng-container>

    </span>
    <div class="flex-grow-0">
      <ng-container *ngIf="!!dates[i].to">
        <button (click)="deleteDelegateEvent.emit(i)" [disabled]="disabled"
                class="btn btn-sm btn-danger align-self-center ml-2" type="button">
          <i aria-hidden="true" class="fa fa-trash"></i>
        </button>
    </ng-container>
    </div>
  </span>
</span>
