/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ConfigData } from '../model/configData';
import { CronTaskData } from '../model/cronTaskData';
import { DirectoryData } from '../model/directoryData';
import { NotificationData } from '../model/notificationData';
import { PrivilegeData } from '../model/privilegeData';
import { RuleData } from '../model/ruleData';
import { SprofileData } from '../model/sprofileData';
import { StorageData } from '../model/storageData';
import { SynchResult } from '../model/synchResult';
import { TagData } from '../model/tagData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PlatformService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create directory
     * 
     * @param payload payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDirectory(payload: DirectoryData, observe?: 'body', reportProgress?: boolean): Observable<DirectoryData>;
    public createDirectory(payload: DirectoryData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DirectoryData>>;
    public createDirectory(payload: DirectoryData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DirectoryData>>;
    public createDirectory(payload: DirectoryData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling createDirectory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DirectoryData>(`${this.basePath}/api/platform/directories`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create storage
     * 
     * @param payload payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStorage(payload: StorageData, observe?: 'body', reportProgress?: boolean): Observable<StorageData>;
    public createStorage(payload: StorageData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StorageData>>;
    public createStorage(payload: StorageData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StorageData>>;
    public createStorage(payload: StorageData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling createStorage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<StorageData>(`${this.basePath}/api/platform/storage`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete sprofile and all references in all tenants (To use only if you understand how the platform works)
     * 
     * @param alias alias
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePlatformSprofileList(alias: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deletePlatformSprofileList(alias: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deletePlatformSprofileList(alias: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deletePlatformSprofileList(alias: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling deletePlatformSprofileList.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (alias !== undefined && alias !== null) {
            queryParameters = queryParameters.set('alias', <any>alias);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/platform/sprofiles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Flush caches
     * 
     * @param alias Cache alias
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public flushCaches(alias?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public flushCaches(alias?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public flushCaches(alias?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public flushCaches(alias?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (alias !== undefined && alias !== null) {
            queryParameters = queryParameters.set('alias', <any>alias);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/platform/clear-caches`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get config
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfig(observe?: 'body', reportProgress?: boolean): Observable<ConfigData>;
    public getConfig(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConfigData>>;
    public getConfig(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConfigData>>;
    public getConfig(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ConfigData>(`${this.basePath}/api/platform/config`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCronTaskList
     * 
     * @param limit limit
     * @param offset offset
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCronTaskListUsingGET(limit: number, offset: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CronTaskData>>;
    public getCronTaskListUsingGET(limit: number, offset: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CronTaskData>>>;
    public getCronTaskListUsingGET(limit: number, offset: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CronTaskData>>>;
    public getCronTaskListUsingGET(limit: number, offset: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling getCronTaskListUsingGET.');
        }

        if (offset === null || offset === undefined) {
            throw new Error('Required parameter offset was null or undefined when calling getCronTaskListUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CronTaskData>>(`${this.basePath}/api/platform/cron/${encodeURIComponent(String(offset))}/${encodeURIComponent(String(limit))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCronTask
     * 
     * @param cronId cronId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCronTaskUsingGET(cronId: number, observe?: 'body', reportProgress?: boolean): Observable<CronTaskData>;
    public getCronTaskUsingGET(cronId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CronTaskData>>;
    public getCronTaskUsingGET(cronId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CronTaskData>>;
    public getCronTaskUsingGET(cronId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cronId === null || cronId === undefined) {
            throw new Error('Required parameter cronId was null or undefined when calling getCronTaskUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CronTaskData>(`${this.basePath}/api/platform/cron/${encodeURIComponent(String(cronId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get directory list
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDirectoryList(observe?: 'body', reportProgress?: boolean): Observable<Array<DirectoryData>>;
    public getDirectoryList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DirectoryData>>>;
    public getDirectoryList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DirectoryData>>>;
    public getDirectoryList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DirectoryData>>(`${this.basePath}/api/platform/directories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getList
     * 
     * @param code code
     * @param keys keys
     * @param name name
     * @param values values
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListUsingGET(code: number, keys: string, name: string, values: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Array<string>>>;
    public getListUsingGET(code: number, keys: string, name: string, values: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Array<string>>>>;
    public getListUsingGET(code: number, keys: string, name: string, values: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Array<string>>>>;
    public getListUsingGET(code: number, keys: string, name: string, values: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling getListUsingGET.');
        }

        if (keys === null || keys === undefined) {
            throw new Error('Required parameter keys was null or undefined when calling getListUsingGET.');
        }

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling getListUsingGET.');
        }

        if (values === null || values === undefined) {
            throw new Error('Required parameter values was null or undefined when calling getListUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (keys !== undefined && keys !== null) {
            queryParameters = queryParameters.set('keys', <any>keys);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (values !== undefined && values !== null) {
            queryParameters = queryParameters.set('values', <any>values);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Array<string>>>(`${this.basePath}/api/platform/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get notifications
     * 
     * @param enduserId Enduser
     * @param limit Limit
     * @param offset Offset
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotifications(enduserId: string, limit: number, offset: number, observe?: 'body', reportProgress?: boolean): Observable<Array<NotificationData>>;
    public getNotifications(enduserId: string, limit: number, offset: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotificationData>>>;
    public getNotifications(enduserId: string, limit: number, offset: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotificationData>>>;
    public getNotifications(enduserId: string, limit: number, offset: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling getNotifications.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling getNotifications.');
        }

        if (offset === null || offset === undefined) {
            throw new Error('Required parameter offset was null or undefined when calling getNotifications.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NotificationData>>(`${this.basePath}/api/platform/notifications/${encodeURIComponent(String(enduserId))}/${encodeURIComponent(String(offset))}/${encodeURIComponent(String(limit))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get full list of sprofiles
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlatformSprofileList(observe?: 'body', reportProgress?: boolean): Observable<Array<SprofileData>>;
    public getPlatformSprofileList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SprofileData>>>;
    public getPlatformSprofileList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SprofileData>>>;
    public getPlatformSprofileList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SprofileData>>(`${this.basePath}/api/platform/sprofiles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tenant entitlements
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrivileges(observe?: 'body', reportProgress?: boolean): Observable<Array<PrivilegeData>>;
    public getPrivileges(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PrivilegeData>>>;
    public getPrivileges(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PrivilegeData>>>;
    public getPrivileges(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PrivilegeData>>(`${this.basePath}/api/platform/privileges`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of rules
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRules(observe?: 'body', reportProgress?: boolean): Observable<Array<RuleData>>;
    public getRules(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RuleData>>>;
    public getRules(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RuleData>>>;
    public getRules(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<RuleData>>(`${this.basePath}/api/platform/rules`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get storage list
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStorageList(observe?: 'body', reportProgress?: boolean): Observable<Array<StorageData>>;
    public getStorageList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StorageData>>>;
    public getStorageList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StorageData>>>;
    public getStorageList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StorageData>>(`${this.basePath}/api/platform/storage`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get system tag list
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSystemTagList(observe?: 'body', reportProgress?: boolean): Observable<Array<TagData>>;
    public getSystemTagList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TagData>>>;
    public getSystemTagList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TagData>>>;
    public getSystemTagList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TagData>>(`${this.basePath}/api/platform/tags`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Normalize directory for search
     * 
     * @param alias Directory to normalize
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public normalizeDirectoryAny(alias: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public normalizeDirectoryAny(alias: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public normalizeDirectoryAny(alias: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public normalizeDirectoryAny(alias: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (alias === null || alias === undefined) {
            throw new Error('Required parameter alias was null or undefined when calling normalizeDirectoryAny.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (alias !== undefined && alias !== null) {
            queryParameters = queryParameters.set('alias', <any>alias);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<number>(`${this.basePath}/api/platform/normalize-directory`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send un event
     * 
     * @param documentId Document identifier
     * @param enduserId EnduserId identifier
     * @param eventId Event identifier
     * @param sessionId Session identifier
     * @param stepId Step identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendEvent(documentId: number, enduserId: number, eventId: number, sessionId: number, stepId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public sendEvent(documentId: number, enduserId: number, eventId: number, sessionId: number, stepId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public sendEvent(documentId: number, enduserId: number, eventId: number, sessionId: number, stepId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public sendEvent(documentId: number, enduserId: number, eventId: number, sessionId: number, stepId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling sendEvent.');
        }

        if (enduserId === null || enduserId === undefined) {
            throw new Error('Required parameter enduserId was null or undefined when calling sendEvent.');
        }

        if (eventId === null || eventId === undefined) {
            throw new Error('Required parameter eventId was null or undefined when calling sendEvent.');
        }

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling sendEvent.');
        }

        if (stepId === null || stepId === undefined) {
            throw new Error('Required parameter stepId was null or undefined when calling sendEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/api/platform/events/${encodeURIComponent(String(eventId))}/${encodeURIComponent(String(sessionId))}/${encodeURIComponent(String(stepId))}/${encodeURIComponent(String(documentId))}/${encodeURIComponent(String(enduserId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync any directory DB
     * 
     * @param directoryAlias Directory to sync
     * @param mappingProfile Mapping profile
     * @param tenantId Tenant
     * @param file CSV file to sync
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncDirectoryAny(directoryAlias: string, mappingProfile: string, tenantId: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<SynchResult>;
    public syncDirectoryAny(directoryAlias: string, mappingProfile: string, tenantId: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SynchResult>>;
    public syncDirectoryAny(directoryAlias: string, mappingProfile: string, tenantId: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SynchResult>>;
    public syncDirectoryAny(directoryAlias: string, mappingProfile: string, tenantId: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (directoryAlias === null || directoryAlias === undefined) {
            throw new Error('Required parameter directoryAlias was null or undefined when calling syncDirectoryAny.');
        }

        if (mappingProfile === null || mappingProfile === undefined) {
            throw new Error('Required parameter mappingProfile was null or undefined when calling syncDirectoryAny.');
        }

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling syncDirectoryAny.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (directoryAlias !== undefined && directoryAlias !== null) {
            queryParameters = queryParameters.set('directoryAlias', <any>directoryAlias);
        }
        if (mappingProfile !== undefined && mappingProfile !== null) {
            queryParameters = queryParameters.set('mappingProfile', <any>mappingProfile);
        }
        if (tenantId !== undefined && tenantId !== null) {
            queryParameters = queryParameters.set('tenantId', <any>tenantId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<SynchResult>(`${this.basePath}/api/platform/synch-directory`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
