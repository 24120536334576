<app-tooltip [tooltipText]="'HELP.NEW_SESSION' | translate"></app-tooltip>
<app-tutoriel (launchTutoEmit)="launchTuto()"></app-tutoriel>

<div class="fixed-container">
  <div class="fixed-md">
    <h1>{{'NEW_SESSION.TITLE' | translate}}</h1>
    <form *ngIf="newSessionForm"
          [formGroup]="newSessionForm"
          class="row pb-3">

      <div class="col-12">
        <!-- session name -->
        <div class="form-row" id="createSessionStep2" data-position='top'>
          <app-input-text [errors]="{required: 'NEW_SESSION.NAME.ERRORS.REQUIRED' | translate}"
                          [id]="'input-session-name'"
                          [label]="'NEW_SESSION.NAME.TITLE' | translate"
                          [placeholder]="'NEW_SESSION.NAME.PLACEHOLDER' | translate"
                          [tabindex]="1"
                          autoFocus
                          class="col"
                          formControlName="sessionName"
                          (input)="updateField($event.target.value)"
                          icon="tag"
                          [idPostfix]="'sessionName'"
                          inputTextPosition="right"
                          maxLength="255"
                          type="text">
          </app-input-text>
        </div>

        <!-- signers -->
        <div class="form-row" id="createSessionStep3" data-position='top'>
            <span id="createSessionStep4" class="create-new-signer-link_" (click)="openModalNewSigner()" >
              {{'NEW_SESSION.SIGNERS.NEW_SIGNERS.ADD' | translate}}
            </span>

          <app-autocomplete-custom [placeholder]="'NEW_SESSION.SIGNERS.PLACEHOLDER' | translate"
                                   [datasProviders]="signersProvider"
                                   [label]="'NEW_SESSION.SIGNERS.TITLE' | translate"
                                   [filterWithInput]=false
                                   [addEnduser]="true"
                                   [tabIndex]="2"
                                   [errors]="{atLeastOne: 'NEW_SESSION.SIGNERS.ERRORS.AT_LEAST_ONE' | translate}"
                                   (valueInputChange)="reloadProviders('S', $event)"
                                   class="col-12"
                                   [id]="'input-signer'"
                                   formControlName="selectedSigner"
                                   icon="users">

          </app-autocomplete-custom>

          <div class="w-100">
            <div *ngFor="let signer of selectedSignersArray" class="container pl-1 pr-1">
              <app-enduser-item [enduser]="signer"
                                [ngClass]="{'w-100': !showSort}"
                                [disabledSelectCircle]="false"
                                (onUpdateExpected)="updateNbOfRequiredSignersInCircle($event)"
                                (delete)="removeSigner($event)"></app-enduser-item>

              <ng-container *ngIf="showSort">
                <div class="enduser-sort">
                  <button [class.btn-sorted]="sortedUsers"
                          [disabled]="noUp(signer)"
                          (click)="up(signer)"
                          class="btn"><i class="fa fa-arrow-circle-up"></i></button>
                  <button [class.btn-sorted]="sortedUsers"
                          [disabled]="noDown(signer)"
                          (click)="down(signer)"
                          class="btn"><i class="fa fa-arrow-circle-down"></i></button>
                </div>
              </ng-container>
            </div>

            <div class="links_sort pb-2" *ngIf="selectedSignersArray?.length > 1">
              <a *ngIf="showSort"
                 class="reset-link" id="sort-button"
                 (click)="unsort()"
                 href="javascript:">{{'RESET_ORDER' | translate}}</a>
              <a class="reset-link" id="sort-button"
                 *ngIf="!showSort"
                 (click)="sort()"
                 href="javascript:">{{'DEFINE_ORDER' | translate }}</a>
            </div>

          </div>
        </div>

        <!-- watchers -->
        <div
          class="form-row"
          id="createSessionStep5"
          data-position='top'
          *ngIf="!watchersDisabled">

          <app-autocomplete-custom [placeholder]="'NEW_SESSION.WATCHERS.PLACEHOLDER' | translate"
                                   [datasProviders]="watchersProvider"
                                   [label]="'NEW_SESSION.SIGNERS.TITLE' | translate"
                                   [filterWithInput]=false
                                   [addEnduser]="true"
                                   [tabIndex]="3"
                                   (valueInputChange)="reloadProviders('W', $event)"
                                   [id]="'input-watcher'"
                                   class="col-12"
                                   formControlName="selectedWatcher"
                                   icon="eye">

          </app-autocomplete-custom>

          <div class="watchers_list col-12">
            <div *ngFor="let watcher of selectedWatchersArray">
              <app-enduser-item (delete)="removeWatcher($event)"
                                [enduser]="watcher"></app-enduser-item>
            </div>
          </div>

        </div>

        <!-- documents -->
        <div class="form-row" id="createSessionStep6" data-position='top'>
          <app-input-file (ngModelChange)="addDocuments($event)"
                          [browseLabel]="'NEW_SESSION.DOCUMENTS.BROWSE' | translate"
                          [errors]="{
                              required: 'NEW_SESSION.DOCUMENTS.ERRORS.AT_LEAST_ONE' | translate,
                              supportedFormats: 'NEW_SESSION.DOCUMENTS.ERRORS.INVALID_FILE_EXTENSION' | translate : {supportedFormats: this.appService?.config?.allowedMime?.join(', ')},
                              sizeLimit: 'NEW_SESSION.DOCUMENTS.ERRORS.SIZELIMIT_EXCEDEED' | translate : {filename: getDocumentFileNameError(), maxSize: this.FILE_SIZE_LIMIT | fileSize}
                            }"
                          [id]="'input-document'"
                          [label]="'NEW_SESSION.DOCUMENTS.TITLE' | translate"
                          [multiple]="true"
                          [placeholder]="'NEW_SESSION.DOCUMENTS.PLACEHOLDER' | translate"
                          [tabindex]="4"
                          class="col-12"
                          formControlName="documents"
                          icon="image"></app-input-file>

          <div class="docList col-12">
            <div *ngFor="let item of selectedDocumentsArray.controls; let i = index;"
                 formArrayName="selectedDocuments">
              <app-document-item (addAcroform)="addAcroform(item.value.document)"
                                 (delete)="removeDocument(item.value.document, i)"
                                 [canAddAcroform]="selectedSignersArray.length > 0 && isFilePDF(item.value.document.name)"
                                 [document]="item.value.document"
                                 [signersDetails]="getSignersDetails(item.value.document.name)"
                                 [progress]="getDocumentProgress()"
                                 [documentUploadingId]="uploadingIds[i]"
                                 (cancelReupload)="cancelUploadDocumentRequest($event)">
              </app-document-item>
            </div>
            <span *ngIf="!canAddDocument" class="text-danger form-text pb-2">{{'NEW_SESSION.DOCUMENTS.ERRORS.INVALID_FILE_EXTENSION_PDF' | translate}}</span>          </div>
        </div>

        <!-- sprofiles -->
        <div class="form-row" id="createSessionStep7" data-position='top' *ngIf="!loadingSprofile">

          <app-autocomplete-custom [placeholder]="'NEW_SESSION.SPROFILE.TITLE' | translate"
                                   [datasProviders]="sprofileProvider"
                                   [tabIndex]="5"
                                   class="col-12"
                                   [testId]="'sprofile'"
                                   (ngModelChange)="checkFormatOfDocuments()"
                                   formControlName="selectedSProfile">
          </app-autocomplete-custom>

        </div>
      </div>

      <!-- submit -->
      <div class="col-6 offset-3 d-flex" id="createSessionStep8">
        <button [disabled]="newSessionForm.invalid || !canAddDocument || this.selectedSigners().length === 0 || submitting"
                (click)="onSubmit()"
                class="btn flex-grow-1 btn-primary mr-2"
                tabindex="6"
                id="submitButton"
                type="submit">{{'NEW_SESSION.SUBMIT' | translate}}</button>
      </div>
    </form>

  </div>
</div>
