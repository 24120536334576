import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Data} from "@angular/router";
import {UrlService} from "../../../services/services/url.service";
import {DocumentData, DocumentService, TenantService} from "../../../../../luxtrust-cosi-api";
import {AlertService} from "../../../services/services/alert-service";
import {TranslateService} from "../../../core/service/translate.service";


@Component({
  selector: 'app-doc-personal-data',
  templateUrl: './doc-personal-data.component.html',
  styleUrls: ['./doc-personal-data.component.scss']
})
export class DocPersonalDataComponent implements OnInit {

  documentUploaded: DocumentData;
  private sessionId: number;
  private stepId: number;

  constructor(private route: ActivatedRoute,
              private urlService: UrlService,
              private tenantService: TenantService,
              private documentService: DocumentService,
              public alertService: AlertService) { }

  ngOnInit() {
    const documentId = this.route.snapshot.params.documentId;
    this.stepId= this.route.snapshot.params.stepId;
    this.sessionId= this.route.snapshot.params.sessionId;
    this.documentService.getDocument(documentId,this.sessionId,this.stepId).subscribe(doc => {
      doc.id = documentId;
      this.documentUploaded = doc;
    });

  }

}
