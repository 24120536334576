<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" *ngIf="updateMode; else createMode">
    {{'MODAL_TYPE_LIST_TAG_MANAGEMENT.TITLE_UPDATE' | translate}}
  </h4>
  <ng-template #createMode>
    <h4 class="modal-title" id="modal-basic-title">
      {{'MODAL_TYPE_LIST_TAG_MANAGEMENT.TITLE_CREATE' | translate}}
    </h4>
  </ng-template>
</div>
<div class="modal-body">
  <div class="metadata-list-edit-cont">
    <table class="custom-table table-values">
      <thead>
        <tr class="table-header">
          <th>{{'MODAL_TYPE_LIST_TAG_MANAGEMENT.DEFAULT_VALUE' | translate}}</th>
          <th>{{'MODAL_TYPE_LIST_TAG_MANAGEMENT.VALUE' | translate}}</th>
          <th>{{'MODAL_TYPE_LIST_TAG_MANAGEMENT.ACTIONS' | translate}}</th>
          <th>{{'MODAL_TYPE_LIST_TAG_MANAGEMENT.ORDER' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr id="value-row-{{i}}" *ngFor="let item of tag?.possibleValues; let i = index; trackBy: trackByPossibleValues" class="table-row">
          <td class="column-default-status">
            <input id="default-value-tag-{{i}}" tabindex="-1" type="radio" class="custom-control-input"
              (click)="onDefaultSelect(i)" [checked]="isDefaultValue(i)">
            <label for="default-value-tag-{{i}}" class="custom-control-label"></label>
          </td>
          <td class="column-value">
            <input id="value-input-{{i}}" class="metadata-input form-control" type="text" [value]="item"
              [ngClass]="{'input-error': isValueInError(i)}" (input)="onItemValueChange(valueInput.value, i)"
              #valueInput>
            <div *ngIf="isValueInError(i)" class="error-message">
              {{'MODAL_TYPE_LIST_TAG_MANAGEMENT.INVALID_MAX_LENGTH' | translate}}
            </div>
          </td>
          <td class="column-actions">
            <button class="metadata-list-edit-btn" (click)="removeFromList(i)" [disabled]="!canDeleteItem()" >
              <i class="fa fa-trash"></i>
            </button>
          </td>
          <td class="column-order">
            <button class="btn btn-sorted" (click)="onMoveDown(i)" [disabled]="!canMoveDown(i)">
              <i class="fa fa-arrow-circle-down"></i>
            </button>
            <button class="btn btn-sorted" (click)="onMoveUp(i)" [disabled]="!canMoveUp(i)">
              <i class="fa fa-arrow-circle-up"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="error-list-row">
      <div class="error-message" *ngIf="isListSizeInError()">
        {{'MODAL_TYPE_LIST_TAG_MANAGEMENT.INVALID_LIST_SIZE' | translate}}
      </div>
    </div>
    <div class="add-value-row">
      <button class="btn btn-primary" (click)="addToList()" [disabled]="errorValueEmpty()">
        <i class="fa fa-plus icon"></i>
        <span class="btn-label">{{'MODAL_TYPE_LIST_TAG_MANAGEMENT.ADD_VALUE' | translate}}</span>
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="text-center mb-3">
    <button (click)="onCancel()" class="btn mr-3 btn-danger" type="button">
      {{'MODAL_TYPE_LIST_TAG_MANAGEMENT.CANCEL' | translate}}
    </button>
    <button (click)="onValidate()" class="btn mr-3 btn-primary" type="button" [disabled]="isListInError() || errorValueEmpty()">
      {{'MODAL_TYPE_LIST_TAG_MANAGEMENT.VALIDATE' | translate}}
    </button>
  </div>
