<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <span *ngIf="wizardService.sessionIsTemplate || sessionData.template"
          class="breadcrumb-isTemplate">{{'SESSION.IS_TEMPLATE' | translate}}</span>
    <li class="breadcrumb-item">
      <a id="breadcrumb-session-name"
         *ngIf="!wizardService.disableWizardHeaderItems"
         [queryParamsHandling]="'preserve'"
         [routerLink]="['/session', sessionData?.id || 0]"
         [title]="sessionData | sessionTitle">
        <i aria-hidden="true" class="fa fa-code-fork mr-1"></i>
        <i class="fab fa-usb mr-2"></i>
        <lt-text-truncate [label]="sessionData  | sessionTitle"
                          [lgMaxCharacters]="25"
                          [mdMaxCharacters]="30"
                          [smMaxCharacters]="30"
                          [xlMaxCharacters]="27"
                          [xsMaxCharacters]="23"></lt-text-truncate>
      </a>

      <i aria-hidden="true" class="fa fa-code-fork mr-1" *ngIf="wizardService.disableWizardHeaderItems"></i>
      <i class="fab fa-usb mr-2" *ngIf="wizardService.disableWizardHeaderItems"></i>
      <lt-text-truncate [label]="sessionData  | sessionTitle"
                        [lgMaxCharacters]="25"
                        [mdMaxCharacters]="30"
                        [smMaxCharacters]="30"
                        [xlMaxCharacters]="27"
                        [xsMaxCharacters]="23"
                        *ngIf="wizardService.disableWizardHeaderItems">

      </lt-text-truncate>

    </li>
    <li aria-current="page" class="breadcrumb-item active"
        [title]="sessionData?.label !== stepData?.label ? stepData?.label : ' (' + ('STEP.BREADCRUMB' | translate) + ') ' + stepData?.label">
      <i class="fas fa-map-marker-alt mr-2"></i>
      <lt-text-truncate [label]="sessionData?.label !== stepData?.label
        ? stepData?.label : ' (' + ('STEP.BREADCRUMB' | translate) + ') ' + stepData?.label"
                        [lgMaxCharacters]="25"
                        [mdMaxCharacters]="30"
                        [smMaxCharacters]="30"
                        [xlMaxCharacters]="27"
                        [xsMaxCharacters]="23"></lt-text-truncate>
    </li>
  </ol>

  <!--  <app-breadcrumb
     [stepLabel]="stepData?.label"
     [session]="sessionData"
   ></app-breadcrumb> -->
</nav>

<div (panleft)="panLeft()" (panright)="panRight()" class="wizard-header-container">
  <div class="step-bar">
    <ul class="step-bar-list">
      <li *ngIf="wizardService.currentIndex === 0" class="step-bar-list-item-first"></li>

      <li (click)="goToStep(step)"
          *ngFor="let step of filteredSteps"
          [class.disabled]="wizardService.disableWizardHeaderItems"
          [ngClass]="{
              'current': step.index === wizardService.currentIndex,
              'previous': step.index === wizardService.previousIndex,
              'next': step.index === wizardService.nextIndex}"
          class="step-bar-list-item">

        <i id="wizard-configurator-tab" *ngIf="step.path === stepsName.config"
           class="step-bar-icon fas fa-user-cog"></i>
        <i id="wizard-general-tab" *ngIf="step.path === stepsName.general" class="step-bar-icon fas fa-info-circle"></i>
        <i id="wizard-users-tab" *ngIf="step.path === stepsName.users" class="step-bar-icon fas fa-users"></i>
        <i id="wizard-documents-tab" *ngIf="step.path === stepsName.documents"
           class="step-bar-icon fas fa-file-alt"></i>
        <i id="wizard-signatures-tab" *ngIf="step.path === stepsName.signature"
           class="step-bar-icon fas fa-file-signature"></i>
        <i id="wizard-legal-notices-tab" *ngIf="step.path === stepsName.legal"
           class="step-bar-icon fas fa-balance-scale"></i>
        <i id="wizard-metadata-tab" *ngIf="step.path === stepsName.metadata" class="step-bar-icon fas fa-database"></i>
        <i id="wizard-reminders-tab" *ngIf="step.path === stepsName.reminders"
           class="step-bar-icon fas fa-calendar-alt"></i>
        <i id="wizard-notifications-tab" *ngIf="step.path === stepsName.notifications"
           class="step-bar-icon fas fa-bell"></i>
        <i id="wizard-comments-tab" *ngIf="step.path === stepsName.comments" class="step-bar-icon fas fa-comments"></i>

        <span *ngIf="wizardService.sessionIsTemplate || sessionData.template || step.path !== stepsName.config"
              class="step-bar-name">{{step.label | translate}}</span>
      </li>

      <li *ngIf="wizardService.currentIndex === filteredSteps.length - 1"
          class="step-bar-list-item-end"></li>
    </ul>
  </div>
</div>
