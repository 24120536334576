import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiError} from '../../../error/api-error.model';
import {AlertService} from '../../../services/services/alert-service';
import {CircleData, CircleOfEndusersService, EnduserData} from '../../../../../luxtrust-cosi-api';

@Component({
  selector: 'app-modal-delete-circle',
  templateUrl: './modal-delete-circle.component.html',
  styleUrls: ['./modal-delete-circle.component.scss']
})
export class ModalDeleteCircleComponent {

  @Input() circle: EnduserData;
  deleting = false;

  constructor(public activeModal: NgbActiveModal, private circleService: CircleOfEndusersService, private alertService: AlertService) {
  }

  delete() {
    this.deleting = true;
    this.circleService.deleteCircle(this.circle.id).subscribe(() => {
      this.activeModal.close('circle_deleted');
    }, (error: ApiError) => {
      this.deleting = false
      this.alertService.errorApi(error);
    });
  }
}
