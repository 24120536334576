import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FileSizePipe } from './file-size.pipe';
import { OrderBy } from './order-by.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { TruncatePipe } from './truncate.pipe';
import { OrderLanguagePipe } from './order-language.pipe';
import { SearchFilterPipe } from './search-filter.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FileSizePipe, TruncatePipe, TitleCasePipe, OrderBy, OrderLanguagePipe, SearchFilterPipe],
  exports: [FileSizePipe, TruncatePipe, TitleCasePipe, OrderBy, OrderLanguagePipe, SearchFilterPipe],
  providers: [FileSizePipe, TruncatePipe]
})
export class PipesModule {
}
