import {Component, OnInit, ViewChild, TemplateRef, HostListener} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {OtpPayload} from 'luxtrust-cosi-api/model/otpPayload';
import {AuthenticationService} from 'luxtrust-cosi-api/api/authentication.service';
import {EnduserData} from 'luxtrust-cosi-api/model/enduserData';
import {InvitationPayload} from 'luxtrust-cosi-api/model/invitationPayload';
import {NotifierService} from 'src/app/services/services/notifier.service';
import {CONFIG, REGEXES, SESSION_STORAGE_SYSTEM_DOC_KEY} from '../../app.constant';
import {AlertService} from '../../services/services/alert-service';
import {ItsmeAuthenticationService} from 'luxtrust-cosi-api/api/itsmeAuthentication.service';
import {ItsmeAuthServiceType, ItsmeService} from '../../services/services/itsme.service';
import {ItsmeAuthorizationDto} from 'luxtrust-cosi-api/model/itsmeAuthorizationDto';
import {AppService} from '../../services/services/app.service';
import {ValidatorHelper} from '../../services/validators/validators.helper';
import {ApiError} from '../../error/api-error.model';
import {ConfigurationService} from '../../services/services/configuration.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ValidationCodeError} from '../../services/enum/validation-code-error';
import {DocumentationService} from "../../admin/documentation/documentation.service";
import {OrelyAuthenticationService} from '../../../../luxtrust-cosi-api';
import {environment} from '../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  templateUrl: './invite.component.html', styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  user: EnduserData;
  sessionId: number;
  stepId: number;
  registerForm: FormGroup;
  authCodeFormGroup: FormGroup;
  signInMandatory: boolean;
  signUpMandatory: boolean;
  authCodeChannel: string; // email or sms
  otpIsRequired: boolean = false;
  jwtWithOtp: string;
  orelyAuthId: number;
  currentSsn: string;
  authMode: string;
  orelyAuthUrl: SafeResourceUrl;
  tenantName: string;
  basePath: string;
  adfsMultiTenant: string;
  rawToken: string;
  isFromLogin: false;

  @ViewChild('authCodeModal', { read: TemplateRef, static: false }) authCodeModal: TemplateRef<any>;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private authenticationService: AuthenticationService,
              private alertService: AlertService,
              private appService: AppService,
              private route: ActivatedRoute,
              private configurationService: ConfigurationService,
              private notifierService: NotifierService,
              private modalService: NgbModal,
              private domSanitizer: DomSanitizer,
              private orelyAuthenticationService: OrelyAuthenticationService,
              private documentationService: DocumentationService,
              private itsmeAuthenticationService: ItsmeAuthenticationService,
              private itsmeService: ItsmeService) {
    const state = this.router.getCurrentNavigation().extras.state;
    this.isFromLogin = state ? state['isFromLogin'] : false;
  }

  ngOnInit() {
    const jwt = this.route.snapshot.queryParams['jwt'];
    this.basePath = environment.BASE_URL;
    this.tenantName = this.route.snapshot.queryParams['tenantName'];
    this.sessionId = parseInt(this.route.snapshot.queryParams['sessionId'], 10);
    this.stepId = parseInt(this.route.snapshot.queryParams['stepId'], 10);
    
    this.configurationService.tenantConfig.then(tenantConfig => {
      this.jwtWithOtp = tenantConfig[CONFIG.TENANT.AUTH.JWT_WITH_OTP_KEY];
      this.signUpMandatory  = !!tenantConfig[CONFIG.TENANT.SIGNUP_MANDATORY.KEY];
      this.signInMandatory = !!tenantConfig[CONFIG.TENANT.SIGNIN_MANDATORY.KEY];
      let mainAuthMode = tenantConfig[CONFIG.TENANT.AUTH.MAIN_MODE_KEY];
      let authModeUrl = this.route.snapshot.queryParams['authenticationMode'];
      this.authMode = authModeUrl || mainAuthMode || 'ba';
      this.adfsMultiTenant = tenantConfig[CONFIG.TENANT.AUTH.ADFS_MULTI_TENANT_KEY];
      if (jwt) {
        if (this.jwtWithOtp && !this.isFromLogin) {
          this.otpIsRequired = true;
          this.loginWithJwt(jwt, this.jwtWithOtp, true, false);
        } else {
          this.loadEndUserIfPossible(this.sessionId, this.stepId, jwt);
        }
      }
    });

    this.registerForm = this.formBuilder.group({
      firstName: [{value: '', disabled: true}],
      lastName: [{value: '', disabled: true}],
      email: [{value: '', disabled: true}],
      password: ['',
        [
          Validators.required,
          Validators.pattern(REGEXES.PASSWORD.NUMERIC_CHARS),
          Validators.pattern(REGEXES.PASSWORD.SPECIAL_CHARS),
          Validators.pattern(REGEXES.PASSWORD.ALPHA_CASED_CHARS),
          Validators.minLength(6)
        ]
      ],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    }, { validator: ValidatorHelper.matchPassword });

    this.authCodeFormGroup = this.formBuilder.group({
      authCode: ['', [Validators.required, Validators.pattern(REGEXES.AUTH_CODE)]]
    });

  }

  private loadEndUserIfPossible(sessionId: number, stepId: number, jwt?: string): void {
    this.appService.validateToken(jwt);
    if (this.appService.isLoggedIn()) {
      this.appService.loadEnduser().then(user => {
        this.appService.setIsAuthenticated('false');
        if (user.enrolled) {
          if (this.signInMandatory) {
            this.appService.removeTokenFromStorage();
            this.navigateLogin(sessionId, stepId, jwt);
          } else {
            this.appService.refresh(jwt);
            if (this.jwtWithOtp) {
              this.otpIsRequired = true;
              this.loginWithJwt(jwt, this.jwtWithOtp, true, false);
            } else {
              this.navigate();
            }
          }
        } else {
          if (!this.signUpMandatory && !this.signInMandatory) {
            this.appService.refresh(jwt);
            this.navigate();
          } else {
            this.rawToken = jwt;
          }
          if (this.authMode === 'luxtrust') {
            this.appService.refresh(jwt);
            this.orelyAuthUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.ORELY_URL}/auth/login?reason=LINK&tenantName=${this.tenantName}&jwt=${this.appService.get().raw}`);
          }
        }
        this.user = user;
        this.registerForm.get('firstName').patchValue(this.user.firstName);
        this.registerForm.get('lastName').patchValue(this.user.lastName);
        this.registerForm.get('email').patchValue(this.user.email);
      });
    }
  }

  loginWithJwt(jwt: string, jwtWithOtp: string, openModal: boolean, mustSendCode: boolean) {
    const payload: OtpPayload = {
      action: OtpPayload.ActionEnum.GENERATE,
      mustSendCode: mustSendCode
    };
    this.appService.validateToken(jwt);
    this.authenticationService.authenticateWithOtp(payload).pipe(take(1)).subscribe((isCodeSent: any) => {
      this.appService.setIsAuthenticated('false');
      const jsonValue = JSON.parse(jwtWithOtp);
      const channel = jsonValue['channel'];
      this.authCodeChannel = channel.toLowerCase();
      if (openModal) {
        this.modalService.open(this.authCodeModal, { ariaLabelledBy: 'modal-basic-title', backdrop: false });
      }
      if (isCodeSent) {
        this.alertService.success('LOGIN.AUTH_CODE.ALERT_SENT');
      }
    }, (err) => {
      this.appService.removeTokenFromStorage();
      if (err && err.error && err.error.code === ValidationCodeError.ACCOUNT_IS_DISABLED) {
        this.alertService.error('LOGIN.ERRORS.DISABLED');
      }
    });
  }

  generateCode() {
    const jwt = this.route.snapshot.queryParams['jwt'];
    this.loginWithJwt(jwt, this.jwtWithOtp, false, true);
  }

  onSubmit() {
    this.registerForm.markAllAsTouched();
    if (this.registerForm.invalid || this.otpIsRequired) {
      return;
    }

    const payload: InvitationPayload = {
      email: this.registerForm.value.email,
      password: this.registerForm.value.password || undefined,
      firstName: this.registerForm.value.firstName,
      lastName: this.registerForm.value.lastName
    };
    this.authenticationService.newUserInvitationSignup(payload).toPromise().then(() => {
      this.notifierService.notifyDetectUpdatePage('displayHome');
      this.appService.refresh(this.rawToken);
      this.navigate();
    }).catch((error: ApiError) => {
      this.alertService.errorApi(error);
    });
  }

  onProceed() {
    if (this.otpIsRequired) return;
    this.notifierService.notifyDetectUpdatePage('displayHome');
    this.appService.refresh(this.rawToken);
    this.navigate();
  }

  // Needed to display error immediately if any
  markAsTouched(controlName: string) {
    const control = this.registerForm.get(controlName);
    control.markAsTouched();
  }

  onAuthCodeSubmit() {
    const authCode: string = this.authCodeFormGroup.value.authCode.trim();
    const authOTPPayload: OtpPayload = {
      action: OtpPayload.ActionEnum.VERIFY,
      code: authCode,
      sessionId: this.sessionId,
      stepId: this.stepId
    }
    this.authenticationService.authenticateWithOtp(authOTPPayload).pipe(take(1)).subscribe((response: any) => {
      this.otpIsRequired = false;
      this.loadEndUserIfPossible(this.sessionId, this.stepId, response['access_token']);
      this.onAuthCodeModalDismiss();
    }, (err) => {
      switch(err.error.code) {
        case ValidationCodeError.OTP_INCORRECT:
          this.alertService.error('LOGIN.AUTH_CODE.INCORRECT');
          break;
        case ValidationCodeError.OTP_LIMIT_EXCEEDED:
          this.alertService.error('LOGIN.AUTH_CODE.LIMIT_EXCEEDED');
          this.onAuthCodeModalDismiss();
          break;
        case ValidationCodeError.OTP_EXPIRED:
          this.alertService.error('LOGIN.AUTH_CODE.EXPIRED');
          break;
        default:
          this.alertService.error('ALERT.ERROR_OCCURRED');
      }
    });
  }

  onAuthCodeModalDismiss() {
    this.authCodeFormGroup.reset();
    this.modalService.dismissAll('normal');
  }

  updateAuthCode(val: EventListener) {
    if (!!val && val.length === 3) {
      this.authCodeFormGroup.setValue({authCode: val+' '});
    }
  }

  getOrelySsn() {
    this.orelyAuthenticationService.getOrelySsn().subscribe(data => {
      this.orelyAuthId = data.id;
      this.currentSsn = data.ssn;
    }, (error: ApiError) => this.alertService.errorApi(error));
  }

  authWithOrely(response) {
    this.appService.refresh(response);
    this.alertService.success('ALERT.SIGN_IN_SUCCESS');
    if (!sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)) {
      this.documentationService.buildSystemDocumentation();
    }
    this.navigate();
  }

  @HostListener('window:message', ['$event']) iFrameEventListener(event: MessageEvent) {    
    const data = event.data;
    if (data) {
      if (data.flowStatus === 'USER_CLICKED_CANCELLED') {
      } else if ((data && data.type && !data.type.toString().includes('webpack')) || !data.type) {
        if (data.ssn) {
          this.getOrelySsn();
          this.authWithOrely(data.access_token);
          this.alertService.success('USER_PROFILE.LUXTRUST.SSN_FOUND');
        } else {
          this.alertService.error('LOGIN.ORELY.ERRORS.FAILURE_SSN_NOT_REGISTERED');
        }
      }
    }
  }

  navigate() {
    if (!sessionStorage.getItem(SESSION_STORAGE_SYSTEM_DOC_KEY)) {
      this.documentationService.buildSystemDocumentation();
    }
    this.router.navigate(['/session', this.sessionId, 'step', this.stepId]);
  }

  navigateLogin(sessionId: number, stepId: number, jwt: string) {
    this.router.navigate(['/login'], {queryParams: {sessionId, stepId, jwt}})
  }

  authWithADFS() {
    let route = this.basePath + '/adfs/auth/tenant/' + this.tenantName + '?';
    route = route + (!!this.adfsMultiTenant ? 'multitenant='+this.adfsMultiTenant+'&' : '')

    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    if (!!sessionId) {
      route += 'sessionId='+sessionId+'&';
    }
    if (!!stepId) {
      route += 'stepId='+stepId+'&';
    }
    window.location.href = route;
  }

  
  authWithItsme() {
    const redirectUri = this.itsmeService.getRedirectUrlForAuth(ItsmeAuthServiceType.LOGIN);    
    this.itsmeAuthenticationService.itsmeAuthorizationUrl(ItsmeAuthServiceType.LOGIN, redirectUri)
      .pipe(take(1))
      .subscribe((dto: ItsmeAuthorizationDto) => window.location.href = dto.url, (error: ApiError) => this.alertService.errorApi(error));    
  }

  authWithMc() {
    window.location.href = this.basePath + '/api/mc/auth/start_discovery?role=login';
  }

  authWithSamlv2(target: string) {
    let url = this.basePath + `/saml/auth/${target}?`;
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    if (!!sessionId) {
      url += `sessionId=${sessionId}&`;
    }
    if (!!stepId) {
      url += `stepId=${stepId}&`;
    }
    window.location.href = url;
  }

  authWithAzure() {
    let url = this.basePath + '/api/azuread/secure/aad/' + this.tenantName + '?';
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    if (!!sessionId) {
      url += 'sessionId='+sessionId+'&';
    }
    if (!!stepId) {
      url += 'stepId='+stepId+'&';
    }
    window.location.href = url;
  }

  authWithOidc() {
    let route = this.basePath + '/oidc/auth/' + this.tenantName + '?';
    const sessionId = this.route.snapshot.queryParams['sessionId'];
    const stepId = this.route.snapshot.queryParams['stepId'];
    if (!!sessionId) {
      route += 'sessionId='+sessionId+'&';
    }
    if (!!stepId) {
      route += 'stepId='+stepId+'&';
    }
    window.location.href = route;
  }

}
