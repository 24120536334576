<app-tooltip [tooltipText]="'HELP.WIZARD_DOCUMENTS' | translate"></app-tooltip>
<app-wizard-step *ngIf="session && step">
  <div class="main fixed-md" content>
    <div class="mt-5">
      <div>
        <div class="w2 left s-documents">
          <app-document-table
            (onMutualAppendixIn)="retakeMutualAppendix($event)"
            (onMutualDocumentIn)="retakeMutualDocument($event)"
            *ngIf="hasCheckStepTag && documents"
            [buttonLabel]="'WIZARD.DOCUMENTS.ADD_DOCUMENT' | translate"
            [disabled]="documents.documentMutual?.length === 0 || retakingDocument"
            [documents]="mutualDocumentsSorted"
            [isLoading]="documents.isLoadingMutual"
            [mutual]="true"
            [noResult]="'WIZARD.DOCUMENTS.NO_RESULT' | translate"
            [session]="session"
            [step]="step"
            [hasStepTagPositionSignatureLocation]="hasStepTagPositionSignatureLocation"
            [title]="'WIZARD.DOCUMENTS.MUTUAL' | translate">
          </app-document-table>
        </div>

        <div class="fixed-md">
          <app-document-table
            (onAddAcroform)="addAcroform($event)"
            (onClone)="cloneDocument($event, 'document')"
            (onDelete)="deleteDocument($event)"
            (onMutual)="createMutualDocument($event)"
            (onUpdate)="updateDocument($event)"
            (onUpload)="uploadDocument($event)"
            (onFailed)="onFailed()"
            (openModal)="updateDocumentName($event)"
            (onReuploadDoc)="reuploadDocument($event)"
            (cancelReupload)="cancelUploadDocumentRequest($event)"
            *ngIf="hasCheckStepTag && documents && sprofiles  "
            [buttonLabel]="'WIZARD.DOCUMENTS.ADD_DOCUMENT' | translate"
            [disabled]="creatingAcroform || creatingAnnotation || cloningDocument || deletingDocument || creatingMutualDocument
            || updatingDocument || uploadingDocument || updatingDocumentName || reuploadingDocument"
            [documents]="documentsToSignSorted"
            [isLoading]="documents.isLoadingToSign"
            [hasFailed]="documents.hasFailed"
            [noResult]="'WIZARD.DOCUMENTS.NO_RESULT' | translate"
            [signatures]="getSignatures(documents.documentToSign)"
            [signingUsers]=" signers"
            [sprofiles]="sprofiles"
            [session]="session"
            [step]="step"
            [signersByDocuments]="signersByDocument"
            [title]="'WIZARD.DOCUMENTS.TO_BE_SIGNED' | translate"
            [hasStepTagPositionSignatureLocation]="hasStepTagPositionSignatureLocation"
            [documentIdsReuploading]="getDocumentIds()"
            [progress]="getDocumentProgress()"
            [uploading]="uploadingDocument || cloningDocument"
            idSuffix="document"
          >
          </app-document-table>
        </div>

        <div class="fixed-md">
          <app-document-table
            (onClone)="cloneDocument($event, 'appendix')"
            (onDelete)="deleteAppendix($event)"
            (onMutual)="createMutualAppendix($event)"
            (onUpdate)="updateDocument($event)"
            (onUpload)="uploadAppendix($event)"
            (openModal)="updateDocumentName($event)"
            (onReuploadDoc)="reuploadDocument($event)"
            (cancelReupload)="cancelUploadDocumentRequest($event)"
            *ngIf="hasCheckStepTag && documents "
            [annexe]="true"
            [buttonLabel]="'WIZARD.DOCUMENTS.ADD_DOCUMENT' | translate"
            [disabled]="cloningAppendix || deletingAppendix || creatingMutualAppendix || updatingDocument
            || uploadingAppendix || updatingDocumentName || reuploadingDocument"
            [documents]="appendixToSeeSorted"
            [isLoading]="documents.isLoadingAppendix"
            [noResult]="'WIZARD.DOCUMENTS.NO_RESULT' | translate"
            [title]="'WIZARD.DOCUMENTS.ATTACHMENTS' | translate"
            [session]="session"
            [step]="step"
            [hasStepTagPositionSignatureLocation]="hasStepTagPositionSignatureLocation"
            [documentIdsReuploading]="getAppendixIds()"
            [progress]="getAppendixProgress()"
            [uploading]="uploadingAppendix || cloningDocument"
            idSuffix="apppendix"
          >
          </app-document-table>
        </div>
      </div>
    </div>
  </div>

  <div footer>
    <div class="fixed-container footer">
      <div class="fixed-md">
        <app-wizard-step-button [action]="stepActions.PREVIOUS"
                                [label]="'WIZARD.PREVIOUS_STEP' | translate"></app-wizard-step-button>

        <app-wizard-step-button [action]="stepActions.NEXT" [label]="'WIZARD.NEXT_STEP' | translate"
                                class="float-right"></app-wizard-step-button>
      </div>
    </div>
  </div>

</app-wizard-step>
