import {Injectable} from '@angular/core';
import {saveAs}  from 'file-saver';

@Injectable()
export class DownloadService {

  download(blob: Blob, filename?: string, mime = 'application/pdf') {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
      return;
    }

    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    if (!iOS){
      saveAs(blob,filename);
    }else{
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const dataUrl: string = <any>reader.result;
        const base64 = dataUrl.split(',')[1];

        const linkSource = 'data:' + mime + ';base64,' + base64;
        const anchor = document.createElement('a');

        document.body.appendChild(anchor);
        anchor.setAttribute('style', 'display: none');
        anchor.href = linkSource;
        anchor.download = filename;
        anchor.click();
        document.body.removeChild(anchor);
      };
    }
  }
}
