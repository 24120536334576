export enum SprofileEnum {
  // LTSP (native) signature profiles
  ORIGINAL = 'ORIGINAL', SIGNATURE = 'SIGNATURE', MUTUAL = 'MUTUAL',

  // Orely signature profiles
  ORELY_PADES = 'ORELY_PADES', ORELY_XADES_MANIFEST = 'XADES_MANIFEST', BULK_ORELY_PADES = 'BULK_ORELY_PADES',

  // Nexu profile
  NEXU_PADES = 'NEXU_PADES', BULK_NEXU_PADES = 'BULK_NEXU_PADES',

  // Itsme profile
  ITSME_PADES = 'ITSME_PADES',

  // Document operations
  MERGE_DOC = 'MERGE_DOC', MERGE_HTML = 'MERGE_HTML', ACRO_CREATE = 'ACRO_CREATE', ARCHIVING = 'ARCHIVING', ESEARCH = 'ESEARCH', CLEANING = 'CLEANING', LOCK = 'LOCK',

  // Click-to-sign signature profiles
  PADES_SEAL_SMS = 'PADES_SEAL_SMS', BULK_PADES_SEAL_SMS = 'BULK_PADES_SEAL_SMS',
  PADES_SEAL_EMAIL = 'PADES_SEAL_EMAIL', BULK_PADES_SEAL_EMAIL = 'BULK_PADES_SEAL_EMAIL',
  PADES_SEAL_EMAIL_WITHOUT_DATE = 'PADES_SEAL_EMAIL_WITHOUT_DATE', BULK_PADES_SEAL_EMAIL_WITHOUT_DATE= 'BULK_PADES_SEAL_EMAIL_WITHOUT_DATE',
  PADES_SEAL_IMAGE_EMAIL = 'PADES_SEAL_IMAGE_EMAIL',
  PADES_SEAL_OTP = 'PADES_SEAL_OTP',

  // Seal signature profiles
  PADES_SEAL = 'PADES_SEAL',

  // Visa
  VISA = 'VISA', BULK_VISA = 'BULK_VISA', VISA_WITH_IMAGE = 'VISA_WITH_IMAGE', C2S = 'C2S'
}
